import { useMutation } from "react-query";
import {
  GET_ROLES_MUTATION,
  POST_ROLES_MUTATION,
  PATCH_ROLE_MUTATION,
  DELETE_ROLE,
} from "./keys";

import rolesApi from "../services/rolesApi";

export const useRolesMutation = (history) => {
  const response = useMutation(rolesApi.rolesList, {
    mutationKey: GET_ROLES_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useCreateRoleMutation = (history) => {
  const response = useMutation(rolesApi.createRole, {
    mutationKey: POST_ROLES_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useRoleUpdateMutation = (history) => {
  const response = useMutation(rolesApi.updateRole, {
    mutationKey: PATCH_ROLE_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useRoleDeleteMutation = (history) => {
  const response = useMutation(rolesApi.deleteRole, {
    mutationKey: DELETE_ROLE,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
