import axios from "axios";
import { Saudi_Fintech_URL, FINTECH_BASE_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

export const notificationsApi = {
  // Create and update
  notificationSettings: async (body) => {
    if (body) {
      const jwt = localStorage.getItem("token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };
      const data = await request.post(`/notification-settings`, body, {
        headers: headers,
      });
      return data;
    }
  },

  // get notification settings
  getNotificationSettings: async () => {
    let resourcess = [
      {
        id: 1,
        name: "New member request",
        // description: "Team permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 2,
        name: "New team request",
        // description: "Request permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 3,
        name: "New plan request",
        // description: "Payment permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 4,
        name: "Plan invoice",
        // description: "Settings permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 5,
        name: "New tour request",
        // description: "Bookings permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 6,
        name: "New day pass request",
        // description: "Logs permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
      {
        id: 7,
        name: "Visitor alert",
        // description: "Logs permissions can be deal here",
        permissions: {
          web: false,
          email: false,
        },
      },
    ];
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/notification-settings`, {
      headers: headers,
    });

    for (const [key, value] of Object.entries(data?.data)) {
      resourcess.forEach((r) => {
        if (r.id === 1) {
          r.permissions.web = value.memberRequest.web;
          r.permissions.email = value.memberRequest.email;
        } else if (r.id === 2) {
          r.permissions.web = value.teamRequest.web;
          r.permissions.email = value.teamRequest.email;
        } else if (r.id === 3) {
          r.permissions.web = value.planRequest.web;
          r.permissions.email = value.planRequest.email;
        } else if (r.id === 4) {
          r.permissions.web = value.planInvoice.web;
          r.permissions.email = value.planInvoice.email;
        } else if (r.id === 5) {
          r.permissions.web = value.tourRequest.web;
          r.permissions.email = value.tourRequest.email;
        } else if (r.id === 6) {
          r.permissions.web = value.daypassRequest.web;
          r.permissions.email = value.daypassRequest.email;
        } else if (r.id === 7) {
          r.permissions.web = value.visitorAlert.web;
          r.permissions.email = value.visitorAlert.email;
        }
      });
    }

    return resourcess;
    // return data?.data;
  },
};

export default apiWrapper(notificationsApi, [
  "notificationSettings",
  "getNotificationSettings",
]);
