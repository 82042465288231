import { useForgotPasswordInfo } from "./useForgotPassword";
import React from "react";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import Login_Frame from "../../images/icons/Login Pic.svg";
import BottomLogo from "../../images/icons/loginBottomLogo.svg";
import TopLogo from "../../images/icons/loginTopLogo.svg";
import "./css/styles.css";
import Card from "react-bootstrap/Card";
import "font-awesome/css/font-awesome.min.css";

const ForgotPassword = (props) => {
  const {
    upcaseValid,
    lenghtValid,
    numberValid,
    validEye,
    validation,
    validEye1,
    passwordShown,
    newPassword,
    passwordShown1,
    confirmPassword,
    updatePassword,
    setNewPassword,
    setConfirmPassword,
    togglePassword,
    handleFoucs,
  } = useForgotPasswordInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='lgMain'>
            <Col md={12} lg={3} xl={5} sm={12} xs={12}>
              <img src={TopLogo} alt='NexdusuLogo' className='topLogo' />
              <img src={Login_Frame} className='loginImg' alt='NexdusuLogo' />
            </Col>
            <Col md={12} lg={9} sm={12} xs={12} xl={7}>
              <Row className='forgotpPageCard '>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
                <Col md={6} lg={6} sm={12} xs={12} xl={6}>
                  <Card className='lgCard p-4'>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h4>Setup a new password</h4>
                        <span>
                          To keep your account safe, please update your password
                        </span>
                        <Row>
                          <Col
                            md={12}
                            lg={12}
                            sm={12}
                            xs={12}
                            xl={12}
                            className='pb-3 pt-3'
                          >
                            <h5>Please follow these Guidelines</h5>
                            <li>
                              <span className={upcaseValid}>
                                Must contain an uppercase letter
                              </span>
                            </li>
                            <li>
                              <span className={lenghtValid}>
                                Must be a minimum of 8 characters long
                              </span>
                            </li>
                            <li>
                              <span className={numberValid}>
                                Must contain a number
                              </span>
                            </li>
                          </Col>
                        </Row>

                        <Form>
                          <FormGroup>
                            <h6>New Password</h6>
                            <div className='d-flex '>
                              <Input
                                id='example-date-input-password'
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder='new password'
                                className={`${validation}`}
                                onFocus={() => handleFoucs()}
                                type={passwordShown1 ? "text" : "password"}
                              />
                              <i
                                onClick={() => togglePassword(1)}
                                className={`fa ${validEye1} ${
                                  !passwordShown1 ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                            </div>
                            <h6>Confirm New Password</h6>
                            <div className='d-flex '>
                              <Input
                                id='example-date-input-password'
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                placeholder='confirm password'
                                className={`${validation}`}
                                onFocus={() => handleFoucs()}
                                type={passwordShown ? "text" : "password"}
                              />
                              <i
                                onClick={() => togglePassword(2)}
                                className={`fa ${validEye} ${
                                  !passwordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                            </div>
                          </FormGroup>

                          <Button
                            className='waves-effect waves-light w-100 lgbtn '
                            disabled={
                              confirmPassword === "" ||
                              newPassword === "" ||
                              confirmPassword !== newPassword
                                ? true
                                : false
                            }
                            onClick={() => {
                              updatePassword(true);
                            }}
                          >
                            Set Password
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} xxl={12}>
            <img src={BottomLogo} alt='NexudusLogo' className='bottomLogo' />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ForgotPassword;
