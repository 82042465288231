import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import { useSpring } from "react-spring";
import usersApi from "../../../services/usersApi";
import teamsApi from "../../../services/teamsApi";
import { useQuery } from "react-query";
import "../css/styles.css";

export const useTeamsDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [image, setImage] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [divShowAccor, setDivShowAccor] = useState({
    1: true,
    2: true,
  });
  const [open, setOpen] = useState(false);

  const { data: userData, isLoading: userLoading } = useQuery(
    ["membershipUserData"],
    () => usersApi.FindUserById(data?.userId),
    {
      retry: 0,
    }
  );

  const { data: teamInvoices, isLoading: teamInvoicesLoading } = useQuery(
    ["teamInvoices"],
    () => teamsApi.getTeamInvoices(data?.NexudusTeamId),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //toggle accordion function
  let toggleHandler = (e) => {
    if (e?.target?.id === "1") {
      setDivShowAccor({ 1: !divShowAccor[1], 2: true });
    } else if (e?.target?.id === "2") {
      setDivShowAccor({ 1: true, 2: !divShowAccor[2] });
    } else {
      setDivShowAccor({ 1: true, 2: true });
    }
    setOpen(!open);
  };

  const openAnimation = useSpring({
    config: { duration: "300" },
  });

  return {
    data,
    userData,
    openAnimation,
    divShowAccor,
    modelShow,
    showImg,
    image,
    teamInvoices,
    setShowImg,
    setImage,
    setModelShow,
    navigate,
    toggleHandler,
  };
};
