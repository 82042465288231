import { useState, useEffect } from "react";
import accessControl from "../../../services/accessControlApi";
import { useQuery, useQueryClient } from "react-query";
import {
  useAddNewDepartmentsMutation,
  useDeleteDepartmentMutation,
  useUpdateDepartmentsMutation,
} from "../../../rc-query/accessControlHook";
import { toast } from "react-toastify";

export const useDepartmentsInfo = (props) => {
  const [departmentModel, setDepartmentModel] = useState(false);
  const [updateDepartModel, setUpdateDepartModel] = useState(false);
  const [departDetailShow, setDepartDetailShow] = useState(false);
  const [departmentData, setDepartmentData] = useState();
  const [addTimeModel, setAddtimeModel] = useState(false);
  const [approvalModel, setApprovalModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departmentId, setDepartmentId] = useState(false);
  const QueryClient = useQueryClient();
  const addDepartmentMutation = useAddNewDepartmentsMutation(props.history);
  const updateDepartmentMutation = useUpdateDepartmentsMutation(props.history);
  const deleteDepartmentMutation = useDeleteDepartmentMutation(props.history);

  const { data: departmentLists, isLoading: departmentListsLoading } = useQuery(
    ["departmentsList"],
    () => accessControl.getDepartments(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addNewDepartment = (data) => {
    setLoading(true);
    addDepartmentMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Department added");
        }

        QueryClient.invalidateQueries("departmentsList");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error to create");
        setLoading(false);
      });
    setDepartmentModel(false);
  };

  const updateDepartment = (data) => {
    setLoading(true);

    const updateData = {
      bodyRequest: data,
      Id: departmentId,
    };

    updateDepartmentMutation
      .mutateAsync(updateData)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Department updated");
        }

        QueryClient.invalidateQueries("departmentsList");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error to create");
        setLoading(false);
      });
    setUpdateDepartModel(false);
  };

  const handleDeleteDepartment = () => {
    setLoading(true);
    deleteDepartmentMutation
      .mutateAsync(departmentId)
      .then((data) => {
        if (data?.status === 204) {
          toast.success("Department deleted");
        }
        QueryClient.invalidateQueries("departmentsList");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error to delete");
        setLoading(false);
      });
    setApprovalModel(false);
  };

  return {
    approvalModel,
    loading,
    departmentModel,
    updateDepartModel,
    departmentLists,
    departmentListsLoading,
    departmentId,
    departDetailShow,
    departmentData,
    setDepartmentData,
    setDepartDetailShow,
    updateDepartment,
    handleDeleteDepartment,
    setDepartmentId,
    addNewDepartment,
    setDepartmentModel,
    setUpdateDepartModel,
    setLoading,
    setApprovalModel,
  };
};
