import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import {
  usePasswordOtpMutation,
  usePasswordMutation,
} from "../../rc-query/usersHook";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

export const useChangePasswordInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [otpShow, setotpShow] = useState(false);
  const [OTP, setOTP] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [lenghtValid, setLenghtValid] = useState("");
  const [numberValid, setNumberValid] = useState("");
  const [upcaseValid, setUpcaseValid] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [incorrect, setIncorrect] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("password-field");
  const [displayError, setDisplayError] = useState("hideError");
  const otpMutation = usePasswordOtpMutation(props.history);
  const changePasswordMutation = usePasswordMutation(props.history);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (reEnterPassword === "" || oldPassword === "" || newPassword === "") {
      setIncorrect(true);
    } else if (newPassword === reEnterPassword) {
      setDisplayError("hideError");
      setConfirmPassword("password-field");
      setIncorrect(false);
    } else {
      setDisplayError("displayError");
      setConfirmPassword("invalid");
      setIncorrect(true);
    }

    if (newPassword.length < 8 && newPassword !== "") {
      setLenghtValid("invalidSpan");
      setIncorrect(true);
    } else {
      setLenghtValid("");
    }
    if (!/[A-Z]/.test(newPassword) && newPassword !== "") {
      setUpcaseValid("invalidSpan");
      setIncorrect(true);
    } else {
      setUpcaseValid("");
    }
    if (!/\d/.test(newPassword) && newPassword !== "") {
      setNumberValid("invalidSpan");
      setIncorrect(true);
    } else {
      setNumberValid("");
    }
  }, [oldPassword, newPassword, reEnterPassword]);

  const otpRequest = (value) => {
    const data = {
      newPassword: newPassword,
      oldPassword: oldPassword,
    };

    otpMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Check Your Email", {
            appearance: "success",
            autoDismiss: true,
          });
          setotpShow(value);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          addToast("Invalid old password", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const passwordChange = (OTP) => {
    const data = {
      otp: OTP,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };

    changePasswordMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Password Update Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
        } else {
          addToast(data?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          addToast("Password not matched", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(error?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return {
    otpShow,
    confirmPassword,
    incorrect,
    displayError,
    upcaseValid,
    lenghtValid,
    numberValid,
    setotpShow,
    otpRequest,
    passwordChange,
    setNewPassword,
    setReEnterPassword,
    setOldPassword,
    setOTP,
  };
};
