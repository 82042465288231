import { useState } from "react";
import accessControl from "../../../services/accessControlApi";
import { useQuery, useQueryClient } from "react-query";

export const useAccessLevelInfo = (props) => {
  const [accessLevelModel, setAccessLevelModel] = useState(false);
  const [updateLevelModel, setUpdateLevelModel] = useState(false);
  const [addTimeModel, setAddtimeModel] = useState(false);
  const [approvalModel, setApprovalModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: accessLevel, isLoading: accessLevelLoading } = useQuery(
    ["accessLevel"],
    () => accessControl.getAccessLevel(),
    {
      retry: 0,
    }
  );

  return {
    accessLevelModel,
    updateLevelModel,
    approvalModel,
    loading,
    accessLevel,
    accessLevelLoading,
    setLoading,
    setApprovalModel,
    setUpdateLevelModel,
    setAccessLevelModel,
  };
};
