import { useMutation } from "react-query";
import {
  POST_TOUR_SETTING,
  POST_TOUR_SLOTS,
  ARCHIVED_ALL_TOUR_SLOTS,
  ARCHIVE_ONE_TOUR_SLOT,
  POST_TOUR_ENABLE,
  UPDATE_HOLIDAY_SETTING,
  ADD_HOLIDAY_SETTING,
} from "./keys";

import tourSetting from "../services/tourSettingApi";

export const useToursSettingMutation = (history) => {
  const response = useMutation(tourSetting.postTourSetting, {
    mutationKey: POST_TOUR_SETTING,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useToursEnableMutation = (history) => {
  const response = useMutation(tourSetting.postTourEnable, {
    mutationKey: POST_TOUR_ENABLE,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useToursSlotsMutation = (history) => {
  const response = useMutation(tourSetting.postTourSlots, {
    mutationKey: POST_TOUR_SLOTS,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useArchivedToursSlotsMutation = (history) => {
  const response = useMutation(tourSetting.archivedAllSlots, {
    mutationKey: ARCHIVED_ALL_TOUR_SLOTS,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useArchiveToursSlotMutation = (history) => {
  const response = useMutation(tourSetting.archiveOneSlot, {
    mutationKey: ARCHIVE_ONE_TOUR_SLOT,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useAddHolidaySettingMutation = (history) => {
  const response = useMutation(tourSetting.createHoliday, {
    mutationKey: ADD_HOLIDAY_SETTING,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useUpdateHolidaySettingMutation = (history) => {
  const response = useMutation(tourSetting.updateHoliday, {
    mutationKey: UPDATE_HOLIDAY_SETTING,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
