import React from "react";
import { GrFormClose } from "react-icons/gr";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import usersApi from "../../../services/usersApi";
import { useQuery } from "react-query";
import "./css/styles.css";

const TourDetailModel = (props) => {
  const { data: userData, isLoading: userLoading } = useQuery(
    ["tourCalenderUserData"],
    () => usersApi.FindUserById(props.userData?.userId),
    {
      retry: 0,
    }
  );

  return (
    <>
      {props.userData ? (
        !userLoading ? (
          <Modal isOpen={props.show} centered={true}>
            <div className='d-body'>
              <Row className='rejection-reason-model'>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <ModalBody>
                    <Row>
                      <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                        <GrFormClose
                          size={25}
                          onClick={() => {
                            props.setModelShow(false);
                          }}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Col>

                      <Row className='tourEventModel'>
                        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                          <h5>Personal Details</h5>
                          <hr />
                        </Col>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>First Name</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info '>
                              <h6>{userData?.data?.firstName}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Last Name</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{userData?.data?.lastName}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Phone Number</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{userData?.data?.phoneNumber}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Email</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{userData?.data?.email}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Slot</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{`${props.userData?.slotTime?.startTime} - ${props.userData?.slotTime?.endTime}`}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Linkedin</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>
                                <a
                                  href={userData?.data?.linkedin}
                                  target='_blank'
                                  className='mob-anchor'
                                >
                                  <h6>
                                    {userData?.data?.linkedin.split("in")[3]}
                                  </h6>
                                </a>
                              </h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Twitter</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>
                                <a
                                  href={userData?.data?.twitter}
                                  target='_blank'
                                  className='mob-anchor'
                                >
                                  <h6>
                                    {userData?.data?.twitter.split("com")[1]}{" "}
                                  </h6>
                                </a>
                              </h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Occupation</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{userData?.data?.occupation}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className='tour-user-details'>
                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='info-heading'>
                              <h6>Date</h6>
                            </div>
                          </Col>

                          <Col md={6} lg={6} sm={6} xs={6} xl={6}>
                            <div className='d-info tour-card-info'>
                              <h6>{props.userData?.CreatedDate}</h6>
                            </div>
                          </Col>
                        </Row>
                        <Row></Row>
                        <Row>
                          <Col></Col>
                        </Row>
                      </Row>
                    </Row>
                  </ModalBody>
                </Col>
              </Row>
            </div>
          </Modal>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

export default TourDetailModel;
