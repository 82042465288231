import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useQuery } from "react-query";
import rolesList from "../../../services/rolesApi";
import "../css/styles.css";

export const useUserUsersInfo = (props) => {
  const navigate = useNavigate();

  const { data: roleLists, isLoading: roleListsLoading } = useQuery(
    ["userRoleData"],
    () => rolesList.rolesList(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const details = (row) => {
    navigate("/settings/update-roles", {
      state: {
        rowData: row,
      },
    });
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "name",
      text: "Role",
      sort: true,
      style: { width: "28%", padding: "15px 10px" },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "resources",
      text: "Permissions",
      sort: true,
      formatter: (cell) => Object.keys(cell).join(", "),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    columns,
    roleLists,
    defaultSorted,
    roleListsLoading,
    NoDataIndication,
  };
};
