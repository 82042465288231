import { useState, useEffect } from "react";
import {
  useToursSettingMutation,
  useToursSlotsMutation,
  useArchivedToursSlotsMutation,
  useArchiveToursSlotMutation,
  useToursEnableMutation,
  useUpdateHolidaySettingMutation,
  useAddHolidaySettingMutation,
} from "../../rc-query/tourSettingHook";
import { useSpring } from "react-spring";
import moment from "moment/moment";
import tourSetting from "../../services/tourSettingApi";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import close from "../../images/icons/close.svg";

export const useToursSettingInfo = (props) => {
  const { addToast } = useToasts();
  const [divShowAccor, setDivShowAccor] = useState({
    1: true,
    2: true,
  });
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [archiveAll, setArchiveAll] = useState(false);
  const [disableTour, setDisableTour] = useState(false);
  const [enableTour, setEnableTour] = useState(false);
  const [holidayModel, setHolidayModel] = useState(false);
  const [updateHoliday, setUpdateHoliday] = useState(false);
  const [holidayData, setHolidayData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeModel, setRemoveModel] = useState(false);
  const [saveBtn, setSaveBtn] = useState("tours-setting-hide-btn");
  const [tourRequestEnable, setTourRequestEnable] = useState();
  const [tourDuration, setTourDuration] = useState();
  const [slotDuration, setSlotDuration] = useState();
  const [slotId, setSlotId] = useState();
  const [maxTourPerSlot, setMaxTourPerSlot] = useState();
  const [perMonthTour, setPerMonthTour] = useState();
  const [disableSetting, setDisableSetting] = useState("");
  const tourSettingMutation = useToursSettingMutation(props.history);
  const tourSlotsMutation = useToursSlotsMutation(props.history);
  const archivedAllSlotsMutation = useArchivedToursSlotsMutation(props.history);
  const archiveOneSlotMutation = useArchiveToursSlotMutation(props.history);
  const tourEnableMutation = useToursEnableMutation(props.history);
  const updateHolidayMuation = useUpdateHolidaySettingMutation(props.history);
  const addHolidayMutation = useAddHolidaySettingMutation(props.history);
  const QueryClient = useQueryClient();

  const { data: existedTour, isLoading: settingLoading } = useQuery(
    ["toursSetting"],
    () => tourSetting.getTourSetting(),
    {
      retry: 0,
    }
  );

  const { data: nexudusTime, isLoading: nexudusTimeLoading } = useQuery(
    ["nexudusTime"],
    () => tourSetting.getOpeningTime(),
    {
      retry: 0,
    }
  );

  const { data: tourSlots, isLoading: tourSlotsLoading } = useQuery(
    ["tourSlots", removeModel],
    () => tourSetting.getTourSlots(),
    {
      retry: 0,
    }
  );

  const openAnimation = useSpring({
    config: { duration: "300" },
  });

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //toggle accordion function
  let toggleHandler = (e) => {
    if (e === "1") {
      setDivShowAccor({ 1: !divShowAccor[1], 2: true });
    } else if (e === "2") {
      setDivShowAccor({ 1: true, 2: !divShowAccor[2] });
    } else {
      setDivShowAccor({ 1: true, 2: true });
    }
    setOpen(!open);
  };

  useEffect(() => {
    setTourDuration(existedTour?.tourDuration);
    setSlotDuration(existedTour?.tourDuration);
    setTourRequestEnable(existedTour?.tourEnable);
    setMaxTourPerSlot(existedTour?.maxTourPerSlot);
    setPerMonthTour(existedTour?.toursPerMonth);

    // if (existedTour?.tourEnable) {
    //   setDisableSetting("");
    // } else {
    //   setDisableSetting("disabledDiv");
    // }
  }, [settingLoading]);

  const addSlotFunc = (value) => {
    if (tourDuration && maxTourPerSlot && perMonthTour) {
      setModelShow(value);
    } else {
      addToast("Kindly add all fields ", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };
  const removeSlotFunc = (value, id, day) => {
    setSlotId(id);
    setRemoveModel(value);
  };

  // Add Holiday api
  const addHolidayFunc = (requestData) => {
    addHolidayMutation.mutateAsync(requestData).then((data) => {
      if (data?.statusCode === 200) {
        addToast("Holiday added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        toggleHandler("2");
      }

      QueryClient.invalidateQueries("toursSetting");
    });

    setHolidayModel(false);
  };

  const handleupdateholidayCard = (data) => {
    setHolidayData(data);
    setUpdateHoliday(true);
  };

  // update Holiday api
  const updateHolidayFunc = (requestData, id) => {
    const data = {
      body: requestData,
      holidayId: id,
    };

    updateHolidayMuation.mutateAsync(data).then((data) => {
      if (data?.statusCode === 200) {
        addToast(data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
      }
      QueryClient.invalidateQueries("toursSetting");
    });
    setUpdateHoliday(false);
  };

  const archiveOneSlot = () => {
    archiveOneSlotMutation.mutateAsync(slotId).then((data) => {
      if (data?.status === 200) {
        addToast("Successfully Remove Tour Slot", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      QueryClient.invalidateQueries("tourSlots");
    });

    setRemoveModel(false);
  };

  const addSlots = (slotsData) => {
    tourSlotsMutation.mutateAsync(slotsData).then((data) => {
      if (data?.status === 200) {
        addToast("Successfully Added Tour Settings", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      QueryClient.invalidateQueries("tourSlots");
      QueryClient.invalidateQueries("toursSetting");
    });
  };

  const saveTourSetting = () => {
    if (!tourDuration || !maxTourPerSlot || !perMonthTour) {
      addToast("Kindly add all fields", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      const settingData = {
        maxTourPerSlot: maxTourPerSlot,
        tourDuration: tourDuration,
        toursPerMonth: perMonthTour,
      };

      setLoading(true);

      tourSettingMutation
        .mutateAsync(settingData)
        .then((data) => {
          if (data?.status === 200) {
            addToast("Successfully Added Tour Settings", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            addToast(error?.response?.data?.error?.message, {
              appearance: "warning",
              autoDismiss: true,
            });
          } else {
            addToast("Network error", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });

      QueryClient.invalidateQueries("toursSetting");
      QueryClient.invalidateQueries("tourSlots");
      setLoading(false);
    }
  };

  const disableTourFunc = () => {
    const settingData = {
      tourEnable: tourRequestEnable,
    };

    setLoading(true);

    tourEnableMutation
      .mutateAsync(settingData)
      .then((data) => {
        if (data?.status === 200 && tourRequestEnable === true) {
          addToast("Tour successfully enable", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (data?.status === 200 && tourRequestEnable === false) {
          addToast("Tour successfully disable", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          addToast(error?.response?.data?.error?.message, {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          addToast("Network error", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });

    QueryClient.invalidateQueries("toursSetting");
    QueryClient.invalidateQueries("tourSlots");
    setLoading(false);

    setEnableTour(false);
    setDisableTour(false);
  };

  const tourSlotTimes = () => {
    const tableSize = 840 / slotDuration;

    let firstStart = moment(nexudusTime?.openTime, "hh:mm A");
    let oneHour = moment(nexudusTime?.openTime, "hh:mm A");
    let secondStart = moment(nexudusTime?.openTime, "h:mm:ss A")
      .add(30, "minutes")
      .format("hh:mm A");

    let daySlotIndex = 0;
    let dayFound = false;

    let slots = [];
    let slotsDisplayed = [];
    const week = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    for (let i = 0; i < tableSize; i++) {
      slots.push(
        <tr key={i}>
          {week.map((day, dayIndex) => {
            dayFound = false;
            tourSlots?.map((timeSlots, index) => {
              if (!slotsDisplayed?.includes(timeSlots?.id)) {
                if (slotDuration === 30) {
                  if (
                    moment(timeSlots?.startTime, "h:mm:ss A").isSame(
                      moment(firstStart, "h:mm:ss A")
                    ) &&
                    timeSlots?.archived === false &&
                    timeSlots?.tourDay === day
                  ) {
                    daySlotIndex = index;
                    dayFound = true;
                    slotsDisplayed.push(timeSlots?.id);
                    return true;
                  }
                } else {
                  if (
                    moment(timeSlots?.startTime, "h:mm:ss A").isSame(
                      moment(firstStart, "h:mm:ss A")
                    ) &&
                    timeSlots?.archived === false &&
                    timeSlots?.tourDay === day
                  ) {
                    daySlotIndex = index;
                    dayFound = true;
                    slotsDisplayed.push(timeSlots?.id);
                  } else if (
                    moment(timeSlots?.startTime, "h:mm:ss A").isSame(
                      moment(secondStart, "h:mm:ss A")
                    ) &&
                    timeSlots?.archived === false &&
                    timeSlots?.tourDay === day
                  ) {
                    daySlotIndex = index;
                    dayFound = true;
                    slotsDisplayed.push(timeSlots?.id);
                  } else if (
                    moment(timeSlots?.startTime, "h:mm:ss A").isSame(
                      moment(oneHour, "h:mm:ss A")
                    ) &&
                    timeSlots?.archived === false &&
                    timeSlots?.tourDay === day
                  ) {
                    daySlotIndex = index;
                    dayFound = true;
                    slotsDisplayed.push(timeSlots?.id);
                  }
                }
              }
            });

            let slotId = daySlotIndex;

            return dayFound ? (
              <td key={tourSlots[slotId]?.id}>
                <div
                  className='tourSlotDiv table-responsive'
                  // key={tourSlots[daySlotIndex]?.id}
                >
                  {`${tourSlots[daySlotIndex]?.startTime} - ${tourSlots[daySlotIndex]?.endTime} `}
                  <img
                    src={close}
                    alt='cross icon'
                    className='close-img'
                    onClick={() => {
                      removeSlotFunc(
                        true,
                        tourSlots[slotId]?.id,
                        tourSlots[slotId]?.tourDay
                      );
                    }}
                  />
                </div>
              </td>
            ) : (
              <td className='active-tabledata' key={dayIndex}></td>
            );
          })}
        </tr>
      );

     

      firstStart = moment(firstStart, "h:mm:ss A")
        .add(slotDuration, "minutes")
        .format("hh:mm A");
      oneHour = moment(oneHour, "h:mm:ss A")
        .add(60, "minutes")
        .format("hh:mm A");

      secondStart = moment(secondStart, "h:mm:ss A")
        .add(slotDuration, "minutes")
        .format("hh:mm A");
    }

    return slots;
  };

  // const tourSlotTimes = () => {
  //   const tableSize = 840 / slotDuration;

  //   let startTimes = [];
  //   let currentTime = moment(nexudusTime?.openTime, "hh:mm A");

  //   for (let i = 0; i < tableSize; i++) {
  //     startTimes.push(currentTime.format("hh:mm A"));
  //     currentTime.add(slotDuration, "minutes");
  //   }

  //   const week = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];

  //   const slots = startTimes.map((startTime, rowIndex) => (
  //     <tr key={rowIndex}>
  //       {week.map((day, dayIndex) => {
  //         const matchingSlot = tourSlots?.find(
  //           (timeSlot) =>
  //             moment(timeSlot?.startTime, "h:mm:ss A").isSame(
  //               moment(startTime, "h:mm A")
  //             ) &&
  //             timeSlot?.archived === false &&
  //             timeSlot?.tourDay === day
  //         );

  //         return matchingSlot ? (
  //           <td key={matchingSlot.id}>
  //             <div className='tourSlotDiv table-responsive'>
  //               {`${matchingSlot.startTime} - ${matchingSlot.endTime} `}
  //               <img
  //                 src={close}
  //                 alt='cross icon'
  //                 className='close-img'
  //                 onClick={() => {
  //                   removeSlotFunc(true, matchingSlot.id, matchingSlot.tourDay);
  //                 }}
  //               />
  //             </div>
  //           </td>
  //         ) : (
  //           <td className='active-tabledata' key={dayIndex}></td>
  //         );
  //       })}
  //     </tr>
  //   ));

  //   return slots;
  // };

  const archivedAllSlots = () => {
    setLoading(true);

    archivedAllSlotsMutation
      .mutateAsync()
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully slots archived", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("toursSetting");
          QueryClient.invalidateQueries("tourSlots");
        }
      })
      .catch((error) => {
        addToast("Network Error", {
          appearance: "error",
          autoDismiss: true,
        });
      });

    setArchiveAll(false);
    setLoading(false);
  };

  return {
    modelShow,
    tourRequestEnable,
    tourDuration,
    maxTourPerSlot,
    perMonthTour,
    removeModel,
    loading,
    tourSlotsLoading,
    saveBtn,
    disableSetting,
    existedTour,
    disableTour,
    enableTour,
    openAnimation,
    divShowAccor,
    archiveAll,
    holidayModel,
    existedTour,
    updateHoliday,
    holidayData,
    handleupdateholidayCard,
    setHolidayData,
    updateHolidayFunc,
    setUpdateHoliday,
    addHolidayFunc,
    setHolidayModel,
    setArchiveAll,
    toggleHandler,
    disableTourFunc,
    setEnableTour,
    setDisableTour,
    setDisableSetting,
    archiveOneSlot,
    setSaveBtn,
    addSlots,
    tourSlotTimes,
    archivedAllSlots,
    saveTourSetting,
    setTourRequestEnable,
    setTourDuration,
    setMaxTourPerSlot,
    setPerMonthTour,
    removeSlotFunc,
    addSlotFunc,
  };
};
