import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import { Accordion } from "react-bootstrap-accordion";

import "react-bootstrap-accordion/dist/index.css";
import "../.././css/styles.css";
import { Link } from "react-router-dom";
import UseMembershipTypeSettingDetail from "./useMembershipTypeSettingDetail";

const MemberShipSettingDetail = () => {
  const { dmemberShipDetailsData, newresourceTypeData, planId } =
    UseMembershipTypeSettingDetail();

   

  return (
    <div className='pt-4 px-4 mts_main_bg'>
      <Card>
        <Row className='p-4'>
          
          <Col xl={2} lg={2} md={2} sm={12} xs={12}>
            <img
              src={dmemberShipDetailsData?.ResourceImage}
              alt='img'
              style={{ width: 150, height: 120 }}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <p className='m_d_name_h1'>
              <b> {dmemberShipDetailsData?.ResourceName}</b>
            </p>
            <Row>
              <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                <span className='m_d_name_h2'>Starting Month</span>
                <Button className=' lg-btn1 w-100 month_payment_btns mt-2'>
                  <span className='month_payment_btn_h1'>
                    {dmemberShipDetailsData?.startMonth === 1 ? (
                      "Next Month"
                    ) : (
                      <span>
                        <span>Flexible</span>
                        <span className='month_payment_btn_h2 mx-2'>
                          `{dmemberShipDetailsData?.startMonth}
                          {""} months
                        </span>
                      </span>
                    )}
                  </span>
                </Button>
              </Col>

              <Col xl={4} lg={4} md={4} sm={7} xs={7}>
                <span className='m_d_name_h2'>Payment & Duration</span>
                <Button className='  lg-btn1 w-100 month_payment_btns m-2'>
                  <span className='month_payment_btn_h1'>
                    Monthly
                    <span className='month_payment_btn_h2 mx-2'>
                      `{dmemberShipDetailsData?.monthRange[0]} Min
                    </span>
                    |
                    <span className='month_payment_btn_h2 mx-2'>
                      {dmemberShipDetailsData?.monthRange[1]} Max
                    </span>
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xl={2} lg={2} md={2} sm={12} xs={12}>
            <Link to={`/membership-update-setting/${planId?.id}`}>
              <Button className='edit_button_align waves-effect waves-light lg-btn1 w-100'>
                Edit
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>

      {/* for private office  */}
      {dmemberShipDetailsData?.ResourcePlan?.resources ? (
        <Row className='mt-4 dropDown'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Accordion
              className='w-100 mt-3 member_detail '
              title={
                <span className='w-100'>
                  <Row className='accordation_row   w-100'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <span className='private_Office_occor mt-1 mx-2'>
                        <b>
                          {" "}
                          {newresourceTypeData && newresourceTypeData[0]?.label}
                          (
                          <span className=' private_Office_occor1'>
                            {
                              dmemberShipDetailsData?.ResourcePlan?.resources
                                ?.length
                            }
                          </span>
                          )
                        </b>
                      </span>
                    </Col>
                  </Row>
                  {/* // start   */}
                  <Row className='mt-3 w-100 p-2'>
                    <Col xl={1} lg={1} md={1} sm={10} xs={10}>
                      <p className='p_o_h1 d-flex  mt-4 '>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan?.resources[0]
                            ?.resourceName
                        }{" "}
                      </p>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1    textSizeAdjust'>
                          Team Lead Monthly
                        </p>
                        <p className='p_o_h11 '>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.resources[0]
                              ?.TeamLeadMonthlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1  textSizeAdjust'>
                          Team Lead Yearly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.resources[0]
                              ?.TeamLeadYearlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className=' p_o_h_padding'>
                        <p className=' mt-1  textSizeAdjust'>
                          Team Member Monthly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.resources[0]
                              ?.TeamMemberMonthlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1  textSizeAdjust'>
                          Team Member Yearly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.resources[0]
                              ?.TeamMemberYearlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                  </Row>
                  {/* end  */}
                </span>
              }
            >
              {dmemberShipDetailsData?.ResourcePlan?.resources
                ?.slice(1)
                .map((e, index) => {
                  // const e = ele?.slice(1);

                  return (
                    <Row className='mt-3 w-100 p-2 ' key={index + 1}>
                      <Col xl={1} lg={1} md={1} sm={10} xs={10}>
                        <span className='p_o_h1 d-flex  mt-4 '>
                          {" "}
                          {e?.resourceName}{" "}
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Lead Monthly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamLeadMonthlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Lead Yearly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamLeadYearlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className=' p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Member Monthly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamMemberMonthlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Member Yearly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamMemberYearlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                    </Row>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {/* for dedicated office  */}
      {dmemberShipDetailsData?.ResourcePlan?.TeamLeadMonthlyPlan ? (
        <Row className='mt-4 dropDown'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className='w-100 mt-3 member_detail '>
              <span className='w-100 p-4'>
                <Row className='accordation_row   w-100'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className='private_Office_occor mt-1 mx-2'>
                      <b>
                        {" "}
                        {newresourceTypeData && newresourceTypeData[0]?.label}
                      </b>
                    </span>
                  </Col>
                </Row>
                <Row className='mt-3 w-100 p-2 '>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2 d-flex  mt-1 '>
                        Team Lead Monthly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {
                          dmemberShipDetailsData?.ResourcePlan
                            ?.TeamLeadMonthlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2  d-flex mt-1'>
                        Team Lead Yearly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {
                          dmemberShipDetailsData?.ResourcePlan
                            ?.TeamLeadYearlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className=' p_o_h_padding'>
                      <span className='p_o_h2 d-flex  mt-1'>
                        Team Member Monthly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan
                            ?.TeamMemberMonthlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2 d-flex   mt-1'>
                        Team Member Yearly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan
                            ?.TeamMemberYearlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                </Row>
              </span>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {/* for hybrid plan private office */}
      {dmemberShipDetailsData?.ResourcePlan?.[
        Number(process.env.REACT_APP_PRIVATE_OFFICE)
      ] ? (
        <Row className='mt-4 dropDown'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Accordion
              // style={{ backgroundColor: "yellow" }}
              className='w-100 mt-3 member_detail '
              title={
                <span className='w-100'>
                  <Row className='accordation_row   w-100'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <span className='private_Office_occor mt-1 mx-2'>
                        <b>
                          {" "}
                          Private Office (
                          <span className=' private_Office_occor1'>
                            {
                              dmemberShipDetailsData?.ResourcePlan?.[
                                Number(process.env.REACT_APP_PRIVATE_OFFICE)
                              ]?.resources?.length
                            }
                          </span>
                          )
                        </b>
                      </span>
                    </Col>
                  </Row>
                  {/* // start   */}

                  <Row className='mt-3 w-100 p-2'>
                    <Col xl={1} lg={1} md={1} sm={10} xs={10}>
                      <span className='p_o_h1 d-flex  mt-4 '>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan?.[
                            Number(process.env.REACT_APP_PRIVATE_OFFICE)
                          ]?.resources[0]?.resourceName
                        }{" "}
                      </span>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1 textSizeAdjust'>
                          {" "}
                          Team Lead Monthly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.[
                              Number(process.env.REACT_APP_PRIVATE_OFFICE)
                            ]?.resources[0]?.TeamLeadMonthlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1 textSizeAdjust'>
                          {" "}
                          Team Lead Yearly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.[
                              Number(process.env.REACT_APP_PRIVATE_OFFICE)
                            ]?.resources[0]?.TeamLeadYearlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <span className=' p_o_h_padding'>
                        <p className=' mt-1 textSizeAdjust'>
                          {" "}
                          Team Member Monthly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.[
                              Number(process.env.REACT_APP_PRIVATE_OFFICE)
                            ]?.resources[0]?.TeamMemberMonthlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                      <span className='p_o_h_padding'>
                        <p className=' mt-1 textSizeAdjust'>
                          {" "}
                          Team Member Yearly
                        </p>
                        <p className='p_o_h11'>
                          {
                            dmemberShipDetailsData?.ResourcePlan?.[
                              Number(process.env.REACT_APP_PRIVATE_OFFICE)
                            ]?.resources[0]?.TeamMemberYearlyPlan?.name
                          }
                        </p>
                      </span>
                    </Col>
                  </Row>
                  {/* //     );
                //   }
                // )} */}
                  {/* end  */}
                </span>
              }
            >
              {dmemberShipDetailsData?.ResourcePlan?.[
                Number(process.env.REACT_APP_PRIVATE_OFFICE)
              ]?.resources
                ?.slice(1)
                .map((e, index) => {
                  // const e = ele?.slice(1);

                  return (
                    <Row className='mt-3 w-100 p-2 ' key={index + 1}>
                      <Col xl={1} lg={1} md={1} sm={10} xs={10}>
                        <span className='p_o_h1 d-flex  mt-4 '>
                          {" "}
                          {e?.resourceName}{" "}
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Lead Monthly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamLeadMonthlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Lead Yearly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamLeadYearlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <span className=' p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Member Monthly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamMemberMonthlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                        <span className='p_o_h_padding'>
                          <p className=' mt-1 textSizeAdjust'>
                            Team Member Yearly
                          </p>
                          <p className='p_o_h11'>
                            {e?.TeamMemberYearlyPlan?.name}
                          </p>
                        </span>
                      </Col>
                    </Row>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {/* for hybrid office dedicated office  */}
      {dmemberShipDetailsData?.ResourcePlan?.[
        Number(process.env.REACT_APP_DEDICATED_OFFICE)
      ]?.resources?.TeamLeadMonthlyPlan ? (
        <Row className='mt-4 dropDown'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className='w-100 mt-3 member_detail '>
              <span className='w-100 p-4'>
                <Row className='accordation_row   w-100'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className='private_Office_occor mt-1 mx-2'>
                      <b>Dedicated</b>
                    </span>
                  </Col>
                </Row>
                <Row className='mt-3 w-100 p-2 '>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2 d-flex  mt-1 '>
                        Team Lead Monthly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {
                          dmemberShipDetailsData?.ResourcePlan?.[
                            Number(process.env.REACT_APP_DEDICATED_OFFICE)
                          ]?.resources?.TeamLeadMonthlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2  d-flex mt-1'>
                        Team Lead Yearly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {
                          dmemberShipDetailsData?.ResourcePlan?.[
                            Number(process.env.REACT_APP_DEDICATED_OFFICE)
                          ]?.resources?.TeamLeadYearlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className=' p_o_h_padding'>
                      <span className='p_o_h2 d-flex  mt-1'>
                        Team Member Monthly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan?.[
                            Number(process.env.REACT_APP_DEDICATED_OFFICE)
                          ]?.resources?.TeamMemberMonthlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                    <span className='p_o_h_padding'>
                      <span className='p_o_h2 d-flex   mt-1'>
                        Team Member Yearly
                      </span>
                      <p className=' mt-1 p_o_h11'>
                        {" "}
                        {
                          dmemberShipDetailsData?.ResourcePlan?.[
                            Number(process.env.REACT_APP_DEDICATED_OFFICE)
                          ]?.resources?.TeamMemberYearlyPlan?.name
                        }
                      </p>
                    </span>
                  </Col>
                </Row>
              </span>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};

export default MemberShipSettingDetail;
