import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const templateApi = {
  createTemplate: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.post(
      "/rejection-templates",
      {
        reasons: body.reasons,
      },
      {
        headers: headers,
      }
    );

    return data;
  },

  templateLists: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      "/rejection-templates",

      {
        headers: headers,
      }
    );

    return data;
  },

  updateTemplate: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/rejection-templates/${body.id}`,
      {
        reasons: body.reasons,
      },
      {
        headers: headers,
      }
    );

    return data;
  },

  deleteTemplate: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/delete-rejection-templates/${body.id}`,
      {
        reasons: body.reasons,
      },
      {
        headers: headers,
      }
    );

    return data;
  },
};

export default apiWrapper(templateApi, [
  "createTemplate",
  "templateLists",
  "updateTemplate",
  "deleteTemplate",
]);
