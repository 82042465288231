import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import rolesApi from "../../../services/rolesApi";
import { useQuery } from "react-query";
import "./css/styles.css";

const AddMember = (props) => {
  const addMember = () => {};
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='add-memeber-card'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='add-member-col'
                  >
                    <div>
                      <h5>Add Member</h5>
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => props?.setModelShow(false)}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Form onSubmit={addMember}>
                  <FormGroup>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Name</h6>
                        <Input
                          id='input-name'
                          className='add-member-field'
                          placeholder='Name'
                          type='text'
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Email</h6>

                        <Input
                          id='input-email'
                          className='add-member-field'
                          placeholder='ahmad@gmail.com'
                          type='text'
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Work email</h6>
                        <div className='d-flex '>
                          <Input
                            id='input-password'
                            className='add-member-field'
                            type='text'
                            placeholder='ahmad@gmail.com'
                          />
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Phone Number</h6>
                        <div className='d-flex '>
                          <Input
                            id='input-password'
                            className='add-member-field'
                            type='text'
                            placeholder='+92 559 2930490'
                          />
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Work title</h6>
                        <div className='d-flex '>
                          <Input
                            id='input-password'
                            className='add-member-field'
                            type='text'
                            placeholder='designer'
                          />
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Joining date</h6>
                        <div className='d-flex '>
                          <Input
                            id='input-password'
                            className='add-member-field'
                            type='date'
                            placeholder='+92 559 2930490'
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} xl={6} lg={6} sm={0} xs={0}></Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='card-btn-col'
                      >
                        <div className='d-flex'>
                          <Button
                            className=' w-100 tours-remove-slot-btn'
                            onClick={() => props.setModelShow(false)}
                          >
                            {" "}
                            Cancel
                          </Button>{" "}
                          <Button className='tours-add-slot-btn w-100'>
                            Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddMember;
