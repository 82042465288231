import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../css/styles.css";
import { useToasts } from "react-toast-notifications";
import usersApi from "../../../services/usersApi";
import DeleteIcon from "../../../images/icons/delete-icon.svg";
import { useQuery, useQueryClient } from "react-query";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../../rc-query/usersHook";

export const useUserUsersInfo = (props) => {
  const { addToast } = useToasts();
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [userID, setUserID] = useState("");
  const [request, setRequest] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const userCreateMutation = useCreateUserMutation(props.history);
  const userUpdateMutation = useUpdateUserMutation(props.history);
  const userdeleteMutation = useDeleteUserMutation(props.history);
  const QueryClient = useQueryClient();

  const { data: portalUsers, isLoading: usersLoading } = useQuery(
    ["portalUsers", addUser, currentPage, pageSize],
    () => usersApi.getAllPortalUser(currentPage, pageSize),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createNewUser = (data) => {
    userCreateMutation
      .mutateAsync(data)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("User Created", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("portalUsers");
        }
        setModelShow(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          addToast(err?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });

    setAddUser(false);
  };

  const updateUserRoleFunc = (data) => {
    const value = {
      user: data,
      id: userID,
    };

    userUpdateMutation
      .mutateAsync(value)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("User updated", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("portalUsers");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          addToast(err?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });

    setModelShow(false);
    setIsUpdate(false);
  };

  const modelFunc = (value) => {
    setModelShow1(value);
  };

  const addUserFunc = (value) => {
    setAddUser(value);
  };

  const setRequestFunc = (value) => {
    setRequest(value);
  };

  // *****Delete Admin User*********
  const approvalFunc = () => {
    setLoading(true);

    userdeleteMutation
      .mutateAsync(userID)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("User deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("portalUsers");
        }
        setModelShow1(false);
      })
      .catch((err) => {
        setModelShow1(false);
        setLoading(false);
        if (err?.response?.status === 400) {
          addToast(err?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  // delete Request
  const approval = (id) => {
    setUserID(id);
    setModelShow1(true);
    setRequest("userRole");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const updateUserFunc = (data) => {
    setUserID(data.id);
    setIsUpdate(true);
    setModelShow(true);
    setUserData(data);
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "userName",
      text: "Name",
      style: { width: "28%", padding: "15px 10px" },
      // align: "center",
    },
    {
      dataField: "userEmail",
      text: "Email",
      style: { width: "28%" },
    },

    {
      dataField: "userRole",
      text: "Role",
      style: { width: "30%" },
    },

    {
      dataField: "requests",
      text: "Actions",
      // style: { width: "10%" },
      // headerAlign: "right",
      // align: "right",
      // sort: true,
      style: { width: "30%" },
      formatter: (cell, row) => (
        <a href={row.fileUrl} download>
          <div className='listEditBtnContainer w-100'>
            <a
              href='javascript:void(0)'
              onClick={() => {
                updateUserFunc(row);
              }}
            >
              Edit
            </a>

            <div className='userImgContainer w-100'>
              <img
                src={DeleteIcon}
                alt=''
                height={17}
                style={{ cursor: "pointer" }}
                onClick={() => approval(row.id)}
              />
            </div>
          </div>
        </a>
      ),
    },
  ];

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = portalUsers
      ? Math.trunc(portalUsers?.totalPages / pageSize + 1)
      : 0;
    let buttons = [];

    setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }

    return buttons;
  };

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    modelShow,
    userID,
    request,
    loading,
    addUser,
    usersLoading,
    portalUsers,
    isUpdate,
    userData,
    modelShow,
    modelShow1,
    handleIncrementBtn,
    setModelShow1,
    setModelShow,
    updateUserRoleFunc,
    setIsUpdate,
    createNewUser,
    addUserFunc,
    approvalFunc,
    modelFunc,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  };
};
