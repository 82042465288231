import { useQuery } from "react-query";
import dashboardApi from "../../services/dashboardApi";
import { useNavigate } from "react-router-dom";

export const useDashboardInfo = (props) => {
  const navigate = useNavigate();

  const { data: status, isLoading: statusLoading } = useQuery(
    ["dashboardStatus"],
    () => dashboardApi.getDashboardStats(),
    {
      retry: 0,
    }
  );

  const calculateTimeDiff = (eventTime) => {
    const eventDateTime = new Date(eventTime);
    const currentDateTime = new Date();
    const timeDifference = currentDateTime - eventDateTime;

    // Calculate the time ago
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let timeAgoString = "";
    if (days > 0) {
      timeAgoString = `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (hours > 0) {
      timeAgoString = `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (minutes > 0) {
      timeAgoString = `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      timeAgoString = `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    }

    return timeAgoString;
  };

  return { status, statusLoading, calculateTimeDiff, navigate };
};
