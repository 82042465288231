import { useState, useEffect } from "react";
import bookingAndPayment from "../../services/bookingAndPaymentApi";
import { useBookingCreateMutation } from "../../rc-query/bookingAndPaymentHook";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

export const useBookingPaymentInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [daypassDiscount, setDaypassDiscount] = useState();
  const [daypassDiscountObj, setDaypassDiscountObj] = useState();
  const [dayPassConfirm, setDayPassConfirm] = useState();
  const [saveBtn, setSaveBtn] = useState("tours-setting-hide-btn");
  const [dayPassDiscountConfirm, setDayPassDiscountConfirm] = useState(false);
  const [dayPassDiscountPercent, setDayPassDiscountPercent] = useState(0);
  const [meetingRoomDiscountConfirm, setMeetingRoomDiscountConfirm] =
    useState(false);
  const [passUserMeetingRoomConfirmation, setPassUserMeetingRoomConfirmation] =
    useState(false);
  const [memberMeetingRoomConfirmation, setMemberMeetingRoomConfirmation] =
    useState(false);
  const bookingCreateMutation = useBookingCreateMutation(props.history);
  const QueryClient = useQueryClient();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: discountCode, isLoading: codeLoading } = useQuery(
    ["discountCode"],
    () => bookingAndPayment.getDiscountCode(),
    {
      retry: 0,
    }
  );

  const { data: bookingSetting, isLoading: bookingSettingLoading } = useQuery(
    ["bookingSetting"],
    () => bookingAndPayment.bookingSetting(),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    setDaypassDiscount(
      bookingSetting?.daypassDiscount?.Id
        ? bookingSetting?.daypassDiscount?.Id
        : 0
    );
    setDayPassConfirm(
      bookingSetting?.daypassConfirm ? bookingSetting?.daypassConfirm : false
    );
    setPassUserMeetingRoomConfirmation(
      bookingSetting?.passUserMeetingRoomConfirmation
        ? bookingSetting?.passUserMeetingRoomConfirmation
        : false
    );
    setMemberMeetingRoomConfirmation(
      bookingSetting?.memberMeetingRoomConfirmation
        ? bookingSetting?.memberMeetingRoomConfirmation
        : false
    );
    setDaypassDiscountObj({
      Id: bookingSetting?.daypassDiscount?.Id,
      Code: bookingSetting?.daypassDiscount?.Code,
    });
    setDayPassDiscountConfirm(
      bookingSetting?.daypassDiscount?.discount
        ? bookingSetting?.daypassDiscount?.discount
        : false
    );
    setDayPassDiscountPercent(
      bookingSetting?.meetingroomDiscount?.percent
        ? bookingSetting?.meetingroomDiscount?.percent
        : 0
    );
    setMeetingRoomDiscountConfirm(
      bookingSetting?.meetingroomDiscount?.discount
        ? bookingSetting?.meetingroomDiscount?.discount
        : false
    );
  }, [bookingSettingLoading]);

  const daypassDiscountFunc = (value) => {
   
    setDaypassDiscount(value);

    const data = discountCode?.filter((discount) => {
      if (discount.Id === Number(value)) {
        return discount;
      }
    });

    setDaypassDiscountObj({ Id: data[0].Id, Code: data[0].code });
  };

  const createBookingSetting = () => {
    const data = {
      daypassConfirm: dayPassConfirm,
      passUserMeetingRoomConfirmation: passUserMeetingRoomConfirmation,
      memberMeetingRoomConfirmation: memberMeetingRoomConfirmation,
      daypassDiscount: {
        discount: dayPassDiscountConfirm,
        ...daypassDiscountObj,
      },
      meetingroomDiscount: {
        discount: meetingRoomDiscountConfirm,
        percent: dayPassDiscountPercent,
      },
    };

    bookingCreateMutation.mutateAsync(data).then((data) => {
      if (data?.data?.statusCode == 202) {
        addToast("Successfully Updated", {
          appearance: "success",
          autoDismiss: true,
        });

        QueryClient.invalidateQueries("bookingSetting");
        navigate(-1);
      } else if (data?.data?.statusCode == 200) {
        addToast("Successfully Created", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    });
  };

  return {
    discountCode,
    dayPassConfirm,
    dayPassDiscountConfirm,
    dayPassDiscountPercent,
    daypassDiscount,
    meetingRoomDiscountConfirm,
    passUserMeetingRoomConfirmation,
    memberMeetingRoomConfirmation,
    bookingSettingLoading,
    saveBtn,
    setSaveBtn,
    createBookingSetting,
    daypassDiscountFunc,
    setDayPassConfirm,
    setPassUserMeetingRoomConfirmation,
    setMemberMeetingRoomConfirmation,
    setDayPassDiscountConfirm,
    setDayPassDiscountPercent,
    setMeetingRoomDiscountConfirm,
  };
};
