import { useMutation } from "react-query";
import { INVOICE_VERIFIED, INVOICE_OBJECTED } from "./keys";

import teamInvoices from "../services/teamInvoicesApi";

export const useInvoicesObjectedMutaion = (history) => {
  const response = useMutation(teamInvoices.invoiceObjected, {
    mutationKey: INVOICE_OBJECTED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useInvoicesVerifiedMutaion = (history) => {
  const response = useMutation(teamInvoices.invoiceVerified, {
    mutationKey: INVOICE_VERIFIED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
