import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useInvoiceDetailInfo = () => {
  const { state } = useLocation();
  const { data } = state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { data };
};
