import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { TabContext } from "../../../context/TabContext";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import accessControl from "../../../services/accessControlApi";
import { useQuery } from "react-query";
import "../css/styles.css";

export const useAccessLogInfo = (props) => {
  const navigate = useNavigate();
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [filterData, setFilterData] = useState();
  const [apiStatus, setApiStatus] = useState(
    activeTabJustify === "1"
      ? "all"
      : activeTabJustify === "2"
      ? "Successfull"
      : activeTabJustify === "3"
      ? "Failed"
      : "All"
  );

  const { data: allCheckIns, isLoading: allCheckInsLoading } = useQuery(
    ["allCheckIns", currentPage, pageSize],
    () => accessControl.getAllCheckIns(currentPage, pageSize),
    {
      retry: 0,
    }
  );

  // const { data: exportLogsList, isLoading: exportLogsLoading } = useQuery(
  //   ["exportLogsList", apiStatus, search, date],
  //   () => logsApi.exportErrorLogs(apiStatus, search, date),
  //   {
  //     retry: 0,
  //   }
  // );

  const headers = [
    { label: "No", key: "index_id" },
    { label: "Log ID", key: "id" },
    { label: "User ID", key: "userId" },
    { label: "Severity", key: "severity" },
    { label: "Action", key: "action" },
    { label: "User Name", key: "userName" },
    { label: "Tour Day", key: "tourDay" },
    { label: "Comment", key: "component" },
    { label: "Status", key: "status" },
  ];

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const details = (row) => {
    navigate("/logs-details", {
      state: {
        data: row,
      },
    });
  };

  const columns = [
    {
      dataField: "date",
      text: "Last Date, Time",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "userName",
      text: "User",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "component",
      text: "Log Type",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },

      formatter: (cell, row) =>
        cell === "Successfull" ? (
          <span className='SuccessSpan'>{cell}</span>
        ) : (
          <span className='FailedSpan'>{cell}</span>
        ),
    },
  ];

  // const setSearchDate = (e) => {
  //   if (e.target.value === null || e.target.value === "") {
  //     setDate();
  //     setCurrentPage(1);
  //   } else {
  //     setDate(e.target.value);
  //     setCurrentPage(1);
  //   }
  // };

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = Math.trunc(allCheckIns?.totalPages / pageSize + 1);
    let buttons = [];

    // setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
      setApiStatus("all");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
      setApiStatus("Successfull");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else {
      setActiveTabJustify(tab);
      setApiStatus("Failed");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
  };

  // const handleSearchFunc = (e) => {
  //   setSearch(e.target.value);
  //   setCurrentPage(1);
  // };

  return {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    filterData,
    headers,
    allCheckInsLoading,
    allCheckIns,
    setCurrentPage,
    handleIncrementBtn,
    // handleSearchFunc,
    // setSearchDate,
    toggleCustomJustified,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  };
};
