import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useUserUpdateMutation } from "../../rc-query/usersHook";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/styles.css";

export const useProfileUpdateInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [name, setName] = useState(data?.fullName);
  const [btnDisable, setBtnDisable] = useState(true);
  const [email, setEmail] = useState(data?.email);
  const userUpdateMutation = useUserUpdateMutation(props);

  const profileUpdateFunc = () => {
    const bodyData = {
      id: data.id,
      fullName: name,
      email: email,
    };

    userUpdateMutation.mutateAsync(bodyData).then((data) => {
      if (data?.status === 200) {
        addToast("Profile Update Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    if (name === "" || email === "") {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [name, email]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    data,
    name,
    email,
    btnDisable,
    setName,
    setEmail,
    profileUpdateFunc,
  };
};
