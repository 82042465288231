import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const meetingRoom = {
  meetingRoomList: async (body, page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      `/meetingRooms-status/${body}/${page}/${size}`,
      {
        headers: headers,
        params: { search: search, date: date },
      }
    );

    const meetingRoom = data?.data?.data?.map((room, index) => ({
      id: room.id,
      index_id: index + 1,
      paymentStatus: room.paymentStatus,
      BookingId: room.BookingId,
      team: room.Team,
      name: room.CoworkerName,
      status: room.status,
      resourceName: room.ResourceName,
      date: moment(room?.BookedOn)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));


   

    return {
      meetingRooms: meetingRoom,
      totalPages: data?.data?.totalMeetingRoom,
    };
  },

  exportMeetingRoomList: async (body, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-meetingRooms-status/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const meetingRoom = data?.data?.data?.map((room, index) => ({
      id: room.id,
      index_id: index + 1,
      paymentStatus: room.paymentStatus,
      BookingId: room.BookingId,
      team: room.Team,
      name: room.CoworkerName,
      status: room.status,
      bookingTime: room.ToTime,
      bookingCreated: room.createdAt,
      totalPrice: room.Price,
      resourceName: room.ResourceName,
      declineReason: room.declineReason,
      date: moment(room?.BookedOn)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return {
      meetingRooms: meetingRoom,
      totalPages: data?.data?.totalMeetingRoom,
    };
  },

  MeetingRoomDetail: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/meetings/${body}`, {
      headers: headers,
    });

    return data?.data;
  },

  meetingRoomObjected: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/meetings/objection/${body.id}`,
      {
        paymentStatus: "objected",
        ObjectionReason: body.reason,
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  meetingRoomDenied: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/meeting-decline/${body.id}`,
      {
        status: "deny",
        DeclineReason: body.reason,
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  meetingRoomApproved: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/meeting-approve/${body}`,
      {
        status: "approved",
        paymentStatus: "approved",
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  meetingRoomRefund: async (body) => {
    // const jwt = localStorage.getItem("token");
    // const headers = {
    //   Authorization: "Bearer " + jwt,
    // };
    // const data = await request.patch(
    //   `/meeting-approve/${body}`,
    //   {
    //     status: "approved",
    //     paymentStatus: "approved",
    //   },
    //   {
    //     headers: headers,
    //   }
    // );
    // return data?.data;
  },
};

export default apiWrapper(meetingRoom, [
  "meetingRoomList",
  "MeetingRoomDetail",
  "meetingRoomObjected",
  "meetingRoomApproved",
  "meetingRoomDenied",
  "exportMeetingRoomList",
  "meetingRoomRefund",
]);
