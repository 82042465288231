import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import rolesApi from "../../../services/rolesApi";
import { useQuery } from "react-query";
import "./css/styles.css";

const AddUser = (props) => {
  const [validEye, setValidEye] = useState("userRoeleye");
  const [passwordShown, setPasswordShown] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState();
  const [role, setRole] = useState("");
  const [roleObj, setRoleObj] = useState({});

  const { data: rolesList, isLoading: rolesLoading } = useQuery(
    ["rolesList"],
    () => rolesApi.rolesList(),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    if (props?.data) {
      setName(props?.data?.userName);
      setEmail(props?.data?.userEmail);
      setRole(props?.data?.roleId);
      setRoleObj({
        id: props?.data?.roleId,
        name: props?.data?.userRole,
      });
    }
  }, [props?.data, props.show]);
  useEffect(() => {
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      role === "" ||
      role === "null"
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [name, email, password, role]);
  function toLowerCase(email) {
    return email.toLowerCase();
  }

  const addRoles = () => {
    if (props.isUpdate) {
      const data = {
        name: name,
        email: toLowerCase(email),
        password: password,
        role: roleObj,
      };

      props.update(data);
      setRole({});
      setpassword("");
      setEmail("");
      setName("");
      setRoleObj({});
    } else {
      const data = {
        name: name,
        email: toLowerCase(email),
        password: password,
        role: roleObj,
      };

      props.create(data);

      setRole("");
      setpassword("");
      setEmail("");
      setName("");
      setRoleObj({});
    }
  };

  const setRoleFunc = (e) => {
    setRole(e.target.value);
    rolesList.map((role) => {
      if (e.target.value === role.id) {
        setRoleObj({
          id: role.id,
          name: role.name,
        });
      }
    });
  };

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const cancalFunc = () => {
    setRole("");
    setpassword("");
    setEmail("");
    setName("");
    setRoleObj({});

    props.setModelShow(false);
    props.setIsUpdate(false);
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='add-memeber-card'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='add-member-col'
                  >
                    <div>
                      {props.isUpdate ? (
                        <h5>Update User</h5>
                      ) : (
                        <h5>Add User</h5>
                      )}
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => cancalFunc()}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Form onSubmit={addRoles}>
                  <FormGroup>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Name</h6>
                        <Input
                          id='input-name'
                          className='add-member-field'
                          placeholder='Name'
                          value={name}
                          min={3}
                          type='text'
                          onChange={(e) => {
                            const input = e.target.value;
                            const filteredInput = input.replace(
                              /[^A-Za-z\s]/g,
                              ""
                            ); // Filter out non-alphabetic characters except spaces
                            setName(filteredInput);
                          }}
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Email</h6>

                        <Input
                          id='input-email'
                          className='add-member-field'
                          placeholder='@gmail.com'
                          value={email}
                          type='text'
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Password</h6>
                        <div className='d-flex '>
                          <Input
                            id='input-password'
                            className='add-member-field'
                            defaultValue={props.isUpdate ? "" : ""}
                            placeholder='Password'
                            type={passwordShown ? "text" : "password"}
                            onChange={(e) => setpassword(e.target.value)}
                            required={props.isUpdate ? false : true}
                            minLength={8}
                          />
                          <i
                            onClick={() => togglePassword()}
                            className={`fa ${validEye} ${
                              !passwordShown ? "fa-eye-slash" : "fa-eye"
                            }`}
                          ></i>
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select Role</h6>
                        <select
                          id='input-password'
                          className='add-member-field'
                          value={role}
                          placeholder='Select Role'
                          type='select'
                          onChange={(e) => setRoleFunc(e)}
                          required
                        >
                          <option value='null' selected>
                            Select Role
                          </option>
                          {rolesList?.map((role, index) => {
                            return (
                              <option value={role.id} key={role.id}>
                                {role.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='permision-card-col'
                      ></Col>
                      <Col md={4} xl={4} lg={4} sm={12} xs={12}></Col>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='card-btn-col'
                      >
                        <Button
                          className='tours-add-slot-btn w-100'
                          disabled={btnDisable}
                        >
                          {props.isUpdate ? "Update" : "Add User"}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddUser;
