import { useMutation } from "react-query";
import {
  GET_PASSES_MUTATION,
  PATCH_PASSES_APPROVED_MUTATION,
  PATCH_PASSES_DENIED_MUTATION,
  PASSES_SUSPEND_MUTATION,
} from "./keys";

import passesApi from "../services/passesApi";

export const usePassesMutation = (history) => {
  const response = useMutation(passesApi.passesList, {
    mutationKey: GET_PASSES_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePassesApprovedMutation = (history) => {
  const response = useMutation(passesApi.passesRequestApproval, {
    mutationKey: PATCH_PASSES_APPROVED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePassesDeniedMutation = (history) => {
  const response = useMutation(passesApi.passesRequestDenial, {
    mutationKey: PATCH_PASSES_DENIED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePassesSuspendMutation = (history) => {
  const response = useMutation(passesApi.suspendPassUser, {
    mutationKey: PASSES_SUSPEND_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
