import { useState, useEffect, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import TeamApi from "../../../services/teamsApi";
import PlanApi from "../../../services/plansApi";
import { useQuery, useQueryClient } from "react-query";
import {
  usePlansApprovedMutation,
  usePlansDeniedMutation,
  usePlansPatchMutation,
} from "../../../rc-query/plansHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import moment from "moment";
import "../css/styles.css";
import { toast } from "react-toastify";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const[dedicatedResources1,setDedicatedResources1] = useState([])
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [isPoEnabled, setIsPoEnabled] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [request, setRequest] = useState("");
  const [capacity, setCapacity] = useState();
  const[privateOfficeAval,setPrivateOfficeAval]=useState(false)
  const [resourceId, setResourceId] = useState();
  const [desk, setDesk] = useState(data?.Desk);
  const [ pOObj,setPOObj]= useState({})
  const [pOName, setPOName] = useState("");
  const [planID, setPlanID] = useState("");
  const [requestModel, setRequestModel] = useState(false);
  const[preReqOffice,setPreReqOffice] = useState("")
  const [declineModel, setDeclineModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [unCategorized, setUnCategorized] = useState([]);
  const [columns, setColumns] = useState({});
  const [status, setStatus] = useState("");
  const QueryClient = useQueryClient();
  const[doubleResouceSlected,setDoubleResouceSlected] = useState(false);
  const approvalMutation = usePlansApprovedMutation(props.history);
  const deniedMutation = usePlansDeniedMutation(props.history);
  const patchMutation = usePlansPatchMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  

  var found = false;

  const { data: memberList, isLoading: memberLoading } = useQuery(
    ["memberList"],
    () => TeamApi.GetNexudusIdTeam(data?.teamId),
    {
      retry: 0,
    }
  );

  // Get Available Dedicated Desks
  const { data: dedicatedResources, isLoading: dedicatedResourcesLoading } =
    useQuery(
      ["Dedicatedresources", data?.dedicatedResourceTypeId],
      () =>
        PlanApi.getDedicatedResources({
          Id: data?.ResourceTypeId
            ? data?.ResourceTypeId
            : data?.dedicatedResourceTypeId,
          type:
            data?.ResourceTypeId ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? true
              : data?.dedicatedResourceTypeId ===
                Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? true
              : false,
        }),
      {
        retry: 0,
      }
    );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: resources, isLoading: resourcesLoading } = useQuery(
    ["resources"],
    () =>
      PlanApi.GetResources({
        Id: data?.ResourceTypeId
          ? data?.ResourceTypeId
          : data?.privateResourceTypeId,
        capacity: 0,
      }),
    {
      retry: 0,
    }
  );



  const { data: pastPlans, isLoading: plansLoading } = useQuery(
    ["pastPlans"],
    () => PlanApi.plansListByTeamId(data?.teamId),
    {
      retry: 0,
    }
  );


  useEffect(() => {
    setTimeout(() => {
      const preOffice = (resources && pastPlans[0]?.desk)
        ? resources.find(obj => pastPlans[0]?.desk === obj?.Id)
        : undefined;
     
      setPOObj({
        id: preOffice.Id,
        name: preOffice.name,
        });
        setDesk(preOffice.Id)
        setPOName(preOffice.name)
        setPreReqOffice(preOffice.name)
    }, 500);

  

    // this is check if private office is not avaliable
    // {resources?.map(
    //   (resource, index) => {
    //   if (
    //   resource?.contractedCowrkerId === null
    //   ) {
    //     setPrivateOfficeAval(true)}})}
  }, [pastPlans, resources]);





  useEffect(() => {
    const checkCondition = () => {
      // Assuming resources is a state variable that contains your array
      // If it's not, replace it with the appropriate variable containing your array
      const isPONamePresent = resources?.some(
        (resource) => resource.name === pOName && resource?.contractedCowrkerId === null
      );
      setIsPoEnabled(isPONamePresent)
     
    };

    // Delay the check by 500 milliseconds using setTimeout
    const timeoutId = setTimeout(checkCondition, 500);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [pOName, resources]);
 
  
//   useEffect(()=>{
//     setTimeout(() => {
//       {resources?.map(
//         (resource, index) => {
//   if(   resource.name === pOName && resource?.contractedCowrkerId === null ){  
//     setIsPoEnabled(true)

//   }
// else{

//   setIsPoEnabled(false)
// }
//         })}

//   },500)
// }, [pOName]);


 



  const tasks = [];
 
  useEffect(() => {

    // resources?.map((resource=>{
    //   setPOObj({id:resource?.Id,name:resource?.name})
    // }))



    setCapacity(data?.capacity);

    for (let i = 0; i < memberList?.companyMembers.length; i++) {
      found = false;
      for (let j = 0; j < data?.teamMembers.length; j++) {
        if (memberList?.companyMembers[i].Id === data?.teamMembers[j].Id) {
          found = true;
          break;
        }
      }
      if (found === false) {
        unCategorized.push(memberList?.companyMembers[i]);
      }
    }
  

    setColumns(
      data?.ResourceTypeId === Number(process.env.REACT_APP_PRIVATE_OFFICE)
        ? {
            Unallocated_Members: {
              id: 103,
              name: "Unallocated Members",
              // items: tasks,
              items: memberList?.companyMembers
                ? unCategorized?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
            Private_Office: {
              id: 203,
              name: "Private Office",
              items: data?.teamMembers
                ? data?.teamMembers?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
          }
        : data?.ResourceTypeId ===
          Number(process.env.REACT_APP_DEDICATED_OFFICE)
        ? {
            Unallocated_Members: {
              id: 103,
              name: "Unallocated Members",
              // items: tasks,
              items: memberList?.companyMembers
                ? unCategorized?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },

            Dedicated_Desk: {
              id: 204,
              name: "Dedicated Desk",
              items: data?.teamMembers
                ? data?.teamMembers?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
          }
        : {
            Unallocated_Members: {
              id: 103,
              name: "Unallocated Members",
              // items: tasks,
              items: memberList?.companyMembers
                ? unCategorized?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
            Private_Office: {
              id: 203,
              name: "Private Office",
              items: data?.privateMembers
                ? data?.privateMembers?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
            Dedicated_Desk: {
              id: 204,
              name: "Dedicated Desk",
              items: data?.dedicatedMembers
                ? data?.dedicatedMembers?.map((element) => {
                    return {
                      id: element?.Id?.toString(),
                      content: element?.fullName,
                      fullName: element?.fullName,
                      Id: element?.Id,
                      isAdministrator: element?.isAdministrator,
                      isPayingMember: element?.isPayingMember,
                    };
                  })
                : tasks,
            },
          }
    );
  }, [memberList?.companyMembers]);

  

  

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source?.droppableId];
      const destColumn = columns[destination?.droppableId];
      const sourceItems = [...sourceColumn?.items];
      const destItems = [...destColumn?.items];
  
      const [removed] = sourceItems?.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
          // dawood check 
   if( destination.droppableId === "Private_Office" ){
   
    for (const obj of destItems) {
      delete obj.deskName;
      delete obj.desk;
      // destItems.push({ desk: parseInt(pOObj?.id) },{deskName:pOObj?.name})
      // destItems.unshift({ desk: parseInt(pOObj?.id), deskName: pOObj?.name });
    }
   }
   //end

    

      // setColumns({
      //   ...columns,
      //   [source.droppableId]: {
      //     ...sourceColumn,
      //     items: sourceItems,
      //   },
      //   [destination.droppableId]: {
      //     ...destColumn,
      //     items: destItems,
      //   },
      // });
      setColumns(prevColumns => ({
        ...prevColumns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      }));
    }
     else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, height: "9px" }),
  };

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const modelRequestFunc = (value) => {
    setRequestModel(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  // *****Plan Request Aproval Request api*********
  const approvalFunc = (approvalData) => {
   
    // console.log("data of desks", {
    //   dedicatedAuto: false,
    //   Desk: desk,
    //   deskName: pOName,
    //   teamMembers: [
    //     ...columns?.Dedicated_Desk?.items,
    //     ...columns?.Private_Office?.items,
    //   ],
    //   dedicatedMembers: columns?.Dedicated_Desk?.items,
    //   privateMembers: columns?.Private_Office?.items,
    // });
    
    setLoading(true);

    if (
      data?.ResourceTypeId === Number(process.env.REACT_APP_DEDICATED_OFFICE)
    ) {
      const patchData = {
        dedicatedAuto: false,
        teamMembers: columns?.Dedicated_Desk?.items,
      };

      patchMutation.mutateAsync({ patchData, id: data?.id }).then((data) => {
        if (data?.data?.statusCode === 200) {
          approvalMutation
            .mutateAsync(approvalData)
            .then((res) => {
              if (res?.data?.statusCode === 200) {
                addToast("Successfully Approved", {
                  appearance: "success",
                  autoDismiss: true,
                });
                navigate(-1);
                QueryClient.invalidateQueries("plansLists");
                generateErrMutaion
                  .mutateAsync({
                    action: "Plan Approval",
                    severity: "info",
                    status: "Successfull",
                    component: "Plan Requests",
                  })
                  .then((data) => {});
                setLoading(false);
                setModelShow(false);
              }
            })
            .catch((err) => {
              toast.error(err?.response?.data?.error?.message);
              setLoading(false);
              setModelShow(false);
              generateErrMutaion
                .mutateAsync({
                  action: "Plan approval",
                  severity: "info",
                  status: "Failed",
                  component: "Plan Requests",
                })
                .then((data) => {});
            });
        }
      });
    } else if (
      data?.ResourceTypeId === Number(process.env.REACT_APP_PRIVATE_OFFICE)
    ) {
      const patchData = {
        Desk: desk,
        deskName: pOName,
        deskCapacity: capacity,
        resourceId: resourceId,
        teamMembers: columns?.Private_Office?.items,
      };

      patchMutation
        .mutateAsync({ patchData, id: data?.id })
        .then((data) => {
          if (data?.data?.statusCode === 200) {
            approvalMutation
              .mutateAsync(approvalData)
              .then((res) => {
                if (res?.data?.statusCode === 200) {
                  addToast("Successfully Approved", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  navigate(-1);
                  QueryClient.invalidateQueries("plansLists");

                  generateErrMutaion
                    .mutateAsync({
                      action: "Plan Approval",
                      severity: "info",
                      status: "Successfull",
                      component: "Plan Requests",
                    })
                    .then((data) => {});

                  setLoading(false);
                  setModelShow(false);
                }
              })
              .catch((err) => {
                toast.error(err?.response?.data?.error?.message);
                setLoading(false);
                setModelShow(false);
              });
          }
        })
        .catch((err) => {
          generateErrMutaion
            .mutateAsync({
              action: "Plan approval",
              severity: "info",
              status: "Failed",
              component: "Plan Requests",
            })
            .then((data) => {});
        });
    } else {
      const patchData = {
        dedicatedAuto: false,
        Desk: desk,
        resourceId: resourceId,
        deskName: pOName,
        teamMembers: [
          ...columns?.Dedicated_Desk?.items,
          ...columns?.Private_Office?.items,
        ],
        dedicatedMembers: columns?.Dedicated_Desk?.items,
        privateMembers: columns?.Private_Office?.items,
      };

      patchMutation
        .mutateAsync({ patchData, id: data?.id })
        .then((data) => {
          if (data?.data?.statusCode === 200) {
            approvalMutation
              .mutateAsync(approvalData)
              .then((res) => {
                if (res?.data?.statusCode === 200) {
                  addToast("Successfully Approved", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  navigate(-1);
                  QueryClient.invalidateQueries("plansLists");
                  generateErrMutaion
                    .mutateAsync({
                      action: "Plan Approval",
                      severity: "info",
                      status: "Successfull",
                      component: "Plan Requests",
                    })
                    .then((data) => {});
                  setLoading(false);
                  setModelShow(false);
                }
              })
              .catch((err) => {
                toast.error(err?.response?.data?.error?.message);
                setLoading(false);
                setModelShow(false);
              });
          }
        })
        .catch((err) => {
          generateErrMutaion
            .mutateAsync({
              action: "Plan approval",
              severity: "info",
              status: "Failed",
              component: "Plan Requests",
            })
            .then((data) => {
              setLoading(false);
              setModelShow(false);});
        });
    }
  };


  // *****Plan Request Deny Request api*********
  const deniedFunc = (data) => {
    setLoading(true);

    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          QueryClient.invalidateQueries("plansLists");
          generateErrMutaion
            .mutateAsync({
              action: "Plan deny",
              severity: "info",
              status: "Successfull",
              component: "Plan Requests",
            })
            .then((data) => {});
          setLoading(false);
          setModelShow1(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Plan deny",
            severity: "info",
            status: "Failed",
            component: "Plan Requests",
          })
          .then((data) => {});
      });
  };

  const approval = (id) => {
    if (
      data?.ResourceTypeId === Number(process.env.REACT_APP_DEDICATED_OFFICE)
    ) {
      for (let i = 0; i < columns?.Dedicated_Desk?.items?.length; i++) {
        if (!columns?.Dedicated_Desk?.items[i].desk) {
          addToast("Kinldy Select All dedicated resources", {
            appearance: "warning",
            autoDismiss: true,
          });
          return;
        }
      }
      setPlanID(id);
      setModelShow(true);
      setStatus("plan-requests");
      setRequest("approval");
    } else if (
      data?.ResourceTypeId === Number(process.env.REACT_APP_PRIVATE_OFFICE)
    ) {
      setPlanID(id);
      setModelShow(true);
      setStatus("plan-requests");
      setRequest("approval");
    } else {
      for (let i = 0; i < columns?.Dedicated_Desk?.items?.length; i++) {
        if (!columns?.Dedicated_Desk?.items[i].desk) {
          addToast("Kinldy Select All dedicated resources", {
            appearance: "warning",
            autoDismiss: true,
          });
          return;
        }
      }
      setPlanID(id);
      setModelShow(true);
      setStatus("plan-requests");
      setRequest("approval");
    }
  };

  const rejectApproval = (id) => {
    setPlanID(id);
    setRequest("deny");
    setStatus("plan-requests");
    setDeclineModel(true);
  };

  const resourceMembers = (e) => {

    
    const officeValue = JSON.parse(e?.target?.value);
   
    const resourceData = resources?.filter((event) => {
      return event?.Id.toString().match(officeValue?.id);
    });

    if (
      resourceData[0]?.contractedCowrkerId === null ||
      (resourceData[0]?.contractedCowrkerId &&
        data?.userCompany === resourceData[0]?.contractedTeamName)
    ) {
      setCapacity(resourceData[0].capacity);
      setResourceId(resourceData[0].resourceId);
      setDesk(parseInt(officeValue?.id));
      setPOName(officeValue?.name);
      setPOObj(officeValue)
    } else {
      toast.warning("This private office already Assign to User");
      return;
    }
  };

  useEffect(()=>{
    const abc = columns?.Dedicated_Desk?.items?.some((a, index, array) => {
      // Check if there is any other object with the same desk property
      const hasSameDesk = array.findIndex(
        (b) => b?.desk && a?.desk === b?.desk && a !== b
      ) !== -1;
    
     
    
      return hasSameDesk;
    });
    
    if (abc) {
      
      setDoubleResouceSlected(true);
    } else {
      setDoubleResouceSlected(false);
    }

  },[doubleResouceSlected])

   useMemo(()=>{
    setDedicatedResources1(dedicatedResources)
  },[dedicatedResources])




  const dedicatedDeskOnchange = (e, index) => {
    const obj = JSON.parse(e?.target.value);


    // both member should not select same desk 
  const abc =  
      columns?.Dedicated_Desk?.items?.some((a) => { 
        if(a?.desk === parseInt(obj.id)){  
             return true
  }})
    if(abc){
      toast.error("This desk is already selected")
      setDoubleResouceSlected(true)
    }
    else{
      setDoubleResouceSlected(false)
    }

      // both member should not select same desk end
    
  

    const newData = {
      Id: columns?.Dedicated_Desk?.items[index].Id,
      id: columns?.Dedicated_Desk?.items[index].id,
      content: columns?.Dedicated_Desk?.items[index].content,
      fullName: columns?.Dedicated_Desk?.items[index].fullName,
      isAdministrator: columns?.Dedicated_Desk?.items[index].isAdministrator,
      isPayingMember: columns?.Dedicated_Desk?.items[index].isPayingMember,
      desk: parseInt(obj.id),
      deskName: obj.name,
    };
    
    columns?.Dedicated_Desk?.items.splice(index, 1, newData);




    //both desk should not select same value , object remove from arr

    // setDedicatedResources1((prevResources) => {
    //   return dedicatedResources?.filter((resource) => {
    //     const itemShow = columns?.Dedicated_Desk?.items?.some((e) => e?.desk === resource.Id);
    //     return !itemShow;
    //   });
    // });
    
       //both desk should not select same value , object remove from arr end
    
 
}

  const lastMonthCalculate = (startMonth, months, duration) => {
    if (duration === "Yearly") {
      var lastMonth = moment(startMonth).add(1, "y");
    } else {
      var futureMonth = moment(startMonth).add(months, "M");
      var lastMonth = moment(futureMonth).subtract(1, "d");
    }
    return moment(lastMonth).toISOString();
  };

  return {
    isPoEnabled,
    doubleResouceSlected,
    dedicatedResources1,
    pOObj,
    pOName,
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    planID,
    loading,
    capacity,
    memberLoading,
    columns,
    colourStyles,
    unCategorized,
    resources,
    dedicatedResources,
    dedicatedResourcesLoading,
    desk,
    plansLoading,
    pastPlans,
    preReqOffice,
    lastMonthCalculate,
    dedicatedDeskOnchange,
    resourceMembers,
    onDragEnd,
    setColumns,
    approvalFunc,
    deniedFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    modelRequestFunc,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
    privateOfficeAval,
    doubleResouceSlected
  };
};
