import { useToursSettingInfo } from "./useToursSetting";
import React from "react";
import { Button } from "react-bootstrap";
import { Card, Col, Row, Input, Table } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ToursSlot from "../../components/molecules/addToursSlotModel";
import Holiday from "../../images/icons/addHoliday.svg";
import Switch from "react-switch";
import { animated } from "react-spring";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveSlotModel from "../../components/molecules/removeTourSlotModel";
import Spinner from "react-bootstrap/Spinner";
import ApproveModel from "../../components/molecules/approvalModel";
import { toast } from "react-toastify";
import moment from "moment/moment";
import HolidayModel from "../../components/molecules/holidayModel";
import "./css/styles.css";

const ToursSettings = (props) => {
  const {
    modelShow,
    tourRequestEnable,
    tourDuration,
    maxTourPerSlot,
    perMonthTour,
    removeModel,
    loading,
    tourSlotsLoading,
    saveBtn,
    disableSetting,
    disableTour,
    holidayData,
    setHolidayData,
    addHolidayFunc,
    disableTourFunc,
    enableTour,
    setEnableTour,
    setDisableTour,
    archiveOneSlot,
    setDisableSetting,
    setHolidayModel,
    updateHolidayFunc,
    existedTour,
    holidayModel,
    archiveAll,
    setArchiveAll,
    divShowAccor,
    toggleHandler,
    updateHoliday,
    handleupdateholidayCard,
    setUpdateHoliday,
    openAnimation,
    setSaveBtn,
    addSlots,
    tourSlotTimes,
    archivedAllSlots,
    saveTourSetting,
    setTourRequestEnable,
    setTourDuration,
    setMaxTourPerSlot,
    setPerMonthTour,
    removeSlotFunc,
    addSlotFunc,
  } = useToursSettingInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Row>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <Card className='tours-setting-card'>
                <Row>
                  <Col
                    md={4}
                    lg={4}
                    xl={4}
                    sm={10}
                    xs={10}
                    className='tours-setting-header'
                  >
                    <h3>Enable or Disable Tour Request</h3>
                  </Col>
                  <Col md={4} lg={4} xl={4} sm={2} xs={2}>
                    <Switch
                      id={5}
                      checked={tourRequestEnable}
                      onChange={(e) => {
                        setTourRequestEnable(e);
                        if (e === true) {
                          setEnableTour(true);
                        } else {
                          setDisableTour(true);
                        }
                      }}
                      // disabled={samePlanResources}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      checkedHandleIcon={false}
                      height={20}
                      onColor='#0129FA'
                      className='mr-1 mx-2 d-flex  mt-1'
                    />
                  </Col>
                  <Col md={2} lg={2} xl={2} sm={0} xs={1}>
                    {" "}
                  </Col>
                  <Col md={2} lg={2} xl={2} sm={12} xs={12}>
                    {loading ? (
                      <Button
                        className='tours-setting-btn w-100'
                        disabled={true}
                      >
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />{" "}
                        Save
                      </Button>
                    ) : (
                      <Button
                        className={`${saveBtn} w-100`}
                        onClick={() => saveTourSetting()}
                      >
                        Save
                      </Button>
                    )}{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <hr className='line-break' />
                  </Col>
                </Row>

                <div className={!tourRequestEnable ? "disabledDiv" : ""}>
                  <Row>
                    <Col
                      md={12}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      className='tours-setting-header tourTimeDurationField '
                    >
                      <h3>Tour Time Duration</h3>

                      <Row>
                        <Col md={12} lg={11} xl={11} sm={12} xs={12}>
                          <Select
                            menuPortalTarget={document.body}
                            value={{
                              label: `${String(tourDuration)} mins`,
                              value: tourDuration,
                            }}
                            options={[
                              {
                                value: 30,
                                label: "30 mins",
                              },
                              {
                                value: 60,
                                label: "1 hour",
                              },
                              {
                                value: 90,
                                label: "1 hour 30 mins",
                              },
                              {
                                value: 120,
                                label: "2 hours",
                              },
                            ]}
                            placeholder={
                              <span className='dropdown_placeholder  '>
                                Tours Duration
                              </span>
                            }
                            onChange={(e) => {
                              setSaveBtn("tours-setting-btn");
                              setTourDuration(parseInt(e.value));
                            }}
                            classNamePrefix='select2-selection  '
                            className='custom-select'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={12}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      className='tours-setting-header  '
                    >
                      <h3>Maximum Participants (Per Slot)</h3>
                      <Row>
                        <Col md={12} lg={11} xl={11} sm={12} xs={12}>
                          <CreatableSelect
                            menuPortalTarget={document.body}
                            value={{
                              label: String(maxTourPerSlot),
                              value: maxTourPerSlot,
                            }}
                            options={[
                              { value: 1, label: "1" },
                              { value: 2, label: "2" },
                              { value: 3, label: "3" },
                              { value: 4, label: "4" },
                              { value: 5, label: "5" },
                              { value: 6, label: "6" },
                              { value: 7, label: "7" },
                              { value: 8, label: "8" },
                              { value: 9, label: "9" },
                              { value: 10, label: "10" },
                            ]}
                            // onChange={(e) => {
                            //   setSaveBtn("tours-setting-btn");
                            //   setMaxTourPerSlot(parseInt(e.value));
                            // }}
                            onChange={(e) => {
                              const selectedValue = parseInt(e.value);
                              if (selectedValue > 0) {
                                setSaveBtn("tours-setting-btn");
                                setMaxTourPerSlot(selectedValue);
                              } else {
                                // Handle the case where the user selected a non-positive value (e.g., show an error message)
                                toast.warn("Enter positive number");
                              }
                            }}
                            min={0}
                            placeholder={
                              <span className='dropdown_placeholder  '>
                                Select a number
                              </span>
                            }
                            classNamePrefix='select2-selection  '
                            className='custom-select'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={12}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      className='tours-setting-header  '
                    >
                      <h3>Maximum Tours (Per Month)</h3>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <CreatableSelect
                            menuPortalTarget={document.body}
                            value={{
                              label: String(perMonthTour),
                              value: perMonthTour,
                            }}
                            options={[
                              { value: 1, label: "1" },
                              { value: 2, label: "2" },
                              { value: 3, label: "3" },
                              { value: 4, label: "4" },
                              { value: 5, label: "5" },
                              { value: 6, label: "6" },
                              { value: 7, label: "7" },
                              { value: 8, label: "8" },
                              { value: 9, label: "9" },
                              { value: 10, label: "10" },
                            ]}
                            placeholder={
                              <span className='dropdown_placeholder  '>
                                Select a number
                              </span>
                            }
                            onChange={(e) => {
                              if (e.value < 31) {
                                setSaveBtn("tours-setting-btn");
                                setPerMonthTour(parseInt(e.value));
                              } else {
                                toast.warn(
                                  "You can set a maximum value of 31 tours per month."
                                );
                              }
                            }}
                            classNamePrefix='select2-selection  '
                            className='custom-select'
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                      <hr className='line-break' />
                    </Col>
                  </Row>
                  <animated.div style={openAnimation}>
                    <div>
                      <Row>
                        <Col
                          md={10}
                          lg={10}
                          xl={10}
                          sm={9}
                          xs={9}
                          className='tours-setting-header  '
                        >
                          <h3>Holidays</h3>
                          <p>Tours will not be available on holidays</p>
                        </Col>
                        <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                          <div
                            onClick={() => setHolidayModel(true)}
                            className='d-flex justify-content-end'
                          >
                            <img
                              src={Holiday}
                              alt={"AddMember"}
                              role='button'
                            />
                          </div>
                        </Col>
                        <Col md={1} lg={1} xl={1} sm={2} xs={2}>
                          <div className='toggleExpand'>
                            <div className='toggleStyle'>
                              <animated.i role='button'>
                                <ExpandMoreIcon
                                  onClick={(e) => toggleHandler(e?.target?.id)}
                                  id={"2"}
                                />
                              </animated.i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div id={2} hidden={divShowAccor[2]}>
                        <Row>
                          {existedTour?.holidays?.map((holiday) => {
                            return (
                              <Col
                                md={4}
                                lg={3}
                                xl={3}
                                sm={12}
                                xs={12}
                                className='holidayCardCol'
                              >
                                <Card
                                  className='holidayCard p-2 pt-3 mt-3 '
                                  role='button'
                                  onClick={() => {
                                    handleupdateholidayCard(holiday);
                                  }}
                                  key={holiday?.id}
                                >
                                  <Row>
                                    <Col
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <h4>{holiday?.title}</h4>
                                      {holiday?.onDay ? (
                                        <p className='pt-2'>
                                          {moment(holiday.fromDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      ) : (
                                        <p className='pt-2'>
                                          {` ${moment(holiday.fromDate).format(
                                            "DD/MM/YYYY"
                                          )} - ${moment(holiday.toDate).format(
                                            "DD/MM/YYYY"
                                          )}`}
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </div>
                  </animated.div>

                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                      <hr className='line-break' />
                    </Col>
                  </Row>
                  <animated.div style={openAnimation}>
                    <div>
                      <Row>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          sm={6}
                          xs={6}
                          className='tours-setting-header '
                        >
                          <h3>Tour Slots</h3>
                          <p>Manage Slots</p>
                        </Col>
                        <Col md={5} lg={5} xl={5} sm={5} xs={5}></Col>
                        <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                          <div className='toggleExpand'>
                            <div className='toggleStyle'>
                              <animated.i role='button'>
                                <ExpandMoreIcon
                                  onClick={(e) => toggleHandler(e?.target?.id)}
                                  id={"1"}
                                />
                              </animated.i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div id={1} hidden={divShowAccor[1]}>
                        <Row>
                          <Row>
                            <Col md={8} lg={8} xl={8} sm={0} xs={0}></Col>
                            <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                              <div className='membershipDetailBtn w-100'>
                                <Button
                                  className='w-100 tours-remove-slot-btn'
                                  onClick={() => setArchiveAll(true)}
                                >
                                  Remove all slots
                                </Button>
                                <Button
                                  className='tours-add-slot-btn w-100'
                                  onClick={() => addSlotFunc(true)}
                                >
                                  Add a slot
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <Card className='calender-card '>
                              <Row>
                                <Col
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  sm={12}
                                  xs={12}
                                  className='table-responsive'
                                >
                                  <Table
                                    className='calender-table table-responsive table-wrap data-wrap'
                                    borderless
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sunday</th>
                                        <th>Monday</th>
                                        <th>Tuesday</th>
                                        <th>Wednesday</th>
                                        <th>Thursday</th>
                                        <th>Friday</th>
                                        <th>Saturday</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!tourSlotsLoading ? tourSlotTimes() : ""}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </animated.div>
                  <Row className='pt-4'>
                    <Col md={9} lg={9} xl={10} sm={0} xs={0}></Col>
                    <Col md={3} lg={3} xl={2} sm={12} xs={12}></Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>

        <ApproveModel
          approvalFunc={disableTourFunc}
          show={disableTour}
          setModelShow={setDisableTour}
          tourEnable={tourRequestEnable}
          loading={loading}
          request={"TourDisbale"}
          tourEnableInput={setTourRequestEnable}
          headerText='Disable Tour'
          textBody='Are you sure you want to disable the tour?'
        />

        <ApproveModel
          approvalFunc={archivedAllSlots}
          show={archiveAll}
          setModelShow={setArchiveAll}
          loading={loading}
          request={"archiveAll"}
          headerText='Remove All Tour Slots'
          textBody='Are you sure you want to remove this tour slot? This will not remove any already approved tours at this time slot.'
        />

        <ApproveModel
          approvalFunc={disableTourFunc}
          show={enableTour}
          loading={loading}
          setModelShow={setEnableTour}
          request={"TourEnable"}
          tourEnableInput={setTourRequestEnable}
          headerText='Enable Tour'
          textBody='Are you sure you want to enable the tour?'
        />

        <ToursSlot
          show={modelShow}
          setModelShow={addSlotFunc}
          duration={tourDuration}
          addSlots={addSlots}
          maxTourPerSlot={maxTourPerSlot}
        />
      </Row>
      <RemoveSlotModel
        show={removeModel}
        setModelShow={removeSlotFunc}
        removeSlot={archiveOneSlot}
        headerText='Remove Tour Slot?'
        textBody='Are you sure you want to remove this tour slot? This will not remove any already approved tours at this time slot.'
      />

      <HolidayModel
        show={holidayModel}
        setModelShow={setHolidayModel}
        loading={loading}
        action={addHolidayFunc}
        request={"create"}
        // create={}
      />
      <HolidayModel
        show={updateHoliday}
        setModelShow={setUpdateHoliday}
        loading={loading}
        data={holidayData}
        action={updateHolidayFunc}
        request={"update"}
        // create={}
      />
    </>
  );
};

export default ToursSettings;
