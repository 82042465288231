import { useTeamsDetailInfo } from "./useTeamDetails";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import IMAGE from "../../../images/sidenavicons/home.svg";
import Table from "react-bootstrap/Table";
import { animated } from "react-spring";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import AddMember from "../../../components/molecules/addMemberModel";
import set from "lodash/set";
import ImageView from "../../../components/molecules/imageViewer";
import "../css/styles.css";

const Loader = require("react-loader");

const TeamsDetail = (props) => {
  const {
    data,
    userData,
    openAnimation,
    divShowAccor,
    modelShow,
    showImg,
    image,
    teamInvoices,
    setShowImg,
    setImage,
    setModelShow,
    navigate,
    toggleHandler,
  } = useTeamsDetailInfo(props);

  return (
    <>
      {userData ? (
        <Row className='membershipPage'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col md={12} xl={6} lg={6} sm={12} xs={12} className='p-3 h-100'>
                <Card className='membershipCard mb-3'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Plan Details</h3>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='cardHeading memberCardData'>
                        <h5>Plan</h5>
                        {data?.planType && data?.planDuration ? (
                          <h6>
                            {data?.planType} ({data?.planDuration})
                          </h6>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Start Month</h5>
                        <h6>{data?.startDate} </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Last Month</h5>
                        <h6>{data?.RenewalMonth} </h6>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Personal Details</h3>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='cardHeading memberCardData'>
                        <h5>First Name</h5>
                        <h6>
                          {userData?.data?.firstName
                            ? userData?.data?.firstName
                            : userData?.data?.fullName}
                        </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Last Name</h5>
                        <h6> {userData?.data?.lastName}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Phone Number</h5>
                        <h6> {userData?.data?.phoneNumber}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Email</h5>

                        <h6> {userData?.data?.email}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Work Title</h5>

                        <h6> {userData?.data?.workTitle}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Employment Status </h5>

                        <h6> {userData?.data?.employmentStatus}</h6>
                      </div>

                      <div className='cardHeading memberCardData'>
                        <h5>Linkedin</h5>

                        <h6>
                          <a
                            href={userData?.data?.linkedin}
                            target='blank'
                            className='mob-anchor'
                          >
                            {userData?.data?.linkedin?.split("in")[3]}
                          </a>
                        </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Twitter</h5>
                        <h6>
                          <a
                            href={userData?.data?.twitter}
                            target='blank'
                            className='mob-anchor'
                          >
                            {userData?.data?.twitter?.split("com")[1]}
                          </a>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12} xl={6} lg={6} sm={12} xs={12} className='p-3'>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Company Information</h3>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='cardHeading memberCardData'>
                        <h5>Company Name</h5>
                        <h6>{ (data?.userCompany && data?.userCompany.length > 50)
  ? data?.userCompany.slice(0, 40) + '...'
  : data?.userCompany} </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Company Status</h5>
                        <h6> Active</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Registration Status</h5>
                        <h6> {data?.registration}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Email</h5>
                        <h6> {data?.email}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Phone Number</h5>
                        <h6>{data?.phoneNumber}</h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Website</h5>
                        <a
                          href={data?.website}
                          target='blank'
                          className='mob-anchor'
                        >
                          {" "}
                          <h6>{data?.website}</h6>
                        </a>
                      </div>

                      <div className='cardHeading memberCardData'>
                        <h5>Linkedin</h5>
                        <h6>
                          <a
                            href={data?.linkedin}
                            target='blank'
                            className='mob-anchor'
                          >
                            {data?.linkedin?.split("in")[3]}
                          </a>
                        </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Twitter</h5>
                        <h6>
                          <a
                            href={data?.twitter}
                            target='blank'
                            className='mob-anchor'
                          >
                            {data?.twitter?.split("com")[1]}
                          </a>
                        </h6>
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Description</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='memberDescription'>
                        <p>{data?.description}</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12} className='p-3'>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Documents</h3>
                      </div>
                    </Col>
                    <Row>
                      <hr />
                      <Row>
                        {data?.attachLogo ? (
                          <Col
                            md={3}
                            xl={3}
                            lg={3}
                            sm={12}
                            xs={12}
                            className='mt-3'
                          >
                            <img
                              src={data?.attachLogo}
                              alt='invoices'
                              role='button'
                              className=' documentsImg'
                              onClick={() => {
                                setImage(data?.attachLogo);
                                setShowImg(true);
                              }}
                            />{" "}
                            <span>Logo</span>
                          </Col>
                        ) : (
                          ""
                        )}

                        {data?.images?.map((img, index) => {
                          return (
                            <Col
                              md={3}
                              xl={3}
                              lg={3}
                              sm={12}
                              xs={12}
                              className='mt-3'
                              key={index}
                            >
                              <img
                                src={img}
                                alt='invoices'
                                role='button'
                                className='documentsImg'
                                onClick={() => {
                                  setImage(img);
                                  setShowImg(true);
                                }}
                              />{" "}
                              <span>Document {index + 1}</span>
                            </Col>
                          );
                        })}
                      </Row>
                    </Row>
                  </Row>
                </Card>
              </Col>
            </Row>
            <animated.div style={openAnimation}>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12} className='p-3'>
                  <Card className='membershipCard'>
                    <Row>
                      <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                        <Row>
                          <Col md={9} xl={9} lg={9} sm={9} xs={9}>
                            <div className='membershipCardHeading'>
                              <h3>Team members</h3>
                            </div>
                          </Col>
                          <Col md={2} xl={2} lg={2} sm={2} xs={2}></Col>
                          <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                            <div className='toggleExpand'>
                              <div className='toggleStyle'>
                                <animated.i role='button'>
                                  <ExpandMoreIcon
                                    onClick={(e) => toggleHandler(e)}
                                    id={"1"}
                                  />
                                </animated.i>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Row>
                        <div id={1} hidden={divShowAccor[1]}>
                          <hr />
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <div className='teamCardHeading'>
                              <Table responsive='sm xl lg xs md '>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Work Title</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.members?.map((member) => {
                                    return (
                                      <tr>
                                        <td>
                                          {member?.fullName}
                                          <div className='teamStatus'>
                                            {member.isPayingMember === true ? (
                                              <span className='payingMember '>
                                                Paying Member
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {member.isAdministrator === true ? (
                                              <span className='adminstrator'>
                                                Adminstrator
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </td>
                                        <td>{member?.email}</td>
                                        <td>{member?.phoneNumber}</td>
                                        <td>{member?.workTitle}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </div>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </animated.div>

            <animated.div style={openAnimation}>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12} className='p-3'>
                  <Card className='membershipCard'>
                    <Row>
                      <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                        <Row>
                          <Col md={11} xl={11} lg={11} sm={11} xs={11}>
                            <div className='membershipCardHeading'>
                              <h3>Payment history</h3>
                            </div>
                          </Col>
                          <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                            <div className='toggleExpand'>
                              <div className='toggleStyle'>
                                <animated.i role='button'>
                                  <ExpandMoreIcon
                                    onClick={(e) => toggleHandler(e)}
                                    id={2}
                                  />
                                </animated.i>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Row>
                        <div id={2} hidden={divShowAccor[2]}>
                          <hr />
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <div className='teamCardHeading'>
                              <Table responsive='sm xl lg xs md '>
                                <thead>
                                  <tr>
                                    <th>Invoice ID</th>
                                    <th>Due date</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                    <th> </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teamInvoices?.map((invoices) => {
                                    return (
                                      <tr>
                                        <td>{invoices?.InvoiceNo}</td>
                                        <td>
                                          {moment(invoices?.DueDate).format(
                                            "DD MMMM, YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {`${invoices?.currencyCode}  ${invoices?.TotalPrice}`}
                                        </td>
                                        {invoices?.status === "pending" ? (
                                          <td className='pending'>Pending</td>
                                        ) : invoices?.status === "objected" ? (
                                          <td className='objection'>
                                            Objection
                                          </td>
                                        ) : invoices?.status === "approved" ? (
                                          <td className='paid'>Paid</td>
                                        ) : invoices?.status === "deny" ? (
                                          <td className='denied'>Denied</td>
                                        ) : (
                                          ""
                                        )}
                                        <td>
                                          <span
                                            className='invoceAchnerDetail'
                                            role='button'
                                            onClick={() =>
                                              navigate("/invoice-detail", {
                                                state: {
                                                  data: {
                                                    invoice: invoices,
                                                    company: data,
                                                  },
                                                },
                                              })
                                            }
                                          >
                                            View details
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </div>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              </Row>{" "}
            </animated.div>
            <Row>
              <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
              <Col md={4} xl={4} lg={4} sm={12} xs={12} className='p-3'></Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <AddMember show={modelShow} setModelShow={setModelShow} />
      <ImageView show={showImg} setModelShow={setShowImg} image={image} />
    </>
  );
};

export default TeamsDetail;
