import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import dayPass from "../../../services/dayPassApi";
import teamInvoices from "../../../services/teamInvoicesApi";
import {
  useDaypassObjectedMutaion,
  useDaypassApprovedMutaion,
  useDaypassDeniedMutaion,
  useDaypassRefundMutaion,
} from "../../../rc-query/daypassHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";

import { useQuery } from "react-query";
import "../css/styles.css";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [invoicePic, setsetInvoicePic] = useState();
  const [declineModel, setDeclineModel] = useState(false);
  const [objectionModel, setObjectionModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const objectionMutation = useDaypassObjectedMutaion(props.history);
  const approvalMutation = useDaypassApprovedMutaion(props.history);
  const deniedMutation = useDaypassDeniedMutaion(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  const redundMutation = useDaypassRefundMutaion(props.history);

  const { data: dayPassData, isLoading: passDataLoading } = useQuery(
    ["dayPassData", data],
    () => dayPass.dayPassDetail(data),
    {
      retry: 0,
    }
  );

  const { data: invoiceTax, isLoading: invoiceTaxLoading } = useQuery(
    ["calculateInvoice", data],
    () => teamInvoices.getTeamInvoicesTax(),
    {
      retry: 0,
    }
  );

 const invId =  dayPassData?.daypass?.CoworkerInvoiceNumber
   // // ********* invoice start ***********
   const { data: invoiceListData } = useQuery(
    ["invoiceListData",dayPassData],
    () => teamInvoices.invoice(invId),
    {
      retry: 0,
    }
  );

  const invData = invoiceListData?.data?.data[0];

  

  const calculateVat = () => {
    if (invoiceTax?.isTaxEnable === "true") {
      const calculatedTax =
        dayPassData?.daypass?.Price * (invoiceTax?.taxRate / 100);

      return parseFloat(calculatedTax.toFixed(2));
    } else {
      return 0;
    }
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const imageViewFunc = (value, pic) => {
    setsetInvoicePic(pic);
    setImageView(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };

  const objectionModelFunc = (value) => {
    setObjectionModel(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  const requestObjectedFunc = (reason) => {
    setLoading(true);

    const objData = {
      reason: reason,
      id: data,
    };

    objectionMutation
      .mutateAsync(objData)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully Objected", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setLoading(false);

          generateErrMutaion
            .mutateAsync({
              action: "Day Pass Invoice Objection",
              severity: "info",
              status: "Successfull",
              component: "Day Pass Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Day Pass Invoice Objection",
            severity: "info",
            status: "Failed",
            component: "Day Pass Requests",
          })
          .then((data) => {});
      });
    setObjectionModel(false);
  };

  const approvalFunc = () => {
    setLoading(true);

    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        setLoading(false);
        setModelShow(false);
        if (data?.status === 200) {
          addToast("Successfully approved", {
            appearance: "success",
            autoDismiss: true,
          });
          // navigate(-1);
          navigate("/day-pass")
          

          // generateErrMutaion
          //   .mutateAsync({
          //     action: "Day Pass  approval",
          //     severity: "info",
          //     status: "Successfull",
          //     component: "Day Pass Requests",
          //   })
          //   .then((data) => {});
        }
      })
      .catch((err) => {
      
        setLoading(false);
        setModelShow(false);
        navigate("/day-pass")
        addToast(err?.message, {
          appearance: "error",
          autoDismiss: true,
        });
        // generateErrMutaion
        //   .mutateAsync({
        //     action: "Day Pass approval",
        //     severity: "info",
        //     status: "Failed",
        //     component: "Day Pass Requests",
        //   })
        //   .then((data) => {});
      });
  };

  const deniedFunc = () => {
    setLoading(true);

    const objData = {
      id: data,
      reason: declineReason,
    };

    deniedMutation
      .mutateAsync(objData)
      .then((data) => {
        setLoading(false);
        if (data?.status === 200) {
          addToast("Successfully denied", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setModelShow1(false);

          generateErrMutaion
            .mutateAsync({
              action: "Day Pass deny",
              severity: "info",
              status: "Successfull",
              component: "Day Pass Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        setModelShow1(false);
        setLoading(false);
        generateErrMutaion
          .mutateAsync({
            action: "Dau Pass deny",
            severity: "info",
            status: "Failed",
            component: "Day Pass Requests",
          })
          .then((data) => {});
      });
  };

  const approval = () => {
    setModelShow(true);
  };

  const rejectApproval = () => {
    setDeclineModel(true);
  };

  const objectionFunc = () => {
    setObjectionModel(true);
  };

  const handleRefundPayment = () => {
    redundMutation.mutateAsync().then((data) => {
      // if (data?.status === 200) {
      //   addToast("Successfully Objected", {
      //     appearance: "success",
      //     autoDismiss: true,
      //   });
      //   navigate(-1);
      //   setLoading(false);
      //   generateErrMutaion
      //     .mutateAsync({
      //       action: "Day Pass Invoice Objection",
      //       severity: "info",
      //       status: "Successfull",
      //       component: "Day Pass Requests",
      //     })
      //     .then((data) => {});
      // }
    });
    // .catch((err) => {
    //   generateErrMutaion
    //     .mutateAsync({
    //       action: "Day Pass Invoice Objection",
    //       severity: "info",
    //       status: "Failed",
    //       component: "Day Pass Requests",
    //     })
    //     .then((data) => {});
    // });
  };

  return {
    invData,
    declineModel,
    modelShow,
    modelShow1,
    loading,
    objectionModel,
    imageView,
    passDataLoading,
    dayPassData,
    invoicePic,
    invoiceTaxLoading,
    calculateVat,
    handleRefundPayment,
    deniedFunc,
    approvalFunc,
    requestObjectedFunc,
    imageViewFunc,
    objectionFunc,
    objectionModelFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setDeclineFunc,
  };
};
