import { useEffect } from "react";
import { useQuery } from "react-query";
import { membershipTypeSettingApi } from "../../../services/membershipTypeSetting";

export const UseMembershipDetailInfo = (props) => {
  // get plans
  const {
    data: membershipsettingplansDataa,
    isLoading: membershipsettingplansDataLoading,
  } = useQuery([""], () =>
    membershipTypeSettingApi.membershipTypePlanListing()
  );
  const membershipsettingplansData = membershipsettingplansDataa?.data;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { membershipsettingplansData };
};
