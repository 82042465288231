import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/verticalLayout";
import Header from "../components/header";
import { Col, Container, Row } from "react-bootstrap";
import "./css/auth.css";
import React, { useState } from "react";
import { TabContext } from "../context/TabContext";

const useAuth = () => {
  const token = localStorage.getItem("token");
  if (token) return true;
  else return false;
};

const PrivateRoute = ({ allowedRoles }) => {
  const [navShow, setNavShow] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");

  const isAuth = useAuth();
  return isAuth ? (
    <TabContext.Provider value={{ activeTabJustify, setActiveTabJustify }}>
      <div className='scroller'>
        <Row>
          <Col md={3} xl={3} lg={3} sm={12} xs={12} className='sidebar-width'>
            <div className={` ${navShow ? "tab-show" : "nav-tab"}`}>
              <Sidebar
                navshow={navShow}
                setNavShow={setNavShow}
                className='sidebar'
              />
            </div>
          </Col>
          <Col
            md={9}
            xl={9}
            lg={9}
            sm={12}
            xs={12}
            // style={{ width: "80%" }}
            className='col-header'
          >
            <Row className='header-row'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                {" "}
                <Header
                  navshow={navShow}
                  setNavShow={setNavShow}
                  className='header-row'
                  fixed='top'
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <Container fluid className=' maincontainercss'>
                  <Outlet />
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </TabContext.Provider>
  ) : (
    <div>
      <Navigate replace to='/login' />
    </div>
  );
};

export default PrivateRoute;
