import { useAccessControllInfo } from "./useAccessControll";
import { useDepartmentsInfo } from "../departmentsPage/useDepartments";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  Col,
  Row,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Spinner,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { VscRefresh } from "react-icons/vsc";
import BootstrapTable from "react-bootstrap-table-next";
import { Shimmer } from "react-shimmer";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "../css/styles.css";
import { Navigate } from "react-router";
import moment from "moment";
// const Loader = require("react-loader");
const { SearchBar } = Search;

const AccessControll = (props) => {
  const {
    checkIns,
    checkInsLoading,
    lastRefresh,
    navigate,
    refreshCheckin,
    calculateTimeDiff,
  } = useAccessControllInfo(props);

  const { departmentListsLoading, departmentLists } = useDepartmentsInfo(props);

  return (
    <>
      <Row className='accessControlPage p-3 pt-5'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Row>
            <Col md={8} lg={8} xl={8} sm={12} xs={12}>
              <h4>Access Control</h4>
            </Col>
            <Col md={4} lg={4} xl={4} sm={12} xs={12}>
              <div className='d-flex'>
                <Button
                  className='waves-effect waves-light w-100 sync-btn'
                  // onClick={(e) => {
                  //   rejectApproval(data?.id);
                  // }}
                >
                  Sync
                </Button>
                <Button
                  className='waves-effect waves-light w-100 datails-btn2'
                  onClick={(e) => {
                    navigate("/access-settings");
                  }}
                >
                  Settings
                </Button>
              </div>
            </Col>
          </Row>

          <Row className=' mt-4'>
            <Col md={8} lg={8} xl={8} sm={12} xs={12}>
              <Card className=' deparymentsAccessCard   p-3 '>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <h4>Departments</h4>
                    <p>Access Levels allocated to departments </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    {!departmentListsLoading ? (
                      <div className='departmentCardsContainer '>
                        {departmentLists?.map((deparment, index) => {
                          return (
                            <Card
                              className='departmentCards px-3 py-2 '
                              key={deparment.id}
                            >
                              <div className='d-flex justify-content-between  align-items-center'>
                                <div>
                                  <h5>{deparment.name}</h5>
                                </div>

                                <div className=' w-75 text-wrap'>
                                  <span>Access levels</span>
                                  <p>
                                    {deparment.accessLevels?.map(
                                      (accesslevel, index) => {
                                        return ` ${accesslevel.name},`;
                                      }
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <Card className='shimmerCard  p-3 mt-3 '>
                          <div className='d-flex justify-content-between '>
                            <span>
                              <Shimmer
                                width={120}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span>
                              <Shimmer
                                width={150}
                                height={8}
                                className='mt-3'
                              />
                              <Shimmer
                                width={300}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span></span>
                          </div>
                        </Card>
                        <Card className='shimmerCard  p-3 mt-3 '>
                          <div className='d-flex justify-content-between '>
                            <span>
                              <Shimmer
                                width={120}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span>
                              <Shimmer
                                width={150}
                                height={8}
                                className='mt-3'
                              />
                              <Shimmer
                                width={300}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span></span>
                          </div>
                        </Card>
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
              {/* <Row>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className='inner-custom-pagination d-flex  deparymentsAccessCard   mt-3'>
                    <span className='paginationitmes'>Items</span>
                    <div className='d-inline pagination-btn '>
                      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                        <DropdownToggle caret className='pageBtn'>
                          {pageSize}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            value='1'
                            onClick={() => pageSizeFunc(10)}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            value='2'
                            onClick={() => pageSizeFunc(30)}
                          >
                            30
                          </DropdownItem>
                          <DropdownItem
                            value='3'
                            onClick={() => pageSizeFunc(50)}
                          >
                            50
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>

                    <span className='paginationitmes1 '>Show</span>

                    <div className='text-md-right ms-auto '>
                      <AiOutlineLeft
                        className='page-btn'
                        onClick={() => handleIncrementBtn(false)}
                        role='button'
                      />

                      {totalPages()}

                      <AiOutlineRight
                        className='page-btn'
                        onClick={() => handleIncrementBtn(true)}
                        role='button'
                      />
                    </div>
                  </div>
                </Col>
              </Row> */}
            </Col>
            <Col md={4} lg={4} xl={4} sm={12} xs={12}>
              <Row className='checkinContainer'>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between accessControllCheckIn'>
                    <div>
                      <div className='d-flex'>
                        <h3>Check-ins</h3>
                        <VscRefresh
                          className={`page-btn mx-3  ${
                            checkInsLoading ? "rotate" : ""
                          }`}
                          size={18}
                          color='#828282'
                          role='button'
                          onClick={() => refreshCheckin()}
                        />{" "}
                      </div>
                      <p>
                        Last refresh :{" "}
                        {moment(lastRefresh).format("DD MMMM, YYYY h:mm a")}
                      </p>
                    </div>
                    <div role='button' onClick={() => navigate("/access-log")}>
                      <span>See all</span>
                    </div>
                  </div>
                </Col>

                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    {!checkInsLoading ? (
                      <div className='checkinCardsContainer'>
                        {/* <Card className='checkinCards red p-3 mt-3'>
                          <h3>Omar Nayaan</h3>
                          <div className='d-flex justify-content-between '>
                            <span>10 mins ago</span>
                            <span>Meeting room 3</span>
                          </div>
                        </Card> */}
                        {checkIns?.map((users, index) => {
                          return (
                            <Card
                              className={
                                users.eventLevel === 0
                                  ? "checkinCards green p-3 mt-3"
                                  : "checkinCards red p-3 mt-3"
                              }
                              key={users?.id}
                            >
                              <div className='d-flex justify-content-between '>
                                <h3>{users?.name}</h3>
                                <span>{users?.eventName}</span>
                              </div>
                              <div className='d-flex justify-content-between '>
                                <span>
                                  {calculateTimeDiff(users?.eventTime)}
                                </span>
                                <span>{users?.eventPointName}</span>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <Card className='shimmerCard  p-3 mt-3 '>
                          <h3>
                            <Shimmer width={120} height={8} />
                          </h3>
                          <div className='d-flex justify-content-between '>
                            <span>
                              <Shimmer
                                width={120}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span>
                              <Shimmer
                                width={100}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                          </div>
                        </Card>
                        <Card className='shimmerCard  p-3 mt-3 '>
                          <h3>
                            <Shimmer width={120} height={8} />
                          </h3>
                          <div className='d-flex justify-content-between '>
                            <span>
                              <Shimmer
                                width={120}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                            <span>
                              <Shimmer
                                width={100}
                                height={8}
                                className='mt-3'
                              />
                            </span>
                          </div>
                        </Card>
                      </>
                    )}
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AccessControll;
