import { useState, useRef, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import {
  useAccountCreateOtpMutation,
  useVerifyLockedAccountMutation,
} from "../../rc-query/usersHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useAccountLockedInfo = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState();
  const [otpVerify, setOtpVerify] = useState(false);

  const createOtpMutation = useAccountCreateOtpMutation(props.history);
  const verifyAccountMutation = useVerifyLockedAccountMutation(props.history);

  const handleVerifyAccount = () => {
    createOtpMutation
      .mutateAsync(email)
      .then((data) => {
        if (data?.status === 200) {
          setOtpVerify(true);
        }
      })
      .catch((error) => {
        toast.success(error?.response?.data?.error?.message);
      });
  };

  const handleVerifyOtp = () => {
    setOtpVerify(true);
    const data = {
      email: String(email),
      otp: Number(otp),
    };

    verifyAccountMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Account is unlocked");
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error?.message);
      });
  };

  return {
    email,
    otp,
    otpVerify,
    createOtpMutation,
    verifyAccountMutation,
    handleVerifyAccount,
    handleVerifyOtp,
    setEmail,
    setOtp,
  };
};
