import { useDetailInfo } from "./useDetail";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import Table from "react-bootstrap/Table";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import "../css/styles.css";
import ObjectionModel from "../../../components/molecules/objectionModel";
import ImageView from "../../../components/molecules/imageViewer";
import moment from "moment";
const Loader = require("react-loader");

const DayPassDetail = (props) => {
  const {
    invData,
    declineModel,
    modelShow,
    modelShow1,
    loading,
    objectionModel,
    imageView,
    passDataLoading,
    dayPassData,
    calculateVat,
    invoiceTaxLoading,
    invoicePic,
    handleRefundPayment,
    deniedFunc,
    approvalFunc,
    requestObjectedFunc,
    imageViewFunc,
    objectionFunc,
    objectionModelFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setDeclineFunc,
  } = useDetailInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <>
      {!passDataLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className='tourDetailPage mt-4'>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Card className='toursCardView p-4'>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='detailPageHeader'>
                        <h3>Personal Details</h3>
                      </div>
                      <hr />
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>First Name</h5>
                            <h6>{dayPassData?.user?.firstName}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Last Name</h5>
                            <h6>{dayPassData?.user?.lastName}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Phone</h5>
                            <h6>{dayPassData?.user?.phoneNumber}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Employment Status</h5>
                            <h6>{dayPassData?.user?.employmentStatus}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Email Address</h5>
                            <h6>{dayPassData?.user?.email}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Occupation</h5>
                            <h6>{dayPassData?.user?.occupation}</h6>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col
                    md={12}
                    lg={6}
                    xl={6}
                    sm={12}
                    xs={12}
                    className='bookingCardCol'
                  >
                    <Card className='toursCardView p-4 h-100 mt-4'>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='detailPageHeader'>
                            <h3>Booking Details</h3>
                          </div>
                          <hr />
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <div className='info-heading cardHeading'>
                                <h5>Resource</h5>
                                <h6>{dayPassData?.daypass?.ResourceName}</h6>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <div className='info-heading cardHeading'>
                                <h5>Booking Requested</h5>
                                <h6>
                                  {moment
                                    .utc(dayPassData?.daypass?.BookedOn)
                                    .format("DD MMMM,YYYY h:mm a")
                                    .toString()}
                                </h6>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <div className='info-heading cardHeading'>
                                <h5>Booking Created</h5>
                                <h6>
                                  {moment(
                                    dayPassData?.daypass?.bookingCreated
                                  ).format("DD MMMM, YYYY h:mm a")}
                                </h6>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              {" "}
                              <div className='info-heading cardHeading'>
                                <h5>Booking Date</h5>
                                <h6>
                                  {moment(dayPassData?.daypass?.FromTime)
                                    .format("DD MMMM,YYYY")
                                    .toString()}
                                </h6>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <div className='info-heading cardHeading'>
                                <h5>Email Address</h5>
                                <h6>{dayPassData?.user?.email}</h6>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    md={12}
                    lg={6}
                    xl={6}
                    sm={12}
                    xs={12}
                    className='paymentCardCol'
                  >
                    <Card className='toursCardView p-4 h-100 mt-4 '>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='detailPageHeader'>
                            <h3>Payment Details</h3>
                            {(dayPassData?.daypass?.status === "deny" ||
                              dayPassData?.daypass?.status === "cancelled") &&
                              dayPassData?.daypass?.totalPayable > 0 ? (
                              <div className='w-25 d-flex justify-content-end'>
                                {/* <Button
                                  className='tours-add-slot-btn w-100'
                                  onClick={() => {
                                    handleRefundPayment();
                                  }}
                                >
                                  Refund
                                </Button> */}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <div className='info-heading cardHeading'>
                                    <h5>Day pass</h5>
                                    <h6>
                                      {process.env.REACT_APP_CURRENCY_CODE}{" "}
                                      {/* {Number(invData?.SubTotal+ invData?.DiscountAmount).toFixed(2)} */}
                                      {dayPassData?.daypass?.subTotal}
                                      
                                    </h6>
                                  </div>
                                </Col>
                              </Row>
                          <hr />
                          <Row>
                          
                              {/* <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <div className='info-heading cardHeading'>
                                    <h5>Day(s)</h5>
                                    <h6>1 d</h6>
                                  </div>
                                </Col>
                              </Row> */ }
                              <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <div className='info-heading cardHeading'>
                                    <h5>Sub total</h5>
                                    <h6>
                                     
                                    {/* {Number(invData?.SubTotal+ invData?.DiscountAmount).toFixed(2)} */}
                                    {dayPassData?.daypass?.subTotal}
                                    </h6>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <div className='info-heading cardHeading'>
                                    <h5>Vat ({
                                    // invData?.TaxRate
                                    dayPassData?.daypass?.vatPercentage
                                    }%)</h5>
                                    <h6>
                                      {process.env.REACT_APP_CURRENCY_CODE}{" "}
                                      {/* {invoiceTaxLoading ? 0 : calculateVat()} */}
                                      {
                                      // Number(invData?.TaxAmount).toFixed(2)
                                      dayPassData?.daypass?.vat
                                      }
                                    </h6>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <div className='info-heading cardHeading'>
                                    <h5>Discount 
                                      {/* {dayPassData?.daypass?.discountCode?dayPassData?.daypass?.discountCode:"-"} */}
                                    (  
                                      {/* {invData?.DiscountCode} */}
                                      {dayPassData?.daypass?.discountCode}
                                       )
                                    </h5>
                                    <h6>
                                      {/* {dayPassData?.daypass?.discountedAmount}{" "} */}
                                      {
                                        // Number(invData?.DiscountAmount).toFixed(2)
                                        dayPassData?.daypass?.discountedAmount
                                      }
                                      
                                    </h6>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <hr />{" "}
                                  <div className='info-heading cardHeading'>
                                    <h5>Total amount</h5>
                                    <h6>
                                      {`${process.env.REACT_APP_CURRENCY_CODE}`}{" "}
                                      {/* {((
                                        dayPassData?.daypass?.Price  +
                                        calculateVat()
                                      )-(dayPassData?.daypass?.discountedAmount?dayPassData?.daypass?.discountedAmount:0)).toFixed(2)} */}
                                      {/* {Number(invData?.SubTotal).toFixed(2)} */}
                                      {dayPassData?.daypass?.totalPayable}
                                    </h6>
                                  </div>
                                </Col>
                              </Row>
                         

                            <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                              <div
                                className='imgContainer'
                                role='button'
                                onClick={() =>
                                  imageViewFunc(
                                    true,
                                    dayPassData?.daypass?.InvoiceDoc
                                  )
                                }
                              >
                                {dayPassData?.daypass?.InvoiceDoc ? (
                                  <>
                                    {" "}
                                    <img
                                      src={dayPassData?.daypass?.InvoiceDoc}
                                      alt='invoiceImg'
                                      width='100'
                                      height={130}
                                    />
                                    <i className='fullviewBtn'>View Full</i>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <Card className='membershipCard mt-5'>
                      <Row>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='membershipCardHeading'>
                            <h3>Payment objections</h3>
                          </div>
                          <hr />
                        </Col>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='objectionTable'>
                            <Table responsive='sm xl lg xs md '>
                              <thead>
                                <tr>
                                  <th>Invoices</th>
                                  <th>Customer</th>
                                  <th>Reason</th>
                                  <th>User's Comment</th>
                                  <th>Date</th>
                                  <th>Archive</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dayPassData?.pastRequests?.map(
                                  (dayPass, index) => {
                                    return dayPass?.PaymentsObjections?.map(
                                      (objections, index) => {
                                        return (
                                          <tr key={dayPass.id}>
                                            <td>
                                              {objections.paymentInvoiceNo}
                                            </td>
                                            <td>{dayPassData?.user?.email}</td>
                                            <td>{objections.reason}</td>
                                            <td>
                                              {objections.paymentComments}
                                            </td>
                                            <td>
                                              {moment(objections.date)
                                                .format("DD MMMM,YYYY")
                                                .toString()}
                                            </td>

                                            <td>
                                              {objections.paymentProve ? (
                                                <i
                                                  role='button'
                                                  onClick={() =>
                                                    imageViewFunc(
                                                      true,
                                                      objections.paymentProve
                                                    )
                                                  }
                                                >
                                                  View
                                                </i>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row className='mt-5'>
                  <Col md={6} xl={6} lg={6} sm={12} xs={12}></Col>
                  <Col md={6} xl={6} lg={6} sm={12} xs={12}>
                    {dayPassData?.daypass?.status === "pending" ? (
                      <div className='membershipDetailBtn mt-4'>
                        <Button
                          className='waves-effect waves-light w-100 objectionBtn'
                          onClick={(e) => {
                            objectionFunc();
                          }}
                        >
                          Objection
                        </Button>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn1'
                          onClick={(e) => {
                            rejectApproval();
                          }}
                        >
                          Decline
                        </Button>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn2'
                          onClick={(e) => {
                            approval();
                          }}
                        >
                          Approve
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <Card className='membershipCard mt-4'>
                      <Row>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='membershipCardHeading'>
                            <h3>Past Requests</h3>
                          </div>
                          <hr />
                        </Col>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='teamCardHeading'>
                            <Table responsive='sm xl lg xs md '>
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>Booking Date</th>
                                  <th>Resource</th>
                                  <th>Request Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dayPassData?.pastRequests?.map(
                                  (dayPass, index) => {
                                    if (
                                      dayPass.InvoiceNo !==
                                      dayPassData?.daypass?.InvoiceNo
                                    ) {
                                      if (dayPass.status === "deny") {
                                        return (
                                          <tr key={dayPass.id}>
                                            <td>{dayPass.InvoiceNo}</td>
                                            <td>{dayPass.CoworkerName}</td>
                                            <td>
                                              {moment(dayPass?.BookedOn)
                                                .format("DD MMMM,YYYY")
                                                .toString()}
                                            </td>
                                            <td>{dayPass.ResourceName}</td>
                                            <td className='d-flex'>
                                              <button
                                                className='detailDeniedBtn'
                                                disabled
                                              >
                                                DENIED
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      } else if (
                                        dayPass.status === "approved"
                                      ) {
                                        return (
                                          <tr key={dayPass.id}>
                                            <td>{dayPass.InvoiceNo}</td>
                                            <td>{dayPass.CoworkerName}</td>
                                            <td>
                                              {moment(dayPass?.BookedOn)
                                                .format("DD MMMM,YYYY")
                                                .toString()}
                                            </td>
                                            <td>{dayPass.ResourceName}</td>
                                            <td className='d-flex'>
                                              <button
                                                className='detailApprovedBtn'
                                                disabled
                                              >
                                                APPROVED
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      } else if (
                                        dayPass.status === "rescheduled"
                                      ) {
                                        return (
                                          <tr key={dayPass.id}>
                                            <td>{dayPass.InvoiceNo}</td>
                                            <td>{dayPass.CoworkerName}</td>
                                            <td>
                                              {moment(dayPass?.BookedOn)
                                                .format("DD MMMM,YYYY")
                                                .toString()}
                                            </td>
                                            <td>{dayPass.ResourceName}</td>
                                            <td className='d-flex'>
                                              <button
                                                className='detailDeniedBtn'
                                                disabled
                                              >
                                                RESCHEDULED
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      } else if (
                                        dayPass.status === "cancelled"
                                      ) {
                                        return (
                                          <tr key={dayPass.id}>
                                            <td>{dayPass.InvoiceNo}</td>
                                            <td>{dayPass.CoworkerName}</td>
                                            <td>
                                              {moment(dayPass?.BookedOn)
                                                .format("DD MMMM,YYYY")
                                                .toString()}
                                            </td>
                                            <td>{dayPass.ResourceName}</td>
                                            <td className='d-flex'>
                                              <button
                                                className='detailDeniedBtn'
                                                disabled
                                              >
                                                CANCELLED
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    }
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <ApproveModel
        approvalFunc={approvalFunc}
        show={modelShow}
        setModelShow={modelFunc}
        setApproved={modelFunc}
        request={"dayPass"}
        loading={loading}
        headerText='Approve Request'
        textBody='Are you sure you want to approve this request?'
      />
      <ApproveModel
        show={modelShow1}
        deniedFunc={deniedFunc}
        setModelShow={modelFunc1}
        request={"deny"}
        loading={loading}
        headerText='Rejected Request'
        textBody='Are you sure you want to reject this request?'
      />

      <DeclineModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        templates={templatesList?.data}
        setDeclineReason={setDeclineFunc}
      />
      <ObjectionModel
        show={objectionModel}
        loading={loading}
        setModelShow={objectionModelFunc}
        objectionFunc={requestObjectedFunc}
      />

      <ImageView
        show={imageView}
        setModelShow={imageViewFunc}
        image={invoicePic}
      />
    </>
  );
};

export default DayPassDetail;
