import { useLogsInfo } from "./useLogs";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  Col,
  Row,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment";
import "../css/styles.css";
const Loader = require("react-loader");

const Logs = (props) => {
  const {
    columns,
    exportLogsList,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    logsList,
    logsLoading,
    headers,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    toggleCustomJustified,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  } = useLogsInfo(props);

  return (
    <div>
      <Row className='p-4 '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div>
            <Row className='mb-2 p-2 tab-row'>
              <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                <div>
                  <Nav className=' nav-justified nav-width nav-btn'>
                    <NavItem className='nav-item'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "1",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className='d-none d-sm-block '>All</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "2",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className='d-none d-sm-block '>Successfull</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "3",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("3");
                        }}
                      >
                        <span className='d-none d-sm-block '>Failed</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col lg={3} md={2} sm={3} xs={12} xl={3}></Col>
              <Col lg={3} md={5} sm={5} xs={6} xl={2} className='searchBarCol'>
                <Input
                  type='text'
                  className='searchbcss'
                  placeholder='Search'
                  onChange={(e) => {
                    handleSearchFunc(e);
                  }}
                />
              </Col>
              <Col lg={2} md={5} sm={4} xs={6} xl={2}>
                <Input
                  type='date'
                  id='example-date-input'
                  className=' search-bar '
                  onChange={(e) => {
                    setSearchDate(e);
                  }}
                />
              </Col>
            </Row>
            <ToolkitProvider
              keyField='id'
              columns={columns}
              key={logsList ? logsList.id : ""}
              data={logsList ? logsList?.logs : []}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  {!logsLoading ? (
                    <Row className='data-wrap'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <Card className='input-wrap2 p-2 '>
                          <div className='table-responsive table-wrap '>
                            <BootstrapTable
                              loading={true}
                              hover
                              keyField='id'
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle tableBody view-table "
                              }
                              headerWrapperClasses={"thead-light"}
                              headerClasses='tableHeadercss'
                              wrapperClasses='table-responsive'
                              {...toolkitProps.baseProps}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </div>
                          <div className='inner-custom-pagination d-flex '>
                            <span className='paginationitmes'>Items</span>
                            <div className='d-inline pagination-btn '>
                              <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                                <DropdownToggle caret className='pageBtn'>
                                  {pageSize}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    value='1'
                                    onClick={() => pageSizeFunc(10)}
                                  >
                                    10
                                  </DropdownItem>
                                  <DropdownItem
                                    value='2'
                                    onClick={() => pageSizeFunc(30)}
                                  >
                                    30
                                  </DropdownItem>

                                  <DropdownItem
                                    value='3'
                                    onClick={() => pageSizeFunc(50)}
                                  >
                                    50
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                            <span className='paginationitmes1 '>Show</span>
                            <div className='d-flex mx-3'>
                              <CSVLink
                                className='csvButton'
                                data={
                                  exportLogsList ? exportLogsList?.logs : []
                                }
                                headers={headers}
                                filename={
                                  activeTabJustify === "1"
                                    ? "allLogs.csv"
                                    : activeTabJustify === "2"
                                    ? "pendingLogs.csv"
                                    : activeTabJustify === "3"
                                    ? "approvedLogs.csv"
                                    : activeTabJustify === "4"
                                    ? "deniedLogs.csv"
                                    : ""
                                }
                              >
                                Export
                              </CSVLink>
                            </div>
                            <div className='text-md-right ms-auto'>
                              <span className='arrowPaginationBtn'>
                                <AiOutlineLeft
                                  className='page-btn'
                                  onClick={() => handleIncrementBtn(false)}
                                  role='button'
                                />
                              </span>

                              {totalPages()}

                              <span>
                                {" "}
                                <AiOutlineRight
                                  className='page-btn'
                                  onClick={() => handleIncrementBtn(true)}
                                  role='button'
                                />
                              </span>
                            </div>
                          </div>
                        </Card>

                        <Row className='align-items-md-center mt-3 bottom-btn '>
                          <Col className='inner-custom-pagination d-flex'></Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Loader className='spinner' />
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Logs;
