// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCcdY2_xnBTzxlxyaSwEwRM2qJ5Q0vYV5M",
    authDomain: "fintech-5e50d.firebaseapp.com",
    projectId: "fintech-5e50d",
    storageBucket: "fintech-5e50d.appspot.com",
    messagingSenderId: "597153010623",
    appId: "1:597153010623:web:39846ab58e9a0d1bec470a",
    measurementId: "G-LY8DXKS807"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseDB = getDatabase(app);