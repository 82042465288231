import { useMutation } from "react-query";
import { PATCH_MEMBER_REQUEST } from "./keys";

import memberRequest from "../services/memberRequestApi";

export const useMeetingRoomObjectedMutaion = (history) => {
  const response = useMutation(memberRequest.patchMemberRequest, {
    mutationKey: PATCH_MEMBER_REQUEST,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
