import { useBookingPaymentInfo } from "./useBookingPayment";
import { Card, Col, Row, Button, Input } from "reactstrap";
import Switch from "react-switch";
import "./css/styles.css";
const Loader = require("react-loader");

const BookingPayment = (props) => {
  const {
    discountCode,
    dayPassConfirm,
    dayPassDiscountConfirm,
    dayPassDiscountPercent,
    daypassDiscount,
    meetingRoomDiscountConfirm,
    passUserMeetingRoomConfirmation,
    memberMeetingRoomConfirmation,
    bookingSettingLoading,
    saveBtn,
    setSaveBtn,
    createBookingSetting,
    daypassDiscountFunc,
    setDayPassConfirm,
    setPassUserMeetingRoomConfirmation,
    setMemberMeetingRoomConfirmation,
    setDayPassDiscountConfirm,
    setDayPassDiscountPercent,
    setMeetingRoomDiscountConfirm,
  } = useBookingPaymentInfo(props);
  return (
    <>
      {!bookingSettingLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className='bookingSettingPage mt-4'>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Card className='bookingSettingCardViwe p-4 pb-5'>
                  <h1>Booking and Payment Configuration</h1>
                  <p>
                    These settings define how the bookings and payments are
                    managed by the system.
                  </p>

                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='w-100 confirmBookingContainer p-3'>
                        <h2>Booking Confirmation</h2>
                        <Card className='p-3 pb-4'>
                          <Row>
                            <Col md={12} lg={6} xl={6} sm={12} xs={12}>
                              <h1>Day pass</h1>
                              <p>
                                Should this booking be confirmed by an admin?
                              </p>
                              <Row>
                                <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Day Pass User</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                        <Switch
                                          id={1}
                                          checked={dayPassConfirm}
                                          onChange={(e) => {
                                            setDayPassConfirm(e);
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                          width={40}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          checkedHandleIcon={false}
                                          height={20}
                                          onColor='#0129FA'
                                          className='mr-1 mx-2 d-flex  mt-1'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              md={12}
                              lg={6}
                              xl={6}
                              sm={12}
                              xs={12}
                              className='meetingRoomCol'
                            >
                              <h1>Meeting room</h1>
                              <p>
                                Should this booking be confirmed by an admin?
                              </p>

                              <Row>
                                <Col
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sm={12}
                                  xs={12}
                                  className='dayToggleCard'
                                >
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Day Pass User</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                      
                                        <Switch
                                          id={2}
                                          checked={
                                            passUserMeetingRoomConfirmation
                                          }
                                          onChange={(e) => {
                                            setPassUserMeetingRoomConfirmation(
                                              e
                                            );
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                          width={40}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          checkedHandleIcon={false}
                                          height={20}
                                          onColor='#0129FA'
                                          className='mr-1 mx-2 d-flex  mt-1'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sm={12}
                                  xs={12}
                                  className='membershipHolderCol'
                                >
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Membership holders</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                        <Switch
                                          id={3}
                                          checked={
                                            memberMeetingRoomConfirmation
                                          }
                                          onChange={(e) => {
                                            setMemberMeetingRoomConfirmation(e);
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                          width={40}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          checkedHandleIcon={false}
                                          height={20}
                                          onColor='#0129FA'
                                          className='mr-1 mx-2 d-flex  mt-1'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </Col>
                  </Row>

                  <Row className='pt-4'>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='w-100 confirmBookingContainer p-3'>
                        <h2>Discounts </h2>
                        <Card className='p-3 pb-4'>
                          <Row>
                            <Col md={12} lg={6} xl={6} sm={12} xs={12}>
                              <h1>Day pass users</h1>
                              <p>
                                Should a default discount be applied on
                                bookings?
                              </p>
                              <Row>
                                <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Discount</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                        <Switch
                                          id={4}
                                          checked={dayPassDiscountConfirm}
                                          onChange={(e) => {
                                            setDayPassDiscountConfirm(e);
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                          width={40}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          checkedHandleIcon={false}
                                          height={20}
                                          onColor='#0129FA'
                                          className='mr-1 mx-2 d-flex  mt-1'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sm={12}
                                  xs={12}
                                  className='dayPassDiscountDDCol'
                                >
                                  {/* <select
                                    className='dayPassDiscountDD px-2'
                                    onChange={(e) => {
                                      daypassDiscountFunc(e.target.value);
                                      setSaveBtn("tours-setting-btn");
                                    }}
                                    disabled={!dayPassDiscountConfirm}
                                    value={daypassDiscount}
                                  >
                                    <option value='null' disabled selected>
                                      Discount Code
                                    </option>
                                    {discountCode?.map((discount, index) => {
                                      return (
                                        <option
                                          value={discount.Id}
                                          key={discount.Id}
                                        >
                                          {discount.code}
                                        </option>
                                      );
                                    })}
                                  </select> */}

<select
  className='dayPassDiscountDD px-2'
  onClick={(e) => {
    daypassDiscountFunc(e.target.value);
    setSaveBtn("tours-setting-btn");
  }}
  // onChange={(e) => {
  //   daypassDiscountFunc(e.target.value);
  //   setSaveBtn("tours-setting-btn");
  // }}
  disabled={!dayPassDiscountConfirm}
  value={daypassDiscount}
>
  <option value='null' disabled>Discount Code</option>
  {discountCode?.map((discount, index) => (
    <option value={discount.Id} key={discount.Id}>
      {discount.code}
    </option>
  ))}
</select>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              md={12}
                              lg={6}
                              xl={6}
                              sm={12}
                              xs={12}
                              className='meetingRoomCol'
                            >
                              <h1>Membership holders </h1>
                              <p>
                                Should a default discount be applied on
                                bookings?
                              </p>

                              <Row>
                                <Col
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sm={12}
                                  xs={12}
                                  className='dayToggleCard'
                                >
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Discount</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                        <Switch
                                          id={5}
                                          checked={meetingRoomDiscountConfirm}
                                          onChange={(e) => {
                                            setMeetingRoomDiscountConfirm(e);
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                          width={40}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          checkedHandleIcon={false}
                                          height={20}
                                          onColor='#0129FA'
                                          className='mr-1 mx-2 d-flex  mt-1'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sm={12}
                                  xs={12}
                                  className='membershipHolderCol'
                                >
                                  <Card className='dayPassCard p-2 px-3 pt-3'>
                                    <Row>
                                      <Col md={8} lg={8} sm={8} xs={8} xl={8}>
                                        <h6>Enter discount%</h6>
                                      </Col>
                                      <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                                        <Input
                                          type='number'
                                          className='discountInput'
                                          disabled={!meetingRoomDiscountConfirm}
                                          value={dayPassDiscountPercent}
                                          min={0}
                                          max={100}
                                          onChange={(e) => {
                                            setDayPassDiscountPercent(
                                              e.target.value
                                            );
                                            setSaveBtn("tours-setting-btn");
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Row className='pt-4'>
                  <Col md={9} lg={9} xl={10} sm={0} xs={0}></Col>
                  <Col md={3} lg={3} xl={2} sm={12} xs={12}>
                    <Button
                      className={`${saveBtn} w-100`}
                      onClick={() => createBookingSetting()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}
    </>
  );
};

export default BookingPayment;
