import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const passesApi = {
  passesList: async (body = "pending", page, size, search, date) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/passes/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalPasses = data?.data?.data?.map((passes, index) => ({
      id: passes.id,
      userId: passes.userId,
      index_id: index + 1,
      userName: passes.userName,
      userCompany: passes.userCompany,
      slotTime: passes.slot,
      status: passes.status,
      createdAt: moment(passes.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
    }));

    return { passes: totalPasses, totalPages: data?.data?.totalPasses };
  },

  exportPasses: async (body = "pending", search, date) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/export-passes/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalPasses = data?.data?.data?.map((passes, index) => ({
      id: passes.id,
      userId: passes.userId,
      index_id: index + 1,
      userName: passes.userName,
      userCompany: passes.userCompany,
      slotTime: passes.slot,
      declineReason: passes.declineReason,
      status: passes.status,
      createdAt: moment(passes.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
    }));

    return { passes: totalPasses, totalPages: data?.data?.totalPasses };
  },

  passesRequestApproval: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/${statusData.route}/${statusData.id}`,
      {
        status: "approved",
      },
      {
        headers: headers,
      }
    );
  },

  passesRequestDenial: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.patch(
      `/${statusData.route}/${statusData.id}`,
      {
        status: statusData.status,
        declineReason: statusData.declineReason,
      },
      {
        headers: headers,
      }
    );
  },

  getPassHistory: async (id) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const history = await request.get(`/passes-past-request/${id}`, {
      headers: headers,
    });

    return history?.data;
  },

  suspendPassUser: async (id) => {
    // const jwt = localStorage.getItem("token");
    // const headers = {
    //   Authorization: "Bearer " + jwt,
    // };
    // const history = await request.get(`/passes-past-request/${id}`, {
    //   headers: headers,
    // });
    // return history?.data;
  },
};

export default apiWrapper(passesApi, [
  "passesList",
  "passesRequestApproval",
  "passesRequestDenial",
  "getPassHistory",
  "exportPasses",
  "suspendPassUser",
]);
