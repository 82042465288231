import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import PrivateRoute from "../services/auth";
import Pages from "../routes/index.js";
import Login from "./login/index";
import ForgotPassword from "./forgotPassword/index";
import LockedAccount from "./accountLocked";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route
            path='/'
            element={
              <Navigate
                replace
                to={`${
                  localStorage.getItem("token") !== "" ? "/dashboard" : "/login"
                }`}
              />
            }
          />

          <Route path='/login' element={<Login />} />
          <Route path='/account-verify' element={<LockedAccount />} />
          <Route path='' element={<PrivateRoute />} key={"1xbt"}>
            {Pages.map((page) => (
              <Route path={page?.path} element={page?.element} key={page?.id} />
            ))}
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
