import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSpring } from "react-spring";
import {
  useMembershipTypeSettingCreateMutation,
  useUploadPhoto,
} from "../../../rc-query/membershipSettingHook";
import { membershipTypeSettingApi } from "../../../services/membershipTypeSetting";
import { useNavigate } from "react-router-dom";

export const UseMembershipSettingsType = (props) => {
  const navigate = useNavigate();
  const picUploadApi = useUploadPhoto();
  const [photoDataByApi, setPhotoDataByApi] = useState();
  const [divShowAccor, setDivShowAccor] = useState({
    1: true,
    2: true,
  });
  const [open, setOpen] = useState(false);
  const [selectedFiles1, setSelectedFiles1] = useState([]);
  const [resourceArr, setSourceArr] = useState([{ value: 1 }]);
  const [slotValue, setSlotValue] = useState(false);
  const [slotValue1, setSlotValue1] = useState(false);
  const [slotValueMonths, setSlotValueMonths] = useState("");
  const [membershipName, setMembershipName] = useState("");
  const [noOfMonths, setNoOfMonths] = useState(null);
  const [samePlanResources, setSamePlanResources] = useState(true);
  const [specificResources, setSpecificResources] = useState(false);
  const [samePlanResources1, setSamePlanResources1] = useState(true);
  const [specificResources1, setSpecificResources1] = useState(false);
  const [monthlyRange, setMonthlyRange] = useState({});
  const [resourcePlansArr, setResourcePlansArr] = useState([]);
  const [resourcePlansArr1, setResourcePlansArr1] = useState([]);
  const [planResourceid, setPlanResourceid] = useState({});
  const [sapratePlanResources, setSapratePlanResources] = useState(null);
  const [planResourceid1, setPlanResourceid1] = useState({});
  const [sapratePlanResources1, setSapratePlanResources1] = useState(null);
  const createSettingMutation = useMembershipTypeSettingCreateMutation(
    props.history
  );

  const totalMonths = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAcceptedFiles1 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles1(files);

    // upload Api
    picUploadApi
      .mutateAsync(files)
      .then((data) => {
        if (data?.status === 200) {
          setPhotoDataByApi(data);
        } else
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeResourceArr = () => {
    setSourceArr(resourceArr.slice(0, -1));
  };

  const tog_static = () => {
    setSlotValue(!slotValue);
  };

  const tog_static1 = () => {
    setSlotValue1(!slotValue1);
  };

  const tog_static2 = (val, no) => {
    setSlotValueMonths(val);
  };

  const TeamMemberYearlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamMemberYearlyPlan: { id: e?.value, name: e?.label },
    };
    resourcePlansArr[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };
  };

  const TeamMemberMonthlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamMemberMonthlyPlan: { id: e?.value, name: e?.label },
    };
    resourcePlansArr[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };
  };

  const TeamLeadMonthlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadMonthlyPlan: { id: e?.value, name: e?.label } };
    resourcePlansArr[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };
  };

  const TeamLeadYearlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadYearlyPlan: { id: e?.value, name: e?.label } };
    resourcePlansArr[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };
  };

  // exp start
  const TeamMemberYearlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex((r) => r.resourceId === i?.name);
    const newObject = {
      TeamMemberYearlyPlan: { id: e?.value, name: e?.label },
    };
    resourcePlansArr1[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };
  };

  const TeamMemberMonthlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamMemberMonthlyPlan: { id: e?.value, name: e?.label },
    };
    resourcePlansArr1[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };
  };

  const TeamLeadMonthlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadMonthlyPlan: { id: e?.value, name: e?.label } };
    resourcePlansArr1[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };
  };

  const TeamLeadYearlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadYearlyPlan: { id: e?.value, name: e?.label } };
    resourcePlansArr1[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };
  };
  // exp end

  //open animation with react spring

  const openAnimation = useSpring({
    config: { duration: "300" },
  });

  //toggle accordion function
  let toggleHandler = (e) => {
    if (e?.target?.id === "1") {
      setDivShowAccor({ 1: !divShowAccor[1], 2: true });
    } else if (e?.target?.id === "2") {
      setDivShowAccor({ 1: true, 2: !divShowAccor[2] });
    } else {
      setDivShowAccor({ 1: true, 2: true });
    }
    setOpen(!open);
  };

  const MembershupSettingCreate = async () => {
    if (slotValue === false || slotValue1 === false) {
      toast.error("Please select  payment methods");
    } else if (monthlyRange?.MinRange > monthlyRange?.MaxRange) {
      toast.error(
        " max monthly range should be greater then min monthly range"
      );
    } else if (!planResourceid?.desk?.value) {
      toast.error("Please select a resource");
    } else if (!membershipName) {
      toast.error("Please enter membership Name");
    } else if (!noOfMonths) {
      toast.error("Number of months are required");
    }

    //start
    else if (
      slotValue === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamLeadMonthlyPlan === undefined ||
        resourcePlansArr?.TeamLeadMonthlyPlan === "")
    ) {
      toast.error("Please select all team lead monthly plans");
    } else if (
      slotValue === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamMemberMonthlyPlan === undefined ||
        resourcePlansArr?.TeamMemberMonthlyPlan === "")
    ) {
      toast.error("Please select all team members monthly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamMemberYearlyPlan === undefined ||
        resourcePlansArr?.TeamMemberYearlyPlan === "")
    ) {
      toast.error("Please select all team Member yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamLeadYearlyPlan === undefined ||
        resourcePlansArr?.TeamLeadYearlyPlan === "")
    ) {
      toast.error("Please select all team Lead yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamLeadYearlyPlan === undefined || e?.TeamLeadYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamMemberYearlyPlan === undefined ||
          e?.TeamMemberYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member yearly plans");
    }

    // need to be fixed in
    else if (
      slotValue === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamLeadMonthlyPlan === undefined || e?.TeamLeadMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead monthly plans");
    } else if (
      slotValue === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamMemberMonthlyPlan === undefined ||
          e?.TeamMemberMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member Monthly plans");
    }

    // end 1

    //exp
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamLeadMonthlyPlan === undefined || e?.TeamLeadMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead monthly plans");
    }

    // *****2****
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamMemberMonthlyPlan === undefined ||
          e?.TeamMemberMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member Monthly plans");
    }
    // *****3 ******* \
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamLeadMonthlyPlan === undefined ||
        resourcePlansArr1?.TeamLeadMonthlyPlan === "")
    ) {
      toast.error("Please select all team lead monthly plans");
    }

    // *****4**********
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamMemberMonthlyPlan === undefined ||
        resourcePlansArr1?.TeamMemberMonthlyPlan === "")
    ) {
      toast.error("Please select all team members monthly plans");
    }

    // ******* 5 ******
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamLeadYearlyPlan === undefined || e?.TeamLeadYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead yearly plans");
    }

    // **** 6 **********
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamMemberYearlyPlan === undefined ||
          e?.TeamMemberYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member yearly plans");
    }
    // ******7 *****
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamMemberYearlyPlan === undefined ||
        resourcePlansArr1?.TeamMemberYearlyPlan === "")
    ) {
      toast.error("Please select all team Member yearly plans");
    } else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamLeadYearlyPlan === undefined ||
        resourcePlansArr1?.TeamLeadYearlyPlan === "")
    ) {
      toast.error("Please select all team Lead yearly plans");
    }

    //exp end

    //end 2
    else {
      var data = {
        ResourceImage: photoDataByApi?.data?.files[0]?.originalname,
        ResourceName: `${membershipName}`,
        ResourceId: Number(planResourceid?.desk?.value),
        isMultiple: false,
        isMonthAllow: slotValue,
        isYearAllow: slotValue1,
        isAllowSpecificResource: specificResources,
        startMonth: Number(noOfMonths),

        monthRange: [
          Number(monthlyRange?.MinRange),
          Number(monthlyRange?.MaxRange),
        ],
        ResourcePlan: {},
      };

      if (resourceArr?.length === 2) {
        delete data["ResourceId"];
        data.isMultiple = true;
        data.PrivateOfficeResourceId = Number(
          process.env.REACT_APP_PRIVATE_OFFICE
        );
        data.DDResourceId = Number(process.env.REACT_APP_DEDICATED_OFFICE);
        data.ResourcePlan = {
          [(planResourceid?.desk?.value).toString()]:
            planResourceid?.desk?.value ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? { resources: { ...resourcePlansArr } }
              : {
                  resources: resourcePlansArr,
                },
          [(planResourceid1?.desk?.value).toString()]:
            planResourceid1?.desk?.value ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? { resources: { ...resourcePlansArr1 } }
              : {
                  resources: resourcePlansArr1,
                },
        };
      } else if (resourceArr?.length < 2) {
        if (samePlanResources === true) {
          data.ResourcePlan = {
            ...resourcePlansArr,
          };
        } else {
          data.ResourcePlan = { resources: [...resourcePlansArr] };
        }
      }
      createSettingMutation.mutateAsync(data).then((data) => {
        if (data?.status === 200) {
          toast.success("Successfully create membership Settings");
          navigate(`/membership-setting-detail/${data?.data?.data?.id}`);
        } else {
          toast.error(data?.response?.data?.error?.message);
        }
      });
    }
  };

  // get resource types
  const { data: resourceTypeData, isLoading: resourceTypesLoading } = useQuery(
    ["resourceTypes"],
    () => membershipTypeSettingApi.membershipTypeSettingrestype()
  );

  // get plans
  const { data: plansDropdownData, isLoading: plansLoading } = useQuery(
    ["plansDropdownData"],
    () => membershipTypeSettingApi.membershipTypeSettingplans()
  );

  // get sparate resource by turn off  same 1toggle

  const { data: sapResourceData } = useQuery(
    [`${planResourceid?.desk?.value}`, `${samePlanResources}`],
    () =>
      membershipTypeSettingApi.membershipTypeSaprateResources(
        Number(planResourceid?.desk?.value),
        samePlanResources
      )
  );

  // get sparate resource by turn off same 2toggle

  const { data: sapResourceData1 } = useQuery(
    [`${planResourceid1?.desk?.value}`, `${samePlanResources1}`],
    () =>
      membershipTypeSettingApi.membershipTypeSaprateResources(
        Number(planResourceid1?.desk?.value),
        samePlanResources1
      )
  );

  useEffect(() => {
    setSapratePlanResources(sapResourceData?.data?.data?.Records);
  }, [sapResourceData]);
  useEffect(() => {
    setSapratePlanResources1(sapResourceData1?.data?.data?.Records);
  }, [sapResourceData1]);

  useMemo(() => {
    // if (sapResourceData == null) {

    const data = sapResourceData?.data?.data?.Records.map((e) => ({
      resourceId: e?.ResourceId,
      resourceName: e?.ResourceName,
    }));
    setResourcePlansArr(data);
    // }
  }, [sapratePlanResources]);

  useMemo(() => {
    // if (sapResourceData == null) {

    const data = sapResourceData1?.data?.data?.Records.map((e) => ({
      resourceId: e?.ResourceId,
      resourceName: e?.ResourceName,
    }));

    setResourcePlansArr1(data);
    // }
  }, [sapratePlanResources1]);

  return {
    samePlanResources1,
    specificResources1,
    sapratePlanResources1,
    resourcePlansArr1,
    planResourceid1,
    divShowAccor,
    resourceArr,
    slotValue,
    noOfMonths,
    totalMonths,
    samePlanResources,
    resourceTypeData,
    specificResources,
    openAnimation,
    plansDropdownData,
    resourcePlansArr,
    monthlyRange,
    planResourceid,
    sapratePlanResources,
    slotValue1,
    membershipName,
    slotValueMonths,
    selectedFiles1,
    setPlanResourceid1,
    setSamePlanResources1,
    setSpecificResources1,
    setResourcePlansArr1,
    TeamLeadMonthlyPlans1,
    TeamLeadYearlyPlans1,
    TeamMemberMonthlyPlans1,
    TeamMemberYearlyPlans1,
    TeamLeadMonthlyPlans,
    TeamLeadYearlyPlans,
    TeamMemberMonthlyPlans,
    toggleHandler,
    setSelectedFiles1,
    handleAcceptedFiles1,
    setSourceArr,
    removeResourceArr,
    tog_static,
    tog_static1,
    tog_static2,
    MembershupSettingCreate,
    setSamePlanResources,
    setMembershipName,
    setNoOfMonths,
    setResourcePlansArr,
    setMonthlyRange,
    setSpecificResources,
    setPlanResourceid,
    TeamMemberYearlyPlans,
  };
};
