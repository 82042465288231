import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import Dropdownunallocated from "../.././images/icons/dropdownunallocated.svg";
import Dropdowngroup from "../.././images/icons/dropdowngroup.svg";
import "./styles.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
const Loader = require("react-loader");

const tasks = [
  { id: "1", content: "First task" },
  { id: "2", content: "Second task" },
  { id: "3", content: "Third task" },
  { id: "4", content: "Fourth task" },
  { id: "5", content: "Fifth task" },
];

const taskStatus = {
  Unallocated_Members: {
    id: 103,
    // name: "Unallocated Members",
    items: tasks,
  },
  Private_Office: {
    id: 203,
    name: "Private Office",
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source?.droppableId];
    const destColumn = columns[destination?.droppableId];
    const sourceItems = [...sourceColumn?.items];
    const destItems = [...destColumn?.items];
    const [removed] = sourceItems?.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    // const sourceColumn = columns[source.droppableId];
    // const destColumn = columns[destination.droppableId];
    // const sourceItems = [...sourceColumn.items];
    // const destItems = [...destColumn.items];
    // const [removed] = sourceItems.splice(source.index, 1);
    // destItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

const TestPage = (props) => {
  const [columns, setColumns] = useState(taskStatus);

  const colourStyles = {
    control: (styles) => ({ ...styles, height: "9px" }),
  };

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='tourDetailPage mt-4'>
            <Col>
              <Row>
                <Col className='pt-4'>
                  <Card className='toursCardView p-4'>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <div className='detailPageHeader'>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            <DragDropContext
                              onDragEnd={(result) =>
                                onDragEnd(result, columns, setColumns)
                              }
                            >
                              {Object.entries(columns).map(
                                ([columnId, column], index) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor:
                                          index === 0
                                            ? "rgba(240, 95, 35, 0.05)"
                                            : "rgba(1, 41, 250, 0.05)",
                                        padding: " 10px 20px 0 20px ",
                                        marginRight: 15,
                                        borderRadius: 4,
                                        // alignItems: "center",
                                      }}
                                      key={columnId}
                                    >
                                      <p className='dd_Card_h1 '>
                                        {index === 0 ? (
                                          <span>
                                            <img
                                              className='mx-2'
                                              src={Dropdownunallocated}
                                            />
                                            <span>Unallocated Members </span>
                                            <span className='dd_members_h dd_max_no'>
                                              3
                                            </span>
                                            <span className='dd_members_h dd_mex_members'>
                                              Members
                                            </span>
                                          </span>
                                        ) : (
                                          <span>
                                            <img
                                              className='mx-2'
                                              src={Dropdowngroup}
                                            />
                                            <Select
                                              styles={colourStyles}
                                              options=''
                                              className='w-50  dd_card_select'
                                            />
                                            <span className='dd_members_h dd_max_no'>
                                              2
                                            </span>
                                            <span className='dd_members_h dd_mex_members'>
                                              Max Members
                                            </span>
                                          </span>
                                        )}
                                      </p>
                                      <div style={{ margin: 8 }}>
                                        <Droppable
                                          droppableId={columnId}
                                          key={columnId}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                  width: 349,
                                                  minHeight: 500,
                                                }}
                                              >
                                                {column.items.map(
                                                  (item, index) => {
                                                    return (
                                                      <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}
                                                      >
                                                        {(
                                                          provided,
                                                          snapshot
                                                        ) => {
                                                          return (
                                                            <div
                                                              className='dd_name_Entity'
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              style={{
                                                                userSelect:
                                                                  "none",
                                                                padding: 10,
                                                                margin:
                                                                  "0 0 8px 0",
                                                                minHeight:
                                                                  "27px",
                                                                // backgroundColor:
                                                                //   snapshot.isDragging
                                                                //     ? "#7FFFD4"
                                                                //     : "transparent",
                                                                // color: "white",
                                                                ...provided
                                                                  .draggableProps
                                                                  .style,
                                                              }}
                                                            >
                                                              <span className='dd_name_fonts'>
                                                                {item.content}
                                                              </span>
                                                            </div>
                                                          );
                                                        }}
                                                      </Draggable>
                                                    );
                                                  }
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            );
                                          }}
                                        </Droppable>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </DragDropContext>
                          </div>
                        </div>
                        <hr />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TestPage;
