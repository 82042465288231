import { useMutation } from "react-query";
import {
  GET_TOURS_MUTATION,
  PATCH_TOURS_APPROVED_MUTATION,
  PATCH_TOURS_DENIED_MUTATION,
} from "./keys";

import toursApi from "../services/toursApi";

export const useToursMutation = (history) => {
  const response = useMutation(toursApi.toursList, {
    mutationKey: GET_TOURS_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useToursApprovedMutation = (history) => {
  const response = useMutation(toursApi.toursRequestApproval, {
    mutationKey: PATCH_TOURS_APPROVED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useToursDeniedMutation = (history) => {
  const response = useMutation(toursApi.toursRequestDenial, {
    mutationKey: PATCH_TOURS_DENIED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
