import { useTeamMemberInfo } from "./useTeamMember";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  Col,
  Row,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import "../css/styles.css";
const Loader = require("react-loader");

const Members = (props) => {
  const {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    memberID,
    modelShow1,
    loading,
    declineModel,
    memberList,
    memberLoading,
    exportMemberList,
    headers,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    deniedFunc,
    setDeclineFunc,
    declineModelFunc,
    modelFunc1,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
    toggleCustomJustified,
  } = useTeamMemberInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <div>
      <Row className='p-4 '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div>
            <Row className='mb-2 p-2 tab-row'>
              <Col lg={6} md={12} sm={12} xs={12} xl={6}>
                <div>
                  <Nav className=' nav-justified nav-width nav-btn'>
                    <NavItem className='nav-item'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "1",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className='d-none d-sm-block '>All </span>
                      </NavLink>
                    </NavItem>
                    <NavItem className='nav-item'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "2",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className='d-none d-sm-block '>Pending</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "3",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("3");
                        }}
                      >
                        <span className='d-none d-sm-block '>Approved</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "4",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("4");
                        }}
                      >
                        <span className='d-none d-sm-block '>Denied</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col lg={1} md={2} sm={3} xs={12} xl={1}></Col>
              <Col lg={3} md={5} sm={5} xs={6} xl={2} className='searchBarCol'>
                <Input
                  type='text'
                  className='searchbcss'
                  placeholder='Search'
                  onChange={(e) => {
                    handleSearchFunc(e);
                  }}
                />
              </Col>
              <Col lg={2} md={5} sm={4} xs={6} xl={2}>
                <Input
                  type='date'
                  id='tours-date-input'
                  className=' search-bar '
                  onChange={(e) => {
                    setSearchDate(e);
                  }}
                />
              </Col>
            </Row>
            <ToolkitProvider
              keyField='id'
              columns={columns}
              key={memberList?.members}
              data={memberList ? memberList?.members : []}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  {!memberLoading ? (
                    <Row className='data-wrap'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <Card className='input-wrap2 p-2 '>
                          <div className='table-responsive table-wrap '>
                            <BootstrapTable
                              loading={true}
                              hover
                              keyField='index_id'
                              key={memberList ? memberList.id : ""}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle tableBody view-table "
                              }
                              headerWrapperClasses={"thead-light"}
                              headerClasses='tableHeadercss'
                              wrapperClasses='table-responsive'
                              {...toolkitProps.baseProps}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </div>
                          <div className='inner-custom-pagination d-flex '>
                            <span className='paginationitmes'>Items</span>
                            <div className='d-inline pagination-btn '>
                              <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                                <DropdownToggle caret className='pageBtn'>
                                  {pageSize}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    value='1'
                                    onClick={() => pageSizeFunc(10)}
                                  >
                                    10
                                  </DropdownItem>
                                  <DropdownItem
                                    value='2'
                                    onClick={() => pageSizeFunc(30)}
                                  >
                                    30
                                  </DropdownItem>

                                  <DropdownItem
                                    value='3'
                                    onClick={() => pageSizeFunc(50)}
                                  >
                                    50
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                            <span className='paginationitmes1 '>Show</span>
                            <div className='d-flex mx-3'>
                              <CSVLink
                                className='csvButton'
                                data={
                                  exportMemberList
                                    ? exportMemberList?.members
                                    : []
                                }
                                headers={headers}
                                filename={
                                  activeTabJustify === "1"
                                    ? "allTeamInvoices.csv"
                                    : activeTabJustify === "2"
                                    ? "pendingTeamInvoices.csv"
                                    : activeTabJustify === "3"
                                    ? "approvedTeamInvoices.csv"
                                    : activeTabJustify === "4"
                                    ? "deniedTeamInvoices.csv"
                                    : ""
                                }
                              >
                                Export
                              </CSVLink>
                            </div>
                            <div className='text-md-right ms-auto'>
                              <AiOutlineLeft
                                className='page-btn'
                                onClick={() => handleIncrementBtn(false)}
                                role='button'
                              />

                              {totalPages()}

                              <AiOutlineRight
                                className='page-btn'
                                onClick={() => handleIncrementBtn(true)}
                                role='button'
                              />
                            </div>
                          </div>
                        </Card>

                        <Row className='align-items-md-center mt-3 bottom-btn '>
                          <Col className='inner-custom-pagination d-flex'></Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Loader className='spinner' />
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      </Row>

      <ApproveModel
        show={modelShow1}
        deniedFunc={deniedFunc}
        setModelShow={modelFunc1}
        loading={loading}
        request={"memberRequest"}
        headerText='Rejected Request'
        textBody='Are you sure you want to reject this request?'
      />

      <DeclineModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        templates={templatesList?.data}
        setDeclineReason={setDeclineFunc}
        ID={memberID}
      />
    </div>
  );
};

export default Members;
