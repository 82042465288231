import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import notificationsApi from "../../services/notificationsApi";
import { useNotificationSettingsMutation } from "../../rc-query/notificationHook";
import { useGenerateErrorLogsMutation } from "../../rc-query/logsHook";
import { toast } from "react-toastify";
import "./css/styles.css";

export const useNotificationSettingsInfo = (props) => {
  const [saveBtn, setSaveBtn] = useState("hideSaveBtn");
  const QueryClient = useQueryClient();
  const createNotifications = useNotificationSettingsMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const [tableData, setTableData] = useState();

  const { data: settings, isLoading: settingsLoading } = useQuery(
    ["notificationSettings"],
    () => notificationsApi.getNotificationSettings(),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    if (settings) {
      setTableData(settings);
    }
  }, [settingsLoading, settings]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (row, id, isChecked, permission) => {
    const newTableData = [...tableData];
    let viewVal = newTableData.find((r) => r.id === id).permissions.web;
    let manageVal = newTableData.find((r) => r.id === id).permissions.email;

    if (permission === "web") {
      newTableData.find((r) => r.id === id).permissions.web = !viewVal;

      setTableData(newTableData);
    } else if (permission === "email") {
      newTableData.find((r) => r.id === id).permissions.email = !manageVal;
      setTableData(newTableData);
    }
    setSaveBtn("tours-add-slot-btn");
  };

  const columns = [
    {
      dataField: "name",
      text: "Notification type",
      style: { width: "65%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <div>
          <div>{cell}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#999",
              fontWeight: "400",
              lineHeight: "12.19px",
            }}
          >
            {row.description}
          </div>
        </div>
      ),
    },
    {
      dataField: "permissions.web",
      text: "Web",
      editable: true,

      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <>
          <input
            type='checkbox'
            role='button'
            checked={cell}
            className='input-tours'
            onChange={(e) => {
              handleCheckboxChange(row, row.id, e.target.checked, "web");
            }}
          />
        </>
      ),
    },
    {
      dataField: "permissions.email",
      text: "Email",
      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          role='button'
          checked={cell}
          className='input-tours'
          onChange={(e) =>
            handleCheckboxChange(row, row.id, e.target.checked, "email")
          }
        />
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  const handleNotifcationSettings = () => {
    const data = {
      memberRequest: {
        web: tableData[0].permissions.web,
        email: tableData[0].permissions.email,
      },
      teamRequest: {
        web: tableData[1].permissions.web,
        email: tableData[1].permissions.email,
      },
      planRequest: {
        web: tableData[2].permissions.web,
        email: tableData[2].permissions.email,
      },
      planInvoice: {
        web: tableData[3].permissions.web,
        email: tableData[3].permissions.email,
      },
      tourRequest: {
        web: tableData[4].permissions.web,
        email: tableData[4].permissions.email,
      },
      daypassRequest: {
        web: tableData[5].permissions.web,
        email: tableData[5].permissions.email,
      },
      visitorAlert: {
        web: tableData[6].permissions.web,
        email: tableData[6].permissions.email,
      },
    };

    createNotifications
      .mutateAsync(data)
      .then((data) => {
        if (data.status === 200) {
          setSaveBtn("hideSaveBtn");
          toast.success("Notification settings added");
          QueryClient.invalidateQueries("notificationSettings");
          generateErrMutaion
            .mutateAsync({
              action: "Notification settings",
              severity: "info",
              status: "Successfull",
              component: "Update Settings",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Notification settings",
            severity: "info",
            status: "Failed",
            component: "Update Settings",
          })
          .then((data) => {});
      });
  };

  return {
    columns,
    defaultSorted,
    tableData,
    saveBtn,
    settingsLoading,
    handleNotifcationSettings,
    NoDataIndication,
  };
};
