import React from "react";
import { Button, Col, Row, Modal, ModalBody } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import "./css/approvalModel.css";

import Spinner from "react-bootstrap/Spinner";

const ApproveModel = (props) => {
  const navigate = useNavigate();

  async function approval() {
    if (props.request === "approval") {
      const statusData = {
        route: props.status,
        id: props.ID,
      };
      props.approvalFunc(statusData);
    } else if (props.request === "deny") {
      const statusData = {
        route: props.status,
        id: props.ID,
        status: "deny",
        declineReason: props.reason,
      };

      props.deniedFunc(statusData);
    } else if (props.request === "createRole") {
      props.approvalFunc(props.createData);
    } else if (props.request === "cancelRequest") {
      navigate(-1);
      props.setModelShow(false);
    } else if (props.request === "deny") {
      props.deniedFunc();
    } else if (props.request === "memberRequest") {
      props.deniedFunc();
    } else if (props.request === "SuspendAccount") {
      props.approvalFunc();
    } else if (props.request === "SuspendAccount") {
      props.approvalFunc();
    } else {
      props.approvalFunc();
    }
  }

  return (
    <Modal isOpen={props.show} centered={true} className='model-body'>
      <span className='requestBody'>
        <Row>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <ModalBody>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='cardHeader'
                >
                  <span className='model-text'>{props.headerText}</span>
                </Col>
                <Col md={2} xl={2} lg={2} sm={1} xs={1}>
                  <span>
                    <GrFormClose
                      size={25}
                      onClick={() => {
                        props.setModelShow(false);
                        props.tourEnableInput(!props.tourEnableInput);
                      }}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='cardBody mt-3'
                >
                  <p>{props.textBody}</p>
                </Col>
              </Row>
              <Row className='approval-btn mt-3'>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                  <Button
                  disabled={props.loading}
                    className='waves-effect waves-light mr-5 w-100 lg-btn1 '
                    onClick={() => {
                      props.setModelShow(false);
                      if (props.tourEnable === false) {
                        props.tourEnableInput(true);
                      } else {
                        props.tourEnableInput(!props.tourEnableInput);
                      }
                    }}
                  >
                    No
                  </Button>
                </Col>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>

                <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                  {props.loading ? (
                    <Button
                      className='waves-effect waves-light mr-1 w-100 lg-btn2 '
                      onClick={() => approval()}
                      loading={true}
                      disabled={true}
                    >
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />{" "}
                      Submiting
                    </Button>
                  ) : (
                    <Button
                      className='waves-effect waves-light mr-1 w-100 lg-btn2 '
                      onClick={() => approval()}
                    >
                      Yes
                    </Button>
                  )}
                </Col>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
              </Row>
            </ModalBody>
          </Col>
        </Row>
      </span>
    </Modal>
  );
};

export default ApproveModel;
