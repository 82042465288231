import React from "react";
import { useChangePasswordInfo } from "./useChangePassword";
import { FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Card, Form, Col, Row, Input, Button } from "reactstrap";
import OTPModel from "../../components/molecules/OTPModel";
import "./css/styles.css";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const {
    otpShow,
    confirmPassword,
    incorrect,
    displayError,
    upcaseValid,
    lenghtValid,
    numberValid,
    setotpShow,
    otpRequest,
    passwordChange,
    setNewPassword,
    setReEnterPassword,
    setOldPassword,
    setOTP,
  } = useChangePasswordInfo(props);

  return (
    <>
      <Row className=' p-4'>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Card className='password-card p-3 pb-4  '>
            <Form>
              <FormGroup>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                    <h4>Change Password</h4>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={12} className='pb-3'>
                    <h5>Please follow these Guidelines</h5>
                  </Col>
                  <Col md={12} lg={12} sm={12} xs={12} xl={5}>
                    <li>
                      <span className={upcaseValid}>
                        Must contain an uppercase letter
                      </span>
                    </li>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={5}>
                    <li>
                      <span className={lenghtValid}>
                        Must be a minimum of 8 characters long
                      </span>
                    </li>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={5}>
                    <li>
                      <span className={numberValid}>Must contain a number</span>
                    </li>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>Old Password</h6>

                    <Input
                      className='password-field'
                      id='example-date-input-text'
                      placeholder='Old Password'
                      type='password'
                      onChange={(e) => setOldPassword(e.target.value)}
                    ></Input>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>New Password</h6>
                    <Input
                      id='example-date-input-text'
                      className={`${confirmPassword}`}
                      placeholder='New Password'
                      type='password'
                      onChange={(e) => setNewPassword(e.target.value)}
                    ></Input>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>Re-enter new password</h6>
                    <Input
                      id='example-date-input-text'
                      className={`${confirmPassword}`}
                      placeholder='Re-enter'
                      type='password'
                      onChange={(e) => setReEnterPassword(e.target.value)}
                    ></Input>
                    <p className={`${displayError}`}>Password does not match</p>
                  </Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4}></Col>
                </Row>
              </FormGroup>
            </Form>
          </Card>

          <Row>
            <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
            <Col md={4} xl={4} lg={4} sm={12} xs={12} className='pt-4'>
              <div className='membershipDetailBtn'>
                <Button
                  className='waves-effect waves-light w-100 datails-btn1'
                  onClick={() => navigate(-1)}
                >
                  CANCEL
                </Button>
                <Button
                  className='waves-effect waves-light w-100 datails-btn2'
                  onClick={(e) => {
                    otpRequest(true);
                  }}
                  disabled={incorrect}
                >
                  UPDATE
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <OTPModel
        show={otpShow}
        setModelShow={setotpShow}
        newOTP={setOTP}
        passwordChange={passwordChange}
      />
    </>
  );
};

export default ChangePassword;
