import { useReceiptsInfo } from "./useReceipts";
import React from "react";
import {
  Card,
  Col,
  Row,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../css/styles.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import VerifyModel from "../../../components/molecules/verifyModel";
import moment from "moment";
const Loader = require("react-loader");
const { SearchBar } = Search;

const todatDate = moment();

const Receipts = (props) => {
  const {
    columns,
    tourLists,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    modelShow,
    declineReason,
    userID,
    request,
    status,
    modelShow1,
    loading,
    declineModel,
    toursMutation,
    deniedFunc,
    approvalFunc,
    setRequestFunc,
    setDeclineFunc,
    declineModelFunc,
    modelFunc1,
    modelFunc,
    modelRequestFunc,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
    toggleCustomJustified,
  } = useReceiptsInfo(props);

  return (
    <div>
      <Row className='p-4 '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div>
            <ToolkitProvider
              keyField='id'
              columns={columns}
              data={tourLists}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className='mb-2 p-2 tab-row'>
                    <Col lg={7} md={7} sm={12} xs={12} xl={6}></Col>
                    <Col lg={3} md={3} sm={6} xs={6} xl={3}>
                      <div className='search-row'>
                        <SearchBar
                          className='searchbcss '
                          {...toolkitProps.searchProps}
                        />
                      </div>
                    </Col>
                    <Col lg={2} md={2} sm={6} xs={6} xl={2}>
                      <Input
                        type='date'
                        defaultValue={todatDate.format("YYYY-MM-DD")}
                        id='example-date-input'
                        className=' searchbcss  search-bar '
                      />
                    </Col>
                  </Row>
                  {toursMutation.isLoading ? (
                    <Loader className='spinner' />
                  ) : (
                    <Row className='data-wrap'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <Card className='input-wrap2 p-2 '>
                          <div className='table-responsive table-wrap '>
                            <BootstrapTable
                              // condensed={true}
                              loading={true}
                              hover
                              keyField='id'
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle tableBody view-table "
                              }
                              headerWrapperClasses={"thead-light"}
                              headerClasses='tableHeadercss'
                              wrapperClasses='table-responsive'
                              {...toolkitProps.baseProps}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </div>
                          <div className='inner-custom-pagination d-flex '>
                            <span className='paginationitmes'>Items</span>
                            <div className='d-inline pagination-btn '>
                              <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                                <DropdownToggle
                                  caret
                                  // color='white'
                                  className='pageBtn'
                                  // bore={true}
                                >
                                  {pageSize}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    value='1'
                                    onClick={() => pageSizeFunc(10)}
                                  >
                                    10
                                  </DropdownItem>
                                  <DropdownItem
                                    value='2'
                                    onClick={() => pageSizeFunc(30)}
                                  >
                                    30
                                  </DropdownItem>

                                  <DropdownItem
                                    value='3'
                                    onClick={() => pageSizeFunc(50)}
                                  >
                                    50
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                            <span className='paginationitmes1 '>Show</span>
                            <div className='text-md-right ms-auto'>
                              <AiOutlineLeft className='page-btn' />

                              {totalPages()}

                              <AiOutlineRight className='page-btn' />
                            </div>
                          </div>
                        </Card>

                        <Row className='align-items-md-center mt-3 bottom-btn '>
                          <Col className='inner-custom-pagination d-flex'></Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      </Row>

      <VerifyModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        setRequest={setRequestFunc}
        setDeclineReason={setDeclineFunc}
        userID={userID}
      />
    </div>
  );
};

export default Receipts;
