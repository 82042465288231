import { useMutation } from "react-query";
import {
  PATCH_PLAN_APPROVED_MUTATION,
  PATCH_PLAN_DENIED_MUTATION,
  PATCH_PLAN_MUTATION,
} from "./keys";

import plansApi from "../services/plansApi";

export const usePlansApprovedMutation = (history) => {
  const response = useMutation(plansApi.plansRequestApproval, {
    mutationKey: PATCH_PLAN_APPROVED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const usePlansDeniedMutation = (history) => {
  const response = useMutation(plansApi.plansRequestDenial, {
    mutationKey: PATCH_PLAN_DENIED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePlansPatchMutation = (history) => {
  const response = useMutation(plansApi.patchPlanRequest, {
    mutationKey: PATCH_PLAN_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
