import { useMutation } from "react-query";
import { GENERATE_ERR_LOG } from "./keys";

import logsApi from "../services/logsApi";

export const useGenerateErrorLogsMutation = (history) => {
  const response = useMutation(logsApi.generateErrorlogs, {
    mutationKey: GENERATE_ERR_LOG,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
