import { useEffect } from "react";
import usersApi from "../../services/usersApi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const useProfleInfo = () => {
  const navigate = useNavigate();

  const { data: userProfile, isLoading: userProfileLoading } = useQuery(
    ["userProfile"],
    () => usersApi.getUserProfile(),
    {
      retry: 0,
      onError: (err) => {
        if (
          err.response.status === 401 &&
          err.response.statusText === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/");
        }
      },
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { userProfileLoading, userProfile };
};
