import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const logsApi = {
  errorLogs: async (body = "All", page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      `/portal-logs-lists/${body}/${page}/${size}`,
      {
        headers: headers,
        params: { search: search, date: date },
      }
    );

    const logsLists = data?.data?.data.map((log, index) => ({
      id: log.id,
      index_id: index + 1,
      userId: log.actor,
      severity: log.severity,
      action: log.action,
      userName: log.createdBy,
      status: log.status,
      component: log.component,
      date: moment(log.createdAt).format("DD MMMM,YYYY h:mm a").toString(),
    }));

    return { logs: logsLists, totalPages: data?.data.totalLogs };
  },

  exportErrorLogs: async (body = "All", search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-portal-logs-lists/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const logsLists = data?.data?.data.map((log, index) => ({
      id: log.id,
      index_id: index + 1,
      userId: log.actor,
      severity: log.severity,
      action: log.action,
      userName: log.createdBy,
      status: log.status,
      component: log.component,
      date: moment(log?.createdAt)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY h:mm a"),
    }));

    return { logs: logsLists, totalPages: data?.data.totalLogs };
  },

  generateErrorlogs: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.post(`/portal-logs`, body, {
      headers: headers,
    });

    return data;
  },
};

export default apiWrapper(logsApi, [
  "errorLogs",
  "generateErrorlogs",
  "exportErrorLogs",
]);
