import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { TabContext } from "../../../context/TabContext";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useToasts } from "react-toast-notifications";
import {
  useToursApprovedMutation,
  useToursDeniedMutation,
} from "../../../rc-query/toursHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import toursApi from "../../../services/toursApi";
import { useQuery, useQueryClient } from "react-query";
import "./css/styles.css";
import { useMemo } from "react";

export const useToursInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToasts();
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [tourID, setTourID] = useState("");
  const [request, setRequest] = useState("");
  const [declineModel, setDeclineModel] = useState(false);
  const [calenderViewModel, setCalenderViewModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState(
    activeTabJustify === "1"
      ? "all"
      : activeTabJustify === "2"
      ? "pending"
      : activeTabJustify === "3"
      ? "approved"
      : activeTabJustify === "4"
      ? "deny"
      : "all"
  );
  const approvalMutation = useToursApprovedMutation(props.history);
  const deniedMutation = useToursDeniedMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  const QueryClient = useQueryClient();

  const { data: toursList, isLoading: toursLoading } = useQuery(
    ["toursLists", apiStatus, currentPage, search, date, pageSize],
    () => toursApi.toursList(apiStatus, currentPage, pageSize, search, date),
    {
      retry: 0,
    }
  );

  const { data: exportList, isLoading: exportListLoading } = useQuery(
    ["exportData", apiStatus, search, date],
    () => toursApi.exportToursList(apiStatus, search, date),
    {
      retry: 0,
    }
  );

  const headers = [
    { label: "No", key: "index_id" },
    { label: "User ID", key: "userId" },
    { label: "Tour Id", key: "tourId" },
    { label: "Requested On", key: "date" },
    { label: "User Name", key: "userName" },
    { label: "Tour Slot", key: "slotTime" },
    { label: "Tour Day", key: "tourDay" },
    { label: "Tour Date", key: "date" },
    { label: "Decline Reason", key: "declineReason" },
    { label: "Status", key: "status" },
  ];

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSearchDate = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setDate();
      setCurrentPage(1);
    } else {
      setDate(e.target.value);
      setCurrentPage(1);
    }
  };

  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const calenderViewFunc = (value) => {
    setCalenderViewModel(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  const approvalFunc = (data) => {
    setLoading(true);
    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("toursLists");
          generateErrMutaion
            .mutateAsync({
              action: "tour approval",
              severity: "info",
              status: "Successfull",
              component: "Tour Requests",
            })
            .then((data) => {});
          setLoading(false);
          setModelShow(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "tour approval",
            severity: "info",
            status: "Failed",
            component: "Tour Requests",
          })
          .then((data) => {});
        setLoading(false);
        setModelShow(false);
      });
  };

  const deniedFunc = (data) => {
    setLoading(true);
    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("toursLists");
          generateErrMutaion
            .mutateAsync({
              action: "tour deny",
              severity: "info",
              status: "Successfull",
              component: "Tour Requests",
            })
            .then((data) => {});

          setLoading(false);
          setModelShow1(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "tour deny",
            severity: "info",
            status: "Failed",
            component: "Tour Requests",
          })
          .then((data) => {});
        setLoading(false);
        setModelShow1(false);
      });
  };

  // Approval Request
  const approval = (id) => {
    setTourID(id);

    setModelShow(true);
    setRequest("approval");
    setStatus("tours");
  };
  // Denied Request
  const rejectApproval = (id) => {
    setTourID(id);
    setRequest("deny");
    setStatus("tours");
    setDeclineModel(true);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const details = (row) => {
    navigate("/tour-detail", {
      state: {
        data: row,
      },
    });
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "index_id",
      text: "ID #",
      sort: true,
      headerStyle: { width: "8%" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "createdAt",
      text: "Requested on",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "userName",
      text: "Name",
      sort: true,

      headerStyle: { width: "150px" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "date",
      text: "Slot Date",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "slotTime",
      text: "Slot Time",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "requests",
      text: "Requests",
      sort: true,
      formatter: (cell, row) => (
        <a href={row.fileUrl} download>
          {row.paymentStatus === "objected" && row.status === "pending" ? (
            <div>
              <span>
                <button
                  className='btn-circle-denied'
                  onClick={() => details(row)}
                >
                  Objected
                </button>
              </span>
            </div>
          ) : row.status === "approved" ? (
            <div>
              <span>
                {" "}
                <button
                  className='btn-circle-approved'
                  onClick={() => details(row)}
                >
                  Approved
                </button>{" "}
              </span>
            </div>
          ) : row.status === "deny" ? (
            <div>
              <span>
                <button
                  className='btn-circle-denied'
                  onClick={() => details(row)}
                >
                  Denied
                </button>
              </span>
            </div>
          ) : row.status === "pending" ? (
            <div>
              <span>
                <button
                  className='btn-circle-object'
                  onClick={() => details(row)}
                >
                  Pending
                </button>
              </span>
            </div>
          ) : row.status === "rescheduled" ? (
            <div>
              <span>
                <button
                  className='btn-circle-object'
                  onClick={() => details(row)}
                >
                  Rescheduled
                </button>
              </span>
            </div>
          ) : row.status === "cancelled" ? (
            <div>
              <span>
                <button
                  className='btn-circle-cancel'
                  onClick={() => details(row)}
                >
                  Cancelled
                </button>
              </span>
            </div>
          ) : (
            ""
          )}
        </a>
      ),
    },
  ];

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = toursList
      ? Math.trunc(toursList?.totalPages / pageSize + 1)
      : 0;
    let buttons = [];

    setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleSearchFunc = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
      setApiStatus("all");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
      setApiStatus("pending");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "3") {
      setActiveTabJustify(tab);
      setApiStatus("approved");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else {
      setActiveTabJustify(tab);
      setApiStatus("deny");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
  };

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );


  useMemo(()=>{
    
    
    if(location?.state?.path === "dashboard"){
      calenderViewFunc(true)
  }
  },[])

  return {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    modelShow,
    declineReason,
    tourID,
    request,
    status,
    modelShow1,
    loading,
    declineModel,
    calenderViewModel,
    toursList,
    toursLoading,
    exportListLoading,
    exportList,
    headers,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    calenderViewFunc,
    deniedFunc,
    approvalFunc,
    setRequestFunc,
    setDeclineFunc,
    declineModelFunc,
    modelFunc1,
    modelFunc,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
    toggleCustomJustified,
  };
};
