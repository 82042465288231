import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TabContext } from "../../../context/TabContext";
import { Button } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import teamApi from "../../../services/teamsApi";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import "../css/styles.css";

export const useTeamsInfo = (props) => {
  const navigate = useNavigate();
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(
    activeTabJustify === "1"
      ? "all"
      : activeTabJustify === "2"
      ? "active"
      : activeTabJustify === "3"
      ? "inactive"
      : "all"
  );
  const QueryClient = useQueryClient();

  const { data: teamList, isLoading: teamsLoading } = useQuery(
    ["teamLists", apiStatus, currentPage, search, date, pageSize],
    () => teamApi.teamList(apiStatus, currentPage, pageSize, search, date),
    {
      retry: 0,
    }
  );

  const { data: exportTeamList, isLoading: exportTeamListLoading } = useQuery(
    ["exportTeamList", apiStatus, search, date],
    () => teamApi.exportTeamList(apiStatus, search, date),
    {
      retry: 0,
    }
  );

  const headers = [
    { label: "No", key: "index_id" },
    { label: "Request ID", key: "id" },
    { label: "User ID", key: "userId" },
    { label: "Team ID", key: "NexudusTeamId" },
    { label: "Start Date", key: "startDate" },
    { label: "Last Date", key: "RenewalMonth" },
    { label: "Team Lead Name", key: "userName" },
    { label: "Plan Type", key: "planType" },
    { label: "Total Members", key: "teamMembers" },
    { label: "Resource Type ID", key: "ResourceTypeId" },
    { label: "Dedicated Resource Type ID", key: "dedicatedResourceTypeId" },
    { label: "Private Resource Type ID", key: "privateResourceTypeId" },
    { label: "Dedicated Desk Members", key: "dedicatedMembers" },
    { label: "Private Office Members", key: "privateMembers" },
    { label: "All Active Members", key: "allActiveMembers" },
    { label: "Desk", key: "Desk" },
    { label: "Registration Status", key: "registrationStatus" },
    { label: "Website", key: "website" },
    { label: "Plan Duration", key: "planDuration" },
    { label: "Linkedin", key: "linkedin" },
    { label: "Twitter", key: "twitter" },
    { label: "Company Name", key: "userCompany" },
    { label: "Company Description", key: "description" },
  ];

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSearchDate = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setDate();
      setCurrentPage(1);
    } else {
      setDate(e.target.value);
      setCurrentPage(1);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const details = (row) => {
    navigate("/teams-detail", {
      state: {
        data: row,
      },
    });
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "index_id",
      text: "ID #",
      sort: true,
      headerStyle: { width: "8%" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "userName",
      text: "Team Lead",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "userCompany",
      text: "Company Name",
      sort: true,
      formatter: (cell, row) => (

        <p>
        { cell&& cell.length > 50
          ? cell.slice(0, 40) + '...'
          : cell}
      </p>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "planType",
      text: "Plan",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "startDate",
      text: "Starting Month",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "RenewalMonth",
      text: "Last Month",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
  ];

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = teamList
      ? Math.trunc(teamList?.totalPages / pageSize + 1)
      : 0;
    let buttons = [];

    setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage != 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
      setApiStatus("all");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
      setApiStatus("active");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "3") {
      setActiveTabJustify(tab);
      setApiStatus("inactive");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
  };

  const handleSearchFunc = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    columns,
    teamsLoading,
    teamList,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    exportTeamList,
    headers,
    toggleCustomJustified,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  };
};
