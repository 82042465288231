import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastProvider } from "react-toast-notifications";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { queryClient } from "./rc-query/queryClient";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <ToastContainer />
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </ToastProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
