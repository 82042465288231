import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import membershipTypeSettingApi from "../../../../services/membershipTypeSetting";

const UseMembershipTypeSettingDetail = () => {
  const planId = useParams();

  // get  membership detils
  const {
    data: dmemberShipDetailsDataa,
    isLoading: dmemberShipDetailsLoading,
  } = useQuery(["membershipDetails", planId?.id], () =>
    membershipTypeSettingApi.membershipTypeSettingdetails(planId?.id)
  );

  const dmemberShipDetailsData = dmemberShipDetailsDataa?.data;

  // get resource types
  const { data: resourceTypeData, isLoading: resourceTypesLoading } = useQuery(
    [planId?.id],
    () => membershipTypeSettingApi.membershipTypeSettingrestype()
  );

  const newresourceTypeData = resourceTypeData?.filter(
    (e) => e.value === dmemberShipDetailsData?.ResourceId
  );

  

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { dmemberShipDetailsData, newresourceTypeData, planId };
};

export default UseMembershipTypeSettingDetail;
