import React from "react";
import { useAddTourSlotInfo } from "./useAddTourSlot";
import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";

const ToursSlot = (props) => {
  const {
    endTime,
    tourDays,
    timingsLoading,
    sundayBooked,
    mondayBooked,
    tuesdayBooked,
    wednesdayBooked,
    thursdayBooked,
    fridayBooked,
    saturdayBooked,
    bookedSlots,
    bookedLoading,
    addTourDay,
    slotTimings,
    handleSubmit,
    handleTimeChange,
    setTourDays,
    setEndTime,
  } = useAddTourSlotInfo(props);
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        {
          <div className='d-body'>
            <Row className='tour-slot-model'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <ModalBody>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='slot-model'>
                        <h5>Add a new tour slot</h5>
                      </div>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                          setEndTime("");
                          setTourDays([]);
                        }}
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={5}
                      xl={5}
                      lg={5}
                      sm={12}
                      xs={12}
                      className='tour-slot-col'
                    >
                      <h3>Select time</h3>
                      <Input
                        className='tourslotDuration'
                        id='example-date-input-text'
                        type='select'
                        onChange={(e) => handleTimeChange(e.target.value)}
                      >
                        <option>Start Time</option>
                        {!timingsLoading ? slotTimings() : ""}
                      </Input>
                    </Col>
                    <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                    <Col
                      md={5}
                      xl={5}
                      lg={5}
                      sm={12}
                      xs={12}
                      className='tour-slot-col'
                    >
                      {" "}
                      <h3>End time</h3>
                      <Input
                        className='tourslotDuration'
                        id='example-date-input-text'
                        type='text'
                        value={endTime}
                        disabled={true}
                      ></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      xl={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      className='selectdays-col'
                    >
                      {" "}
                      <h3>Select Days</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      {" "}
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Sunday</span>
                        </Col>
                        <Col md={1} xl={2} lg={2} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Sunday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Sunday}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Monday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Monday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Monday}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      {" "}
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Tuesday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Tuesday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Tuesday}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Wednesday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Wednesday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Wednesday}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      {" "}
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Thursday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Thursday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Thursday}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Friday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Friday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Friday}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} xl={3} lg={3} sm={12} xs={12}>
                      {" "}
                      <Row className='tours-day-row'>
                        <Col md={11} xl={8} lg={8} sm={11} xs={11}>
                          <span>Saturday</span>
                        </Col>
                        <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                          <Input
                            type='checkbox'
                            className='input-tours-days'
                            onChange={(e) =>
                              addTourDay("Saturday", e.target.checked)
                            }
                            disabled={bookedSlots?.obj?.Saturday}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className='pt-2'>
                    <>
                      <Col md={3} xl={3} lg={3} sm={0} xs={0}></Col>
                      <Col md={6} xl={6} lg={6} sm={12} xs={12}>
                        <div className='addSlotModelBtn mt-4 '>
                          <Button
                            className='waves-effect waves-light w-100 cancelPrimaryBtn'
                            onClick={() => {
                              props.setModelShow(false);
                              setTourDays([]);
                              tourDays.splice();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className='waves-effect waves-light w-100 datails-btn2'
                            onClick={() => handleSubmit()}
                          >
                            Save Details
                          </Button>
                        </div>
                      </Col>
                      <Col md={3} xl={3} lg={3} sm={0} xs={0}></Col>
                    </>
                  </Row>
                </ModalBody>
              </Col>
            </Row>
          </div>
        }
      </Modal>
    </>
  );
};

export default ToursSlot;
