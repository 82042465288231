import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "../declineModel/css/declineModel.css";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

const DeniedModel = (props) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  async function approvalDeny(e) {
    e.preventDefault();
    if (reason) {
      const statusData = {
        route: "tours",
        id: props.ID,
        status: "deny",
        declineReason: reason,
      };

      props.deniedFunc(statusData);
    }
  }
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel '
      >
        <div className='d-body'>
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row className='mb-3'>
                  <Col md={10} xl={11} lg={11} sm={10} xs={10}>
                    <span className='cancelModelHeader'>
                      Cancel Approved Tour{" "}
                    </span>
                  </Col>
                  <Col md={2} xl={1} lg={1} sm={2} xs={2}>
                    <span className='lgModelCancel'>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <p>
                      Please select a reason for canceling this tour. It is an
                      approved tour, User will be notified by email with the new
                      reason, you will add below.
                    </p>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col md={11} xl={11} lg={11} sm={11} xs={11}>
                    <span style={{ color: "#999999" }}>Add Reasons</span>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      id='example-date-input4'
                      type='textarea'
                      placeholder=''
                      className='dm-textarea'
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                    <Button
                      className='waves-effect waves-light mr-5 w-100 approvedDenyBtn1 '
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>

                  <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                    {loading ? (
                      <Button
                        className='waves-effect waves-light mr-1 w-100 approvedDenyBtn2 '
                        onClick={(e) => approvalDeny(e)}
                        disabled={true}
                      >
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />{" "}
                        Cancel Tour
                      </Button>
                    ) : (
                      <Button
                        className='waves-effect waves-light mr-1 w-100 approvedDenyBtn2 '
                        onClick={(e) => approvalDeny(e)}
                      >
                        Cancel Tour
                      </Button>
                    )}
                  </Col>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DeniedModel;
