import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import usersApi from "../../../services/usersApi";
import toursApi from "../../../services/toursApi";
import { useQuery } from "react-query";
import {
  useToursApprovedMutation,
  useToursDeniedMutation,
} from "../../../rc-query/toursHook";
import "../requests/css/styles.css";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [tourID, setTourID] = useState("");
  const [request, setRequest] = useState("");
  const [requestModel, setRequestModel] = useState(false);
  const [declineModel, setDeclineModel] = useState(false);
  const [deniedModel, setDeniedModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [status, setStatus] = useState("");
  const approvalMutation = useToursApprovedMutation(props.history);
  const deniedMutation = useToursDeniedMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const { data: userData, isLoading: userLoading } = useQuery(
    ["tourUserData"],
    () => usersApi.FindUserById(data?.userId),
    {
      retry: 0,
    }
  );

  const { data: tourHistory, isLoading: tourHistoryLoading } = useQuery(
    ["tourHistory"],
    () => toursApi.getTourHistory(data?.userId),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const modelFunc2 = (value) => {
    setModelShow1(value);
  };

  const modelRequestFunc = (value) => {
    setRequestModel(value);
  };

  const declineModelFunc = (value) => {
    setDeniedModel(value);
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };
  // *****Tours Aproval Request api*********
  const approvalFunc = (data) => {
    setLoading(true);

    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "tour Approval",
              severity: "info",
              status: "Successfull",
              component: "Tour Requests",
            })
            .then((data) => {});
          setModelShow(false);
          setLoading(false);
        }
        navigate("/tours");
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "tour approval",
            severity: "info",
            status: "Failed",
            component: "Tour Requests",
          })
          .then((data) => {});
        setModelShow(false);
        setLoading(false);
      });
  };

  // *****Tours Deny Request api*********
  const deniedFunc = (data) => {
    setLoading(true);

    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "tour deny",
              severity: "info",
              status: "Successfull",
              component: "Tour Requests",
            })
            .then((data) => {});
          navigate("/tours");
          setLoading(false);
          setModelShow1(false);
          setDeniedModel(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "tour deny",
            severity: "info",
            status: "Failed",
            component: "Tour Requests",
          })
          .then((data) => {});
        setLoading(false);
        setModelShow1(false);
        setDeniedModel(false);
      });
  };

  const approval = (id) => {
    setTourID(id);
    setModelShow(true);
    setRequest("approval");
    setStatus("tours");
  };

  const deneiedApproval = (id) => {
    setTourID(id);
    setRequest("deny");
    setStatus("tours");
    setDeniedModel(true);
  };

  const rejectApproval = (id) => {
    setTourID(id);
    setRequest("deny");
    setStatus("tours");
    setDeclineModel(true);
  };

  return {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    tourID,
    loading,
    deniedModel,
    tourHistoryLoading,
    tourHistory,
    approvalFunc,
    deniedFunc,
    deneiedApproval,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    modelFunc2,
    modelRequestFunc,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  };
};
