import { useState, useEffect } from "react";
import { useChangePasswordMutation } from "../../rc-query/usersHook";
import { useNavigate } from "react-router-dom";

export const useForgotPasswordInfo = (props) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState("lgInput");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validEye, setValidEye] = useState("forgotPasswordEye");
  const [validEye1, setValidEye1] = useState("forgotPasswordEye1");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [upcaseValid, setUpcaseValid] = useState("");
  const [lenghtValid, setLenghtValid] = useState("");
  const [numberValid, setNumberValid] = useState("");
  const changePassword = useChangePasswordMutation(props.history);

  // Password toggle handler
  const togglePassword = (eye) => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown

    if (eye === 1) {
      setPasswordShown1(!passwordShown1);
    } else {
      setPasswordShown(!passwordShown);
    }
  };

  const handleFoucs = () => {
    setValidEye("forgotPasswordEye eyeColor");
    setValidEye1("forgotPasswordEye1 eyeColor");
  };

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setValidation("lgInput");
    } else {
      setValidation("validate");
    }

    if (newPassword.length < 8 && newPassword !== "") {
      setLenghtValid("invalidSpan");
    } else {
      setLenghtValid("");
    }
    if (!/[A-Z]/.test(newPassword) && newPassword !== "") {
      setUpcaseValid("invalidSpan");
    } else {
      setUpcaseValid("");
    }
    if (!/\d/.test(newPassword) && newPassword !== "") {
      setNumberValid("invalidSpan");
    } else {
      setNumberValid("");
    }
  }, [confirmPassword, newPassword]);

  const updatePassword = () => {
    changePassword.mutateAsync(confirmPassword).then((data) => {
      if (data?.status) {
        navigate("/");
        localStorage.removeItem("token");
      }
    });
  };

  return {
    upcaseValid,
    lenghtValid,
    numberValid,
    validEye,
    validation,
    validEye1,
    passwordShown,
    newPassword,
    passwordShown1,
    confirmPassword,
    updatePassword,
    setNewPassword,
    setConfirmPassword,
    togglePassword,
    handleFoucs,
  };
};
