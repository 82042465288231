import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import { GrFormClose } from "react-icons/gr";
import accessControl from "../../../services/accessControlApi";
import { useQuery } from "react-query";
import Spinner from "react-bootstrap/Spinner";
import "./css/styles.css";

const AddDepartment = (props) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState();
  const [resourceType, setResourceType] = useState();
  const [resources, setResources] = useState();
  const [accessLevel, setAccessLevel] = useState();

  const { data: resourceTypeList, isLoading: resourceTypeListLoading } =
    useQuery(["resourceTypeList"], () => accessControl.getResourceType(), {
      retry: 0,
    });

  const { data: getDepartment, isLoading: getDepartmentLoading } = useQuery(
    ["getDepartment", props.departmentId],
    () => accessControl.getDepartment(props.departmentId),
    {
      retry: 0,
    }
  );

  const { data: accessLevelList, isLoading: accessLevelLoading } = useQuery(
    ["accessLevelList"],
    () => accessControl.getAccessLevelList(),
    {
      retry: 0,
    }
  );

  const { data: resourcesList, isLoading: resourcesListLoading } = useQuery(
    ["resourcesList", resourceType, getDepartment],
    () => accessControl.getResources(resourceType?.value),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    setCode(getDepartment?.code);
    setName(getDepartment?.name);
    setResourceType(getDepartment?.resourceType);
    setResources(getDepartment?.resources);
    setAccessLevel(getDepartment?.accesslevels);
  }, [getDepartmentLoading, getDepartment, props.show]);

  const cancalFunc = () => {
    setResources();
    setAccessLevel();
    setCode();
    setResourceType();
    setName("");

    props.setModelShow(false);
    props.setIsUpdate(false);
  };

  const handleAddNewDepartment = () => {
    const resources2 = resources.map((item) => ({
      name: item.label,
      id: item.value,
    }));

    const accessLevels2 = accessLevel.map((item) => ({
      name: item.label,
      id: item.value,
    }));

    const data = {
      name: name,
      code: Number(code),
      resourceType: {
        name: resourceType.label,
        id: resourceType.value,
      },
      resources: resources2,
      accessLevels: accessLevels2,
    };
    if (props.request === "create") {
      props.addDepartment(data);
    } else if (props.request === "update") {
      props.updateDepartment(data);
    }
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='add-memeber-card'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='add-member-col'
                  >
                    <div>
                      {props.request === "create" ? (
                        <h5>Add Department</h5>
                      ) : (
                        <h5>Update Department</h5>
                      )}
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => cancalFunc()}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Form onSubmit={handleAddNewDepartment}>
                  <FormGroup>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Name</h6>
                        <Input
                          id='input-name'
                          className='addAccessLevelName'
                          placeholder='Name'
                          value={name}
                          type='text'
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Code</h6>

                        <Input
                          id='input-name'
                          className='addAccessLevelName'
                          min={0}
                          placeholder='Code'
                          value={code}
                          type='number'
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select resource type</h6>
                        <Select
                          // menuPortalTarget={document.body}
                          value={resourceType}
                          options={resourceTypeList}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select resource type
                            </span>
                          }
                          isLoading={resourceTypeListLoading}
                          onChange={(e) => {
                            setResourceType(e);
                            setResources();
                          }}
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select resources</h6>
                        <Select
                          // menuPortalTarget={document.body}
                          value={resources}
                          options={resourcesList}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select resources
                            </span>
                          }
                          isMulti={true}
                          isLoading={resourcesListLoading}
                          onChange={(e) => {
                            if (e.length === 0) {
                              setResources();
                            } else {
                              setResources(e);
                            }
                          }}
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select Access Levels</h6>
                        <Select
                          // menuPortalTarget={document.body}
                          value={accessLevel}
                          options={accessLevelList}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select access levels
                            </span>
                          }
                          isMulti={true}
                          isLoading={accessLevelLoading}
                          onChange={(e) => {
                            if (e.length === 0) {
                              setAccessLevel();
                            } else {
                              setAccessLevel(e);
                            }
                          }}
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                      <Col md={6} xl={6} lg={6} sm={12} xs={12}></Col>
                    </Row>

                    <Row>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='permision-card-col'
                      ></Col>
                      <Col md={4} xl={4} lg={4} sm={12} xs={12}></Col>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='card-btn-col'
                      >
                        {props.loading ? (
                          <Button
                            className='tours-add-slot-btn w-100'
                            disabled={true}
                          >
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                              variant='light'
                            />
                            Submitting
                          </Button>
                        ) : (
                          <Button
                            className='tours-add-slot-btn w-100'
                            disabled={
                              !name || !code || !resourceType || !resources
                                ? true
                                : false
                            }
                          >
                            {props.request === "update" ? "Update" : "Add"}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddDepartment;
