import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const rolesApi = {
  rolesList: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get("/roles", {
      headers: headers,
    });

    // const totalRoles = data?.data?.map((roles, index) => ({
    //   id: roles.id,
    //   name: roles.name,
    //   userName: tours.userName,
    //   userId: tours.userId,
    //   userCompany: tours.userCompany,
    //   slotTime: tours.slot.startTime + " to " + tours.slot.endTime,
    //   status: tours.status,
    //   date: moment(tours.date).format("DD-MMMM-YYYY"),
    // }));

    return data?.data;
  },

  createRole: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.post(`/roles`, body, {
      headers: headers,
    });

    return data;
  },

  updateRole: async (roleData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(`/roles/${roleData.id}`, roleData, {
      headers: headers,
    });
  },

  deleteRole: async (roleData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.delete(`/roles/${roleData.id}`, {
      headers: headers,
    });
  },
};

export default apiWrapper(rolesApi, [
  "rolesList",
  "createRole",
  "updateRole",
  "deleteRole",
]);
