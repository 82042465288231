import axios from "axios";
import { Saudi_Fintech_URL, FINTECH_BASE_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

export const membershipTypeSettingApi = {
  // Delete
  membershipTypeSettingDelete: async (body) => {
    if (body.planId) {
      const jwt = localStorage.getItem("token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };
      const data = await request.delete(
        `/resource-plans/${body?.planId?.id}`,

        {
          headers: headers,
        }
      );

      return data;
    }
  },

  // update
  membershipTypeSettingUpdate: async (body) => {
    if (body.planId) {
      const jwt = localStorage.getItem("token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };
      const data = await request.patch(
        `/resource-plans/${body?.planId?.id}`,
        body?.data,
        {
          headers: headers,
        }
      );

      return data;
    }
  },
  //create
  membershipTypeSettingCreate: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.post(`/resource-plans`, body, {
      headers: headers,
    });

    return data;
  },

  membershipTypeSettingrestype: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
      
    };
    const res = await axios.get(
      `${FINTECH_BASE_URL}/en/publicresources?_depth=3`,
      {
        headers: headers,
      }
    );
    const data = await res?.data?.ResourceTypes.map((e) => ({
      value: e?.Id,
      label: e?.Name,
    }));
    return data;
  },

  membershipTypeSettingplans: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    };
    const res = await axios.get(`${Saudi_Fintech_URL}/plans`, {
      headers: headers,
    });

    const data = await res?.data?.data?.map((e) => ({
      value: e?.Id,
      label: e?.Name,
    }));
    return data;
  },

  membershipTypeSettingdetails: async (id) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    };
    const data = await axios.get(`${Saudi_Fintech_URL}/resource-plans/${id}`, {
      headers: headers,
    });

    return data;
  },

  membershipTypeSaprateResources: async (id, samePlanResources) => {
    // if (!samePlanResources) {

    if (id) {
      const jwt = localStorage.getItem("token");
      const headers = {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      };
      // const data = await axios.get(`${Saudi_Fintech_URL}/resource/${id}/0`, {
      const data = await axios.get(
        `${Saudi_Fintech_URL}/get-all-resources/${id}`,
        {
          headers: headers,
        }
      );

      return data;
    }
    // }
  },

  membershipTypePlanListing: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    };
    const data = await axios.get(`${Saudi_Fintech_URL}/resource-plans`, {
      headers: headers,
    });

    return data;
  },

  uploadPhoto: async (body) => {
    var formdata = new FormData();
    formdata.append("file", body[0]);

    const data = await request.post(
      `files`,
      // { ...body },
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json, text/plain",
        },
      }
    );

    return data;
  },
};

export default apiWrapper(membershipTypeSettingApi, [
  "membershipTypeSettingDelete",
  "membershipTypeSettingUpdate",
  "membershipTypeSettingCreate",
  "membershipTypeSettingrestype",
  "membershipTypeSettingplans",
  "membershipTypeSettingdetails",
  "uploadPhoto",
]);
