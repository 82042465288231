import { useMutation } from "react-query";
import {
  PATCH_TEMPLATE_BY_ID,
  DEL_TEMPLATE_BY_ID,
  POST_NEW_TEMPLATE,
} from "./keys";

import templateApi from "../services/templatesApi";

export const useTemplateCreateMutation = (history) => {
  const response = useMutation(templateApi.createTemplate, {
    mutationKey: POST_NEW_TEMPLATE,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useTemplatePatchMutation = (history) => {
  const response = useMutation(templateApi.updateTemplate, {
    mutationKey: PATCH_TEMPLATE_BY_ID,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useTemplateDeleteMutation = (history) => {
  const response = useMutation(templateApi.deleteTemplate, {
    mutationKey: DEL_TEMPLATE_BY_ID,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
