import { QueryClient, QueryCache } from "react-query";
import { toast } from "react-toastify";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        if (error?.response?.status === 500) {
          const title =
            error instanceof Error
              ? error?.response?.statusText.toString().replace(/^Error:\s*/, "")
              : "Error Connecting to server";
          toast.error(title);
        } else if (error?.response?.status === 400) {
          const title =
            error instanceof Error
              ? error?.response?.data?.error?.message
                  ?.toString()
                  .replace(/^Error:\s*/, "")
              : "Error Connecting to server";
          toast.warning(title);
        } else if (error?.response?.status === 403) {
          window.location.href = "#/access-fail";
        } else if (error?.response?.status === 404) {
          const title =
            error instanceof Error
              ? error?.response?.data?.error?.message
                  ?.toString()
                  .replace(/^Error:\s*/, "")
              : "Error Connecting to server";
          toast.warning(title);
        } else if (
          error?.response?.status === 401 &&
          error?.response?.data?.error?.message.includes("jwt expired")
        ) {
          window.location.href = "#/login";
          localStorage.removeItem("token");
        } else {
          const title =
            error instanceof Error
              ? error?.message.toString().replace(/^Error:\s*/, "")
              : "Error Connecting to server";
          toast.error(title);
        }
      },
    },
  },
});
export const queryCache = new QueryCache();
