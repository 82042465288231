import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/styles.css";
import Spinner from "react-bootstrap/Spinner";

const RejectionReason = (props) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState("");

  async function approvalDeny(e) {
    e.preventDefault();
    if (reason) {
      props.setDeclineReason(reason);
      props.setModelShow(false);
      props.setDenyRequest(true);
      setChecked(false);
    }
  }

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row className='mb-3'>
                  <Col md={10} xl={11} lg={11} sm={10} xs={10}>
                    <span
                      style={{
                        color: "#000000",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      Rejection Reason
                    </span>
                  </Col>
                  <Col md={2} xl={1} lg={1} sm={2} xs={2}>
                    <span className='lgModelCancel'>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      id='example-date-input4'
                      type='textarea'
                      placeholder=''
                      disabled={true}
                      value={props.rejectionReason}
                      className='dm-textarea'
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className=' mt-4'>
                  <Col md={2} xl={2} lg={2} sm={12} xs={12}>
                    <Button
                      className='waves-effect waves-light mr-5 w-100 dm-btn2 '
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default RejectionReason;
