import { useAccessLevelInfo } from "./useAccessControl";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  Col,
  Row,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import DeleteIcon from "../../../images/icons/delete-icon.svg";
import Edit from "../../../images/icons/editImg.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "../css/styles.css";
import AddAccessLevel from "../../../components/molecules/addAccessLevelModel";
import ApproveModel from "../../../components/molecules/approvalModel";
const Loader = require("react-loader");
const { SearchBar } = Search;

const AccessLevel = (props) => {
  const {
    accessLevelModel,
    updateLevelModel,
    approvalModel,
    loading,
    accessLevel,
    accessLevelLoading,
    setUpdateLevelModel,
    setLoading,
    setApprovalModel,
    setAccessLevelModel,
  } = useAccessLevelInfo(props);

  return (
    <>
      {!accessLevelLoading ? (
        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row className='accessTimeControlComponent '>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between accessTimeHeader'>
                  <div>
                    <h1>Access levels</h1>
                    <p>
                      This is the list of all access levels defined in the ZKT
                      system.
                    </p>
                  </div>
                  <div className='w-25 d-flex justify-content-end'></div>
                </div>
              </Col>
              <Row>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className='accessCardsContainer '>
                    {accessLevel?.map((levels, index) => {
                      return (
                        <Card
                          className='departmentCards px-3 py-2 '
                          key={levels.id}
                        >
                          <div className='d-flex   align-items-center'>
                            <div className='w-25'>
                              <h5>{index + 1}</h5>
                            </div>
                            <div>
                              <span>Name</span>
                              <p>{levels.name}</p>
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <AddAccessLevel
        show={accessLevelModel}
        setModelShow={setAccessLevelModel}
        request={"create"}
      />
      <AddAccessLevel
        show={updateLevelModel}
        setModelShow={setUpdateLevelModel}
        request={"update"}
      />

      <ApproveModel
        // approvalFunc={approvalModel}
        show={approvalModel}
        setModelShow={setApprovalModel}
        // setApproved={modelFunc}
        request={"deleteAccessLevel"}
        loading={loading}
        headerText='Delete Access?'
        textBody='Are you sure you want to delete this Access?'
      />
    </>
  );
};

export default AccessLevel;
