import { useDepartmentsInfo } from "./useDepartments";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import DeleteIcon from "../../../images/icons/delete-icon.svg";
import Edit from "../../../images/icons/editImg.svg";
import ApproveModel from "../../../components/molecules/approvalModel";
import AddDepartment from "../../../components/molecules/addDepartmentModel";
import "../css/styles.css";
import DepartmentDetail from "../../../components/molecules/departmentDetailModel";
const Loader = require("react-loader");

const Departments = (props) => {
  const {
    approvalModel,
    loading,
    departmentModel,
    updateDepartModel,
    departmentLists,
    departmentListsLoading,
    departmentId,
    departDetailShow,
    departmentData,
    setDepartmentData,
    setDepartDetailShow,
    updateDepartment,
    handleDeleteDepartment,
    setDepartmentId,
    addNewDepartment,
    setDepartmentModel,
    setUpdateDepartModel,
    setLoading,
    setApprovalModel,
  } = useDepartmentsInfo(props);

  return (
    <>
      {!departmentListsLoading ? (
        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row className='accessTimeControlComponent '>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between accessTimeHeader'>
                  <div>
                    <h1>Departments</h1>
                    <p>
                      This is the list of all departments created in the system
                    </p>
                  </div>
                  <div className='w-25 d-flex justify-content-end'>
                    <Button
                      className='tours-add-slot-btn AccessSettingBtnResponsive'
                      onClick={() => {
                        setDepartmentModel(true);
                      }}
                    >
                      Add new
                    </Button>
                  </div>
                </div>
              </Col>
              <Row>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className='accessCardsContainer departmentCardWidth'>
                    {departmentLists.map((department, index) => {
                      return (
                        <Card
                          className='departmentCards px-3 py-2 '
                          key={department.id}
                        >
                          <div className='d-flex justify-content-between  align-items-center '>
                            <div
                              className='w-25'
                              onClick={() => {
                                setDepartDetailShow(true);
                                setDepartmentData(department);
                              }}
                              role='button'
                            >
                              <h5>{department.name}</h5>
                            </div>
                            <div
                              className='w-25'
                              onClick={() => {
                                setDepartDetailShow(true);
                                setDepartmentData(department);
                              }}
                              role='button'
                            >
                              <span>Resource Type</span>
                              <p>{department.resourceType.name}</p>
                            </div>
                            <div>
                              <img
                                src={DeleteIcon}
                                alt=''
                                height={17}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDepartmentId(department.id);
                                  setApprovalModel(true);
                                }}
                              />
                              <img
                                src={Edit}
                                alt=''
                                height={17}
                                style={{ cursor: "pointer" }}
                                className='mx-3'
                                onClick={() => {
                                  setDepartmentId(department.id);
                                  setUpdateDepartModel(true);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <AddDepartment
        show={departmentModel}
        setModelShow={setDepartmentModel}
        loading={loading}
        addDepartment={addNewDepartment}
        request={"create"}
      />

      <AddDepartment
        show={updateDepartModel}
        setModelShow={setUpdateDepartModel}
        departmentId={departmentId}
        loading={loading}
        updateDepartment={updateDepartment}
        request={"update"}
      />

      <ApproveModel
        approvalFunc={handleDeleteDepartment}
        show={approvalModel}
        setModelShow={setApprovalModel}
        // setApproved={modelFunc}
        request={"deleteDepartment"}
        loading={loading}
        headerText='Delete Access?'
        textBody='Are you sure you want to delete this Access?'
      />

      <DepartmentDetail
        show={departDetailShow}
        setModelShow={setDepartDetailShow}
        data={departmentData}
      />
    </>
  );
};

export default Departments;
