import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const dayPass = {
  dayPassList: async (body, page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      `/daypasses-status/${body}/${page}/${size}`,
      {
        headers: headers,
        params: { search: search, date: date },
      }
    );

    const dayPass = data?.data?.data?.map((pass, index) => ({
      id: pass.id,
      index_id: index + 1,
      paymentStatus: pass.paymentStatus,
      BookingId: pass.BookingId,
      name: pass.CoworkerName,
      status: pass.status,
      resourceName: pass.ResourceName,
      date: moment(pass?.BookedOn)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { daypass: dayPass, totalPages: data?.data?.totalDaypass };
  },

  exportDayPassList: async (body, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-daypasses-status/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const dayPass = data?.data?.data?.map((pass, index) => ({
      id: pass.id,
      index_id: index + 1,
      paymentStatus: pass.paymentStatus,
      BookingId: pass.BookingId,
      InvoiceNo: pass.InvoiceNo,
      bookingtime: pass.ToTime,
      name: pass.CoworkerName,
      status: pass.status,
      resourceName: pass.ResourceName,
      price: pass.Price,
      date: moment(pass?.BookedOn)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { daypass: dayPass, totalPages: data?.data?.totalDaypass };
  },

  dayPassDetail: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/daypasses/${body}`, {
      headers: headers,
    });

    return data?.data;
  },

  dayPassObjected: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/daypasss/objection/${body.id}`,
      {
        paymentStatus: "objected",
        ObjectionReason: body.reason,
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  dayPassDenied: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/daypasss-decline/${body.id}`,
      {
        status: "deny",
        DeclineReason: body.reason,
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  dayPassApproved: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/daypasss-approve/${body}`,
      {
        status: "approved",
        paymentStatus: "approved",
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  daypassRedund: async (body) => {
    // const jwt = localStorage.getItem("token");
    // const headers = {
    //   Authorization: "Bearer " + jwt,
    // };
    // const data = await request.patch(
    //   `/daypasss-approve/${body}`,
    //   {
    //     status: "approved",
    //     paymentStatus: "approved",
    //   },
    //   {
    //     headers: headers,
    //   }
    // );
    // return data?.data;
  },
};

export default apiWrapper(dayPass, [
  "dayPassList",
  "dayPassDetail",
  "dayPassObjected",
  "dayPassApproved",
  "dayPassDenied",
  "exportDayPassList",
  "daypassRedund",
]);
