import { useMutation } from "react-query";
import {
  GET_MEMBERSHIP_MUTATION,
  PATCH_MEMBERSHIP_APPROVED_MUTATION,
  PATCH_MEMBERSHIP_DENIED_MUTATION,
} from "./keys";

import memberShipApi from "../services/membershipApi";

export const useMembershipMutation = (history) => {
  const response = useMutation(memberShipApi.membershipList, {
    mutationKey: GET_MEMBERSHIP_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useMembershipApprovedMutation = (history) => {
  const response = useMutation(memberShipApi.membershipRequestsApproval, {
    mutationKey: PATCH_MEMBERSHIP_APPROVED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useMembershipDeniedMutation = (history) => {
  const response = useMutation(memberShipApi.membershipRequestsdeny, {
    mutationKey: PATCH_MEMBERSHIP_DENIED_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
