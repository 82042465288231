import ChangePassword from "../pages/changePassword";
import Dashboard from "../pages/dashboard";
import Logs from "../pages/logs/request";
import MembershipTypeDetail from "../pages/membershipSetting/membershipTypeDetail";
import MembershipSettings from "../pages/membershipSetting/membershipTypeSettings";
import MembershipDetail from "../pages/membership/detailPage";
import Membership from "../pages/membership/requests";
import PassDetail from "../pages/pass/detailPage";
import Passes from "../pages/pass/requests";
import PlanDetail from "../pages/planRequest/detailPage";
import PlanRequest from "../pages/planRequest/requests";
import Receipts from "../pages/receipts/request";
import Rejection from "../pages/regectionEmail";
import Settings from "../pages/settings";
import MemberDetail from "../pages/teamMembers/detailPage";
import Members from "../pages/teamMembers/request";
import Teams from "../pages/teams/request";
import TestPage from "../pages/test";
import ToursSettings from "../pages/toursSetting";
import DetailPage from "../pages/tours/detailPage";
import Tours from "../pages/tours/requests";
import Users from "../pages/usersRoles/users";
import UserRoles from "../pages/usersRoles/roles";
import UserCreateRoles from "../pages/usersRoles/createRole";
import UserUpdateRoles from "../pages/usersRoles/updateRole";

import Accesslog from "../pages/visitorLog/request/index";
import MembershipSettingDetail from "../pages/membershipSetting/membershipTypeSettings/membershipTypeSettingDetail";
import MembershipUpdateSettings from "../pages/membershipSetting/membershipTypeSettings/membershipTypeSettingUpdate";
import TeamsDetail from "../pages/teams/detailPage";
import ProfileSection from "../pages/profileSection";
import LogsDetail from "../pages/logs/detailPage";
import BookingPayment from "../pages/bookingAndPaymentSetting";
import ProfileUpdate from "../pages/profileUpdate";
import MeetingRoom from "../pages/meetingRoom/request";
import DayPass from "../pages/dayPass/request";
import TeamInvoices from "../pages/teamInvoices/request";
import DayPassDetail from "../pages/dayPass/detailPage";
import MeetingRoomDetail from "../pages/meetingRoom/detailPage";
import TeamInvoiceDetail from "../pages/teamInvoices/detailPage";
import InvoiceDetail from "../pages/teams/invoiceDetail";
import NotificationSettings from "../pages/notificationSettings";
import AccessControll from "../pages/accessControllPages/mainPage";
import AccessSetting from "../pages/accessControllPages/settingPage";
import AccessLimited from "../pages/accessLimited";

const Pages = [
  {
    id: "1",
    path: "/member-request",
    element: <Members />,
  },

  {
    id: "2",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    id: "3",
    path: "/passes",
    element: <Passes />,
  },
  {
    id: "4",
    path: "/tours",
    element: <Tours />,
  },
  {
    id: "5",
    path: "/tour-detail",
    element: <DetailPage />,
  },
  {
    id: "6",
    path: "/pass-detail",
    element: <PassDetail />,
  },
  {
    id: "7",
    path: "/member-detail",
    element: <MemberDetail />,
  },
  {
    id: "8",
    path: "/Teams",
    element: <Teams />,
  },
  {
    id: "9",
    path: "plan-requests",
    element: <PlanRequest />,
  },
  {
    id: "10",
    path: "/membership",
    element: <Membership />,
  },
  // {
  //   id: "11",
  //   path: "/receipts",
  //   element: <Receipts />,
  // },
  {
    id: "12",
    path: "/logs",
    element: <Logs />,
  },
  {
    id: "13",
    path: "/access-log",
    element: <Accesslog />,
  },
  {
    id: "14",
    path: "/plan-requests-detail",
    element: <PlanDetail />,
  },
  {
    id: "15",
    path: "/membership-detail",
    element: <MembershipDetail />,
  },
  {
    id: "16",
    path: "/settings",
    element: <Settings />,
  },
  {
    id: "17",
    path: "/rejection-reason-template",
    element: <Rejection />,
  },
  {
    id: "18",
    path: "/users",
    element: <Users />,
  },
  {
    id: "19",
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    id: "20",
    path: "/tours-setting",
    element: <ToursSettings />,
  },
  {
    id: "21",
    path: "/testing",
    element: <TestPage />,
  },

  {
    id: "22",
    path: "/membership-type-details",
    element: <MembershipTypeDetail />,
  },
  {
    id: "23",
    path: "/membership-type-settings",
    element: <MembershipSettings />,
  },
  {
    id: "24",
    path: "/membership-setting-detail/:id",
    element: <MembershipSettingDetail />,
  },
  {
    id: "25",
    path: "/membership-update-setting/:id",
    element: <MembershipUpdateSettings />,
  },
  {
    id: "26",
    path: "/teams-detail",
    element: <TeamsDetail />,
  },
  {
    id: "27",
    path: "/profile-section",
    element: <ProfileSection />,
  },
  {
    id: "30",
    path: "/logs-details",
    element: <LogsDetail />,
  },
  {
    id: "28",
    path: "/users-roles",
    element: <UserRoles />,
  },
  {
    id: "29",
    path: "/role-details",
    element: <UserCreateRoles />,
  },
  {
    id: "31",
    path: "/settings/update-roles",
    element: <UserUpdateRoles />,
  },
  {
    id: "32",
    path: "/booking-&-payment-setting",
    element: <BookingPayment />,
  },
  {
    id: "33",
    path: "/profile-update",
    element: <ProfileUpdate />,
  },
  {
    id: "34",
    path: "/meeting-room",
    element: <MeetingRoom />,
  },
  {
    id: "35",
    path: "/day-pass",
    element: <DayPass />,
  },
  {
    id: "36",
    path: "/team-invoices",
    element: <TeamInvoices />,
  },
  {
    id: "37",
    path: "/day-pass-detail",
    element: <DayPassDetail />,
  },
  {
    id: "38",
    path: "/meeting-room-detail",
    element: <MeetingRoomDetail />,
  },

  {
    id: "39",
    path: "/team-invoice-detail",
    element: <TeamInvoiceDetail />,
  },
  {
    id: "40",
    path: "/invoice-detail",
    element: <InvoiceDetail />,
  },
  {
    id: "41",
    path: "/notification-settings",
    element: <NotificationSettings />,
  },
  {
    id: "42",
    path: "/access-control",
    element: <AccessControll />,
  },
  {
    id: "43",
    path: "/access-settings",
    element: <AccessSetting />,
  },
  {
    id: "44",
    path: "/access-fail",
    element: <AccessLimited />,
  },
];

export default Pages;
