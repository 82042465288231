import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  useInvoicesVerifiedMutaion,
  useInvoicesObjectedMutaion,
} from "../../../rc-query/teamInvoicesHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import teamInvoices from "../../../services/teamInvoicesApi";
import "../css/styles.css";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passID, setPassID] = useState("");
  const [request, setRequest] = useState("");
  const [request1, setRequest1] = useState("");
  const [invoicePic, setsetInvoicePic] = useState();

  const [rejectionShow, setRejectionShow] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [objectionModel, setObjectionModel] = useState(false);
  const [declineReason1, setDeclineReason1] = useState("");
  const [status, setStatus] = useState("");
  const verifyMutation = useInvoicesVerifiedMutaion(props.history);
  const objectionMutation = useInvoicesObjectedMutaion(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const { data: detailInvoice, isLoading: detailInvoiceLoading } = useQuery(
    ["detailInvoice", data],
    () => teamInvoices.getSingleTeamInvoice(data),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc = (value) => {
    setModelShow(value);
  };

  const modelFunc2 = (value) => {
    setModelShow2(value);
  };

  const imageViewFunc = (value, pic) => {
    setsetInvoicePic(pic);
    setImageView(value);
  };

  const objectionModelFunc = (value) => {
    setObjectionModel(value);
  };

  const setRequestFunc1 = (value) => {
    setRequest1(value);
  };

  const setObjectionFunc1 = (value) => {
    setDeclineReason1(value);
  };

  const approval = () => {
    setModelShow(true);
  };

  const objectionFunc = (id) => {
    setPassID(id);
    setRequest("objection");
    setObjectionModel(true);
  };

  const requestObjectedFunc = (reason) => {
    setLoading(true);
    const objData = {
      reason: reason,
      id: data,
    };
    objectionMutation
      .mutateAsync(objData)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully Objected", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setLoading(false);
          generateErrMutaion
            .mutateAsync({
              action: "Team Invoice Objection",
              severity: "info",
              status: "Successfull",
              component: "Team invoices Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Team Invoices Objection",
            severity: "info",
            status: "Failed",
            component: "Team Invoices Requests",
          })
          .then((data) => {});
      });
    setObjectionModel(false);
  };

  const approvalFunc = () => {
    setLoading(true);
    verifyMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully approved", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setModelShow(false);

          generateErrMutaion
            .mutateAsync({
              action: "Team invoice Verify",
              severity: "info",
              status: "Successfull",
              component: "Team invoices Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Team invoices verify",
            severity: "info",
            status: "Failed",
            component: "Team invoices Requests",
          })
          .then((data) => {});
      });
  };

  return {
    modelShow,
    data,
    passID,
    loading,
    objectionModel,
    imageView,
    detailInvoice,
    detailInvoiceLoading,
    invoicePic,
    rejectionShow,
    approvalFunc,
    requestObjectedFunc,
    setRejectionShow,
    imageViewFunc,
    objectionFunc,
    setRequestFunc1,
    setObjectionFunc1,
    objectionModelFunc,
    modelFunc2,
    approval,
    modelFunc,
  };
};
