import React from "react";
import { useNavigate } from "react-router-dom";
import { useProfleInfo } from "./useProfile";
import { Col, Row, Card, Input } from "reactstrap";

import "./css/styles.css";
const Loader = require("react-loader");

const ProfileSection = ({ navshow, setNavShow }) => {
  const navigate = useNavigate();

  const { userProfileLoading, userProfile } = useProfleInfo();
  return (
    <>
      <Row className='p-4'>
        {!userProfileLoading ? (
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Card className='profileCard p-3 pb-4'>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h3>Profile</h3>
                    <span
                      role='button'
                      onClick={() =>
                        navigate("/profile-update", {
                          state: {
                            data: userProfile,
                          },
                        })
                      }
                    >
                      Edit
                    </span>
                  </div>

                  <hr />

                  <Row>
                    <Col
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      xl={4}
                      className='input-col-password pt-2'
                    >
                      <h6>Name</h6>

                      <Input
                        className='profileField w-100'
                        id='example-date-input-text'
                        value={userProfile?.fullName}
                        disabled={true}
                        type='text'
                      ></Input>
                    </Col>
                    <Col
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      xl={4}
                      className='input-col-password pt-2'
                    >
                      <h6>Email</h6>
                      <Input
                        id='example-date-input-text'
                        className='profileField w-100'
                        value={userProfile?.email}
                        disabled={true}
                        type='text'
                      ></Input>
                    </Col>
                    <Col
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      xl={4}
                      className='input-col-password pt-2'
                    >
                      <h6>Role</h6>
                      <Input
                        id='example-date-input-text'
                        className='profileField w-100'
                        value={userProfile?.role?.name}
                        type='text'
                        disabled={true}
                      ></Input>
                    </Col>
                    <Col md={4} lg={4} sm={12} xs={12} xl={4}></Col>
                  </Row>
                </Col>
              </Card>
            </Row>
            <Row>
              <Card className='profileCard p-3 pb-4 mt-4'>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h3>Change Password</h3>
                    <span
                      onClick={() => navigate("/change-password")}
                      role='button'
                    >
                      Edit
                    </span>
                  </div>

                  <hr />

                  <Row>
                    <Col
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      xl={4}
                      className='input-col-password'
                    >
                      <h6>Old Password</h6>

                      <Input
                        className='profileField w-100'
                        id='example-date-input-text'
                        value='Old Password'
                        disabled={true}
                        type='password'
                      ></Input>
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Row>
          </Col>
        ) : (
          <Loader className='spinner' />
        )}
      </Row>
    </>
  );
};

export default ProfileSection;
