import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { GrFormClose } from "react-icons/gr";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";
import TourDetailModel from "../viewTourDetailModel";
import toursApi from "../../../services/toursApi";
import { useQuery } from "react-query";
import "./css/styles.css";

const CalenderViewModel = (props) => {
  const [modelShow, setModelShow] = useState(false);
  const [userData, setUserData] = useState();

  const { data: approvedList, isLoading: approvedLoading } = useQuery(
    ["approvedLists", props.show],
    () => toursApi.approvedList(),
    {
      retry: 0,
    }
  );

  const thisEvent = (e) => {
    setUserData(e.event._def.extendedProps);
    setModelShow(true);
  };
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='rejection-reason-model'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <GrFormClose
                      size={25}
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                  <Row className='calenderModel'>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <FullCalendar
                        defaultView='dayGridMonth'
                        plugins={[dayGridPlugin]}
                        events={approvedList}
                        selectable={true}
                        selectHelper={true}
                        eventClick={(e) => thisEvent(e)}
                      />
                    </Col>
                  </Row>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>

      {modelShow === true ? (
        <TourDetailModel
          show={modelShow}
          setModelShow={setModelShow}
          userData={userData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CalenderViewModel;
