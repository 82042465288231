import { useDetailInfo } from "./useDetail";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button, Table } from "reactstrap";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import moment from "moment";
import "../css/styles.css";
const Loader = require("react-loader");

const PassDetail = (props) => {
  const {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    passID,
    loading,
    passHistory,
    passHistoryLoading,
    suspendModel,
    suspendUserFunc,
    setSuspendModel,
    handleSuspendUser,
    approvalFunc,
    deniedFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  } = useDetailInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <>
      {!passHistoryLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className='tourDetailPage mt-4'>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Card className='toursCardView p-4'>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='detailPageHeader'>
                        <h3>Personal Details</h3>

                        {data.status === "approved" ? (
                          <button className='detailApprovedBtn' disabled>
                            APPROVED
                          </button>
                        ) : data.status === "deny" ? (
                          <button className='detailDeniedBtn' disabled>
                            DENIED
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr />
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>First Name</h5>
                            <h6>{userData?.data?.firstName}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Last Name</h5>
                            <h6>{userData?.data?.lastName}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Phone</h5>
                            <h6>{userData?.data?.phoneNumber}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Employment Status</h5>
                            <h6>{userData?.data?.employmentStatus}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Email Address</h5>
                            <h6>{userData?.data?.email}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Occupation</h5>
                            <h6>{userData?.data?.occupation}</h6>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
                  <Col md={4} xl={4} lg={4} sm={12} xs={12}>
                    {/* {data?.status === "approved" ? (
                      <div className=' suspendBtnContainer  mt-4'>
                        <div className='w-50'>
                          <Button
                            className='waves-effect waves-light w-100 datails-btn1'
                            onClick={(e) => {
                              handleSuspendUser(data?.id);
                            }}
                          >
                            Suspend
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {data?.status === "pending" ? (
                      <div className='membershipDetailBtn mt-4'>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn1'
                          onClick={(e) => {
                            rejectApproval(data?.id);
                          }}
                        >
                          Decline
                        </Button>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn2'
                          onClick={(e) => {
                            approval(data?.id);
                          }}
                        >
                          Approve
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <Card className='membershipCard mt-4'>
                      <Row>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='membershipCardHeading'>
                            <h3>Past Requests</h3>
                          </div>
                          <hr />
                        </Col>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='teamCardHeading'>
                            <Table responsive='sm xl lg xs md '>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email Address</th>
                                  <th>Requested on</th>
                                  <th>Request Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {passHistory?.history?.map((pass, index) => {
                                  {
                                  }
                                  return (
                                    <tr key={pass.id}>
                                      <td>{pass?.userName}</td>
                                      <td>{userData?.data?.email}</td>
                                      <td>
                                        {moment(pass.createdAt)
                                          .format("DD MMMM,YYYY h:mm a")
                                          .toString()}
                                      </td>
                                      <td className='d-flex'>
                                        {pass.status === "deny" ? (
                                          <button
                                            className='detailDeniedBtn'
                                            disabled
                                          >
                                            DENIED
                                          </button>
                                        ) : pass.status === "approved" ? (
                                          <button
                                            className='detailApprovedBtn'
                                            disabled
                                          >
                                            APPROVED
                                          </button>
                                        ) : pass.status === "pending" ? (
                                          <button
                                            className='detailPendingBtn'
                                            disabled
                                          >
                                            PENDING
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <ApproveModel
        approvalFunc={approvalFunc}
        deniedFunc={deniedFunc}
        show={modelShow}
        setModelShow={modelFunc}
        setApproved={modelFunc}
        reason={declineReason}
        ID={passID}
        request={request}
        loading={loading}
        status={status}
        headerText='Approve Account'
        textBody='Are you sure you want to approve this account?'
      />
      <ApproveModel
        show={modelShow1}
        deniedFunc={deniedFunc}
        setModelShow={modelFunc1}
        reason={declineReason}
        loading={loading}
        ID={passID}
        status={status}
        request={request}
        headerText='Rejected Account'
        textBody='Are you sure you want to reject this account?'
      />
      {/* <ApproveModel
        show={suspendModel}
        setModelShow={setSuspendModel}
        approvalFunc={suspendUserFunc}
        loading={loading}
        ID={passID}
        status={status}
        request={request}
        headerText='Suspend Pass'
        textBody='Are you sure you want to suspend passes?'
      /> */}

      <DeclineModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        templates={templatesList?.data}
        setRequest={setRequestFunc}
        setDeclineReason={setDeclineFunc}
        ID={passID}
      />
    </>
  );
};

export default PassDetail;
