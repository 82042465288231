// *** Login Mutations*************
export const LOGIN_MUTATION = "loginMutation";
export const FORGOT_PASSWORD = "forgotMutation";
export const ChangePassword = "ChangePasswordMutation";

// *** Tours Mutation********
export const GET_TOURS_MUTATION = "toursMutaion";
export const PATCH_TOURS_APPROVED_MUTATION = "tourRequestMutation";
export const PATCH_TOURS_DENIED_MUTATION = "tourRequestDeniedMutation";

// *** Roles Mutation********
export const GET_ROLES_MUTATION = "rolesMutaion";
export const POST_ROLES_MUTATION = "createRoleMutation";
export const PATCH_ROLE_MUTATION = "updateRoleMutation";
export const DELETE_ROLE = "deleteRole";

// export const PATCH_TOURS_DENIED_MUTATION = "tourRequestDeniedMutation";

// *** Passes Mutation********
export const GET_PASSES_MUTATION = "passesMutaion";
export const PATCH_PASSES_APPROVED_MUTATION = "PassesRequestMutation";
export const PATCH_PASSES_DENIED_MUTATION = "PassesRequestDeniedMutation";
export const PASSES_SUSPEND_MUTATION = "PassesSuspendMutation";

// *** Membership Mutation********
export const GET_MEMBERSHIP_MUTATION = "membershipMutaion";
export const PATCH_MEMBERSHIP_APPROVED_MUTATION = "membershipRequestMutation";
export const PATCH_MEMBERSHIP_DENIED_MUTATION =
  "membershipRequestDeniedMutation";

// *** Users Mutation********

export const GET_USER_BY_ID = "usersMutation";
export const UPDATE_USER_BY_ID = "usersUpdateMutation";
export const PUT_OTP_MUTATION = "otpMutation";
export const CREATE_ACCOUNT_OTP_MUTATION = "createAccountOtpMutation";
export const VERIFY_LOCKED_ACCOUNT_MUTATION = "verifyLockedAccountMutation";
export const POST_CHANGE_PASSWORD = "passwordChangeMutation";
export const CREATE_NEW_USER = "createUserMutation";
export const UPDATE_USER_ROLE = "updateUserMutation";
export const DELETE_USER_ROLE = "deleteUserMutation";

// *** Rejection Template Mutation********

export const POST_NEW_TEMPLATE = "TemplateCreateMutation";
export const PATCH_TEMPLATE_BY_ID = "TemplateUpdateMutation";
export const DEL_TEMPLATE_BY_ID = "TemplateDeleteMutation";

// *** Plan Request Mutation********

export const GET_PLAN_MUTATION = "plansMutaion";
export const PATCH_PLAN_APPROVED_MUTATION = "plansRequestMutation";
export const PATCH_PLAN_DENIED_MUTATION = "PlansRequestDeniedMutation";
export const PATCH_PLAN_MUTATION = "PlansRequestPatchMutation";

// *** Membership Setting Add Mutation********
export const GET_MEMBERSHIP_Type_SETTING_MUTATION =
  "getMembershipTypeSettingMutaion";
export const PATCH_MEMBERSHIP_Type_SETTING_MUTATION =
  "patchMembershipTypeSettingMutation";
export const POST_MEMBERSHIP_Type_SETTING_MUTATION =
  "PostMembershipTypeSettingMutation";
export const GET_RESOURCESType_SETTING_MUTATION =
  "getResourceTypesSettingMutation";

// *** Tour SettingMutation********
export const POST_TOUR_SETTING = "postTourSettingMutation";
export const POST_TOUR_ENABLE = "postTourEnableMutation";
export const POST_TOUR_SLOTS = "postTourSlotsMutation";
export const ARCHIVED_ALL_TOUR_SLOTS = "archivedTourSlotsMutation";
export const ARCHIVE_ONE_TOUR_SLOT = "archiveTourSlotMutation";
export const ADD_HOLIDAY_SETTING = "addHolidayMutation";
export const UPDATE_HOLIDAY_SETTING = "updateHolidayMutation";
export const DELETE_MEMBERSHIP_Type_SETTING_MUTATION =
  "deleteResourceTypesSettingMutation";

// *** Logs Mutation********
export const GENERATE_ERR_LOG = "generateErrorLogsMugation";

// *** Booking and payment Mutation********
export const CREATE_BOOKING_PAYMENT = "bookingSettingMugation";

// *** Booking and payment Day Pass Mutation********
export const DAYPASS_OBJECTED = "daypassObjectedMutation";
export const DAYPASS_Approved = "daypassAprovedMutation";
export const DAYPASS_DENIED = "daypassDeniedMutation";
export const DAYPASS_REFUND = "daypassRefundMutation";

// *** Booking and payment Meeting Room Mutation********
export const MEETINGROOM_OBJECTED = "meetingRoomObjectedMutation";
export const MEETINGROOM_Approved = "meetingRoomAprovedMutation";
export const MEETINGROOM_DENIED = "meetingRoomDeniedMutation";
export const MEETINGROOM_REFUND = "meetingRoomRedunfMutation";

// *** Booking and payment Team Invoices Mutation********
export const INVOICE_OBJECTED = "teamInvoiceObjectedMutation";
export const INVOICE_VERIFIED = "teamInvoiceAprovedMutation";

// *** Member Request Mutation********
export const PATCH_MEMBER_REQUEST = "patchMemberRequestMutation";

// ***Notification Mutation********
export const CREATE_NOTIFICATION_SETTINGS =
  "createNotificationsSettingsMutation";

// ***Access Controll Mutation********
export const CREATE_NEW_DEPARTMENTS = "createNewDepartmentMutation";
export const UPDATE_DEPARTMENTS = "createNewDepartmentMutation";
export const DELETE_DEPARTMENTS = "deleteNewDepartmentMutation";
