import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import memberRequest from "../../../services/memberRequestApi";
import PlanApi from "../../../services/plansApi";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useMeetingRoomObjectedMutaion } from "../../../rc-query/memberRequestHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import { toast } from "react-toastify";

export const useMemberDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [desk, setDesk] = useState();
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [declineModel, setDeclineModel] = useState(false);
  const patchMemberRequest = useMeetingRoomObjectedMutaion(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const { data: memberDetail, isLoading: memberDetailLoading } = useQuery(
    ["memberDetail", data],
    () => memberRequest.memberDetail(data?.id),
    {
      retry: 0,
    }
  );

  const { data: dedicatedResources, isLoading: dedicatedResourcesLoading } =
    useQuery(
      ["memberDedicatedresources", memberDetail],
      () =>
        PlanApi.getDedicatedResources({
          // Id: Number(process.env.REACT_APP_DEDICATED_OFFICE),
          // type: true,
          Id: memberDetail?.resourceTypeId,
          type:
            data?.resourceTypeId ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? true
              : false,
        }),
      {
        retry: 0,
      }
    );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  const handleApprovalFunc = (status) => {
    setLoading(true);

    const data = {
      id: memberDetail?.id,
      desk: desk,
      status: "approved",
    };

    patchMemberRequest
      .mutateAsync(data)
      .then((data) => {
      
        if (data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "member request approve",
              severity: "info",
              status: "Successfull",
              component: "Member Request",
            })
            .then((data) => {setModelShow(false);});
          navigate(-1);
        }
        else if(data?.statusCode === 400 || data?.statusCode === "400"){
          toast.error("BadRequestError")
          setLoading(false);
          setModelShow(false);
        }
        setLoading(false);
        setModelShow(false);
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "member request approval",
            severity: "info",
            status: "Failed",
            component: "Member Requests",
          })
          .then((data) => {
           
            toast.error("failed")
            setLoading(false);setModelShow(false);});
      });
  };

  const handleDeniedFunc = (status) => {
    setLoading(true);

    const data = {
      id: memberDetail?.id,
      desk: desk,
      status: "deny",
    };

    patchMemberRequest
      .mutateAsync(data)
      .then((data) => {
        if (data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "member request deny",
              severity: "info",
              status: "Successfull",
              component: "Member Request",
            })
            .then((data) => {});
          navigate(-1);
        }
        setLoading(false);
        setModelShow1(false);
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "member request deny",
            severity: "info",
            status: "Failed",
            component: "Member Requests",
          })
          .then((data) => {});
      });
  };

  return {
    memberDetail,
    dedicatedResources,
    memberDetailLoading,
    dedicatedResourcesLoading,
    desk,
    modelShow1,
    declineModel,
    loading,
    modelShow,
    setModelShow,
    handleDeniedFunc,
    setDeclineFunc,
    modelFunc1,
    setDeclineModel,
    setModelShow1,
    setDesk,
    handleApprovalFunc,
  };
};
