import React from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/approvalModel.css";

const RemoveSlotModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true} className='model-body'>
      <span className='requestBody'>
        <Row>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <ModalBody>
              <Row className='mb-4'>
                <Col md={3} xl={3} lg={3} sm={2} xs={2}></Col>
                <Col md={7} xl={7} lg={7} sm={9} xs={9}>
                  <span className='model-text'>{props.headerText}</span>
                </Col>
                <Col md={2} xl={2} lg={2} sm={1} xs={1}>
                  <span className='requestModelCancel '>
                    <GrFormClose
                      size={25}
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <p>{props.textBody}</p>
                </Col>
              </Row>
              <Row className='approval-btn mt-3'>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                  <Button
                    className='waves-effect waves-light mr-5 w-100 lg-btn1 '
                    onClick={() => {
                      props.setModelShow(false);
                    }}
                  >
                    No
                  </Button>
                </Col>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>

                <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                  <Button
                    className='waves-effect waves-light mr-1 w-100 lg-btn2 '
                    onClick={() => props.removeSlot()}
                  >
                    Yes
                  </Button>
                </Col>
                <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
              </Row>
            </ModalBody>
          </Col>
        </Row>
      </span>
    </Modal>
  );
};

export default RemoveSlotModel;
