import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const dashboardApi = {
  getDashboardStats: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const dashboardStats = await request.get(`/dashboard`, {
      headers: headers,
    });
    return dashboardStats?.data;
  },
};

export default apiWrapper(dashboardApi, ["getDashboardStats"]);
