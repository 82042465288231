import { useLoginInfo } from "./useLogin";
import React from "react";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import Login_Frame from "../../images/icons/Login Pic.svg";
import BottomLogo from "../../images/icons/loginBottomLogo.svg";
import TopLogo from "../../images/icons/loginTopLogo.svg";
import "./styles/login.css";
import Card from "react-bootstrap/Card";
import PasswordModel from "../../components/molecules/passwordModel";
import "font-awesome/css/font-awesome.min.css";
import Spinner from "react-bootstrap/Spinner";

const Login = (props) => {
  const {
    modelShow,
    validEye,
    validation,
    validationMsg,
    inputRef,
    loginCard,
    loginMutation,
    passwordShown,
    passwordCard,
    loginBtnDiable,
    blocked,
    navigate,
    loginCardFunc,
    emailFunc,
    passwordFunc,
    modelFunc,
    LoginUser,
    togglePassword,
    handleFoucs,
  } = useLoginInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='lgMain'>
            <Col md={12} lg={3} xl={5} sm={12} xs={12}>
              <img src={TopLogo} alt='NexdusuLogo' className='topLogo' />
              <img src={Login_Frame} className='loginImg' alt='NexdusuLogo' />
            </Col>
            <Col md={12} lg={9} sm={12} xs={12} xl={7}>
              <Row className='loginCard '>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
                <Col md={6} lg={6} sm={12} xs={12} xl={6}>
                  <Card className={`${loginCard}`}>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Login</h1>
                        <Form onSubmit={LoginUser}>
                          <FormGroup>
                            <div>
                              {" "}
                              <Input
                                id='example-date-input-email'
                                onChange={(e) => emailFunc(e)}
                                placeholder='Email'
                                className={`${validation}`}
                                type='email'
                                ref={inputRef}
                              />
                            </div>
                            <div className='d-flex '>
                              <Input
                                id='example-date-input-password'
                                onChange={(e) => passwordFunc(e)}
                                placeholder='password'
                                className={`${validation}`}
                                onFocus={() => handleFoucs()}
                                type={passwordShown ? "text" : "password"}
                                ref={inputRef}
                              />
                              <i
                                onClick={() => togglePassword()}
                                className={`fa ${validEye} ${
                                  !passwordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                            </div>
                            {blocked ? (
                              <p className='validate-msg'>
                                Your account is blocked for security{" "}
                                <span
                                  role='button'
                                  onClick={() => navigate("/account-verify")}
                                >
                                  verify details
                                </span>
                              </p>
                            ) : (
                              <p className={`${validationMsg}`}>
                                Your email or password is incorrect
                              </p>
                            )}
                          </FormGroup>
                          {loginMutation.isLoading ? (
                            <Button className='waves-effect waves-light w-100 lgbtn '>
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                                variant='light'
                              />{" "}
                              Login
                            </Button>
                          ) : (
                            <Button
                              className='waves-effect waves-light w-100 lgbtn '
                              disabled={loginBtnDiable}
                            >
                              Login
                            </Button>
                          )}
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12} xxl={12}>
                        <div className='lganchor pt-3'>
                          <a
                            onClick={() => {
                              loginCardFunc("forgot");
                            }}
                          >
                            Forgot Password
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card className={`${passwordCard}`}>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Forgot Password</h1>
                        <p className='headerJustify'>
                          No worries, you will get a temporary password on your
                          email address
                        </p>
                        <Form>
                          <FormGroup>
                            <h6>Enter your email</h6>
                            <div>
                              {" "}
                              <Input
                                id='example-date-input-email'
                                onChange={(e) => emailFunc(e)}
                                placeholder='Email address'
                                className='lgInput'
                                type='email'
                                ref={inputRef}
                              />
                            </div>
                          </FormGroup>

                          <Button
                            className='waves-effect waves-light w-100 lgbtn '
                            onClick={() => {
                              modelFunc(true);
                            }}
                          >
                            Send Email
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} xxl={12}>
            <img src={BottomLogo} alt='NexudusLogo' className='bottomLogo' />
          </Col>
        </Row>
      </Row>
      <PasswordModel show={modelShow} setModelShow={loginCardFunc} />
    </>
  );
};

export default Login;
