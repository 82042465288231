import React from "react";
import { useDetailInfo } from "./useDetail";
import { Card, Col, Row, Button } from "reactstrap";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Dropdownunallocated from "../../../images/icons/dropdownunallocated.svg";
import Dropdowngroup from "../../../images/icons/dropdowngroup.svg";
import Table from "react-bootstrap/Table";
import moment from "moment";
import "../css/styles.css";
const Loader = require("react-loader");

const PlanDetail = (props) => {
const {
  isPoEnabled,
 doubleResouceSlected,
 pOObj,
 privateOfficeAval,
 pOName,
 preReqOffice,
declineModel,
status,
declineReason,
request,
modelShow,
modelShow1,
data,
planID,
loading,
capacity,
memberLoading,
columns,
colourStyles,
unCategorized,
resources,
dedicatedResources,
dedicatedResourcesLoading,
desk,
plansLoading,
pastPlans,
lastMonthCalculate,
dedicatedDeskOnchange,
resourceMembers,
onDragEnd,
setColumns,
approvalFunc,
deniedFunc,
approval,
rejectApproval,
modelFunc,
modelFunc1,
modelRequestFunc,
declineModelFunc,
setRequestFunc,
setDeclineFunc,
dedicatedResources1
} = useDetailInfo(props);


const { templatesList } = useRejectionInfo(props);

return (
<>
{!memberLoading ? (
<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
<Row className='tourDetailPage mt-4'>
<Col>
<Card className='toursCardView p-4'>
<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
<div className='detailPageHeader'>
<h3>Plan Details</h3>

{data.status === "approved" ? (
<button className='detailApprovedBtn' disabled>
APPROVED
</button>
) : data.status === "deny" ? (
<button className='detailDeniedBtn' disabled>
DENIED
</button>
) : (
""
)}
</div>
<hr />
<Row>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Type</h5>
<h6>{data?.planType}</h6>
</div>
</Col>
<Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Company Name</h5>
<h6>{ (data?.userCompany && data?.userCompany.length > 50)
  ? data?.userCompany.slice(0, 40) + '...'
  : data?.userCompany}</h6>
</div>
</Col>
</Row>
<Row>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Duration</h5>
<h6>{data?.duration}</h6>
</div>
</Col>
<Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Members</h5>
<h6>{data?.teamMembers.length}</h6>
</div>
</Col>
</Row>
<Row>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Start Month</h5>
<h6>{data?.startDate}</h6>
</div>
</Col>
<Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Last Month</h5>
<h6>
{
moment(data?.startDate)
.add(1, "months")
.subtract(1, "days")
.format("DD MMMM,YYYY")
//moment(startDate);

/* {moment(
lastMonthCalculate(
data?.startDate,
data?.months,
data?.duration
)
)
.subtract(moment().utcOffset(), "minutes")
.format("DD MMMM,YYYY")} */
}
</h6>
</div>
</Col>
</Row>
<Row>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
{data?.planType === "DedicatedDesk" ? (
<div className='info-heading cardHeading'>
<h5>Dedicated Desks ({data?.duration})</h5>
<h6>SAR {data?.price}</h6>
</div>
) : (
<div className='info-heading cardHeading'>
<h5>Private Office ({data?.duration})</h5>
<h6>SAR {data?.price}</h6>
</div>
)}
</Col>
<Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
<Col md={12} lg={5} xl={5} sm={12} xs={12}>
<div className='info-heading cardHeading'>
<h5>Time of request</h5>
<h6>{data?.createdAt}</h6>
</div>
</Col>
</Row>
</Col>
</Row>
</Card>
{data.status === "pending" ? (
<Row>
<Col className='pt-4'>
<Card className='toursCardView p-4'>
<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
<div className='detailPageHeader'>
<h3>Resource Confirmation</h3>
</div>
<hr />
</Col>
</Row>

<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
<div className='detailPageHeader table-responsive'>
<div
style={{
display: "flex",
justifyContent: "center",
height: "100%",
}}
>
<DragDropContext
onDragEnd={(result) =>
onDragEnd(result, columns, setColumns)
}
>
{Object.entries(columns).map(
([columnId, column], index) => {
return (
<div
style={{
display: "flex",
flexDirection: "column",
backgroundColor:
index === 0
? "rgba(240, 95, 35, 0.05)"
: "rgba(1, 41, 250, 0.05)",
padding: " 10px 20px 0 20px ",
marginRight: 15,
borderRadius: 4,
}}
key={columnId}
>
<p className='dd_Card_h1 '>
{index === 0 ? (
<span>
<img
className='mx-2'
src={Dropdownunallocated}
alt='Dropdownunallocated'
/>
<span>
Unallocated Members{" "}
</span>
<span className='dd_members_h dd_max_no'>
{unCategorized?.length}
</span>
<span className='dd_members_h dd_mex_members'>
Members
</span>
</span>
) : data?.ResourceTypeId ===
Number(
process.env
.REACT_APP_DEDICATED_OFFICE
) ? (
<span>
<img
className='mx-2'
src={Dropdowngroup}
alt='Dropdowngroup'
/>

<span>Dedicated Desks </span>
<span className='dd_members_h dd_max_no'>
{dedicatedResources?.length}
</span>
<span className='dd_members_h dd_mex_members'>
Available Desks
</span>
</span>
) : index === 1 &&
data?.ResourceTypeId ===
Number(
process.env
.REACT_APP_PRIVATE_OFFICE
) ? (
<span>
<img
className='mx-2'
src={Dropdowngroup}
alt='Dropdowngroup'
/>

<select
name='resources'
id='resources'
styles={colourStyles}
className=' pd_card_select'
value={desk}
onChange={(e) =>
resourceMembers(e)
}
>
<option
value='Select Desk'

>
{/* Change office */}
{isPoEnabled && pOName ? pOName :"Change office"}
</option>
{resources?.map(
(resource, index) => {
if (
resource?.contractedCowrkerId ===
null && resource.name !== pOName
) {
return (
<option
value={JSON.stringify(
{
id: resource.Id,
name: resource.name,
}
)}
key={resource.Id}
>
{resource.name}
</option>
);
}
}
)}
</select>

<span className='dd_members_h dd_max_no'>
{capacity}
</span>
<span className='dd_members_h dd_mex_members'>
Max Members
</span>
<p className="mt-4 mx-2 info-heading cardHeading">Requested {preReqOffice}</p>
</span>
) : index === 2 &&
data?.dedicatedResourceTypeId ===
Number(
process.env.REACT_APP_DEDICATED_OFFICE
) ? (
<span>
<img
className='mx-2'
src={Dropdowngroup}
alt='Dropdowngroup'
/>

<span>Dedicated Desks </span>
<span className='dd_members_h dd_max_no'>
{dedicatedResources?.length}
</span>
<span className='dd_members_h dd_mex_members'>
Available Desks
</span>
</span>
) :
 (
<span>
<img
className='mx-2'
src={Dropdowngroup}
alt='Dropdowngroup'
/>

<select
name='resources'
id='resources'
styles={colourStyles}
className=' pd_card_select'
value={desk}
onChange={(e) =>
resourceMembers(e)
}
>
<option
value='Select Desk'
>
{isPoEnabled && pOName ? pOName :"Change office"}

</option>
{resources?.map(
(resource, index) => {
  
 
if (
 
resource?.contractedCowrkerId === null && resource.name !== pOName
) {
return (
<option
// value={resource.Id}
value={JSON.stringify(
{
id: resource.Id,
name: resource.name,
}
)}
key={resource.Id}
>
{resource.name}

</option>
);
}
}
)}
</select>


<span className='dd_members_h dd_max_no'>
{capacity}
</span>
<span className='dd_members_h dd_mex_members'>
Max Members
</span>
<p className="mt-4 mx-2 info-heading cardHeading">Requested {preReqOffice}</p>
</span>
)
}
</p>
<div style={{ margin: 8 }}>


<Droppable
droppableId={columnId}
key={columnId}
>
{(provided, snapshot) => {
return (
<div
{...provided.droppableProps}
ref={provided.innerRef}
style={{
width: 349,
minHeight: 500,
}}
>
{column.items.map(
(item, index) => {
return (
<Draggable
key={item.id}
draggableId={
item.id
}
index={index}
>
{(
provided,
snapshot
) => {
return (
<div
className='dd_name_Entity'
ref={
provided.innerRef
}
{...provided.draggableProps}
{...provided.dragHandleProps}
style={{
userSelect:
"none",
padding: 10,
margin:
"0 0 8px 0",
minHeight:
"27px",

...provided
.draggableProps
.style,
}}
>
<span className='dd_name_fonts'>
{
item.content
}
</span>
{columnId ===
"Dedicated_Desk" ? (
<span>
<select
name='dedicatedDesk'
id='dedicatedDesk'
className=' dd_card_select'
onChange={(e) => dedicatedDeskOnchange(e,index)}



>
<option
value='Select Desk'
>
Select Desk







</option>

{!dedicatedResourcesLoading ? (
dedicatedResources?.map((resource,index) => {
 
//  var itemShow = columns?.Dedicated_Desk?.items?.filter((e)=>
// { if(e?.desk === resource.Id)
//  {
//   dedicatedResources1.splice(resource, -1)
//  }}
//  )




if (
resource?.contractedCowrkerId ) {
 
} else {
return (
 
<option
value={JSON.stringify(
{
id: resource.Id,
name: resource.name,
}
)}
key={
resource.Id
}
>
{
resource.name
}
</option>
);
}}
// }
)
) : (
<option
value='Select
Desk'
>
Loading...
</option>
)}
</select>
</span>
) : (
""
)}
</div>
);
}}
</Draggable>
);
}
)}
{provided.placeholder}
</div>
);
}}
</Droppable>
</div>
</div>
);
}
)}
</DragDropContext>
</div>
</div>
<hr />
</Col>
</Row>
</Card>
</Col>
</Row>
) : (
""
)}

<Row>
{data?.status === "pending" ? (
<>
<Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
<Col md={4} xl={4} lg={4} sm={12} xs={12}>
<div className='membershipDetailBtn mt-4'>
<Button
className='waves-effect waves-light w-100 datails-btn1'
onClick={(e) => {
rejectApproval(data.id);
}}
>
Decline
</Button>
<Button
disabled={doubleResouceSlected || (!isPoEnabled && data?.planType === "Hybrid")}
className='waves-effect waves-light w-100 datails-btn2'
onClick={(e) => {
approval(data.id);

}}
// disable={!privateOfficeAval}
>
Approve
</Button>
</div>
</Col>
</>
) : (
""
)}
</Row>

<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
{data?.status === "pending" ? (
<Card className='toursCardViewp-4 mt-4'>
<Row>
<Col md={12} lg={12} sm={12} xs={12} xl={12}>
<h3>Plan Request</h3>
<hr />
</Col>
</Row>
<Col md={12} xl={12} lg={12} sm={12} xs={12}>
{!plansLoading ? (
<div className='teamCardHeading planDetailBottomCard '>
<Table responsive='sm xl lg xs md '>
<thead>
<tr>
<th>Type</th>
<th>Duration</th>
<th>Members</th>
<th>Request Date</th>
<th>Request Status</th>
</tr>
</thead>
<tbody>
{pastPlans?.map((plans, index) => {
if (plans?.status !== "pending") {
return (
<tr>
<td>{plans?.planType}</td>
<td>
{plans?.duration}{" "}
{plans?.planDuration === "Monthly"
? "Months"
: "Year"}
</td>
<td>{plans?.members}</td>
<td>{plans?.date}</td>
{plans?.status === "deny" ? (
<td className='d-flex'>
<button
className='detailDeniedBtn'
disabled
>
DENIED
</button>
</td>
) : plans?.status === "approved" ? (
<td className='d-flex'>
<button
className='detailApprovedBtn'
disabled
>
APPROVED
</button>
</td>
) : (
""
)}
</tr>
);
}
})}
</tbody>
</Table>
</div>
) : (
""
)}
</Col>
</Card>
) : (
""
)}
</Col>
</Row>
</Col>
</Row>
</Col>
</Row>
) : (
<Loader className='spinner' />
)}

<ApproveModel
approvalFunc={approvalFunc}
deniedFunc={deniedFunc}
show={modelShow}
setModelShow={modelFunc}
setApproved={modelFunc}
reason={declineReason}
ID={planID}
request={request}
loading={loading}
status={status}
headerText='Approve Request'
textBody='Are you sure you want to approve this request?'
/>
<ApproveModel
show={modelShow1}
deniedFunc={deniedFunc}
setModelShow={modelFunc1}
setApproved={modelRequestFunc}
reason={declineReason}
loading={loading}
ID={planID}
status={status}
request={request}
headerText='Rejected Request'
textBody='Are you sure you want to reject this request?'
/>

<DeclineModel
show={declineModel}
setModelShow={declineModelFunc}
setDenyRequest={modelFunc1}
setRequest={setRequestFunc}
templates={templatesList?.data}
setDeclineReason={setDeclineFunc}
ID={planID}
/>
</>
);
};

export default PlanDetail;
