import { useRejectionInfo } from "./useRejection";
import React from "react";
import { Button } from "react-bootstrap";
import { Card, Col, Row } from "reactstrap";
import "./css/styles.css";
import ReasonModel from "../../components/molecules/addReasonModel";
const Loader = require("react-loader");

const Rejection = (props) => {
  const {
    modelHeading,
    modelShow,
    modelHeader,
    template,
    templateLoading,
    disableField,
    loading,
    emailTemplates,
    tempateActionFunc,
    setTemplate,
    addReasonFunc,
  } = useRejectionInfo(props);

  return (
    <Row>
      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Button className='add-reason' onClick={() => addReasonFunc(true)}>
              Add Reason
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            {templateLoading ? (
              <Loader className='spinner' />
            ) : (
              <Card className='reasons-card'>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                    <h4>Rejection Email Reasons</h4>
                  </Col>
                </Row>
                {emailTemplates()}
              </Card>
            )}
          </Col>
        </Row>
      </Col>
      <ReasonModel
        show={modelShow}
        setModelShow={addReasonFunc}
        reason={modelHeader}
        template={template}
        isLoading={loading}
        disableField={disableField}
        setTemplate={setTemplate}
        tempateActionFunc={tempateActionFunc}
        heading={modelHeading}
      />
    </Row>
  );
};

export default Rejection;
