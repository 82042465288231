import { useAccessControllInfo } from "../accessControllPages/mainPage/useAccessControll";
import { Card, Col, Row } from "reactstrap";
import moment from "moment";
import { useDashboardInfo } from "./useDashboard";
import Calender from "../../images/icons/calender.svg";
import Eye from "../../images/icons/eyeImg.svg";
import { Shimmer } from "react-shimmer";
import { VscRefresh } from "react-icons/vsc";
import Building from "../../images/icons/buildingIcon.svg";
import Request from "../../images/icons/requestIcon.svg";
import Help from "../../images/icons/helpRequestIcon.svg";
import "./css/styles.css";
import { useNavigate } from "react-router-dom";
const Loader = require("react-loader");


const Dashboard = (props) => {
  const Navigate = useNavigate();
  const {
    checkIns,
    checkInsLoading,
    lastRefresh,
    refreshCheckin,
    calculateTimeDiff,
  } = useAccessControllInfo(props);
  const { status, statusLoading, navigate } = useDashboardInfo(props);
  return (
    <>
      <Row className='mx-4'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          {!statusLoading ? (
            <Row>
              <Col md={12} lg={12} xl={8} sm={12} xs={12}>
                <Card className='dashboardCard  mt-4 p-4'>
                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                      <h3>Today</h3>
                    </Col>
                    <Row className='h-100'>
                      <Col
                        md={4}
                        lg={4}
                        xl={4}
                        sm={12}
                        xs={12}
                        className='statsCardCol h-100'
                      >
                        <Card className='statsCard px-3 py-3 mt-3 w-100'>
                          <Row>
                            <Col md={3} lg={3} xl={3} sm={3} xs={3}>
                              <div className='d-flex align-items-center justify-content-center h-100 '>
                                <img src={Calender} alt='' className='w-100' />
                              </div>{" "}
                            </Col>
                            <Col
                              md={9}
                              lg={9}
                              xl={9}
                              sm={9}
                              xs={9}
                              className='h-100'
                              type="button" onClick={()=>
                                window.open("https://dashboard.nexudus.com/operations/bookings", '_blank')}
                            >
                              <div className='d-flex align-items-center  h-100 px-3'>
                                <div>
                                  <h1>{status?.data?.todaysBooking}</h1>
                                  <span>Bookings</span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col
                        md={4}
                        lg={4}
                        xl={4}
                        sm={12}
                        xs={12}
                        className='statsCardCol h-100'
                        type="button" onClick={()=>
                          window.open("https://dashboard.nexudus.com/operations/visitors", '_blank')}
                      >
                        <Card className='statsCard px-3 py-3 mt-3 w-100'
                        >
                          <Row>
                            <Col md={3} lg={3} xl={3} sm={3} xs={3}
                            >
                              <div className='d-flex align-items-center justify-content-center h-100 '>
                                <img src={Eye} alt='' className='w-100' />
                              </div>{" "}
                            </Col>
                            <Col
                              md={9}
                              lg={9}
                              xl={9}
                              sm={9}
                              xs={9}
                              className='h-100'
                             
                            >
                              <div className='d-flex align-items-center  h-100 px-3'>
                                <div>
                                  <h1>{status?.data?.VistorsToday}</h1>
                                  <span>Exp. Visitors</span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                        <Card className='statsCard px-3 py-3 mt-3 w-100' type="button" onClick={() => Navigate("/tours", { state: { path: "dashboard" } })}>
                          <Row>
                            <Col md={3} lg={3} xl={3} sm={3} xs={3}>
                              <div className='d-flex align-items-center justify-content-center h-100 '>
                                <img src={Building} alt='' className='w-100' />
                              </div>{" "}
                            </Col>
                            <Col
                              md={9}
                              lg={9}
                              xl={9}
                              sm={9}
                              xs={9}
                              className='h-100'
                            >
                              <div className='d-flex align-items-center  h-100 px-3'>
                                <div>
                                  <h1>{status?.data?.tours}</h1>
                                  <span>Tours</span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Row className='h-100'>
                        <Col
                          md={4}
                          lg={4}
                          xl={4}
                          sm={12}
                          xs={12}
                          className='statsCardCol h-100'
                        >
                          <Card className='statsCard px-3 py-3 mt-3 w-100'>
                            <Row>
                              <Col md={3} lg={3} xl={3} sm={3} xs={3}>
                                <div className='d-flex align-items-center justify-content-center h-100 '>
                                  <img src={Request} alt='' className='w-100' />
                                </div>{" "}
                              </Col>
                              <Col
                                md={9}
                                lg={9}
                                xl={9}
                                sm={9}
                                xs={9}
                                className='h-100'
                                
                              >
                                <div className='d-flex align-items-center  h-100 px-3'>
                                  <div>
                                    <h1>{status?.data?.openRequests}</h1>
                                    <span>Open Request</span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col
                          md={4}
                          lg={4}
                          xl={4}
                          sm={12}
                          xs={12}
                          className='statsCardCol h-100'
                        >
                          <Card className='statsCard px-3 py-3 mt-3 w-100' type="button" onClick={()=>
                           window.open("https://dashboard.nexudus.com/operations/helpDeskMessages", '_blank')}>
                            <Row>
                              <Col md={3} lg={3} xl={3} sm={3} xs={3}>
                                <div className='d-flex align-items-center justify-content-center h-100 '>
                                  <img src={Help} alt='' className='w-100' />
                                </div>
                              </Col>
                              <Col
                                md={9}
                                lg={9}
                                xl={9}
                                sm={9}
                                xs={9}
                                className='h-100'
                              >
                                <div className='d-flex align-items-center  h-100 px-3'>
                                  <div>
                                    <h1>{status?.data?.helpdesk}</h1>
                                    <span>Help Request</span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                </Card>
              </Col>
              <Col
                md={12}
                lg={12}
                xl={4}
                sm={12}
                xs={12}
                className='accessControlPage'
              >
                <Row className=' mt-4 dashboardCheckin'>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <div className='d-flex justify-content-between accessControllCheckIn '>
                      <div>
                        <div className='d-flex'>
                          <h3>Check-ins</h3>
                          <VscRefresh
                            className={`page-btn mx-3  ${
                              checkInsLoading ? "rotate" : ""
                            }`}
                            size={18}
                            color='#828282'
                            role='button'
                            onClick={() => refreshCheckin()}
                          />{" "}
                        </div>
                        <p>
                          Last refresh :{" "}
                          {moment(lastRefresh).format("DD MMMM, YYYY h:mm a")}
                        </p>
                      </div>
                      <div
                        role='button'
                        onClick={() => navigate("/access-log")}
                      >
                        <span>See all</span>
                      </div>
                    </div>
                  </Col>

                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                      {!checkInsLoading ? (
                        <div className='checkinCardsContainer'>
                          {checkIns?.map((users, index) => {
                            return (
                              <Card
                                className={
                                  users.eventLevel === 0
                                    ? "checkinCards green p-3 mt-3"
                                    : "checkinCards red p-3 mt-3"
                                }
                                key={users?.id}
                              >
                                <div className='d-flex justify-content-between '>
                                  <h3>{users?.name}</h3>
                                  <span>{users?.eventName}</span>
                                </div>
                                <div className='d-flex justify-content-between '>
                                  <span>
                                    {calculateTimeDiff(users?.eventTime)}
                                  </span>
                                  <span>{users?.eventPointName}</span>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      ) : (
                        <>
                          <Card className='shimmerCard  p-3 mt-3 '>
                            <h3>
                              <Shimmer width={120} height={8} />
                            </h3>
                            <div className='d-flex justify-content-between '>
                              <span>
                                <Shimmer
                                  width={120}
                                  height={8}
                                  className='mt-3'
                                />
                              </span>
                              <span>
                                <Shimmer
                                  width={100}
                                  height={8}
                                  className='mt-3'
                                />
                              </span>
                            </div>
                          </Card>
                          <Card className='shimmerCard  p-3 mt-3 '>
                            <h3>
                              <Shimmer width={120} height={8} />
                            </h3>
                            <div className='d-flex justify-content-between '>
                              <span>
                                <Shimmer
                                  width={120}
                                  height={8}
                                  className='mt-3'
                                />
                              </span>
                              <span>
                                <Shimmer
                                  width={100}
                                  height={8}
                                  className='mt-3'
                                />
                              </span>
                            </div>
                          </Card>
                        </>
                      )}
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          ) : (
            <Loader className='spinner' />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
