import { useState, useRef, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useLoginMutation, useForgotMutation } from "../../rc-query/loginHook";
import { useGenerateErrorLogsMutation } from "../../rc-query/logsHook";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export const useLoginInfo = (props) => {
  const navigate = useNavigate();
  const [modelShow, setModelShow] = useState(false);
  const [loginBtnDiable, setLoginBtnDiable] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validEye, setValidEye] = useState("eye1");
  const [validation, setValidation] = useState("lgInput");
  const [blocked, setBlocked] = useState(false);
  const [loginCard, setLoginCard] = useState("lgCard");
  const [passwordCard, setPasswordCard] = useState("loginCardDisplay");
  const [validationMsg, setValidationMsg] = useState("validate-msg1");
  const { addToast } = useToasts();
  const inputRef = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const loginMutation = useLoginMutation(props.history);
  const forgotMutation = useForgotMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        // console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  








  const loginCardFunc = (value) => {
    if (value === "forgot") {
      setLoginCard("loginCardDisplay");
      setPasswordCard("forgetPasswordCard");
    } else {
      setLoginCard("lgCard");
      setPasswordCard("loginCardDisplay");
      setModelShow(false);
    }
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function toLowerCase(email) {
    return email.toLowerCase();
  }

  // ************setStatesValues**********
  const modelFunc = (value) => {
    const values = {
      email: toLowerCase(email),
    };

    forgotMutation
      .mutateAsync({ ...values })
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          setModelShow(value);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          addToast(error?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error?.response?.status === 400) {
          addToast(error?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleFoucs = () => {
    setValidEye("eye1 eyeColor");
  };

  //  ****UseEffect call**********
  useEffect(() => {
    setValidation("lgInput");
    setValidationMsg("validate-msg1");
    setValidEye("eye1");
    setBlocked(false);

    if (email !== "" && password !== "" && password.length >= 8) {
      setLoginBtnDiable(false);
    } else {
      setLoginBtnDiable(true);
    }
  }, [email, password]);
  // ************End**********

  // ******Login Form**********
  async function LoginUser(e) {
    e.preventDefault();

    const values = {
      email: toLowerCase(email),
      password: password,
    };

    // ****react-query post api call****
    loginMutation
      .mutateAsync({ ...values,ipAddress })
      .then((data) => {
        if (data?.status === 200) {
          if (data?.data?.status === "changePassword") {
            localStorage.setItem("passwordToken", data?.data?.token);
            navigate("/forgot-password");
            addToast("Change your password", {
              appearance: "success",
              autoDismiss: true,
            });
          } else if (data?.data?.token) {
            localStorage.setItem("token", data?.data?.token);
            navigate("/dashboard");
            setValidation("lgInput");
            setValidationMsg("validate-msg1");
            addToast("Successfully Logged In", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        }

        generateErrMutaion
          .mutateAsync({
            action: "login",
            severity: "info",
            status: "Successfull",
            component: "Admin Login",
          })
          .then((data) => {});
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          addToast(error?.response?.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error?.response?.status === 0) {
          addToast(error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error?.response?.status === 401) {
          setValidation("validate");
          setValidEye("eye");
          setValidationMsg("validate-msg");
          addToast("Please Enter Correct Email and Passsword", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error?.response?.status === 422) {
          setValidation("validate");
          setValidEye("eye");
          setValidationMsg("validate-msg");
          addToast("Please Enter Correct Email and Passsword", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error?.response?.status === 400) {
          setBlocked(true);
          addToast(error?.response?.data?.error?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  }

  // ************setFormValues**********
  const emailFunc = (e) => {
    setEmail(e.target.value);
  };
  const passwordFunc = (e) => {
    setPassword(e.target.value);
  };
  // ************End**********

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return {
    modelShow,
    validEye,
    validation,
    validationMsg,
    inputRef,
    loginCard,
    loginMutation,
    passwordShown,
    passwordCard,
    loginBtnDiable,
    blocked,
    navigate,
    loginCardFunc,
    emailFunc,
    passwordFunc,
    modelFunc,
    LoginUser,
    togglePassword,
    handleFoucs,
  };
};
