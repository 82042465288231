import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import teamInvoices from "../../../services/teamInvoicesApi";

import meetingRoomApi from "../../../services/meetingRoomApi";
import {
  useMeetingRoomDeniedMutaion,
  useMeetingRoomObjectedMutaion,
  useMeetingRoomApprovedMutaion,
  useMeetingRoomRefundMutaion,
} from "../../../rc-query/meetingRoomHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";

import { useQuery } from "react-query";
import "../css/styles.css";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [declineModel, setDeclineModel] = useState(false);
  const [objectionModel, setObjectionModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [invoicePic, setsetInvoicePic] = useState();
  const objectionMutation = useMeetingRoomObjectedMutaion(props.history);
  const approvalMutation = useMeetingRoomApprovedMutaion(props.history);
  const deniedMutation = useMeetingRoomDeniedMutaion(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  const refundMutaion = useMeetingRoomRefundMutaion(props.history);

  const { data: MeetingRoomData, isLoading: passDataLoading } = useQuery(
    ["MeetingRoomData", data],
    () => meetingRoomApi.MeetingRoomDetail(data),
    {
      retry: 0,
    }
  );

  const { data: invoiceTax, isLoading: invoiceTaxLoading } = useQuery(
    ["calculateInvoice"],
    () => teamInvoices.getTeamInvoicesTax(),
    {
      retry: 0,
    }
  );

  const calculateVat = () => {
    if (invoiceTax?.isTaxEnable === "true") {
      const calculatedTax =
        MeetingRoomData?.meetingRoom?.Price / (1 + invoiceTax?.taxRate / 100);
        // const calculatedTax =  (Number(invoiceTax?.taxRate ) / 100) * MeetingRoomData?.meetingRoom?.Price

      // const calculatedTax =
      //   MeetingRoomData?.meetingRoom?.Price * (invoiceTax?.taxRate / 100);
      return parseFloat(calculatedTax.toFixed(2));
    } else {
      return 0;
    }
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const imageViewFunc = (value, pic) => {
    setsetInvoicePic(pic);
    setImageView(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };

  const objectionModelFunc = (value) => {
    setObjectionModel(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  const requestObjectedFunc = (reason) => {
    setLoading(true);

    const objData = {
      reason: reason,
      id: data,
    };

    objectionMutation
      .mutateAsync(objData)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully Objected", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setLoading(false);

          generateErrMutaion
            .mutateAsync({
              action: "Meeting room invoice Objection",
              severity: "info",
              status: "Successfull",
              component: "Meeting Room Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Meeting room invoice Objection",
            severity: "info",
            status: "Failed",
            component: "Meeting Room Requests",
          })
          .then((data) => {});
      });
    setObjectionModel(false);
  };

  const approvalFunc = () => {
    setLoading(true);

    approvalMutation.mutateAsync(data).then((data) => {
      if (data?.status === 200) {
        addToast("Successfully approved", {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
        setModelShow(false);

        generateErrMutaion.mutateAsync({
          action: "Meeting Room approval",
          severity: "info",
          status: "Successfull",
          component: "Meeting Room Requests",
        });
      }
    });
  };

  const deniedFunc = () => {
    setLoading(true);

    const objData = {
      id: data,
      reason: declineReason,
    };

    deniedMutation
      .mutateAsync(objData)
      .then((data) => {
        if (data?.status === 200) {
          addToast("Successfully denied", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
          setModelShow1(false);

          generateErrMutaion
            .mutateAsync({
              action: "Meeting Room deny",
              severity: "info",
              status: "Successfull",
              component: "Meetinf Room Requests",
            })
            .then((data) => {});
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Meeting Room deny",
            severity: "info",
            status: "Failed",
            component: "Meeting Room Requests",
          })
          .then((data) => {});
      });
  };

  const approval = () => {
    setModelShow(true);
  };

  const rejectApproval = () => {
    setDeclineModel(true);
  };
  const objectionFunc = () => {
    setObjectionModel(true);
  };

  const handleRefundPayment = () => {
    refundMutaion.mutateAsync().then((data) => {
      // if (data?.status === 200) {
      //   addToast("Successfully denied", {
      //     appearance: "success",
      //     autoDismiss: true,
      //   });
      //   navigate(-1);
      //   setModelShow1(false);
      //   generateErrMutaion
      //     .mutateAsync({
      //       action: "Meeting Room deny",
      //       severity: "info",
      //       status: "Successfull",
      //       component: "Meetinf Room Requests",
      //     })
      //     .then((data) => {});
      // }
    });
    // .catch((err) => {
    //   generateErrMutaion
    //     .mutateAsync({
    //       action: "Meeting Room deny",
    //       severity: "info",
    //       status: "Failed",
    //       component: "Meeting Room Requests",
    //     })
    //     .then((data) => {});
    // });
  };

  return {
    invoiceTax,
    declineModel,
    modelShow,
    modelShow1,
    loading,
    objectionModel,
    imageView,
    passDataLoading,
    MeetingRoomData,
    invoicePic,
    calculateVat,
    handleRefundPayment,
    approvalFunc,
    deniedFunc,
    requestObjectedFunc,
    imageViewFunc,
    objectionFunc,
    objectionModelFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setDeclineFunc,
  };
};
