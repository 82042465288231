import { useState, useEffect } from "react";
import moment from "moment/moment";
import { useToasts } from "react-toast-notifications";
import { isEmpty } from "lodash";
import tourSetting from "../../../services/tourSettingApi";
import { useQuery, useQueryClient } from "react-query";
import "./css/styles.css";

export const useAddTourSlotInfo = (props) => {
  const { addToast } = useToasts();
  const [endTime, setEndTime] = useState("");
  const [saturdayBooked, setSaturdayBooked] = useState(false);

  const [startTime, setStartTime] = useState("");
  const [tourDays, setTourDays] = useState([]);
  const QueryClient = useQueryClient();

  const { data: timings, isLoading: timingsLoading } = useQuery(
    ["tourTimings"],
    () => tourSetting.getOpeningTime(),
    {
      retry: 0,
    }
  );

  const { data: bookedSlots, isLoading: bookedLoading } = useQuery(
    ["bookedTours", startTime],
    () => tourSetting.getBookedTime(props.show, startTime, endTime),
    {
      retry: 0,
    }
  );

  const slotTimings = () => {
    const duration = moment(timings?.closeTime, "h:mm:ss A").diff(
      moment(timings?.openTime, "h:mm:ss A"),
      "minutes"
    );

    let time = timings?.openTime;

    let startTime = [];
    for (let i = 0; i < duration / 30; i++) {
      startTime.push(
        <option value={time} key={i}>
          {time}
        </option>
      );

      time = moment(time, "h:mm:ss A").add(30, "minutes").format("hh:mm A");
    }

    return startTime;
  };

  const handleTimeChange = (e) => {
    var date = moment(e, "h:mm:ss A")
      .add(props.duration, "minutes")
      .format("hh:mm A");
    setEndTime(date);
    setStartTime(e);

    QueryClient.invalidateQueries("bookedTours");
  };

  const addTourDay = (day, value) => {
    if (value === true) {
      tourDays.push(day);
    } else {
      const index = tourDays.indexOf(day);
      tourDays.splice(index, 1);
    }
  };

  const handleSubmit = () => {
    if (startTime === "" || isEmpty(tourDays)) {
      addToast("Kindly select start time or days", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      const slotsData = {
        toursDays: tourDays,
        maxTourPerSlot: props.maxTourPerSlot,
        startTime: startTime,
        endTime: endTime,
      };

      props.addSlots(slotsData);

      setEndTime("");
      setStartTime("");
      setTourDays([]);
      props.setModelShow(false);
    }
  };

  return {
    endTime,
    tourDays,
    timingsLoading,
    bookedSlots,
    bookedLoading,
    slotTimings,
    addTourDay,
    handleSubmit,
    handleTimeChange,
    setTourDays,
    setEndTime,
  };
};
