import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import { useMembershipApprovedMutation } from "../../../rc-query/membershipHook";
import { useMembershipDeniedMutation } from "../../../rc-query/membershipHook";
import usersApi from "../../../services/usersApi";
import { useQuery } from "react-query";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import "../css/styles.css";
import { toast } from "react-toastify";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [membershipID, setMembershipID] = useState("");
  const [request, setRequest] = useState("");
  const [declineModel, setDeclineModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [status, setStatus] = useState("");
  const approvalMutation = useMembershipApprovedMutation(props.history);
  const deniedMutation = useMembershipDeniedMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const { data: userData, isLoading: userLoading } = useQuery(
    ["membershipUserData"],
    () => usersApi.FindUserById(data?.userId),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };
  // *****Passes Aproval Request api*********
  const approvalFunc = (data) => {
    setLoading(true);

    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          setModelShow(false);
          setLoading(false);
        }
        navigate("/membership");
        generateErrMutaion
          .mutateAsync({
            action: "Membership Approval",
            severity: "info",
            status: "Successfull",
            component: "Membership Requests",
          })
          .then((data) => {});
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Membership approval",
            severity: "info",
            status: "Failed",
            component: "Membership Requests",
          })
          .then((data) => {});
        toast.error(err.response.data.error.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setModelShow(false);
        setLoading(false);
      });
  };

  // *****Passes Deny Request api*********
  const deniedFunc = (data) => {
    setLoading(true);

    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate("/membership");
          generateErrMutaion
            .mutateAsync({
              action: "Membership deny",
              severity: "info",
              status: "Successfull",
              component: "Membership Requests",
            })
            .then((data) => {});
          setLoading(false);
          setModelShow1(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Membership deny",
            severity: "info",
            status: "Failed",
            component: "Membership Requests",
          })
          .then((data) => {});
        setLoading(false);
        setModelShow1(false);
      });
  };

  const approval = (id) => {
    setMembershipID(id);
    setModelShow(true);
    setRequest("approval");
    setStatus("membership");
  };

  const rejectApproval = (id) => {
    setMembershipID(id);
    setRequest("deny");
    setStatus("membership");
    setDeclineModel(true);
  };

  return {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    membershipID,
    loading,
    approvalFunc,
    deniedFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  };
};
