import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalBody } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/style.css";
import OtpInput from "react-otp-input";

const OTPModel = (props) => {
  const [otp, setOtp] = useState();

  const updatePassword = () => {
    props.passwordChange(otp);
    props.setModelShow(false);
  };

  return (
    <>
      <Modal isOpen={props.show} centered={true}>
        <Row>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <ModalBody className='otpModelCard'>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='otpModelHeader'
                >
                  <h3>OTP Verification</h3>
                  <GrFormClose
                    size={25}
                    onClick={() => {
                      props.setModelShow(false);
                    }}
                    className='crossBtn'
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='otpModelHeader'
                >
                  <p>Enter the OTP sent to your email address.</p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='otpModelHeader'
                >
                  <OtpInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={4}
                    isInputNum={true}
                    size={26}
                    separator={<span>&nbsp;</span>}
                    className='otpInput'
                    inputStyle={{
                      width: "70px",
                      height: "70px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      margin: "20px 10px",
                      marginBottom: "32px",
                      border: "2px solid #CACACA",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <Button
                    className='waves-effect waves-light w-100 lgbtn'
                    onClick={() => updatePassword()}
                  >
                    Update Password
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default OTPModel;
