import { useInvoiceDetailInfo } from "./useInvoiceDetail";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import Table from "react-bootstrap/Table";
import FintechSaudi from "../../../images/icons/fintechSaudi.svg";
import ApproveModel from "../../../components/molecules/approvalModel";
import ObjectionModel from "../../../components/molecules/objectionModel";
import moment from "moment";
import ImageView from "../../../components/molecules/imageViewer";
import RejectionReason from "../../../components/molecules/rejectionReasonDIsplay";
import "../css/styles.css";
const Loader = require("react-loader");

const InvoiceDetail = (props) => {
  const { data, detailInvoice } = useInvoiceDetailInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='tourDetailPage mt-4'>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Card className='toursCardView p-4'>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                    <div className='teamInvoiceHeaderContainer'>
                      <img src={FintechSaudi} alt='fintechLogo' />
                      <div className='invoiceHeaderDetail'>
                        <h6>Invoice ID: {data?.invoice?.InvoiceNo}</h6>
                        <p>
                          VAT #{" "}
                          {moment(data?.invoice?.createdAt)
                            .format("DD/MM/YYYY")
                            .toString()}
                        </p>
                        <h4>{data?.company?.planType}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className='pt-5'>
                  <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Company</h5>
                          <h6>{ (data?.company?.userCompany && data?.company?.userCompany > 50)
  ? data?.company?.userCompany.slice(0, 40) + '...'
  : data?.company?.userCompany}</h6>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Members</h5>
                          <h6>{data?.company?.members?.length}</h6>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Resource Plan</h5>
                          <h6>{data?.company?.planType}</h6>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Payment Plan</h5>
                          <h6>{data?.company?.planDuration}</h6>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2} lg={2} xl={2} sm={0} xs={0}></Col>
                  <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading invoiceBilling'>
                          <h4>Billing</h4>

                          {data?.invoice?.paymentStatus === "pending" ? (
                            <h6 className='pendingStatusShow'>Pending</h6>
                          ) : data?.invoice?.paymentStatus === "objected" ? (
                            <h6 className='objectionStatusShow'>Objection</h6>
                          ) : data?.invoice?.paymentStatus === "approved" ? (
                            <h6 className='paidStatusShow'>Paid</h6>
                          ) : data?.invoice?.paymentStatus ===
                            "deniedStatusShow" ? (
                            <h6 className='pendingStatusShow'>Denied</h6>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Plan Amount</h5>

                          <h6>
                            {`${data?.invoice?.currencyCode}  ${data?.invoice?.PlanPrice}`}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>Other Bookings</h5>

                          {`${data?.invoice?.currencyCode}  ${data?.invoice?.OtherPrice}`}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='info-heading cardHeading'>
                          <h5>VAT</h5>

                          <h6>{`${data?.invoice?.currencyCode}  ${data?.invoice?.TaxPrice}`}</h6>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <hr />
                        <div className='info-heading cardHeading invoiceBilling'>
                          <h3>TOTAL PAYABLE</h3>

                          <h3>{`${data?.invoice?.currencyCode}  ${data?.invoice?.TotalPrice}`}</h3>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetail;
