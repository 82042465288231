import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const toursApi = {
  toursList: async (body = "pending", page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/tours/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalTours = data?.data?.data?.map((tours, index) => ({
      id: tours.id,
      index_id: index + 1,
      userName: tours.userName,
      userId: tours.userId,
      userCompany: tours.userCompany,
      slotTime: tours.slot.startTime + " to " + tours.slot.endTime,
      declineReason: tours.declineReason,
      tourDay: tours.slot.tourDay,
      status: tours.status,
      date: moment(tours.date).format("DD MMMM,YYYY"),
      createdAt: moment(tours.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
    }));

    return { tours: totalTours, totalPages: data?.data?.totalTours };
  },

  exportToursList: async (body = "pending", search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/export-tours/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalTours = data?.data?.data?.map((tours, index) => ({
      tourId: tours.id,
      index_id: index + 1,
      userName: tours.userName,
      userId: tours.userId,
      userCompany: tours.userCompany,
      slotTime: tours.slot.startTime + " to " + tours.slot.endTime,
      declineReason: tours.declineReason,
      tourDay: tours.slot.tourDay,
      status: tours.status,
      date: moment(tours?.data)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
      createdAt: moment(tours.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
    }));

    return { tours: totalTours, totalPages: data?.data?.totalTours };
  },

  approvedList: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/tours-calender-view/approved`, {
      headers: headers,
    });
    const totalTours = data?.data?.map((tours, index) => ({
      id: tours.id,
      index_id: index + 1,
      title: tours.userName,
      HostName: tours.userName,
      userId: tours.userId,
      userCompany: tours.userCompany,
      slotTime: tours.slot,
      CreatedDate: moment(tours.date).format("DD-MM-YYYY"),
      allDay: true,
      start: moment(tours.date).format("YYYY-MM-DD"),
    }));

    return totalTours;
  },

  toursRequestApproval: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/${statusData.route}/${statusData.id}`,
      {
        userId: statusData.userId,
        status: "approved",
      },
      {
        headers: headers,
      }
    );
  },

  toursRequestDenial: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/${statusData.route}/${statusData.id}`,
      {
        userId: statusData.userId,
        status: statusData.status,
        declineReason: statusData.declineReason,
      },
      {
        headers: headers,
      }
    );
  },

  getTourHistory: async (id) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const history = await request.get(`/tours-past-request/${id}`, {
      headers: headers,
    });

    return history?.data;
  },
};

export default apiWrapper(toursApi, [
  "toursList",
  "approvedList",
  "toursRequest",
  "toursRequestDenial",
  "getTourHistory",
  "exportToursList",
]);
