import { useUpdateRole } from "./useUpdateRoles";
import React, { useEffect } from "react";
import { Card, Col, Row, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import "../css/styles.css";

const UserRoles = (props) => {
  const {
    columns,
    defaultSorted,
    roleName,
    modelShow,
    modelShow1,
    loading,
    modelShow2,
    roleEditable,
    resourcess,
    tableData,
    setRoleName,
    deleteRole,
    modelFunc1,
    modelFunc2,
    modelFunc,
    setRoleData,
    NoDataIndication,
    setTableData,
    updateRole,
    updateResources,
    setRoleEditable,
  } = useUpdateRole(props);

  const location = useLocation();
  let { rowData } = location.state;

  const [res, setRes] = React.useState(tableData);

  useEffect(() => {
    setRoleData(rowData);
    setRoleName(rowData?.name);
    for (const [key, value] of Object.entries(rowData)) {
      if (key === "resources") {
        resourcess.forEach((r) => {
          if (value[r.name]) {
            if (value[r.name].permissions.includes("manage")) {
              r.permissions.manage = true;
            }
            if (value[r.name].permissions.includes("view")) {
              r.permissions.view = true;
            }
            if (
              value[r.name].permissions.includes("view") &&
              value[r.name].permissions.includes("manage")
            ) {
              r.permissions.all = true;
            }
          }
        });
      }
    }
    setTableData(resourcess);
    updateResources();
  }, [rowData]);

  return (
    <div>
      <Row className='p-4 '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div>
            <ToolkitProvider
              keyField='id'
              data={res}
              columns={columns}
              cellEdit={() => {}}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row>
                    {roleEditable ? (
                      <Col md={6} lg={6} xl={6} sm={0} xs={0}></Col>
                    ) : (
                      <Col md={10} lg={10} xl={10} sm={10} xs={10}></Col>
                    )}
                    {roleEditable ? (
                      <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                        <div className='membershipDetailBtn w-100'>
                          <Button
                            className='waves-effect waves-light w-100 datails-btn1'
                            onClick={() => modelFunc2(true)}
                          >
                            Delete
                          </Button>
                          <Button
                            className='w-100 tours-remove-slot-btn'
                            onClick={() => {
                              modelFunc1(true);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className='tours-add-slot-btn w-100'
                            onClick={() => {
                              modelFunc(true);
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </Col>
                    ) : (
                      <Col md={2} lg={2} xl={2} sm={12} xs={12}>
                        <div className='membershipDetailBtn w-100'>
                          <Button
                            className='tours-add-slot-btn w-100'
                            onClick={() => setRoleEditable(true)}
                          >
                            Edit
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>

                  <Row className='mt-4'>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <Card className='input-wrap2 p-2 '>
                        <div style={{ margin: "10px" }}>
                          <h4>
                            Role Name <span style={{ color: "red" }}>*</span>
                          </h4>
                          <input
                            placeholder='Enter Name'
                            type='text'
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            style={{ padding: "5px 10px" }}
                          />
                        </div>
                        <div className='table-responsive table-wrap '>
                          <BootstrapTable
                            // condensed={true}
                            loading={true}
                            hover
                            keyField='id'
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle tableBody view-table "}
                            headerWrapperClasses={"thead-light"}
                            headerClasses='tableHeadercss'
                            wrapperClasses='table-responsive'
                            {...toolkitProps.baseProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Card>

                      <Row className='align-items-md-center mt-3 bottom-btn '>
                        <Col className='inner-custom-pagination d-flex'></Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      </Row>

      <ApproveModel
        show={modelShow1}
        setModelShow={modelFunc1}
        loading={loading}
        request={"cancelRequest"}
        headerText='Discard Information?'
        textBody='Are you sure you want to discard this information?'
      />

      <ApproveModel
        approvalFunc={updateRole}
        show={modelShow}
        setModelShow={modelFunc}
        loading={loading}
        request={"updateRole"}
        createData={res}
        headerText='Update Role?'
        textBody='Are you sure you want to update this role?'
      />
      <ApproveModel
        approvalFunc={deleteRole}
        show={modelShow2}
        setModelShow={modelFunc2}
        loading={loading}
        request={"deleteRole"}
        createData={res}
        headerText='Delete Role?'
        textBody={
          "Deleting this role might affect the users Are you sure you want to delete this role ?"
        }
      />
    </div>
  );
};

export default UserRoles;
