import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Switch from "react-switch";

import rolesApi from "../../../services/rolesApi";
import { useQuery } from "react-query";
import "./css/styles.css";
import { toast } from "react-toastify";

const HolidayModel = (props) => {
  const [duration, setDuration] = useState(false);
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const cancelFunc = () => {
    setDuration(false);
    setTitle();
    setFromDate();
    setToDate();
    props.setModelShow(false);
  };

  const addHolidayFunc = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      toast.warn("To date cannot be less than from date.");
      return;
    }

    if (props?.request === "create") {
      const data = {
        title: title,
        onDay: duration,
        fromDate: fromDate,
        toDate: toDate,
      };

      props.action(data);
    } else if (props.request === "update") {
      const data = {
        title: title,
        onDay: duration,
        fromDate: fromDate,
        toDate: toDate,
        action: "update",
      };

      props.action(data, props?.data?.id);
    }

    setDuration(false);
    setTitle();
    setFromDate();
    setToDate();
  };

  useEffect(() => {
    if (props?.data) {
      setDuration(props?.data?.onDay);
      setTitle(props?.data?.title);
      setFromDate(moment(props?.data?.fromDate).format("YYYY-MM-DD"));
      setToDate(moment(props?.data?.toDate).format("YYYY-MM-DD"));
    }
  }, [props?.data, props.show]);

  useEffect(() => {
    if (duration === true) {
      setToDate(fromDate);
    }
  }, [fromDate, duration]);

  const deleteFunc = () => {
    setDuration(false);
    setTitle();
    setFromDate();
    setToDate();

    const data = {
      action: "delete",
    };

    props.action(data, props?.data?.id);
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='add-memeber-card'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='add-member-col'
                  >
                    <div>
                      <h5>Holiday</h5>
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => cancelFunc()}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Form onSubmit={addHolidayFunc}>
                  <FormGroup>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Title</h6>
                        <Input
                          id='input-name'
                          className='add-member-field'
                          placeholder='Eid Holiday'
                          value={title}
                          type='text'
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={5}
                        lg={5}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Duration</h6>

                        <div className='d-flex justify-content-between align-items-center pt-3 durationInput '>
                          <span>Date Range</span>
                          <Switch
                            id={12}
                            checked={duration}
                            onChange={(e) => {
                              setDuration(e);
                            }}
                            width={40}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checkedHandleIcon={false}
                            height={20}
                            onColor='#0129FA'
                            className='mr-1 mx-2 d-flex  mt-1'
                          />
                          <span>One Day</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>From</h6>
                        <Input
                          id='input-name'
                          className='add-member-field'
                          value={fromDate}
                          type='date'
                          min={new Date().toISOString().substr(0, 10)}
                          onChange={(e) => {
                            if (
                              e.target.value === null ||
                              e.target.value === ""
                            ) {
                              setFromDate();
                            } else {
                              setFromDate(e.target.value);
                            }
                          }}
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>To</h6>
                        <Input
                          id='input-name'
                          className='add-member-field'
                          disabled={duration}
                          value={toDate}
                          type='date'
                          min={moment(fromDate)
                            .add(2, "day")
                            .toISOString()
                            .substr(0, 10)}
                          onChange={(e) => {
                            if (
                              e.target.value === null ||
                              e.target.value === ""
                            ) {
                              setToDate();
                            } else {
                              setToDate(e.target.value);
                            }
                          }}
                          required
                        />
                      </Col>

                      <Col
                        md={12}
                        xl={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <p className='boldClass'>
                          Adding a holiday will not affect any pending requests,
                          or scheduled tours
                        </p>
                      </Col>
                    </Row>

                    {props?.request === "create" ? (
                      <Row>
                        <Col
                          md={6}
                          xl={6}
                          lg={6}
                          sm={12}
                          xs={12}
                          className='permision-card-col'
                        ></Col>
                        <Col
                          md={6}
                          xl={6}
                          lg={6}
                          sm={12}
                          xs={12}
                          className='card-btn-col'
                        >
                          <div className='d-flex mt-3'>
                            <Button
                              className=' w-100 tours-remove-slot-btn'
                              onClick={() => cancelFunc()}
                            >
                              {" "}
                              Cancel
                            </Button>

                            {props.loading ? (
                              <Button
                                className='tours-add-slot-btn w-100'
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Submiting
                              </Button>
                            ) : (
                              <Button
                                className='tours-add-slot-btn w-100'
                                onClick={() => addHolidayFunc()}
                                disabled={
                                  !title || !fromDate || !toDate ? true : false
                                }
                              >
                                Add
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col
                          md={4}
                          xl={4}
                          lg={4}
                          sm={12}
                          xs={12}
                          className='permision-card-col'
                        ></Col>
                        <Col
                          md={8}
                          xl={8}
                          lg={8}
                          sm={12}
                          xs={12}
                          className='card-btn-col'
                        >
                          <div className='d-flex mt-3'>
                            <Button
                              className=' w-100 holidayRemoveBtn'
                              onClick={() => deleteFunc()}
                            >
                              Remove
                            </Button>

                            <Button
                              className=' w-100 tours-remove-slot-btn'
                              onClick={() => cancelFunc()}
                            >
                              Cancel
                            </Button>
                            <Button
                              className='tours-add-slot-btn w-100'
                              onClick={() => addHolidayFunc()}
                              disabled={
                                !title || !fromDate || !toDate ? true : false
                              }
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                </Form>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default HolidayModel;
