import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { TabContext } from "../../../context/TabContext";
import { MdDone } from "react-icons/md";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useToasts } from "react-toast-notifications";
import plansApi from "../../../services/plansApi";
import { useQuery, useQueryClient } from "react-query";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import {
  usePlansApprovedMutation,
  usePlansDeniedMutation,
} from "../../../rc-query/plansHook";
import "../css/styles.css";

export const usePlanRequestInfo = (props) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [planID, setPlanID] = useState("");
  const [request, setRequest] = useState("");
  const [requestModel, setRequestModel] = useState(false);
  const [declineModel, setDeclineModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState(
    activeTabJustify === "1"
      ? "all"
      : activeTabJustify === "2"
      ? "pending"
      : activeTabJustify === "3"
      ? "approved"
      : activeTabJustify === "4"
      ? "deny"
      : "all"
  );
  const approvalMutation = usePlansApprovedMutation(props.history);
  const deniedMutation = usePlansDeniedMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);

  const QueryClient = useQueryClient();

  const { data: planlist, isLoading: planLoading } = useQuery(
    ["plansLists", apiStatus, currentPage, search, date, pageSize],
    () => plansApi.plansList(apiStatus, currentPage, pageSize, search, date),
    {
      retry: 0,
    }
  );

  const { data: exportPlanlist, isLoading: exportPlanLoading } = useQuery(
    ["exportPlanlist", apiStatus, search, date],
    () => plansApi.exportPlansList(apiStatus, search, date),
    {
      retry: 0,
    }
  );

  const headers = [
    { label: "No", key: "index_id" },
    { label: "Request ID", key: "id" },
    { label: "User ID", key: "userId" },
    { label: "Team ID", key: "teamId" },
    { label: "Date Applied", key: "startDate" },
    { label: "Requested On", key: "createdAt" },
    { label: "Duration", key: "duration" },
    { label: "Plan Type", key: "planType" },
    { label: "Total Members", key: "teamMembers" },
    { label: "Resource Type ID", key: "ResourceTypeId" },
    { label: "Dedicated Resource Type ID", key: "dedicatedResourceTypeId" },
    { label: "Private Resource Type ID", key: "privateResourceTypeId" },
    { label: "Dedicated Desk Members", key: "dedicatedMembers" },
    { label: "Private Office Members", key: "privateMembers" },
    { label: "Desk", key: "Desk" },
    { label: "Capacity", key: "capacity" },
    { label: "Company Name", key: "userCompany" },
    { label: "Plan Price", key: "price" },
    { label: "Status", key: "status" },
    { label: "Decline Reason", key: "declineReason" },
  ];

  const setSearchDate = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setDate();
      setCurrentPage(1);
    } else {
      setDate(e.target.value);
      setCurrentPage(1);
    }
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const modelRequestFunc = (value) => {
    setRequestModel(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };

  // *****Plan Request Aproval Request api*********
  const approvalFunc = (data) => {
    setLoading(true);

    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("plansLists");
          generateErrMutaion
            .mutateAsync({
              action: "Plan Approval",
              severity: "info",
              status: "Successfull",
              component: "Plan Requests",
            })
            .then((data) => {});
          setLoading(false);
          setModelShow(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Plan approval",
            severity: "info",
            status: "Failed",
            component: "Plan Requests",
          })
          .then((data) => {});
      });
  };

  // *****Plan Request Deny Request api*********
  const deniedFunc = (data) => {
    setLoading(true);

    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("plansLists");
          generateErrMutaion
            .mutateAsync({
              action: "Plan deny",
              severity: "info",
              status: "Successfull",
              component: "Plan Requests",
            })
            .then((data) => {});
          setLoading(false);
          setModelShow1(false);
        }
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Plan deny",
            severity: "info",
            status: "Failed",
            component: "Plan Requests",
          })
          .then((data) => {});
      });
  };

  // Approval Request
  const approval = (id) => {
    setPlanID(id);
    setModelShow(true);
    setStatus("plan-requests");
    setRequest("approval");
  };
  // Denied Request
  const rejectApproval = (id) => {
    setPlanID(id);
    setRequest("deny");
    setStatus("plan-requests");
    setDeclineModel(true);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const details = (row) => {
    navigate("/plan-requests-detail", {
      state: {
        data: row,
      },
    });
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "userName",
      text: "Applicant",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "duration",
      text: "Duration",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "userCompany",
      text: "Company Name",
      sort: true,
      formatter: (cell, row) => (

        <p>
        { cell && cell .length > 50
          ? cell .slice(0, 40) + '...'
          : cell }
      </p>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "planType",
      text: "Type",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "startDate",
      text: "Applied for",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "requests",
      text: "Requests",
      sort: true,
      formatter: (cell, row) => (
        <a href={row.fileUrl} download>
          {activeTabJustify === "1" ? (
            row.status === "pending" ? (
              <div>
                <span>
                  <button
                    className='btn-circle-object'
                    onClick={() => details(row)}
                  >
                    Pending
                  </button>
                </span>
              </div>
            ) : row.status === "approved" ? (
              <div>
                <span>
                  {" "}
                  <button
                    className='btn-circle-approved'
                    onClick={() => details(row)}
                  >
                    Approved
                  </button>{" "}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  {" "}
                  <button
                    className='btn-circle-denied'
                    onClick={() => details(row)}
                  >
                    Denied
                  </button>{" "}
                </span>
              </div>
            )
          ) : activeTabJustify === "2" ? (
            <div>
              <span className='ml-1'>
                {" "}
                <Button
                  className=' bi bi-x-lg btn-circle1 '
                  onClick={(e) => {
                    // approval(row.id);
                    details(row);
                  }}
                >
                  <MdDone size={25} />

                  {cell}
                </Button>{" "}
              </span>

              <span className='ml-1'>
                {" "}
                <Button
                  className='btn-circle2 '
                  onClick={(e) => {
                    rejectApproval(row.id);
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    viewBox='0 0 16 16'
                  >
                    <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                  </svg>

                  {cell}
                </Button>{" "}
              </span>
            </div>
          ) : activeTabJustify === "3" ? (
            <div>
              <span>
                {" "}
                <button
                  className='btn-circle-approved'
                  onClick={() => details(row)}
                >
                  Approved
                </button>{" "}
              </span>
            </div>
          ) : activeTabJustify === "4" ? (
            <div>
              <span>
                {" "}
                <button
                  className='btn-circle-denied'
                  onClick={() => details(row)}
                >
                  Denied
                </button>{" "}
              </span>
            </div>
          ) : (
            ""
          )}
        </a>
      ),
    },
  ];

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = planlist
      ? Math.trunc(planlist?.totalPages / pageSize + 1)
      : 0;
    let buttons = [];

    setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleSearchFunc = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
      setApiStatus("all");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
      setApiStatus("pending");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "3") {
      setActiveTabJustify(tab);
      setApiStatus("approved");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else {
      setActiveTabJustify(tab);
      setApiStatus("deny");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
  };

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    columns,
    planlist,
    planLoading,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    modelShow,
    declineReason,
    planID,
    request,
    status,
    modelShow1,
    declineModel,
    loading,
    exportPlanlist,
    headers,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    approvalFunc,
    deniedFunc,
    setRequestFunc,
    setDeclineFunc,
    declineModelFunc,
    modelFunc1,
    modelFunc,
    modelRequestFunc,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
    toggleCustomJustified,
  };
};
