import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import { GrFormClose } from "react-icons/gr";
import rolesApi from "../../../services/rolesApi";
import { useQuery } from "react-query";
import "./css/styles.css";

const AddAccessLevel = (props) => {
  const [validEye, setValidEye] = useState("userRoeleye");
  const [passwordShown, setPasswordShown] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState();
  const [role, setRole] = useState("");
  const [roleObj, setRoleObj] = useState({});

  const cancalFunc = () => {
    setRole("");
    setpassword("");
    setEmail("");
    setName("");
    setRoleObj({});

    props.setModelShow(false);
    props.setIsUpdate(false);
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='add-memeber-card'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='add-member-col'
                  >
                    <div>
                      {props.request === "create" ? (
                        <h5>Add Access Level</h5>
                      ) : (
                        <h5>Update Access Level</h5>
                      )}
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => cancalFunc()}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Form
                //  onSubmit={addRoles}
                >
                  <FormGroup>
                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Name</h6>
                        <Input
                          id='input-name'
                          className='addAccessLevelName'
                          placeholder='Name'
                          value={name}
                          type='text'
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select resource</h6>

                        <Select
                          menuPortalTarget={document.body}
                          options={[
                            {
                              value: 30,
                              label: "30 mins",
                            },
                            {
                              value: 60,
                              label: "1 hour",
                            },
                            {
                              value: 90,
                              label: "1 hour 30 mins",
                            },
                            {
                              value: 120,
                              label: "2 hours",
                            },
                          ]}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select Resource
                            </span>
                          }
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select time zone</h6>

                        <Select
                          menuPortalTarget={document.body}
                          // value={{
                          //   label: `${String(tourDuration)} mins`,
                          //   value: tourDuration,
                          // }}
                          options={[
                            {
                              value: 30,
                              label: "30 mins",
                            },
                            {
                              value: 60,
                              label: "1 hour",
                            },
                            {
                              value: 90,
                              label: "1 hour 30 mins",
                            },
                            {
                              value: 120,
                              label: "2 hours",
                            },
                          ]}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select Resource
                            </span>
                          }
                          // onChange={(e) => {
                          //   setSaveBtn("tours-setting-btn");
                          //   setTourDuration(parseInt(e.value));
                          // }}
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                      <Col
                        md={6}
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}
                        className='member-field-col'
                      >
                        <h6 className='required'>Select doors</h6>

                        <Select
                          menuPortalTarget={document.body}
                          options={[
                            {
                              value: 30,
                              label: "30 mins",
                            },
                            {
                              value: 60,
                              label: "1 hour",
                            },
                            {
                              value: 90,
                              label: "1 hour 30 mins",
                            },
                            {
                              value: 120,
                              label: "2 hours",
                            },
                          ]}
                          placeholder={
                            <span className='dropdown_placeholder  '>
                              Select Doors
                            </span>
                          }
                          classNamePrefix='select2-selection  '
                          className='custom-select'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='permision-card-col'
                      ></Col>
                      <Col md={4} xl={4} lg={4} sm={12} xs={12}></Col>
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='card-btn-col'
                      >
                        <Button
                          className='tours-add-slot-btn w-100'
                          disabled={btnDisable}
                        >
                          {props.request === "update" ? "Update" : "Add"}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddAccessLevel;
