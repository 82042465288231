import { useDetailInfo } from "./useDetail";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import Table from "react-bootstrap/Table";
import FintechSaudi from "../../../images/icons/fintechSaudi.svg";
import ApproveModel from "../../../components/molecules/approvalModel";
import ObjectionModel from "../../../components/molecules/objectionModel";
import moment from "moment";
import ImageView from "../../../components/molecules/imageViewer";
import RejectionReason from "../../../components/molecules/rejectionReasonDIsplay";
import "../css/styles.css";
const Loader = require("react-loader");

const TeamInvoiceDetail = (props) => {
  const {
    modelShow,
    data,
    passID,
    loading,
    objectionModel,
    imageView,
    detailInvoice,
    detailInvoiceLoading,
    invoicePic,
    rejectionShow,
    approvalFunc,
    requestObjectedFunc,
    setRejectionShow,
    imageViewFunc,
    objectionFunc,
    setRequestFunc1,
    setObjectionFunc1,
    objectionModelFunc,
    modelFunc2,
    approval,
    modelFunc,
  } = useDetailInfo(props);

  return (
    <>
      {!detailInvoiceLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className='tourDetailPage mt-4'>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Card className='toursCardView p-4'>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='teamInvoiceHeaderContainer'>
                        <img src={FintechSaudi} alt='fintechLogo' />
                        <div className='invoiceHeaderDetail'>
                          <h6>Invoice ID: {detailInvoice?.InvoiceNo}</h6>
                          <h4>{detailInvoice?.planType}</h4>
                          <p>
                            Invoice Generated:{" "}
                            {moment(detailInvoice?.createdAt)
                              .format("DD/MM/YYYY")
                              .toString()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='pt-5'>
                    <Col
                      md={4}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      // className='invoiceInfoCol'
                    >
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Company</h5>
                            <h6>{detailInvoice?.TeamName}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Members</h5>
                            <h6>{detailInvoice?.members}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Resource Plan</h5>
                            <h6>{detailInvoice?.planType}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Payment Plan</h5>
                            <h6>{detailInvoice?.planDuration}</h6>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2} lg={2} xl={2} sm={0} xs={0}></Col>
                    <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading invoiceBilling'>
                            <h4>Billing</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Plan Amount</h5>
                            <h6>
                              {process.env.REACT_APP_CURRENCY_CODE}{" "}
                              {detailInvoice?.PlanPrice}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Tax Amount</h5>
                            <h6>
                              {process.env.REACT_APP_CURRENCY_CODE}{" "}
                              {detailInvoice?.TaxPrice}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Other Bookings</h5>
                            <h6>
                              {process.env.REACT_APP_CURRENCY_CODE}{" "}
                              {detailInvoice?.OtherPrice}
                            </h6>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <hr />
                          <div className='info-heading cardHeading invoiceBilling'>
                            <h3>Total Payable</h3>
                            <h3>
                              {process.env.REACT_APP_CURRENCY_CODE}{" "}
                              {detailInvoice?.TotalPrice}
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2} lg={2} xl={2} sm={12} xs={12}>
                      <div
                        className='imgContainer'
                        role='button'
                        onClick={() =>
                          imageViewFunc(true, detailInvoice?.InvoiceDoc)
                        }
                      >
                        {detailInvoice?.InvoiceDoc ? (
                          <img
                            src={detailInvoice?.InvoiceDoc}
                            alt='invoiceImg'
                            width='100'
                          />
                        ) : (
                          ""
                        )}
                        <i className='fullviewBtn'>View Full</i>
                      </div>
                    </Col>
                  </Row>
                </Card>

                {detailInvoice?.status === "pending" ? (
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <Card className='membershipCard mt-5'>
                        <Row>
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <div className='membershipCardHeading'>
                              <h3>Payment objections</h3>
                            </div>
                            <hr />
                          </Col>
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <div className='objectionTable'>
                              <Table responsive='sm xl lg xs md '>
                                <thead>
                                  <tr>
                                    <th>Invoices</th>
                                    <th>Customer</th>
                                    <th>Reason</th>
                                    <th>User's Comment</th>
                                    <th>Date</th>
                                    <th>Archive</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {detailInvoice?.PaymentsObjections?.map(
                                    (objections, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{objections.paymentInvoiceNo}</td>
                                          <td>{detailInvoice?.userName}</td>
                                          <td>{objections.reason}</td>
                                          <td>{objections.paymentComments}</td>
                                          <td>
                                            {moment(objections.date)
                                              .format("DD MMMM,YYYY")
                                              .toString()}
                                          </td>

                                          <td>
                                            <i
                                              role='button'
                                              onClick={() =>
                                                imageViewFunc(
                                                  true,
                                                  objections.paymentProve
                                                )
                                              }
                                            >
                                              View
                                            </i>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {detailInvoice?.status === "pending" ? (
                  <Row className='mt-5'>
                    <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
                    <Col md={4} xl={4} lg={4} sm={12} xs={12}>
                      <div className='membershipDetailBtn mt-4'>
                        <Button
                          className='waves-effect waves-light w-100 objectionInvoiceBtn'
                          onClick={(e) => {
                            objectionFunc();
                          }}
                        >
                          Objection
                        </Button>

                        <Button
                          className='waves-effect waves-light w-100 datails-btn2'
                          onClick={(e) => {
                            approval();
                          }}
                        >
                          Verify
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <ApproveModel
        approvalFunc={approvalFunc}
        show={modelShow}
        setModelShow={modelFunc}
        setApproved={modelFunc}
        request={"verifyInvoice"}
        loading={loading}
        headerText='Verify Receipt'
        textBody='Are you sure you want to verify this receipt?'
      />

      <ObjectionModel
        show={objectionModel}
        loading={loading}
        setModelShow={objectionModelFunc}
        objectionFunc={requestObjectedFunc}
      />

      <ImageView
        show={imageView}
        setModelShow={imageViewFunc}
        image={invoicePic}
      />
      <RejectionReason
        show={rejectionShow}
        setModelShow={setRejectionShow}
        rejectionReason={"this is unaccpected"}
      />
    </>
  );
};

export default TeamInvoiceDetail;
