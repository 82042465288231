import React, { useState, useEffect } from "react";
import { TabContext } from "../../../context/TabContext";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import "./css/styles.css";
import { GrNotification } from "react-icons/gr";
import "remixicon/fonts/remixicon.css";
import { Shimmer } from "react-shimmer";
import moment from "moment";
import Pagination from "./pagination";

import { firebaseDB } from "../../../firebase";
import {
  ref,
  onValue,
  query,
  get,
  limitToLast,
  orderByKey,
  orderByValue,
} from "firebase/database";
import { getClippingParents } from "@fullcalendar/react";

const time = (date) => {
  const newDate = new Date(date);
  return newDate.toISOString();
};

const NotificationDropdown = (props) => {
  const [currentPage, setCurrentPage] = useState(10);
  const [state, setState] = useState(false);
  const [notiNav1, setNotiNav1] = useState("nav-containers1");
  const [notiNav2, setNotiNav2] = useState("nav-containers");
  const [notiNav3, setNotiNav3] = useState("nav-containers");

  // Notifications
  const [notifications, setNotifications] = useState([]);
  const [requestNotifications, setRequestNotifications] = useState([]);
  const [paymentNotifications, setPaymentNotifications] = useState([]);
  const [activeNotifications, setActiveNotifications] = useState(false);
  const [notificationType, setNotificationType] = useState("all");

  const toggle = () => {
    setState(!state);
  };

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setNotiNav1("nav-containers1");
      setNotiNav2("nav-containers");
      setNotiNav3("nav-containers");
    } else if (tab === "2") {
      setNotiNav1("nav-containers");
      setNotiNav2("nav-containers1");
      setNotiNav3("nav-containers");
    } else {
      setNotiNav1("nav-containers");
      setNotiNav2("nav-containers");
      setNotiNav3("nav-containers1");
    }
  };

  useEffect(() => {
    onValue(ref(firebaseDB, `/`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const arrayOfObj = Object.entries(data).map((e) => e[1]);

        arrayOfObj.sort((a, b) => parseInt(b.time) - parseInt(a.time));

        setNotifications(arrayOfObj);
        if (notifications.length !== arrayOfObj.length) {
          setActiveNotifications(true);
          localStorage.setItem("newNotifications", true);
        }
      } else {
        localStorage.setItem("newNotifications", false);
      }
    });
  }, [firebaseDB, notifications]);

  useEffect(() => {
    onValue(ref(firebaseDB, `payments`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        // setActiveNotifications(false)
        const arrayOfObj = Object.entries(data).map((e) => e[1]);

        arrayOfObj.sort((a, b) => parseInt(b.time) - parseInt(a.time));

        setPaymentNotifications(arrayOfObj);

        if (paymentNotifications.length !== arrayOfObj.length) {
          setActiveNotifications(true);
          localStorage.setItem("newNotifications", true);
        }
      } else {
        // setActiveNotifications(false)
        localStorage.setItem("newNotifications", false);
      }
    });
  }, [firebaseDB, paymentNotifications]);

  useEffect(() => {
    const paymentsRef = ref(firebaseDB, "/requests");
    const mostViewedPosts = query(
      paymentsRef,
      orderByKey("time")
      //  limitToLast(100)
    );
    async function asanc() {
      const abc = await get(mostViewedPosts);
      const data = abc.toJSON();

      if (data !== null) {
        const arrayOfObj = Object.entries(data).map((e) => e[1]);
        arrayOfObj.sort((a, b) => parseInt(b.time) - parseInt(a.time));
        setRequestNotifications(arrayOfObj);
        if (requestNotifications.length !== arrayOfObj.length) {
          setActiveNotifications(true);
          localStorage.setItem("newNotifications", true);
        }
      } else {
        // setActiveNotifications(false)
        localStorage.setItem("newNotifications", false);
      }
      // });
    }
    asanc();
  }, [firebaseDB, requestNotifications]);

  // // Detect scroll position
  // window.onscroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //     document.documentElement.offsetHeight
  //   ) {
  //     // Load more data when the user reaches the end of the page
  //     setCurrentPage((prevPage) => prevPage + 9);
  //   }
  // };

  // useEffect(() => {
  //   onValue(ref(firebaseDB, `requests`), (snapshot) => {
  //     const data = snapshot.val();

  //     if (data !== null) {
  //       const arrayOfObj = Object.entries(data)
  //         .map((e) => e[1])
  //         .reverse();
  //       setRequestNotifications(arrayOfObj);
  //       if (requestNotifications.length !== arrayOfObj.length) {
  //         setActiveNotifications(true);
  //       }
  //     } else {
  //       // setActiveNotifications(false)
  //     }
  //   });
  // }, [requestNotifications.length]);

  const notificationItems = notifications.length
    ? Object?.values(notifications[0])
    : [];

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={state}
          toggle={toggle}
          className="d-inline-block notification-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
          >
            <GrNotification className="noti-nav-icon" />
            <span className="noti-dot"></span>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end dropdown-menu-lg p-0 mt-4  notification-dropdownMenu"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 nav-header">Notifications</h6>
                </Col>
                {/* <div className='col-auto'>
                  <Link to='#' className='small mark-all'>
                    {" "}
                    Mark all as read
                  </Link>
                </div> */}
              </Row>
            </div>
            <div className="nav-links">
              <div className={notiNav1}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleCustomJustified("1");
                    setNotificationType("all");
                  }}
                >
                  All{" "}
                  <span>
                    {requestNotifications?.length +
                      paymentNotifications?.length}
                  </span>
                </span>
              </div>
              <div className={notiNav2}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleCustomJustified("2");
                    setNotificationType("requests");
                  }}
                >
                  Requests <span>{requestNotifications?.length}</span>
                </span>
              </div>
              <div className={notiNav3}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleCustomJustified("3");
                    setNotificationType("payments");
                  }}
                >
                  Payments <span>{paymentNotifications?.length}</span>
                </span>
              </div>
              <div className="nav-containers"></div>
            </div>

            <SimpleBar
              style={{
                maxHeight: "290px",
                minWidth: "390px",
                paddingTop: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              className="overflow-auto"
            >
              {notificationType === "all"
                ? [...requestNotifications, ...paymentNotifications]
                    .sort((a, b) => a.time - b.time)
                    .reverse()
                    ?.map((item) => {
                      return (
                        <div>
                          <Link
                            to={`${item.route_to}`}
                            className="text-reset notification-item"
                          >
                            <div className=" notification-container">
                              <span>
                                <span style={{ fontWeight: "600" }}>
                                  {item.title}
                                </span>{" "}
                                {item.description}{" "}
                              </span>
                              <p>
                                {moment(item.time).fromNow(true)} ago{" "}
                                {/* {item.time
                                  ? time(item.time).split("T")[0] +
                                    " " +
                                    time(item.time).split("T")[1].split(".")[0]
                                  : "-"} */}
                                {moment(item.time).format("hh:mm a")}
                              </p>
                            </div>
                          </Link>
                          <hr
                            style={{
                              background: "#D9D9D9",
                              width: "100%",
                              borderRadius: "10px",
                              height: "1px",
                            }}
                          />
                        </div>
                      );
                    })
                : notificationType === "requests"
                ? requestNotifications?.map((item) => {
                    return (
                      <div>
                        <Link
                          to={`${item.route_to}`}
                          className="text-reset notification-item"
                        >
                          <div className=" notification-container">
                            <span>
                              <span style={{ fontWeight: "600" }}>
                                {item.title}
                              </span>{" "}
                              {item.description}{" "}
                            </span>
                            <p>
                              {moment(item.time).fromNow(true)} ago{" "}
                              {/* {item.time
                                ? time(item.time).split("T")[0] +
                                  " " +
                                  time(item.time).split("T")[1].split(".")[0]
                                : "-"} */}
                              {moment(item.time).format("hh:mm a")}
                            </p>
                          </div>
                        </Link>
                        <hr
                          style={{
                            background: "#D9D9D9",
                            width: "100%",
                            borderRadius: "10px",
                            height: "1px",
                          }}
                        />
                      </div>
                    );
                  })
                : notificationType === "payments"
                ? paymentNotifications?.map((item) => {
                    return (
                      <div>
                        <Link
                          to={`${item.route_to}`}
                          className="text-reset notification-item"
                        >
                          <div className=" notification-container">
                            <span>
                              <span style={{ fontWeight: "600" }}>
                                {item.title}
                              </span>{" "}
                              {item.description}{" "}
                            </span>
                            <p>
                              {moment(item.time).fromNow(true)} ago{" "}
                              {/* {item.time
                                ? time(item.time).split("T")[0] +
                                  " " +
                                  time(item.time).split("T")[1].split(".")[0]
                                : "-"} */}
                              {moment(item.time).format("hh:mm a")}
                            </p>
                          </div>
                        </Link>
                        <hr
                          style={{
                            background: "#D9D9D9",
                            width: "100%",
                            borderRadius: "10px",
                            height: "1px",
                          }}
                        />
                      </div>
                    );
                  })
                : ""}

              {/* <div className='shimmerContainer'>
                <Shimmer width={290} height={8} />
              </div>
              <div className='shimmerContainer'>
                <Shimmer width={60} height={8} />
              </div>
              <hr
                style={{
                  background: "#D9D9D9",
                  width: "100%",
                  borderRadius: "10px",
                  height: "1px",
                }}
              /> */}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  );
};

export default NotificationDropdown;

// ******** old code is uper **************
