import React from "react";
import { useNotificationSettingsInfo } from "./useNotificationSetting";
import { Card, Col, Row, Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./css/styles.css";
const Loader = require("react-loader");

const NotificationSettings = (props) => {
  const {
    columns,
    defaultSorted,
    tableData,
    saveBtn,
    settingsLoading,
    handleNotifcationSettings,
    NoDataIndication,
  } = useNotificationSettingsInfo(props);

  const { addToast } = useToasts();

  return (
    <div>
      {!settingsLoading && tableData ? (
        <Row className='p-4 '>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <div>
              <ToolkitProvider
                keyField='id'
                data={tableData}
                columns={columns}
                // cellEdit={() => {}}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className='mt-3'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <Card className='input-wrap2 p-2 '>
                          <div className='d-flex justify-content-between px-2 mt-2'>
                            <div className='notification-setting'>
                              <h3>Manage notifications</h3>
                              <p>Notification bottom line</p>
                            </div>
                            <div className='w-25 d-flex justify-content-end'>
                              <Button
                                className={`w-75 ${saveBtn}`}
                                onClick={() => {
                                  handleNotifcationSettings();
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </div>

                          <div className='table-responsive table-wrap '>
                            <BootstrapTable
                              // condensed={true}
                              loading={true}
                              hover
                              keyField='id'
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle tableBody view-table "
                              }
                              headerWrapperClasses={"thead-light"}
                              headerClasses='tableHeadercss'
                              wrapperClasses='table-responsive'
                              {...toolkitProps.baseProps}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </div>
                        </Card>

                        <Row className='align-items-md-center mt-3 bottom-btn '>
                          <Col className='inner-custom-pagination d-flex'></Col>
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}
    </div>
  );
};

export default NotificationSettings;
