import React, { useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const Settings = () => {
  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <Row>
      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
        <Row className='settings-page'>
          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            {" "}
            <Card
              className='setting-card tourSetting'
              role='button'
              onClick={() => navigate("/tours-setting")}
            >
              <h1>Tour Settings</h1>
            </Card>
          </Col>
          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            <Card
              className='setting-card tourSetting'
              onClick={() => navigate("/membership-type-details")}
              role='button'
            >
              <h1>Membership</h1>
            </Card>
          </Col>
          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            <Card
              onClick={() => navigate("/users")}
              className='setting-card userRoles'
              role='button'
            >
              <h1>Users</h1>
            </Card>
          </Col>

          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            <Card
              className=' setting-card rejectionEmail'
              onClick={() => navigate("/rejection-reason-template")}
              role='button'
            >
              <h1>Rejection Email</h1>
            </Card>
          </Col>
        </Row>
        <Row className='settings-page_row_2'>
          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            {" "}
            <Card
              className='setting-card notificationSetting'
              role='button'
              onClick={() => navigate("/notification-settings")}
            >
              <h1>Notification Settings</h1>
            </Card>
          </Col>
          <Col md={12} lg={4} xl={3} sm={12} xs={12} className='card-setting'>
            <Card
              className='setting-card bookingPayment'
              onClick={() => navigate("/booking-&-payment-setting")}
              role='button'
            >
              <h1>Booking & payment settings </h1>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Settings;
