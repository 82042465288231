import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useToasts } from "react-toast-notifications";
import {
  useRoleUpdateMutation,
  useRoleDeleteMutation,
} from "../../../rc-query/rolesHook";
import "../css/styles.css";

let resourcess = [
  {
    id: 1,
    name: "Team",
    description: "Team permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 2,
    name: "Request",
    description: "Request permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 3,
    name: "Payment",
    description: "Payment permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 4,
    name: "Settings",
    description: "Settings permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 5,
    name: "Bookings",
    description: "Bookings permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 6,
    name: "Logs",
    description: "Logs permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
];

export const useUpdateRole = (props) => {
  const navigate = useNavigate();
  const [staticResource, setStaticResource] = useState([
    {
      id: 1,
      name: "Team",
      description: "Team permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 2,
      name: "Request",
      description: "Request permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 3,
      name: "Payment",
      description: "Payment permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 4,
      name: "Settings",
      description: "Bookings permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 5,
      name: "Bookings",
      description: "Bookings permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 6,
      name: "Logs",
      description: "Logs permissions can be deal here",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
  ]);
  const { addToast } = useToasts();
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleData, setRoleData] = useState();
  const [tableData, setTableData] = useState(resourcess);
  const [roleEditable, setRoleEditable] = useState(false);
  const updateMutation = useRoleUpdateMutation(props.history);
  const deletMutation = useRoleDeleteMutation(props.history);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateResources = () => {
    resourcess = staticResource;
  };
  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };
  const modelFunc2 = (value) => {
    setModelShow2(value);
  };

  const newResource = {
    name: roleName,
    resources: {
      Team: {
        permissions: [],
      },
      Request: {
        permissions: [],
      },
      Payment: {
        permissions: [],
      },
      Settings: {
        permissions: [],
      },
      Bookings: { permissions: [] },
      Logs: { permissions: [] },
    },
  };
  const updatePermissions = (data, name) => {
    if (data.name === name) {
      if (data.permissions.view === true) {
        newResource.resources[name].permissions.push("view");
      }
      if (data.permissions.manage === true) {
        newResource.resources[name].permissions.push("manage");
      }
    }
  };

  const handleCheckboxChange = (row, id, isChecked, permission) => {
    const newTableData = [...tableData];
    let viewVal = newTableData.find((r) => r.id === id).permissions.view;
    let manageVal = newTableData.find((r) => r.id === id).permissions.manage;

    if (permission === "view") {
      newTableData.find((r) => r.id === id).permissions.view = !viewVal;
      if (viewVal === false && manageVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = true;
      } else {
        newTableData.find((r) => r.id === id).permissions.all = false;
      }
      setTableData(newTableData);
    } else if (permission === "manage") {
      newTableData.find((r) => r.id === id).permissions.manage = !manageVal;
      if (manageVal === false && viewVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = true;
      } else {
        newTableData.find((r) => r.id === id).permissions.all = false;
      }
      setTableData(newTableData);
    } else if (permission === "all") {
      let allVal = newTableData.find((r) => r.id === id).permissions.all;
      if (allVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = false;
        newTableData.find((r) => r.id === id).permissions.view = false;
        newTableData.find((r) => r.id === id).permissions.manage = false;
        setTableData(newTableData);
      } else {
        newTableData.find((r) => r.id === id).permissions.all = true;
        newTableData.find((r) => r.id === id).permissions.view = true;
        newTableData.find((r) => r.id === id).permissions.manage = true;
        setTableData(newTableData);
      }
    }
  };

  const updateRole = () => {
    tableData.forEach((d) => {
      updatePermissions(d, d.name);
    });
    newResource.name = roleName;
    setLoading(true);
    newResource.id = roleData.id;

    for (const resource in newResource.resources) {
      if (newResource.resources[resource].permissions.length === 0) {
        delete newResource.resources[resource];
      }
    }

    updateMutation.mutateAsync(newResource).then((data) => {
      if (data?.status === 204) {
        addToast("Updated Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setTableData(resourcess);
        navigate(-1);
      }
      setLoading(false);
    });
  };

  const deleteRole = () => {
    newResource.name = roleName;
    setLoading(true);
    newResource.id = roleData.id;
    deletMutation.mutateAsync(newResource).then((data) => {
      if (data.data.statusCode === 400) {
        addToast(data.data.message, {
          appearance: "error",
          autoDismiss: true,
        });

        modelFunc2(false);
      }
      if (data?.status === 204) {
        addToast("Updated Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setTableData(resourcess);
        navigate(-1);
      }
      setLoading(false);
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Permissions",
      style: { width: "65%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <div>
          <div>{cell}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#999",
              fontWeight: "400",
              lineHeight: "12.19px",
            }}
          >
            {row.description}
          </div>
        </div>
      ),
    },
    {
      dataField: "permissions.manage",
      text: "Manage",
      editable: true,

      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          checked={cell}
          className='input-tours'
          onChange={(e) =>
            handleCheckboxChange(row, row.id, e.target.checked, "manage")
          }
        />
      ),
    },
    {
      dataField: "permissions.view",
      text: "View",
      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          checked={cell}
          className='input-tours'
          onChange={(e) =>
            handleCheckboxChange(row, row.id, e.target.checked, "view")
          }
        />
      ),
    },
    {
      dataField: "permissions.all",
      text: "All",
      editable: true,
      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          checked={row.permissions.manage && row.permissions.view}
          className='input-tours'
          onChange={(e) =>
            handleCheckboxChange(row, row.id, e.target.checked, "all")
          }
        />
      ),
    },
  ];
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );
  return {
    columns,
    defaultSorted,
    roleName,
    modelShow,
    modelShow1,
    loading,
    modelShow2,
    roleEditable,
    resourcess,
    tableData,
    setRoleName,
    deleteRole,
    modelFunc1,
    modelFunc2,
    modelFunc,
    setRoleData,
    NoDataIndication,
    setTableData,
    updateRole,
    updateResources,
    setRoleEditable,
  };
};
