import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import accessControl from "../../../services/accessControlApi";
import { Button } from "reactstrap";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

export const useAccessControllInfo = (props) => {
  const navigate = useNavigate();
  const QueryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [lastRefresh, setLastRefresh] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);

  const [currentPage, setCurrentPage] = useState(1);

  const { data: checkIns, isLoading: checkInsLoading } = useQuery(
    ["checkIns", refresh],
    () => accessControl.getCheckIns(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
    setLastRefresh(new Date());
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshCheckin, 35000); // 35 seconds in milliseconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = 0;
    let buttons = [];

    // setAllBtns(0);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const refreshCheckin = () => {
    setLastRefresh(new Date());
    setRefresh(refresh + 1);
    QueryClient.invalidateQueries("checkIns");
  };

  const calculateTimeDiff = (eventTime) => {
    const currentTime = moment();
    const formattedEventTime = moment
      .utc(eventTime)
      .format("DD MMMM,YYYY h:mm a");

    const diffInSeconds = currentTime.diff(formattedEventTime, "seconds");
    const diffInMinutes = currentTime.diff(formattedEventTime, "minutes");
    const diffInHours = currentTime.diff(formattedEventTime, "hours");
    const diffInDays = currentTime.diff(formattedEventTime, "days");

    let timeAgoString = "";
    if (diffInDays > 0) {
      timeAgoString = `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInHours > 0) {
      timeAgoString = `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInMinutes > 0) {
      timeAgoString = `${diffInMinutes} minute${
        diffInMinutes === 1 ? "" : "s"
      } ago`;
    } else {
      timeAgoString = `${diffInSeconds} second${
        diffInSeconds === 1 ? "" : "s"
      } ago`;
    }

    return timeAgoString;
  };

  return {
    isOpen,
    pageSize,
    checkIns,
    checkInsLoading,
    lastRefresh,
    refreshCheckin,
    calculateTimeDiff,
    navigate,
    handleIncrementBtn,
    totalPages,
    pageSizeFunc,
    toggle,
  };
};
