import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Col, Row } from "reactstrap";
import DeleteIcon from "../../images/icons/delete-icon.svg";
import { useToasts } from "react-toast-notifications";
import templateApi from "../../services/templatesApi";
import {
  useTemplatePatchMutation,
  useTemplateDeleteMutation,
  useTemplateCreateMutation,
} from "../../rc-query/templatesHook";

export const useRejectionInfo = (props) => {
  const { addToast } = useToasts();
  const [modelShow, setModelShow] = useState(false);
  const [modelHeader, setModelHeader] = useState("");
  const [disableField, setDisableField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [modelHeading, setModelHeading] = useState("");
  const [tempateAction, setTempateAction] = useState("");
  const updateTemplateMutation = useTemplatePatchMutation(props.history);
  const deleteTemplateMutation = useTemplateDeleteMutation(props.history);
  const createTemplateMutation = useTemplateCreateMutation(props.history);
  const QueryClient = useQueryClient();

  const { data: templatesList, isLoading: templateLoading } = useQuery(
    ["templateLists", modelShow],
    () => templateApi.templateLists()
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addReasonFunc = (value) => {
    setTemplate({});
    setModelHeader("Add Rejection Reason");
    setModelHeading(
      "Below mentioned rejection reason will be added accordingly"
    );

    setTempateAction("create");
    setModelShow(value);
  };
  const updateReasonFunc = (value, data) => {
    setModelHeader("Update Rejection Reason");
    setModelHeading(
      "Below mentioned rejection reason will be Updated accordingly"
    );
    setTempateAction("update");
    setDisableField(false);
    setTemplate(data);

    setModelShow(value);
  };
  const deleteReasonFunc = (value, data) => {
    setModelHeader("Delete Rejection Reason");
    setModelHeading(
      "Below mentioned rejection reason will be Deleted accordingly"
    );
    setTempateAction("delete");
    setTemplate(data);
    setDisableField(true);
    setModelShow(value);
  };

  const emailTemplates = () => {
    let templates = [];
    for (let i = 0; i < templatesList?.data?.length; i++) {
      templates.push(
        <Row className='reasons-row'>
          <Col md={10} lg={10} sm={8} xs={8} xl={10}>
            <div className='reason-text'>
              <span>{templatesList?.data[i].reasons}</span>
            </div>
          </Col>
          <Col md={2} lg={2} sm={3} xs={3} xl={2} className='edit-btn'>
            <a
              href='javascript:void(0)'
              onClick={() => updateReasonFunc(true, templatesList?.data[i])}
            >
              Edit
            </a>
            <img
              src={DeleteIcon}
              alt=''
              height={17}
              style={{ cursor: "pointer" }}
              onClick={() => deleteReasonFunc(true, templatesList?.data[i])}
            />
          </Col>
        </Row>
      );
    }

    return templates;
  };

  const tempateActionFunc = () => {
    const data = {
      reasons: template.reasons,
      id: template.id,
    };

    setLoading(true);

    if (tempateAction === "update") {
      updateTemplateMutation.mutateAsync(data).then((data) => {
        if (data?.status === 204) {
          addToast("Successfully update template", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("templateLists");
          setModelShow(false);
        } else {
          addToast("Update to Error", {
            appearance: "error",
            autoDismiss: true,
          });
          setModelShow(false);
        }
      });
    } else if (tempateAction === "delete") {
      deleteTemplateMutation.mutateAsync(data).then((data) => {
        if (data?.status === 204) {
          addToast("Successfully delete template", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("templateLists");
          setModelShow(false);
        } else {
          addToast("Delete to Error", {
            appearance: "error",
            autoDismiss: true,
          });
          setModelShow(false);
        }
      });
    } else {
      createTemplateMutation.mutateAsync(data).then((data) => {
        if (data?.status === 200) {
          addToast("Successfully create template", {
            appearance: "success",
            autoDismiss: true,
          });
          QueryClient.invalidateQueries("templateLists");
          setModelShow(false);
        } else {
          addToast("Create to Error", {
            appearance: "error",
            autoDismiss: true,
          });
          setModelShow(false);
        }
      });
    }
    setDisableField(false);
    setTemplate({});
    setLoading(false);
  };

  return {
    modelHeading,
    modelShow,
    modelHeader,
    template,
    templateLoading,
    disableField,
    templatesList,
    loading,
    emailTemplates,
    tempateActionFunc,
    setTemplate,
    addReasonFunc,
  };
};
