import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Col, Row } from "reactstrap";
import sideBtn from "../../images/icons/Hamburger.svg";
import Profile from "../../images/icons/Profile.svg";
import Navbar from "react-bootstrap/Navbar";
import NotificationDropdown from "../molecules/notifcation";
import { AiOutlineSetting } from "react-icons/ai";
import { useProfleInfo } from "../../pages/profileSection/useProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import "./css/header.css";
import { firebaseDB } from "../../firebase";
import { onValue, ref, set } from "firebase/database";

const Header = ({ navshow, setNavShow }) => {
  const [newNotifications, setNewNotifications] = useState([]);
  const notification = localStorage.getItem("newNotifications");
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("-").join(" ");
  const splitHeader = splitLocation.split("/")[1];
  const pattern = pathname.includes("-detail");
  const page = pathname.includes("requests-detail");

  const backBtn = pathname.split("-")[1];

  const { userProfileLoading, userProfile } = useProfleInfo();

  // for dot of notifications
  useEffect(() => {
    onValue(ref(firebaseDB, `/adminNotication`), (snapshot) => {
      const data = snapshot.val();
      setNewNotifications(data);
    });
  }, [notification]);

  const updateDot = () => {
    set(ref(firebaseDB, `adminNotication`), {
      seen: true,
    });
  };

  return (
    <>
      <Row>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <Navbar bg="light" expand="lg" className="bg-white dbNavBar">
            <Container fluid>
              {backBtn === true ||
              pathname.includes("detail") ||
              pathname.includes("setting") ||
              pathname.includes("users") ||
              pathname.includes("template") ? (
                <span>
                  <AiOutlineLeft
                    onClick={() => navigate(-1)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              ) : (
                ""
              )}

              <span className="dbNavRoutes "> {splitHeader} </span>

              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <div className="sideIconsContainer" onClick={() => updateDot()}>
                <NotificationDropdown />
                {newNotifications && newNotifications.seen === false ? (
                  <div style={{ position: "absolute", top: "10px" }}>
                    <span style={{ color: "red", fontSize: "12px" }}>●</span>
                  </div>
                ) : (
                  ""
                )}
                <AiOutlineSetting
                  className="dbNav-icon"
                  onClick={() => navigate("/settings")}
                />

                <button
                  className="profile"
                  onClick={() => navigate("/profile-section")}
                >
                  {userProfile?.fullName.charAt(0)}
                </button>
              </div>

              <div className="dbNavIcons ">
                <img
                  src={sideBtn}
                  alt=""
                  className="sidebtn"
                  onClick={() => {
                    setNavShow(!navshow);
                  }}
                />
              </div>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </>
  );
};

export default Header;
