import { useState, useEffect } from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";

import { useSpring } from "react-spring";
import { toast } from "react-toastify";
import { membershipTypeSettingApi } from "../../../../services/membershipTypeSetting";
import {
  useMembershipTypeSettingUpdateMutation,
  useMembershipTypeSettingDeleteMutation,
  useUploadPhoto,
} from "../../../../rc-query/membershipSettingHook";
// import { useMembershipTypeSettingDeleteMutation } from "../../../../rc-query/membshipSettingHook";
//LINE 643 TO 567 coment

export const UseMembershipSettingsUpdate = (props) => {
  const picUploadApi = useUploadPhoto();
  const [photoDataByApi, setPhotoDataByApi] = useState();
  const[updateLoading,setUpdateLoading] = useState(false);
  const updateSettingMutation = useMembershipTypeSettingUpdateMutation();

  const deleteSettingMutation = useMembershipTypeSettingDeleteMutation();
  // props.history
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const planId = useParams();
  const [planResourceid, setPlanResourceid] = useState({});
  const [planResourceid1, setPlanResourceid1] = useState({});
  const [resourcePlansArr, setResourcePlansArr] = useState([]);
  const[onChangeWorked,setOnChangeWorked] = useState(false);
  
  const [resourcePlansArr1, setResourcePlansArr1] = useState([]);
  const [onChangeWorked1, setOnChangeWorked1] = useState(false)
  // Dawood Fixing
  const [sapratePlanResources, setSapratePlanResources] = useState(null);
  const [sapratePlanResources1, setSapratePlanResources1] = useState(null);
  const [samePlanResources1, setSamePlanResources1] = useState(true);
  const [specificResources1, setSpecificResources1] = useState(false);

  //
  const [monthlyRange, setMonthlyRange] = useState({});
  const [noOfMonths, setNoOfMonths] = useState(null);
  const [membershipName, setMembershipName] = useState("");
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [slotValue, setSlotValue] = useState(false);
  const [request, setRequest] = useState("");
  const [slotValue1, setSlotValue1] = useState(false);
  const [slotValueMonths, setSlotValueMonths] = useState("");
  const [selectedFiles1, setSelectedFiles1] = useState([]);
  const [resourceArr, setSourceArr] = useState([{ value: 1 }]);
  const [samePlanResources, setSamePlanResources] = useState(false);
  const [specificResources, setSpecificResources] = useState(false);
  const [divShowAccor, setDivShowAccor] = useState({
    1: false,
    2: false,
  });

  const totalMonths = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAcceptedFiles1 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles1(files);
    // upload Api
    picUploadApi
      .mutateAsync(files)
      .then((data) => {
        if (data?.status === 200) {
          setPhotoDataByApi(data);
        } else
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeResourceArr = () => {
    setSourceArr(resourceArr.slice(0, -1));
  };
  const tog_static = () => {
    setSlotValue(!slotValue);
  };

  const tog_static1 = () => {
    setSlotValue1(!slotValue1);
  };

  const tog_static2 = (val) => {
    setSlotValueMonths(val);
  };

  const TeamMemberYearlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex(
      (r) => r?.resourceId === i?.name
    );

    const newObject = {
      TeamMemberYearlyPlan: { id: e?.value, name: e?.label },
    };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr1;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr1([...resoruceArray]);
  };

  const TeamMemberMonthlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex(
      (e) => e?.resourceId === i?.name
    );
    const newObject = {
      TeamMemberMonthlyPlan: { id: e?.value, name: e?.label },
    };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr1;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr1([...resoruceArray]);
  };

  const TeamLeadYearlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex(
      (e) => e?.resourceId === i?.name
    );
    const newObject = { TeamLeadYearlyPlan: { id: e?.value, name: e?.label } };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr1;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr1([...resoruceArray]);
  };

  const TeamLeadMonthlyPlans1 = (e, i) => {
    const object = resourcePlansArr1.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamLeadMonthlyPlan: { id: e?.value, name: e?.label },
    };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr1;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr1[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr1([...resoruceArray]);
  };

  const TeamLeadMonthlyPlans = (e, i) => {
    // find object that needs to be update.
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadMonthlyPlan: { id: e?.value, name: e?.label } };

    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr([...resoruceArray]);
  };

  const TeamLeadYearlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = { TeamLeadYearlyPlan: { id: e?.value, name: e?.label } };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr([...resoruceArray]);
  };

  const TeamMemberMonthlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamMemberMonthlyPlan: { id: e?.value, name: e?.label },
    };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr([...resoruceArray]);
  };

  const TeamMemberYearlyPlans = (e, i) => {
    const object = resourcePlansArr.findIndex((e) => e.resourceId === i?.name);
    const newObject = {
      TeamMemberYearlyPlan: { id: e?.value, name: e?.label },
    };
    // Store array valuue for pass by value.
    const resoruceArray = resourcePlansArr;

    // Update specfic properties
    resoruceArray[object] = {
      ...resourcePlansArr[object],
      ...newObject,
    };

    // set properties to state
    setResourcePlansArr([...resoruceArray]);
  };

  //toggle accordion function
  let toggleHandler = (e) => {
    if (e?.target?.id === "1") {
      setDivShowAccor({ 1: !divShowAccor[1] });
      // setDivShowAccor({ 1: !divShowAccor[1], 2: true });
    } else if (e?.target?.id === "2") {
      setDivShowAccor({ 2: !divShowAccor[2] });
      // setDivShowAccor({ 1: true, 2: !divShowAccor[2] });
    } else {
      setDivShowAccor({ 1: true, 2: true });
    }
    setOpen(!open);
  };

  //open animation with react spring

  const openAnimation = useSpring({
    config: { duration: "300" },
  });

  // get resource types
  const { data: resourceTypeData, isLoading: resourceTypesLoading } = useQuery(
    ["resourceTypes"],
    () => membershipTypeSettingApi.membershipTypeSettingrestype()
  );

  // get plans
  const { data: plansDropdownData, isLoading: plansLoading } = useQuery(
    ["plansDropdownData"],
    () => membershipTypeSettingApi.membershipTypeSettingplans()
  );

  // delete Api
  const deleteFunc = () => {
    deleteSettingMutation.mutateAsync({ planId }).then((data) => {
      if (data?.status === 200) {
        Navigate(`/membership-type-details`);
        toast.success("Successfully deleted membership Settings");
      } else {
        toast.error(data?.response?.data?.error?.message);
      }
    });

    setModelShow2(false);
  };
  // update Api
  const updateFunc = () => {
    setUpdateLoading(true);
    if (slotValue === false && slotValue1 === false) {
      toast.error("Please select atleast one payment method");
    } else if (!planResourceid?.desk?.value) {
      toast.error("Please select a resource");
    } else if (!membershipName) {
      toast.error("Please enter membership Name");
    } else if (!noOfMonths) {
      toast.error("Number of months are required");
    }
    //start
    else if (
      slotValue === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamLeadMonthlyPlan === undefined ||
        resourcePlansArr?.TeamLeadMonthlyPlan === "")
    ) {
      toast.error("Please select all team lead monthly plans");
    } else if (
      slotValue === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamMemberMonthlyPlan === undefined ||
        resourcePlansArr?.TeamMemberMonthlyPlan === "")
    ) {
      toast.error("Please select all team members monthly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamMemberYearlyPlan === undefined ||
        resourcePlansArr?.TeamMemberYearlyPlan === "")
    ) {
      toast.error("Please select all team Member yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === true &&
      (resourcePlansArr?.TeamLeadYearlyPlan === undefined ||
        resourcePlansArr?.TeamLeadYearlyPlan === "")
    ) {
      toast.error("Please select all team Lead yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamLeadYearlyPlan === undefined || e?.TeamLeadYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead yearly plans");
    } else if (
      slotValue1 === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamMemberYearlyPlan === undefined ||
          e?.TeamMemberYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member yearly plans");
    }

    // need to be fixed in
    else if (
      slotValue === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamLeadMonthlyPlan === undefined || e?.TeamLeadMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead monthly plans");
    } else if (
      slotValue === true &&
      samePlanResources === false &&
      resourcePlansArr?.some((e) => {
        return (
          e?.TeamMemberMonthlyPlan === undefined ||
          e?.TeamMemberMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member Monthly plans");
    }

    // end 1

    //exp
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamLeadMonthlyPlan === undefined || e?.TeamLeadMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead monthly plans");
    }

    // *****2****
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamMemberMonthlyPlan === undefined ||
          e?.TeamMemberMonthlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member Monthly plans");
    }
    // *****3 ******* \
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamLeadMonthlyPlan === undefined ||
        resourcePlansArr1?.TeamLeadMonthlyPlan === "")
    ) {
      toast.error("Please select all team lead monthly plans");
    }

    // *****4**********
    else if (
      resourceArr?.length === 2 &&
      slotValue === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamMemberMonthlyPlan === undefined ||
        resourcePlansArr1?.TeamMemberMonthlyPlan === "")
    ) {
      toast.error("Please select all team members monthly plans");
    }

    // ******* 5 ******
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamLeadYearlyPlan === undefined || e?.TeamLeadYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team Lead yearly plans");
    }

    // **** 6 **********
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === false &&
      resourcePlansArr1?.some((e) => {
        return (
          e?.TeamMemberYearlyPlan === undefined ||
          e?.TeamMemberYearlyPlan === ""
        );
      })
    ) {
      toast.error("Please select all team member yearly plans");
    }
    // ******7 *****
    else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamMemberYearlyPlan === undefined ||
        resourcePlansArr1?.TeamMemberYearlyPlan === "")
    ) {
      toast.error("Please select all team Member yearly plans");
    } else if (
      resourceArr?.length === 2 &&
      slotValue1 === true &&
      samePlanResources1 === true &&
      (resourcePlansArr1?.TeamLeadYearlyPlan === undefined ||
        resourcePlansArr1?.TeamLeadYearlyPlan === "")
    ) {
      toast.error("Please select all team Lead yearly plans");
    }

    //exp end

    //end 2
    else {
      var data = {
        ResourceImage: photoDataByApi
          ? photoDataByApi?.data?.files[0]?.originalname
          : dmemberShipDetailsData?.ResourceImage,
        ResourceName: `${membershipName}`,
        ResourceId: Number(planResourceid?.desk?.value),
        isMultiple: false,
        isMonthAllow: slotValue,
        isYearAllow: slotValue1,
        isAllowSpecificResource: specificResources,
        startMonth: Number(noOfMonths),

        monthRange: [
          Number(monthlyRange?.MinRange),
          Number(monthlyRange?.MaxRange),
        ],
        ResourcePlan: {},
      };

      if (resourceArr?.length === 2) {
        delete data["ResourceId"];
        data.isMultiple = true;
        data.PrivateOfficeResourceId = Number(
          process.env.REACT_APP_PRIVATE_OFFICE
        );
        data.DDResourceId = Number(process.env.REACT_APP_DEDICATED_OFFICE);
        data.ResourcePlan = {
          [(planResourceid?.desk?.value).toString()]:
            planResourceid?.desk?.value ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? { resources: { ...resourcePlansArr } }
              : {
                  resources: resourcePlansArr,
                },
          [(planResourceid1?.desk?.value).toString()]:
            planResourceid1?.desk?.value ===
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
              ? { resources: { ...resourcePlansArr1 } }
              : {
                  resources: resourcePlansArr1,
                },
        };
      } else if (resourceArr?.length < 2) {
        if (samePlanResources === true) {
          data.ResourcePlan = {
            ...resourcePlansArr,
          };
        } else {
          data.ResourcePlan = { resources: [...resourcePlansArr] };
        }
      }

      updateSettingMutation.mutateAsync({ data, planId }).then((data) => {
        if (data?.status === 200) {
          setUpdateLoading(false);
          navigate(`/membership-type-details`);
          toast.success("Successfully updated membership Settings");
          // navigate(`/membership-setting-detail/${data?.data?.data?.id}`);
        } else {
          setUpdateLoading(false);
          toast.error(data?.response?.data?.error?.message);
        }
      });
    }
    setModelShow(false);
    setUpdateLoading(false);
  };

  // get  membership detils
  const {
    data: dmemberShipDetailsDataa,
    isLoading: dmemberShipDetailsLoading,
  } = useQuery(["membershipDetails", planId?.id], () =>
    membershipTypeSettingApi.membershipTypeSettingdetails(planId?.id)
  );

  
  const dmemberShipDetailsData = dmemberShipDetailsDataa?.data;

  // get sparate resource by turn off  same 1toggle

  const { data: sapResourceData, isLoading: sapResourceDataLoading } = useQuery(
    [
      // planResourceid?.desk?.value, samePlanResources
     `${ planResourceid?.desk?.value}`,
     `${ samePlanResources}`,
     `${ planResourceid?.value}`,
      `${samePlanResources}`
    ],
    () =>
      membershipTypeSettingApi.membershipTypeSaprateResources(
        Number(planResourceid?.desk?.value),
        samePlanResources
      )
  );

  // get sparate resource by turn off same 2toggle

  const { data: sapResourceData1 ,isLoading: sapResourceData1Loading} = useQuery(
    [
      // planResourceid?.desk?.value, samePlanResources1
     `${planResourceid1?.desk?.value}` ,
     `${samePlanResources1}` ,
     `${planResourceid1?.value}`,
      
      
    ],
    () =>
      membershipTypeSettingApi.membershipTypeSaprateResources(
        Number(planResourceid1?.desk?.value),
        samePlanResources1
      )
  );

  useEffect(() => {
    setSapratePlanResources(sapResourceData?.data?.data?.Records);
  }, [sapResourceData]);
  useEffect(() => {
    setSapratePlanResources1(sapResourceData1?.data?.data?.Records);
  }, [sapResourceData1]);

  // todo :
  // // this is for 1st resoucres dropdown

  useMemo(() => {
    
    // setTimeout(()=>{
    if (
      samePlanResources == false &&
      sapResourceData?.data?.data?.Records.length > 0 && onChangeWorked
    ) {
      const data = sapResourceData?.data?.data?.Records.map((e) => ({
        resourceId: e?.Id,
        resourceName: e?.Name,
        
      }));
      setResourcePlansArr(data);
      setOnChangeWorked(false)
    }
  // },1000)
  }, [samePlanResources,sapResourceData,onChangeWorked]);
  // this is for 2nd resoucres dropdown

  // i am coment below code for temprarily updating
  useMemo(() => {
    if (
      samePlanResources1 == false &&
      sapResourceData1?.data?.data?.Records.length > 0 && onChangeWorked1
    ) {
      const data = sapResourceData1?.data?.data?.Records.map((e) => ({
        resourceId: e?.Id,
        resourceName: e?.Name,
        
      }));
      setResourcePlansArr1(data);
      setOnChangeWorked1(false)
    }
  }, [samePlanResources1,sapResourceData1,onChangeWorked1]);
// i am coment upper code for temprarily updating


  // useEffect(() => {
  //   if (
  //     samePlanResources1 == false &&
  //     sapResourceData1?.data?.data?.Records.length > 0
  //   ) {
  //     const data = sapResourceData1?.data?.data?.Records.map((e) => ({
  //       resourceId: e?.Id,
  //       resourceName: e?.Name,
  //     }));
  //     setResourcePlansArr1(data);
  //   }
  // }, [samePlanResources1, planResourceid1?.value, sapResourceData1]);

  // todo :
  // useMemo(() => {
  //   if (samePlanResources == false) {
  //     const data = sapResourceData?.data?.data?.Records.map((e) => ({
  //       resourceId: e?.Id,
  //       resourceName: e?.Name,
  //     }));
  //     setResourcePlansArr(data);
  //   }
  // }, [samePlanResources]);

  useEffect(() => {
    setMembershipName(dmemberShipDetailsData?.ResourceName);
    setSelectedFiles1(
      `https://fintech-uploads.devbeans.io/images/${dmemberShipDetailsData?.ResourceImage}`
    );

    if (dmemberShipDetailsData?.startMonth === 1) {
      tog_static2("nextMonth");
      setNoOfMonths(dmemberShipDetailsData?.startMonth);
    } else {
      tog_static2("flexibleMonth");
      setNoOfMonths(dmemberShipDetailsData?.startMonth);
    }

    setMonthlyRange({
      MinRange: dmemberShipDetailsData?.monthRange[0],
      MaxRange: dmemberShipDetailsData?.monthRange[1],
    });

    setSlotValue(dmemberShipDetailsData?.isMonthAllow);
    setSlotValue1(dmemberShipDetailsData?.isYearAllow);
    // setPlanResourceid({
    //   ...planResourceid,
    //   desk: dmemberShipDetailsData?.ResourceId,
    // });

    //dedicated office
    resourceTypeData?.filter((e) => {
     
      if (e.value === dmemberShipDetailsData?.ResourceId) {
        setPlanResourceid({ desk: { label: e.label, value: e.value } });
      }
    });

    if (dmemberShipDetailsData?.ResourcePlan?.TeamLeadMonthlyPlan) {
      setSamePlanResources(true);
      setResourcePlansArr({
        ...resourcePlansArr,
        TeamLeadMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.TeamLeadMonthlyPlan,
        TeamLeadYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.TeamLeadYearlyPlan,
        TeamMemberMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.TeamMemberMonthlyPlan,
        TeamMemberYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.TeamMemberYearlyPlan,
      });
    }

    //  private office
    else if (dmemberShipDetailsData?.ResourcePlan?.resources) {
      const data = dmemberShipDetailsData?.ResourcePlan?.resources.map((e) => {
        return {
          resourceId: e?.resourceId,
          resourceName: e?.resourceName,
          TeamLeadMonthlyPlan: e?.TeamLeadMonthlyPlan,
          TeamLeadYearlyPlan: e?.TeamLeadYearlyPlan,
          TeamMemberMonthlyPlan: e?.TeamMemberMonthlyPlan,
          TeamMemberYearlyPlan: e?.TeamMemberYearlyPlan,
        };
      });
      setResourcePlansArr(data);
      // test diff plan
      setSapratePlanResources(data);
    }

    //dedicated desk
    // for hybrid plan dedicated office
    if (
      dmemberShipDetailsData?.ResourcePlan?.[
        Number(process.env.REACT_APP_DEDICATED_OFFICE)
      ]?.resources?.TeamLeadMonthlyPlan
    ) {
      // resource type
      resourceTypeData?.filter((e) => {
        if (e.value === dmemberShipDetailsData?.DDResourceId) {
          setPlanResourceid({ desk: { label: e.label, value: e.value } });
        }
      });

      setSamePlanResources(true);
      setResourcePlansArr({
        ...resourcePlansArr,
        TeamLeadMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamLeadMonthlyPlan,
        TeamLeadYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamLeadYearlyPlan,
        TeamMemberMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamMemberMonthlyPlan,
        TeamMemberYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamMemberYearlyPlan,
      });

      // try same plan 2nd
      setSamePlanResources1({
        ...resourcePlansArr,
        TeamLeadMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamLeadMonthlyPlan,
        TeamLeadYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamLeadYearlyPlan,
        TeamMemberMonthlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamMemberMonthlyPlan,
        TeamMemberYearlyPlan:
          dmemberShipDetailsData?.ResourcePlan?.[
            Number(process.env.REACT_APP_DEDICATED_OFFICE)
          ]?.resources?.TeamMemberYearlyPlan,
      });
    }

    // for hybrid plan private office
    if (
      dmemberShipDetailsData?.ResourcePlan?.[
        Number(process.env.REACT_APP_PRIVATE_OFFICE)
      ]
    ) {
      // length
      setSourceArr([{ value: 1 }, { value: 2 }]);

      // resourceype
      resourceTypeData?.filter((e) => {
        if (e.value === dmemberShipDetailsData?.PrivateOfficeResourceId) {
          setPlanResourceid1({ desk: { label: e.label, value: e.value } });
        }
      });
      const data = dmemberShipDetailsData?.ResourcePlan?.[
        Number(process.env.REACT_APP_PRIVATE_OFFICE)
      ]?.resources?.map((e) => {
        return {
          resourceId: e?.resourceId,
          resourceName: e?.resourceName,
          TeamLeadMonthlyPlan: e?.TeamLeadMonthlyPlan,
          TeamLeadYearlyPlan: e?.TeamLeadYearlyPlan,
          TeamMemberMonthlyPlan: e?.TeamMemberMonthlyPlan,
          TeamMemberYearlyPlan: e?.TeamMemberYearlyPlan,
        };
      });
      setSamePlanResources1(false);
      divShowAccor[2] = false;
      setResourcePlansArr1(data);

      // try saprate 2nd
      setSapratePlanResources1(data);
    }
  }, [dmemberShipDetailsData, resourceTypeData]);

  return {
    monthlyRange,
    noOfMonths,
    totalMonths,
    membershipName,
    planResourceid,
    resourcePlansArr,
    resourcePlansArr1,
    planResourceid1,
    samePlanResources1,
    specificResources1,
    divShowAccor,
    selectedFiles1,
    resourceTypeData,
    resourceArr,
    modelShow,
    request,
    modelShow2,
    slotValue1,
    slotValue,
    slotValueMonths,
    samePlanResources,
    specificResources,
    openAnimation,
    plansDropdownData,
    modelShow1,
    sapResourceData1Loading,
    sapResourceDataLoading,
    setOnChangeWorked1,
    setOnChangeWorked,
    deleteFunc,
    setMonthlyRange,
    setNoOfMonths,
    setMembershipName,
    setPlanResourceid,
    setResourcePlansArr,
    TeamMemberYearlyPlans1,
    TeamMemberMonthlyPlans1,
    TeamLeadYearlyPlans1,
    TeamLeadMonthlyPlans1,
    setResourcePlansArr1,
    TeamLeadMonthlyPlans,
    TeamLeadYearlyPlans,
    TeamMemberMonthlyPlans,
    TeamMemberYearlyPlans,
    setPlanResourceid1,
    setSamePlanResources1,
    setSpecificResources1,
    updateFunc,
    //
    toggleHandler,
    setSelectedFiles1,
    handleAcceptedFiles1,
    setSourceArr,
    removeResourceArr,
    setModelShow,
    setModelShow1,
    setRequest,
    setModelShow2,
    tog_static,
    tog_static1,
    tog_static2,
    setSamePlanResources,
    setSpecificResources,
    updateLoading
    //
  };
};
