import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const teamInvoices = {
  getAllTeamInvoices: async (body, page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/invocies-status/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: date },
    });
    const teamInvoices = data?.data?.data?.map((invoice, index) => ({
      id: invoice.id,
      index_id: index + 1,
      paymentStatus: invoice.paymentStatus,
      PaymentComment: invoice.PaymentComment,
      invoice: invoice.InvoiceNo,
      companyName: invoice.TeamName,
      companyName: invoice.TeamName,
      teamId: invoice.TeamId,
      status: invoice.status,
      totalPrice: invoice.TotalPrice,
      resourceName: invoice.ResourceName,
      date: moment(invoice?.DueDate)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { invoices: teamInvoices, totalPages: data?.data?.totalInvoices };
  },

  exportTeamInvoices: async (body, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-teams-invoices/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const teamInvoices = data?.data?.data?.map((invoice, index) => ({
      id: invoice.id,
      index_id: index + 1,
      paymentStatus: invoice.paymentStatus,
      PaymentComment: invoice.PaymentComment,
      invoice: invoice.InvoiceNo,
      companyName: invoice.TeamName,
      companyName: invoice.TeamName,
      teamId: invoice.TeamId,
      status: invoice.status,
      totalPrice: invoice.TotalPrice,
      resourceName: invoice.ResourceName,
      date: moment(invoice?.DueDate)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { invoices: teamInvoices, totalPages: data?.data?.totalInvoices };
  },
  getSingleTeamInvoice: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/invoices/${body}`, {
      headers: headers,
    });

    return data?.data?.data;
  },

  invoiceObjected: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/invoice-objection/${body.id}`,
      {
        paymentStatus: "objected",
        ObjectionReason: body.reason,
      },
      {
        headers: headers,
      }
    );

    return data?.data;
  },

  invoiceVerified: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/invoice-approve/${body}`,
      {
        status: "approved",
        paymentStatus: "approved",
      },
      {
        headers: headers,
      }
    );

    return data;
  },
  getTeamInvoicesTax: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/booking-taxes`, {
      headers: headers,
    });

    return data?.data?.setting;
  },
  invoice: async (id) => {
    
   

    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(`/nexudus-invoice/${id}`, {
        headers: headers,
      });

      return data;
    }
  },
};

export default apiWrapper(teamInvoices, [
  "getAllTeamInvoices",
  "invoiceObjected",
  "invoiceVerified",
  "exportTeamInvoices",
  "getTeamInvoicesTax",
  "invoice"
]);
