import React from "react";
import { useProfileUpdateInfo } from "./useProfileUpdate";
import { FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Card, Form, Col, Row, Input, Button } from "reactstrap";
import "./css/styles.css";

const ProfileUpdate = (props) => {
  const navigate = useNavigate();
  const {
    data,
    email,
    name,
    btnDisable,
    setEmail,
    setName,
    profileUpdateFunc,
  } = useProfileUpdateInfo(props);

  return (
    <>
      <Row className=' p-4'>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Card className='password-card p-3 pb-4  '>
            <Form>
              <FormGroup>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                    <h4>Profile</h4>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>Name</h6>

                    <Input
                      className='password-field'
                      id='example-date-input-text'
                      value={name}
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                    ></Input>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>Email</h6>
                    <Input
                      id='example-date-input-text'
                      className='password-field'
                      value={email}
                      disabled={!data?.superAdmin}
                      type='email'
                      onChange={(e) => setEmail(e.target.value)}
                    ></Input>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    xl={4}
                    className='input-col-password'
                  >
                    <h6>Role</h6>
                    <Input
                      id='example-date-input-text'
                      className='password-field'
                      value={data?.role?.name}
                      disabled={true}
                      type='text'
                      // onChange={(e) => setReEnterPassword(e.target.value)}
                    ></Input>
                  </Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4}></Col>
                </Row>
              </FormGroup>
            </Form>
          </Card>

          <Row>
            <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
            <Col md={4} xl={4} lg={4} sm={12} xs={12} className='pt-4'>
              <div className='membershipDetailBtn'>
                <Button
                  className='waves-effect waves-light w-100 datails-btn1'
                  onClick={() => navigate(-1)}
                >
                  CANCEL
                </Button>
                <Button
                  className='waves-effect waves-light w-100 datails-btn2'
                  onClick={() => profileUpdateFunc()}
                  disabled={btnDisable}
                >
                  UPDATE
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProfileUpdate;
