import { useEffect, useState } from "react";

export const useSettingControlInfo = (props) => {
  const [settingDisplay, setSettingDisplay] = useState(1);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { settingDisplay, setSettingDisplay };
};
