import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import Spinner from "react-bootstrap/Spinner";
import React from "react";
import "./css/styles.css";

const ReasonModel = (props) => {
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row className='rejection-reason-model'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <div className='reason-model'>
                      <h5>{props.reason}</h5>
                    </div>
                    <GrFormClose
                      size={25}
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <div className='reason-model'>
                      <p>{props.heading}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='col-input-add-reason'
                  >
                    <Input
                      id='example-date-input-rejection'
                      onChange={(e) =>
                        props.setTemplate({
                          reasons: e.target.value,
                          id: props.template.id,
                        })
                      }
                      value={props.template.reasons}
                      disabled={props.disableField}
                      type='text'
                      className='input-add-reason'
                    />{" "}
                  </Col>
                </Row>
                <Row className='mt-5'>
                  <Col md={4} xl={4} lg={4} sm={0} xs={0}></Col>
                  <Col
                    md={2}
                    xl={2}
                    lg={2}
                    sm={6}
                    xs={6}
                    className='rejection-btn1-col'
                  >
                    <Button
                      className='rejection-model-btn1'
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                    >
                      No
                    </Button>
                  </Col>

                  <Col
                    md={2}
                    xl={2}
                    lg={2}
                    sm={6}
                    xs={6}
                    className='rejection-btn2-col'
                  >
                    {!props.isLoading ? (
                      <Button
                        onClick={() => props.tempateActionFunc()}
                        className='rejection-model-btn2 w-100'
                      >
                        Yes
                      </Button>
                    ) : (
                      <Button
                        onClick={() => props.tempateActionFunc()}
                        className='rejection-model-btn2 w-100'
                        disabled={true}
                      >
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />{" "}
                        Yes
                      </Button>
                    )}
                  </Col>
                  <Col md={4} xl={4} lg={4} sm={0} xs={0}></Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ReasonModel;
