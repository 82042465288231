import axios from "axios";
import { Saudi_Fintech_URL, FINTECH_BASE_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const fintechRequest = axios.create({
  baseURL: FINTECH_BASE_URL,
});

const accessControl = {
  getDepartments: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/departments`, {
      headers: headers,
    });

    return data?.data;
  },
  getResourceType: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await fintechRequest.get(`/en/publicresources?_depth=3`, {
      headers: headers,
    });

    const list = data?.data?.ResourceTypes.map((types) => ({
      value: types.Id,
      label: types.Name,
    }));

    return list;
  },

  getResources: async (ID) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    if (ID) {
      const data = await request.get(`/resources/${ID}`, {
        headers: headers,
      });

      const list = data?.data?.data.Records.map((types) => ({
        value: types.Id,
        label: types.Name,
      }));

      return list;
    }
  },
  addNewDepartment: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(
      `/departments`,
      { ...body },
      {
        headers: headers,
      }
    );

    return data;
  },

  updateDepartment: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/departments/${body.Id}`,
      { ...body.bodyRequest },
      {
        headers: headers,
      }
    );

    return data;
  },

  deleteDepartment: async (Id) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.delete(`/departments/${Id}`, {
      headers: headers,
    });

    return data;
  },

  getDepartment: async (ID) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    if (ID) {
      const data = await request.get(`/departments/${ID}`, {
        headers: headers,
      });

      const resourceType = {
        label: data.data.resourceType.name,
        value: data.data.resourceType.id,
      };

      const resources = data?.data.resources.map((types) => ({
        value: types.id,
        label: types.name,
      }));
      const accesslevels = data?.data.accessLevels.map((types) => ({
        value: types.id,
        label: types.name,
      }));

      const name = data?.data.name;
      const code = data?.data.code;

      return { resourceType, resources, accesslevels, code, name };
    }
  },

  getAccessLevel: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/zkt-access-levels`, {
      headers: headers,
    });

    return data?.data?.data;
  },

  getAccessLevelList: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/zkt-access-levels`, {
      headers: headers,
    });

    const accessLevels = data?.data?.data?.map((types) => ({
      value: types.id,
      label: types.name,
    }));

    return accessLevels;
  },
  getCheckIns: async () => {
    
    // on the intruction of hamza i added the check if the invirment is production then it zkt will run otherwise not.
    if(Saudi_Fintech_URL === "https://hubportalapi.fintechsaudi.com"){
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/zkt-realtime-access-control`, {
      headers: headers,
    });

    return data?.data?.data;
  }
  },
  getAllCheckIns: async (page, size) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/zkt-realtime/${page}/${size}`, {
      headers: headers,
    });

    return { ZKT: data?.data?.data, totalPages: data?.data?.totalZKT };

    // return data?.data?.data;
  },
};

export default apiWrapper(accessControl, [
  "getDepartments",
  "getResourceType",
  "getResources",
  "deleteDepartment",
  "updateDepartment",
  "addNewDepartment",
  "getDepartment",
  "getAllCheckIns",
  "getAccessLevel",
  "getAccessLevelList",
  "getCheckIns",
]);
