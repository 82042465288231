import { useMutation } from "react-query";
import {
  DAYPASS_OBJECTED,
  DAYPASS_Approved,
  DAYPASS_DENIED,
  DAYPASS_REFUND,
} from "./keys";

import daypass from "../services/dayPassApi";

export const useDaypassObjectedMutaion = (history) => {
  const response = useMutation(daypass.dayPassObjected, {
    mutationKey: DAYPASS_OBJECTED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useDaypassApprovedMutaion = (history) => {
  const response = useMutation(daypass.dayPassApproved, {
    mutationKey: DAYPASS_Approved,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDaypassDeniedMutaion = (history) => {
  const response = useMutation(daypass.dayPassDenied, {
    mutationKey: DAYPASS_DENIED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDaypassRefundMutaion = (history) => {
  const response = useMutation(daypass.daypassRedund, {
    mutationKey: DAYPASS_REFUND,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
