import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const authApi = {
  login: async (body) => {
    // const response = await axios.get("https://api.ipify.org?format=json");
    // const { ip } = response.data;

    return await request.post(`/users/login`, {
      credentials: {
        email: body?.email,
        password: body?.password,
      },
      ipAddress: body.ipAddress,
    });
  },

  forgotPassword: async (body) => {
    return await request.put("/users/forgot-password", body);
  },
};

export default apiWrapper(authApi, ["login", "forgotPassword"]);

