import { useMutation } from "react-query";
import {
  PUT_OTP_MUTATION,
  POST_CHANGE_PASSWORD,
  CREATE_NEW_USER,
  GET_USER_BY_ID,
  UPDATE_USER_ROLE,
  CREATE_ACCOUNT_OTP_MUTATION,
  VERIFY_LOCKED_ACCOUNT_MUTATION,
  DELETE_USER_ROLE,
  UPDATE_USER_BY_ID,
  ChangePassword,
} from "./keys";
import usersApi from "../services/usersApi";

export const useUsersMutation = (history) => {
  const response = useMutation(usersApi.FindUserById, {
    mutationKey: GET_USER_BY_ID,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePasswordOtpMutation = (history) => {
  const response = useMutation(usersApi.createOtp, {
    mutationKey: PUT_OTP_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useAccountCreateOtpMutation = (history) => {
  const response = useMutation(usersApi.accountCreateOtp, {
    mutationKey: CREATE_ACCOUNT_OTP_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useChangePasswordMutation = (history) => {
  const response = useMutation(usersApi.changeForgotPassword, {
    mutationKey: ChangePassword,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useVerifyLockedAccountMutation = (history) => {
  const response = useMutation(usersApi.verifyLockedAccount, {
    mutationKey: VERIFY_LOCKED_ACCOUNT_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePasswordMutation = (history) => {
  const response = useMutation(usersApi.changePasswordRequest, {
    mutationKey: POST_CHANGE_PASSWORD,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useCreateUserMutation = (history) => {
  const response = useMutation(usersApi.createAdminUser, {
    mutationKey: CREATE_NEW_USER,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useUpdateUserMutation = (history) => {
  const response = useMutation(usersApi.updateUserRole, {
    mutationKey: UPDATE_USER_ROLE,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDeleteUserMutation = (history) => {
  const response = useMutation(usersApi.deleteUserRole, {
    mutationKey: DELETE_USER_ROLE,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useUserUpdateMutation = (history) => {
  const response = useMutation(usersApi.updateUserProfile, {
    mutationKey: UPDATE_USER_BY_ID,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
