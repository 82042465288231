import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TabContext } from "../../../context/TabContext";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useToasts } from "react-toast-notifications";
import { Button } from "reactstrap";
import meetingRoomApi from "../../../services/meetingRoomApi";
import { useQuery } from "react-query";
import "../css/styles.css";

export const useMeetingRoomInfo = (props) => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [startBtn, setStartBtn] = useState(1);
  const [allBtns, setAllBtns] = useState(0);
  const [endBtn, setEndBtn] = useState(4);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const [isOpen, setIsOpen] = useState(false);

  const [apiStatus, setApiStatus] = useState(
    activeTabJustify === "1"
      ? "all"
      : activeTabJustify === "2"
      ? "pending"
      : activeTabJustify === "3"
      ? "approved"
      : activeTabJustify === "4"
      ? "deny"
      :
      activeTabJustify === "5"
      ? "cancelled"
      :
      activeTabJustify === "6"
      ? "rescheduled"
      :
      "all"
  );

  const { data: meetingRoom, isLoading: meetingRoomLoading } = useQuery(
    ["meetingRoomList", apiStatus, currentPage, search, date, pageSize],
    () =>
      meetingRoomApi.meetingRoomList(
        apiStatus,
        currentPage,
        pageSize,
        search,
        date
      ),
    {
      retry: 0,
    }
  );

  const { data: ExportMeetingRoom, isLoading: ExportMeetingRoomLoading } =
    useQuery(
      ["ExportMeetingRoom", apiStatus, search, date],
      () => meetingRoomApi.exportMeetingRoomList(apiStatus, search, date),
      {
        retry: 0,
      }
    );

  const headers = [
    { label: "No", key: "index_id" },
    { label: "Invoice No", key: "invoice" },
    { label: "Meeting Room ID", key: "id" },
    { label: "Booking ID", key: "BookingId" },
    { label: "Booking Date", key: "date" },
    { label: "Team Name", key: "team" },
    { label: "Lead Name", key: "name" },
    { label: "Booking Time", key: "bookingTime" },
    { label: "Booking Created", key: "bookingCreated" },
    { label: "Total Price", key: "totalPrice" },
    { label: "Resource Name", key: "resourceName" },
    { label: "Status", key: "status" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Payment Comment", key: "PaymentComment" },
    { label: "Decline Reason", key: "declineReason" },
  ];

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSearchDate = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setDate();
      setCurrentPage(1);
    } else {
      setDate(e.target.value);
      setCurrentPage(1);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const details = (row) => {
    navigate("/meeting-room-detail", {
      state: {
        data: row.id,
      },
    });
  };

  // *****Data Column Set******
  const columns = [
    {
      dataField: "BookingId",
      text: "Invoice ID #",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "team",
      text: "Team",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "date",
      text: "Booking Date",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },
    {
      dataField: "resourceName",
      text: "Resource",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          details(row);
        },
      },
    },

    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      headerStyle: { width: "100px" },
      sort: true,
      formatter: (cell, row) => (
        <a href={row.fileUrl} download>
          {row.paymentStatus === "objected" && row.status === "pending" ? (
            <div>
              <span>
                <button
                  className='btn-circle-denied'
                  onClick={() => details(row)}
                >
                  Objected
                </button>
              </span>
            </div>
          ) : row.status === "approved" ? (
            <div>
              <span>
                {" "}
                <button
                  className='btn-circle-approved'
                  onClick={() => details(row)}
                >
                  Approved
                </button>{" "}
              </span>
            </div>
          ) : row.status === "deny" ? (
            <div>
              <span>
                <button
                  className='btn-circle-denied'
                  onClick={() => details(row)}
                >
                  Denied
                </button>
              </span>
            </div>
          ) : row.status === "pending" ? (
            <div>
              <span>
                <button
                  className='btn-circle-object'
                  onClick={() => details(row)}
                >
                  Pending
                </button>
              </span>
            </div>
          ) : row.status === "rescheduled" ? (
            <div>
              <span>
                <button
                  className='btn-circle-object'
                  onClick={() => details(row)}
                >
                  Rescheduled
                </button>
              </span>
            </div>
          ) : row.status === "cancelled" ? (
            <div>
              <span>
                <button
                  className='btn-circle-cancel'
                  onClick={() => details(row)}
                >
                  Cancelled
                </button>
              </span>
            </div>
          ) : (
            ""
          )}
        </a>
      ),
    },
  ];

  const handlePageBtnDisplay = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setStartBtn(1);
      setEndBtn(4);
    } else if (btnNumber === startBtn) {
      setEndBtn(startBtn);
      setStartBtn(startBtn - 3);
    } else if (btnNumber === endBtn) {
      setStartBtn(endBtn);
      setEndBtn(endBtn + 3);
    }
  };

  // pageButtons
  const totalPages = () => {
    const pages = meetingRoom
      ? Math.trunc(meetingRoom?.totalPages / pageSize + 1)
      : 0;
    let buttons = [];

    setAllBtns(pages);

    for (let i = startBtn; i <= endBtn; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttons.push(
          <Button
            className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2 btn-circle'
            disabled={currentPage === i ? true : false}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtnDisplay(i, pages);
            }}
          >
            {i}
          </Button>
        );
      }
    }
    return buttons;
  };

  const pageSizeFunc = (value) => {
    setCurrentPage(1);
    setPageSize(value);
    setStartBtn(1);
    setEndBtn(4);
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === startBtn) {
        setCurrentPage(currentPage - 1);
        setEndBtn(startBtn);
        setStartBtn(startBtn - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === endBtn) {
        setCurrentPage(currentPage + 1);
        setStartBtn(endBtn);
        setEndBtn(endBtn + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleSearchFunc = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
      setApiStatus("all");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
      setApiStatus("pending");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "3") {
      setActiveTabJustify(tab);
      setApiStatus("approved");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    } else if (tab === "4"){
      setActiveTabJustify(tab);
      setApiStatus("deny");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
    else if (tab === "5"){
      setActiveTabJustify(tab);
      setApiStatus("cancelled");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }
    else if (tab === "6"){
      setActiveTabJustify(tab);
      setApiStatus("rescheduled");
      setCurrentPage(1);
      setStartBtn(1);
      setEndBtn(4);
    }

  };

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    meetingRoom,
    meetingRoomLoading,
    ExportMeetingRoom,
    headers,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
    toggleCustomJustified,
  };
};
