import { useAccessLogInfo } from "./useAccessLog.js";
import React from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  Col,
  Row,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment";
import "../css/styles.css";
const Loader = require("react-loader");

const Accesslog = (props) => {
  const {
    columns,
    exportLogsList,
    defaultSorted,
    isOpen,
    pageSize,
    activeTabJustify,
    logsList,
    logsLoading,
    headers,
    allCheckInsLoading,
    allCheckIns,
    handleIncrementBtn,
    handleSearchFunc,
    setSearchDate,
    toggleCustomJustified,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  } = useAccessLogInfo(props);

  return (
    <div>
      {!allCheckInsLoading ? (
        <Row className='p-4 '>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <div>
              {/* <Row className='mb-2 p-2 tab-row'>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <div>
                    <Nav className=' nav-justified nav-width nav-btn'>
                      <NavItem className='nav-item'>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            ("nav-request-bar",
                            classnames({
                              active: activeTabJustify === "1",
                            }))
                          }
                          onClick={() => {
                            toggleCustomJustified("1");
                          }}
                        >
                          <span className='d-none d-sm-block '>All</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            ("nav-request-bar",
                            classnames({
                              active: activeTabJustify === "2",
                            }))
                          }
                          onClick={() => {
                            toggleCustomJustified("2");
                          }}
                        >
                          <span className='d-none d-sm-block '>
                            Successfull
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            ("nav-request-bar",
                            classnames({
                              active: activeTabJustify === "3",
                            }))
                          }
                          onClick={() => {
                            toggleCustomJustified("3");
                          }}
                        >
                          <span className='d-none d-sm-block '>Failed</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col lg={3} md={2} sm={3} xs={12} xl={3}></Col>
                <Col
                  lg={3}
                  md={5}
                  sm={5}
                  xs={6}
                  xl={2}
                  className='searchBarCol'
                >
                  <Input
                    type='text'
                    className='searchbcss'
                    placeholder='Search'
                    onChange={(e) => {
                      handleSearchFunc(e);
                    }}
                  />
                </Col>
                <Col lg={2} md={5} sm={4} xs={6} xl={2}>
                  <Input
                    type='date'
                    id='example-date-input'
                    className=' search-bar '
                    onChange={(e) => {
                      setSearchDate(e);
                    }}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                  <Card className='checkInLogContaier p-3 accessLevelControlCard'>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        {allCheckIns?.ZKT?.data?.map((users, index) => {
                          return (
                            <Card
                              className={
                                users?.eventLevel === 0
                                  ? "checkInLogsCard successCheckInCard px-3 mb-3"
                                  : "checkInLogsCard failedCheckInCard px-3 mb-3"
                              }
                              key={users?.id}
                            >
                              <Row>
                                <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                  <div className='d-flex align-items-center pt-3'>
                                    <div>
                                      <h5>Check-in time</h5>
                                      <p>
                                        {moment(users?.eventTime).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                  <div className='d-flex align-items-center pt-3 '>
                                    <div>
                                      <h5>Member</h5>
                                      <p>{users.name} </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                  <div className='d-flex align-items-center  pt-3'>
                                    <div>
                                      <h5>Resource</h5>
                                      <p>{users?.eventPointName}</p>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                  <div className='d-flex align-items-center justify-content-center pt-4'>
                                    <div className='pt-2'>
                                      {users?.eventLevel === 0 ? (
                                        <h4>Successful</h4>
                                      ) : (
                                        <h4>Failed</h4>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className='inner-custom-pagination d-flex  deparymentsAccessCard   mt-3'>
                    <span className='paginationitmes'>Items</span>
                    <div className='d-inline pagination-btn '>
                      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                        <DropdownToggle caret className='pageBtn'>
                          {pageSize}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            value='1'
                            onClick={() => pageSizeFunc(10)}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            value='2'
                            onClick={() => pageSizeFunc(30)}
                          >
                            30
                          </DropdownItem>
                          <DropdownItem
                            value='3'
                            onClick={() => pageSizeFunc(50)}
                          >
                            50
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>

                    <span className='paginationitmes1 '>Show</span>

                    <div className='text-md-right ms-auto'>
                      <AiOutlineLeft
                        className='page-btn'
                        onClick={() => handleIncrementBtn(false)}
                        role='button'
                      />

                      {totalPages()}

                      <AiOutlineRight
                        className='page-btn'
                        onClick={() => handleIncrementBtn(true)}
                        role='button'
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Accesslog;
