import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/styles.css";
import Spinner from "react-bootstrap/Spinner";

const ImageView = (props) => {
  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg "}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row className='mb-3'>
                  <Col md={10} xl={11} lg={11} sm={10} xs={10}></Col>
                  <Col md={2} xl={1} lg={1} sm={2} xs={2}>
                    <span className='lgModelCancel'>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    className='h-100 w-100 imageViewCol'
                  >
                    {props.image ? (
                      <img
                        src={props.image}
                        alt=''
                        className='h-50 w-75 mt-3'
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ImageView;
