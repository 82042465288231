import { useMemberDetailInfo } from "./useDetailMember";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button, Container, Input } from "reactstrap";
import moment from "moment";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import "../css/styles.css";
const Loader = require("react-loader");

const MemberDetail = (props) => {
  const {
    memberDetail,
    dedicatedResources,
    memberDetailLoading,
    dedicatedResourcesLoading,
    desk,
    modelShow1,
    declineModel,
    loading,
    modelShow,
    setModelShow,
    handleDeniedFunc,
    setDeclineFunc,
    modelFunc1,
    setDeclineModel,
    setModelShow1,
    setDesk,
    handleApprovalFunc,
  } = useMemberDetailInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <>
      {!memberDetailLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className=' mt-4 px-4'>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Card className='memberDetailCard'>
                  <Row className='leadDetailRow p-4'>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='d-flex justify-content-between'>
                        <h1>Team lead details</h1>
                        <div>
                          <span>
                            {memberDetail?.status === "pending" ? (
                              <button
                                className='btn-circle-object'
                                disabled={true}
                              >
                                Pending
                              </button>
                            ) : memberDetail?.status === "deny" ? (
                              <button
                                className='btn-circle-denied'
                                disabled={true}
                              >
                                Denied
                              </button>
                            ) : memberDetail?.status === "approved" ? (
                              <button
                                className='btn-circle-approved'
                                disabled={true}
                              >
                                Approved
                              </button>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                      <h2>{memberDetail?.teamLead?.fullName}</h2>
                      <h3>{
                      (memberDetail?.companyName && memberDetail?.companyName.length > 50)
                      ? memberDetail?.companyName.slice(0, 40) + '...'
                      : memberDetail?.companyName}</h3>
                      <h3>{memberDetail?.teamLead?.email}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row className='leadDetailRow p-4'>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <h1>New member details</h1>
                    </Col>
                    <Row>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Name</h3>
                        <h4>{memberDetail?.name}</h4>
                      </Col>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Email Address</h3>
                        <h4>{memberDetail?.email}</h4>
                      </Col>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Company name</h3>
                        <h4>
        { <p>
        { memberDetail?.companyName&& memberDetail?.companyName.length > 50
          ? memberDetail?.companyName.slice(0, 40) + '...'
          : memberDetail?.companyName}
      </p>}
        </h4>
                      </Col>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Phone number</h3>
                        <h4>{memberDetail?.phoneNumber}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Title</h3>
                        <h4>{memberDetail?.workTitle}</h4>
                      </Col>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={12}
                        xs={12}
                        className='mt-3'
                      >
                        <h3>Resource</h3>
                        <h4>
                          {memberDetail?.resourceTypeId ===
                          Number(process.env.REACT_APP_PRIVATE_OFFICE)
                            ? "Private Office"
                            : memberDetail?.resourceTypeId ===
                              Number(process.env.REACT_APP_DEDICATED_OFFICE)
                            ? "Dedicated Desk"
                            : ""}
                        </h4>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row className='leadDetailRow p-4'>
                    {memberDetail?.status === "pending" ? (
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Requested resource</h1>

                        {memberDetail?.resourceTypeId ===
                        Number(process.env.REACT_APP_PRIVATE_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <div className='d-flex justify-content-between'>
                                  <h2>Private Office</h2>
                                  <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      {" "}
                                      <div className='squareLogo mx-2'></div>{" "}
                                      <span>Space available</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        ) : memberDetail?.resourceTypeId ===
                          Number(process.env.REACT_APP_DEDICATED_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <div className='d-flex justify-content-between'>
                                  <h2>Dedicated desk</h2>
                                  <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      {" "}
                                      <div className='squareLogo mx-2'></div>{" "}
                                      <span>
                                        {dedicatedResources?.length} available
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Row>
                                <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                  <select
                                    name='resources'
                                    id='resources'
                                    className='SelectDeskMemberField px-2'
                                    value={desk}
                                    onChange={(e) => setDesk(e.target.value)}
                                  >
                                    <option value='null' disabled selected>
                                      Select Desk
                                    </option>
                                    {!dedicatedResourcesLoading ? (
                                      dedicatedResources?.map(
                                        (resource, index) => {
                                          if (resource?.contractedCowrkerId) {
                                          } else {
                                            return (
                                              <option
                                                value={resource.Id}
                                                key={resource.Id}
                                              >
                                                {resource.name}
                                              </option>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <option value='null' disabled selected>
                                        Loading...
                                      </option>
                                    )}
                                  </select>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : memberDetail?.status === "deny" ? (
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Requested resource</h1>

                        {memberDetail?.resourceTypeId ===
                        Number(process.env.REACT_APP_PRIVATE_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <h2>Private Office</h2>
                              </Col>
                            </Col>
                          </Row>
                        ) : memberDetail?.resourceTypeId ===
                          Number(process.env.REACT_APP_DEDICATED_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <h2>Dedicated desk</h2>
                              </Col>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : (
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Allocated resource</h1>

                        {memberDetail?.resourceTypeId ===
                        Number(process.env.REACT_APP_PRIVATE_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <h2>Private Office</h2>
                              </Col>
                            </Col>
                          </Row>
                        ) : memberDetail?.resourceTypeId ===
                          Number(process.env.REACT_APP_DEDICATED_OFFICE) ? (
                          <Row className='mt-3'>
                            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                                <h2>Dedicated desk</h2>
                              </Col>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    )}
                  </Row>
                  {memberDetail?.status === "deny" ? (
                    ""
                  ) : (
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                  {memberDetail?.status === "deny" ? (
                    ""
                  ) : (
                    <Row className='leadDetailRow p-4'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Membership dates</h1>
                        <h3>
                          These dates are defined by the team membership dates.
                        </h3>
                        <Row className='mt-3'>
                          <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                            <h5>Start date</h5>
                            <h4>
                              {moment(memberDetail?.startDate)
                                .format("DD MMMM, YYYY")
                                .toString()}
                            </h4>
                          </Col>
                          <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                            <h5>End date</h5>
                            <h4>
                              {moment(memberDetail?.lastMonth)
                                .format("DD MMMM, YYYY")
                                .toString()}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
              <Row>
                {memberDetail?.status === "pending" ? (
                  <>
                    <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
                    <Col md={4} xl={4} lg={4} sm={12} xs={12}>
                      <div className='membershipDetailBtn mt-4'>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn1'
                          onClick={(e) => {
                            setDeclineModel(true);
                          }}
                        >
                          Decline
                        </Button>
                        <Button
                          className='waves-effect waves-light w-100 datails-btn2'
                          onClick={(e) => {
                            setModelShow(true);
                          }}
                        >
                          Approve
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <ApproveModel
        show={modelShow}
        deniedFunc={handleApprovalFunc}
        setModelShow={setModelShow}
        loading={loading}
        request={"memberRequest"}
        headerText='Approve Request'
        textBody='Are you sure you want to approve this request?'
      />
      <ApproveModel
        show={modelShow1}
        deniedFunc={handleDeniedFunc}
        setModelShow={setModelShow1}
        loading={loading}
        request={"memberRequest"}
        headerText='Rejected Request'
        textBody='Are you sure you want to reject this request?'
      />

      <DeclineModel
        show={declineModel}
        setModelShow={setDeclineModel}
        setDenyRequest={modelFunc1}
        templates={templatesList?.data}
        setDeclineReason={setDeclineFunc}
      />
    </>
  );
};

export default MemberDetail;
