import { Col, Row, Modal, ModalBody } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import LoginModelIcon from "../../../images/icons/Artboard 2.svg";
import "./css/style.css";

const passwordModel = (props) => {
  return (
    <>
      <Modal isOpen={props.show} centered={true}>
        <Row className='tmpPasswordCard'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <GrFormClose
              size={25}
              onClick={() => {
                props.setModelShow(false);
              }}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            />
            <ModalBody>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='passwordModelIcon'
                >
                  <img src={LoginModelIcon} alt='LoginModelIcon' />
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='passwordModelIcon'
                >
                  <h3>Temporary Password Sent</h3>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  className='passwordModelIcon'
                >
                  <p>
                    A temporary has been sent to your email address, reset your
                    password and then Login in to your account.
                  </p>
                </Col>
              </Row>
            </ModalBody>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default passwordModel;
