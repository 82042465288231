import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const memberShipApi = {
  membershipList: async (body = "pending", page, size, search, newDate) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/membership/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: newDate },
    });

    const totalMembership = data?.data?.data?.map((membership, index) => ({
      id: membership.id,
      index_id: index + 1,
      userId: membership.userId,
      userName: membership.userName,
      userCompany: membership.companyName,
      slotTime: membership.slot,
      status: membership.status,
      registration: membership.registrationStatus,
      email: membership.email,
      phoneNumber: membership.phoneNumber,
      website: membership.website,
      linkedin: membership.linkedin,
      twitter: membership.twitter,
      description: membership.companyDescription,
      members: membership.companyMembers,
      date: membership?.date
        ? moment(membership?.date)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : membership?.joinPreference,
      createdAt: moment(membership.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
    }));

    return {
      membership: totalMembership,
      totalPages: data?.data?.totalMembership,
    };
  },
  exportMembership: async (body = "pending", search, newDate) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-membership/${body}`, {
      headers: headers,
      params: { search: search, date: newDate },
    });

    const totalMembership = data?.data?.data?.map((membership, index) => ({
      id: membership.id,
      index_id: index + 1,
      userId: membership.userId,
      userName: membership.userName,
      userCompany: membership.companyName,
      slotTime: membership.slot,
      status: membership.status,
      registration: membership.registrationStatus,
      email: membership.email,
      phoneNumber: membership.phoneNumber,
      website: membership.website,
      linkedin: membership.linkedin,
      twitter: membership.twitter,
      description: membership.companyDescription,
      members: membership.companyMembers
        .map((member) => member.name)
        .join(", "),
      declineReason: membership.declineReason,
      date: membership?.date
        ? moment(membership?.date)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : membership?.joinPreference,
      createdAt: moment(membership?.createdAt)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY h:mm a"),
    }));

    return {
      membership: totalMembership,
      totalPages: data?.data?.totalMembership,
    };
  },

  membershipRequestsApproval: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/membership/team/${statusData.id}`,
      {
        status: "approved",
      },
      {
        headers: headers,
      }
    );
  },
  membershipRequestsdeny: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/membership/team/${statusData.id}`,
      {
        status: "deny",
        declineReason: statusData.declineReason,
      },
      {
        headers: headers,
      }
    );
  },
};

export default apiWrapper(memberShipApi, [
  "membershipList",
  "membershipRequestsApproval",
  "membershipRequestsdeny",
  "exportMembership",
]);
