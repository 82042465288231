import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const teamsApi = {
  teamList: async (body = "all", page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/teams/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalTeams = data?.data?.data?.map((teams, index) => ({
      id: teams.id,
      index_id: index + 1,
      userId: teams.userId,
      NexudusTeamId: teams.NexudusTeamId,
      userName: teams.userName,
      userCompany: teams.companyName,
      slotTime: teams.slot,
      status: teams.status,
      registration: teams.registrationStatus,
      email: teams.email,
      phoneNumber: teams.phoneNumber,
      website: teams.website,
      linkedin: teams.linkedin,
      twitter: teams.twitter,
      planType: teams.planType,
      images: teams?.images,
      attachLogo: teams?.attachLogo,
      planDuration: teams.planDuration,
      startDate: teams.startDate
        ? moment(teams?.startDate)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : "",
      RenewalMonth: teams.RenewalMonth
        ? moment(teams?.RenewalMonth)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : "",
      description: teams.companyDescription,
      members: teams.companyMembers,
      date: moment(teams?.date)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { teams: totalTeams, totalPages: data?.data?.totalTeams };
  },

  exportTeamList: async (body = "all", search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/exports-teams/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalTeams = data?.data?.data?.map((teams, index) => ({
      id: teams.id,
      index_id: index + 1,
      userId: teams.userId,
      NexudusTeamId: teams.NexudusTeamId,
      userName: teams.userName,
      userCompany: teams.companyName,
      slotTime: teams.slot,
      status: teams.status,
      registrationStatus: teams.registrationStatus,
      email: teams.email,
      phoneNumber: teams.phoneNumber,
      website: teams.website,
      linkedin: teams.linkedin,
      twitter: teams.twitter,
      planType: teams.planType,
      images: teams?.images,
      attachLogo: teams?.attachLogo,
      planDuration: teams.planDuration,
      ResourceTypeId: teams.ResourceTypeId,
      privateResourceTypeId: teams.privateResourceTypeId,
      dedicatedResourceTypeId: teams.dedicatedResourceTypeId,
      dedicatedMembers: teams?.dedicatedMembers
        ? teams?.dedicatedMembers?.map((member) => member.fullName).join(", ")
        : "",
      privateMembers: teams.privateMembers
        ? teams.privateMembers.map((member) => member.fullName).join(", ")
        : "",
      Desk: teams.Desk,
      teamMembers: teams.companyMembers
        ? teams.companyMembers.map((member) => member.fullName).join(", ")
        : "",
      allActiveMembers: teams?.allActiveMembers
        ? teams?.allActiveMembers.map((member) => member.fullName).join(", ")
        : "",

      startDate: teams.startDate
        ? moment(teams?.startDate)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : "",
      RenewalMonth: teams.RenewalMonth
        ? moment(teams?.RenewalMonth)
            .subtract(moment().utcOffset(), "minutes")
            .format("DD MMMM,YYYY")
        : "",
      description: teams.companyDescription,
      members: teams.companyMembers,
      date: moment(teams?.date)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { teams: totalTeams, totalPages: data?.data?.totalTeams };
  },

  GetNexudusIdTeam: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/teams-nexudus/${body}`, {
      headers: headers,
    });

    return data?.data?.data;
  },

  getTeamInvoices: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/team-invoices/${body}`, {
      headers: headers,
    });

    return data?.data?.data;
  },
};

export default apiWrapper(teamsApi, [
  "teamList",
  "GetNexudusIdTeam",
  "getTeamInvoices",
  "exportTeamList",
]);
