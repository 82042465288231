import React from "react";
import { useAccessLimitedInfo } from "./useAccessLimited";
import {
  Card,
  Col,
  Row,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Spinner,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import LimitedAccess from "../../images/icons/limitedAccess.svg";
import "./css/styles.css";

const AccessLimited = (props) => {
  const {} = useAccessLimitedInfo(props);

  return (
    <>
      <Row className='h-100 w-100'>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div className='accessLimitedContainer h-100 w-100 mt-5'>
            <div className='mt-5'>
              <img src={LimitedAccess} alt='' width={120} />
              <h1 className='mt-4'>Limited Access</h1>
              <p>
                The page you’re trying to access has restricted access. Please
                contact your system administrator
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AccessLimited;
