import { useMutation } from "react-query";
import {
  CREATE_NEW_DEPARTMENTS,
  DELETE_DEPARTMENTS,
  UPDATE_DEPARTMENTS,
} from "./keys";

import accessControl from "../services/accessControlApi";

export const useAddNewDepartmentsMutation = (history) => {
  const response = useMutation(accessControl.addNewDepartment, {
    mutationKey: CREATE_NEW_DEPARTMENTS,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useUpdateDepartmentsMutation = (history) => {
  const response = useMutation(accessControl.updateDepartment, {
    mutationKey: UPDATE_DEPARTMENTS,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDeleteDepartmentMutation = (history) => {
  const response = useMutation(accessControl.deleteDepartment, {
    mutationKey: DELETE_DEPARTMENTS,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
