import {
  Card,
  Col,
  Row,
  Input,
  Form,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Select from "react-select";
import UploadIcon from "../../.././images/icons/uploadIcon.svg";
import CloseIcon from "../../.././images/icons/icons8-close-48.svg";
import { useNavigate } from "react-router-dom";
import { UseMembershipSettingsType } from "./useMembershipSettings";
import PlusIcon from "../../../images/icons/plusIcon.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { animated } from "react-spring";
import Delete from "../../../images/icons/delete.svg";
import Switch from "react-switch";
import "../css/accordationStyling.css";
import "react-bootstrap-accordion/dist/index.css";
import "../css/styles.css";

const MembershipSettings = (props) => {
  const navigate = useNavigate();
  const {
    samePlanResources1,
    specificResources1,
    sapratePlanResources1,
    resourcePlansArr1,
    planResourceid1,
    divShowAccor,
    resourceArr,
    slotValue,
    noOfMonths,
    totalMonths,
    samePlanResources,
    resourceTypeData,
    specificResources,
    openAnimation,
    plansDropdownData,
    resourcePlansArr,
    monthlyRange,
    planResourceid,
    sapratePlanResources,
    slotValue1,
    membershipName,
    slotValueMonths,
    selectedFiles1,
    setPlanResourceid1,
    setSamePlanResources1,
    setSpecificResources1,
    setResourcePlansArr1,
    TeamLeadMonthlyPlans1,
    TeamLeadYearlyPlans1,
    TeamMemberMonthlyPlans1,
    TeamMemberYearlyPlans1,
    TeamLeadMonthlyPlans,
    TeamLeadYearlyPlans,
    TeamMemberMonthlyPlans,
    toggleHandler,
    setSelectedFiles1,
    handleAcceptedFiles1,
    setSourceArr,
    removeResourceArr,
    tog_static,
    tog_static1,
    tog_static2,
    MembershupSettingCreate,
    setSamePlanResources,
    setMembershipName,
    setNoOfMonths,
    setResourcePlansArr,
    setMonthlyRange,
    setSpecificResources,
    setPlanResourceid,
    TeamMemberYearlyPlans,
  } = UseMembershipSettingsType(props);

  return (
    <>
      <Row className='pt-4 px-4 mts_main_bg'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className='mts_h1'>Create a new Membership Type</span>
          <Card className='p-3 mt-4'>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <p className='mts_h2 '>
                  Membership Name
                  <span className='mts_required_star'>*</span>
                </p>
                <Input
                  value={membershipName}
                  onChange={(e) => {
                    setMembershipName(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <p className='mts_h2 mt-3'>
                  Add image
                  <span className='mts_required_star'>*</span>
                </p>

                <div
                  className='dropzone-previews mt-3 w-100'
                  id='file-previews'
                >
                  {selectedFiles1.length > 0 ? (
                    <Card className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'>
                      <div className='p-2 w-100'>
                        <Row className='align-items-center'>
                          <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                            <img
                              data-dz-thumbnail=''
                              // height='80'
                              className='avatar-sm rounded bg-light w-100'
                              alt={selectedFiles1[0].name}
                              src={selectedFiles1[0].preview}
                            />
                          </Col>
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className='px-2'
                          >
                            <span className=' link text-muted font-weight-bold w-100 '>
                              {selectedFiles1[0].name}
                            </span>
                            <p className='mb-0'>
                              <strong>{selectedFiles1[0].formattedSize}</strong>
                            </p>
                          </Col>
                          <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                            <span onClick={() => setSelectedFiles1([])}>
                              <img
                                src={CloseIcon}
                                alt='logo'
                                width={24}
                                height={24}
                              />
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : (
                    <Card>
                      <Form className='dropzone mt-5 '>
                        <Dropzone
                          accept={".png ,.jpeg,.jpg"}
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles1(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              <div className='dz-message ' {...getRootProps()}>
                                <input {...getInputProps()} />

                                <div className=' drop_logo_center'>
                                  <img
                                    src={UploadIcon}
                                    alt='logo'
                                    width={100}
                                    height={68}
                                  />
                                </div>

                                <p className=' drop_logo_center dropImg_h1'>
                                  <span
                                    className='dropImg_h11 mx-2 cursor-na'
                                    style={{ cursor: "pointer" }}
                                  >
                                    Click here to browse
                                  </span>{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Form>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <p className='mts_h2 '>
                Start month
                <span className='mts_required_star'>*</span>
              </p>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='membershipDetailBtn'>
                  <Button
                    className={` w-100 inacBtn mb-2 ${
                      slotValueMonths === "nextMonth"
                        ? "yearlyactiveBtn"
                        : " slotIncativeMonths"
                    }`}
                    onClick={() => {
                      tog_static2("nextMonth");
                      setNoOfMonths(1);
                    }}
                  >
                    Next Month
                  </Button>
                  <Button
                    className={` w-100 inacBtn mb-2 ${
                      slotValueMonths === "flexibleMonth"
                        ? "yearlyactiveBtn"
                        : " slotIncativeMonths"
                    }`}
                    onClick={() => {
                      tog_static2("flexibleMonth");
                      setNoOfMonths(null);
                    }}
                  >
                    Flexible
                  </Button>
                </div>
              </Col>
              <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                {slotValueMonths === "flexibleMonth" ? (
                  <p className='flex_hours_css mb-2'>
                    Max how many months in advance*
                  </p>
                ) : (
                  ""
                )}
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                {slotValueMonths === "flexibleMonth" ? (
                  <Select
                    menuPortalTarget={document.body}
                    value={{ label: noOfMonths, value: noOfMonths }}
                    options={totalMonths}
                    placeholder={
                      <span className='dropdown_placeholder  '>
                        Select Months
                      </span>
                    }
                    className='mb-2 select_m_dropdown '
                    onChange={(e) => setNoOfMonths(Number(e.value))}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <hr />

            <Row>
              <span className='mts_h2 '>
                Payment & Duration<span className='mts_required_star'>*</span>
              </span>

              <span className='mts_h3 mt-1 mb-2'>
                Select payment plans that are applicable for this membership
                type
              </span>
              <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                <Card
                  type='button'
                  className={`mt-2 mx-2  p-2 h-100 ${
                    slotValue === true ? "monthlyactiveBtn" : ""
                  }`}
                  onClick={() => tog_static()}
                >
                  <span className='mts_card_h1'>Monthly</span>
                  <span className='mts_card_h2'>
                    Select the minimum and maximum duration{" "}
                  </span>
                  <Row className='mt-3'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='p-1'>
                      <InputGroup>
                        <Input
                          placeholder=''
                          type='number'
                          bsSize='sm'
                          onChange={(e) => {
                            setMonthlyRange({
                              ...monthlyRange,
                              MinRange: e?.target.value,
                            });
                          }}
                          min={0}
                        />
                        <InputGroupText className='monthly_duration'>
                          Min
                        </InputGroupText>
                      </InputGroup>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='p-1'>
                      <InputGroup>
                        <Input
                          placeholder=''
                          bsSize='sm'
                          type='number'
                          onChange={(e) => {
                            setMonthlyRange({
                              ...monthlyRange,
                              MaxRange: e?.target.value,
                            });
                          }}
                          min={0}
                        />
                        <InputGroupText className='monthly_duration'>
                          Max
                        </InputGroupText>
                      </InputGroup>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                <Card
                  type='button'
                  className={`mt-2 mx-2 p-2 h-100 ${
                    slotValue1 === true ? "yearlyactiveBtn" : ""
                  }`}
                  onClick={(e) => tog_static1(e)}
                >
                  <span className='mts_card_h1'>Annually</span>
                  <span className='mts_card_h2'>
                    For yearly plan, the duration will be for
                    <span className='annualy_text'> 1 year.</span>
                  </span>
                </Card>
              </Col>
            </Row>
          </Card>

          <Card className='p-3 mt-3' key={1}>
            <Row>
              <p className='mts_h2'>
                Resource(s)<span className='mts_required_star'>*</span>
              </p>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <animated.div
                  className='accordion__item accordion h-100'
                  style={openAnimation}
                >
                  <div className='accordion__header'>
                    <h4>
                      <Row className='accordation_row accordion-header w-100'>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                          <span className='resource_acc_h2  mx-2'>
                            Resource 1
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={10} xs={10}>
                          <Select
                            menuPortalTarget={document.body}
                            options={resourceTypeData}
                            placeholder={
                              <span className='dropdown_placeholder  '>
                                 Select Resource Type
                              </span>
                            }
                            onChange={(e) => {
                              setPlanResourceid({
                                ...planResourceid,
                                desk: e,
                              });
                              // setSamePlanResources(false);
                              // setSpecificResources(true);
                            }}
                            classNamePrefix='select2-selection '
                            className='mx-2 mb-2 mt-1'
                          />
                        </Col>

                        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                          <span className='d-flex mx-3'>
                            <Switch
                              id={1}
                              checked={samePlanResources}
                              onChange={(e) => {
                                
                                setSamePlanResources(e);
                                setSpecificResources(e === true ? false : true);
                              }}
                              width={40}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checkedHandleIcon={false}
                              height={20}
                              onColor='#0129FA'
                              className='mr-1 mx-2 d-flex  mt-1'
                            />

                            <span className='resource_acc_h1 d-flex  mt-1'>
                              Same plan for all the resources
                            </span>
                          </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                          <span className='d-flex mx-3'>
                            <Switch
                              id={3}
                              checked={specificResources}
                              onChange={(e) => {
                                setSpecificResources(e);
                              }}
                              disabled={samePlanResources}
                              width={40}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checkedHandleIcon={false}
                              height={20}
                              onColor='#0129FA'
                              className='mr-1 mx-2 d-flex  mt-1'
                            />

                            <span className='resource_acc_h1  mt-1'>
                              Allow user to select specific resource
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </h4>
                    <animated.i>
                      <ExpandMoreIcon onClick={toggleHandler} id={1} />
                    </animated.i>
                  </div>

                  <div
                    id={1}
                    className='accordion__content'
                    hidden={divShowAccor[1]}
                  >
                    {samePlanResources ? (
                      <div>
                        <Row>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p className='accordation_h2_office mx-2'>
                              Team Lead
                            </p>
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p className='accordation_h2_office mx-2'>
                              Team Member
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <p className='accordation_h3_office mx-2'>
                              Monthly
                            </p>

                            <Select
                              // value={resourcePlansArr?.TeamLeadMonthlyPlan}
                              menuPortalTarget={document.body}
                              isDisabled={!slotValue}
                              options={plansDropdownData}
                              placeholder={
                                <span className='dropdown_placeholder  '>
                                  Select Resource Plans
                                </span>
                              }
                              onChange={(e) =>
                                setResourcePlansArr({
                                  ...resourcePlansArr,
                                  TeamLeadMonthlyPlan: {
                                    id: e.value,
                                    name: e.label,
                                  },
                                })
                              }
                              classNamePrefix='select2-selection '
                              className='mx-2 mb-2 mt-1'
                            />
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <p className='accordation_h3_office mx-2'>Yearly</p>

                            <Select
                              menuPortalTarget={document.body}
                              isDisabled={!slotValue1}
                              options={plansDropdownData}
                              placeholder={
                                <span className='dropdown_placeholder  '>
                                  Select Resource Plans
                                </span>
                              }
                              onChange={(e) => {
                                setResourcePlansArr({
                                  ...resourcePlansArr,
                                  TeamLeadYearlyPlan: {
                                    id: e.value,
                                    name: e.label,
                                  },
                                });
                              }}
                              classNamePrefix='select2-selection '
                              className='mx-2 mb-2 mt-1'
                            />
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <p className='accordation_h3_office mx-2'>
                              Monthly
                            </p>

                            <Select
                              menuPortalTarget={document.body}
                              isDisabled={!slotValue}
                              options={plansDropdownData}
                              placeholder={
                                <span className='dropdown_placeholder  '>
                                  Select Resource Plans
                                </span>
                              }
                              onChange={(e) => {
                                setResourcePlansArr({
                                  ...resourcePlansArr,
                                  TeamMemberMonthlyPlan: {
                                    id: e.value,
                                    name: e.label,
                                  },
                                });
                              }}
                              classNamePrefix='select2-selection '
                              className='mx-2 mb-2 mt-1'
                            />
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <p className='accordation_h3_office mx-2'>Yearly</p>

                            <Select
                              menuPortalTarget={document.body}
                              isDisabled={!slotValue1}
                              options={plansDropdownData}
                              placeholder={
                                <span className='dropdown_placeholder  '>
                                  Select Resource Plans
                                </span>
                              }
                              onChange={(e) => {
                                setResourcePlansArr({
                                  ...resourcePlansArr,
                                  TeamMemberYearlyPlan: {
                                    id: e.value,
                                    name: e.label,
                                  },
                                });
                              }}
                              classNamePrefix='select2-selection '
                              className='mx-2 mb-2 mt-1'
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      sapratePlanResources?.map((e) => {
                        return (
                          <div>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className='accordation_h1_office mx-2'>
                                  {e?.ResourceName}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className='accordation_h2_office mx-2'>
                                  Team Lead
                                </p>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className='accordation_h2_office mx-2'>
                                  Team Member
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Monthly
                                </p>
                                <Select
                                  name={e?.ResourceId}
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e, i) => {
                                    TeamLeadMonthlyPlans(e, i);
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Yearly
                                </p>

                                <Select
                                  name={e?.ResourceId}
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue1}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e, i) => {
                                    TeamLeadYearlyPlans(e, i);
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Monthly
                                </p>

                                <Select
                                  name={e?.ResourceId}
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e, i) => {
                                    TeamMemberMonthlyPlans(e, i);
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Yearly
                                </p>

                                <Select
                                  name={e?.ResourceId}
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue1}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e, i) => {
                                    TeamMemberYearlyPlans(e, i);
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })
                    )}
                  </div>
                </animated.div>
              </Col>
            </Row>
          </Card>
          <Row hidden={resourceArr?.length < 2}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className='p-3 mt-3' key={1}>
                <Row>
                  <p className='mts_h2'>
                    Resource(s)<span className='mts_required_star'>*</span>
                  </p>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <animated.div
                      className='accordion__item accordion h-100'
                      style={openAnimation}
                    >
                      <div className='accordion__header'>
                        <h4>
                          <Row className='accordation_row accordion-header w-100'>
                            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                              <span className='resource_acc_h2  mx-2'>
                                Resource 2
                              </span>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={10} xs={10}>
                              <Select
                                menuPortalTarget={document.body}
                                options={resourceTypeData}
                                placeholder={
                                  <span className='dropdown_placeholder  '>
                                     Select Resource Type
                                  </span>
                                }
                                onChange={(e) => {
                                  setPlanResourceid1({
                                    ...planResourceid1,
                                    desk: e,
                                  });
                                  // setSamePlanResources1(false);
                                  // setSpecificResources1(true);
                                }}
                                classNamePrefix='select2-selection '
                                className='mx-2 mb-2 mt-1'
                              />
                            </Col>

                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                              <span className='d-flex mx-3'>
                                <Switch
                                  id={1}
                                  checked={samePlanResources1}
                                  onChange={(e) => {
                                    setSamePlanResources1(e);

                                    setSpecificResources1(
                                      e === true ? false : true
                                    );
                                  }}
                                  width={40}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  checkedHandleIcon={false}
                                  height={20}
                                  onColor='#0129FA'
                                  className='mr-1 mx-2 d-flex  mt-1'
                                />

                                <span className='resource_acc_h1 d-flex  mt-1'>
                                  Same plan for all the resources
                                </span>
                              </span>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                              <span className='d-flex mx-3'>
                                <Switch
                                  id={3}
                                  checked={specificResources1}
                                  onChange={(e) => {
                                    setSpecificResources1(e);
                                  }}
                                  disabled={samePlanResources1}
                                  width={40}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  checkedHandleIcon={false}
                                  height={20}
                                  onColor='#0129FA'
                                  className='mr-1 mx-2 d-flex  mt-1'
                                />

                                <span className='resource_acc_h1  mt-1'>
                                  Allow user to select specific resource
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </h4>
                        <animated.i>
                          <ExpandMoreIcon onClick={toggleHandler} id={2} />
                        </animated.i>
                      </div>

                      <div
                        id={2}
                        className='accordion__content'
                        hidden={divShowAccor[2]}
                      >
                        {samePlanResources1 ? (
                          <div>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className='accordation_h2_office mx-2'>
                                  Team Lead
                                </p>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className='accordation_h2_office mx-2'>
                                  Team Member
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Monthly
                                </p>

                                <Select
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e) =>
                                    setResourcePlansArr1({
                                      ...resourcePlansArr1,
                                      TeamLeadMonthlyPlan: {
                                        id: e.value,
                                        name: e.label,
                                      },
                                    })
                                  }
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Yearly
                                </p>

                                <Select
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue1}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e) => {
                                    setResourcePlansArr1({
                                      ...resourcePlansArr1,
                                      TeamLeadYearlyPlan: {
                                        id: e.value,
                                        name: e.label,
                                      },
                                    });
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Monthly
                                </p>

                                <Select
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e) => {
                                    setResourcePlansArr1({
                                      ...resourcePlansArr1,
                                      TeamMemberMonthlyPlan: {
                                        id: e.value,
                                        name: e.label,
                                      },
                                    });
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <p className='accordation_h3_office mx-2'>
                                  Yearly
                                </p>

                                <Select
                                  menuPortalTarget={document.body}
                                  isDisabled={!slotValue1}
                                  options={plansDropdownData}
                                  placeholder={
                                    <span className='dropdown_placeholder  '>
                                      Select Resource Plans
                                    </span>
                                  }
                                  onChange={(e) => {
                                    setResourcePlansArr1({
                                      ...resourcePlansArr1,
                                      TeamMemberYearlyPlan: {
                                        id: e.value,
                                        name: e.label,
                                      },
                                    });
                                  }}
                                  classNamePrefix='select2-selection '
                                  className='mx-2 mb-2 mt-1'
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          sapratePlanResources1?.map((e) => {
                            return (
                              <div>
                                <Row>
                                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className='accordation_h1_office mx-2'>
                                      {e?.ResourceName}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className='accordation_h2_office mx-2'>
                                      Team Lead
                                    </p>
                                  </Col>
                                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className='accordation_h2_office mx-2'>
                                      Team Member
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <p className='accordation_h3_office mx-2'>
                                      Monthly
                                    </p>
                                    <Select
                                      name={e?.ResourceId}
                                      menuPortalTarget={document.body}
                                      isDisabled={!slotValue}
                                      options={plansDropdownData}
                                      placeholder={
                                        <span className='dropdown_placeholder  '>
                                          Select Resource Plans
                                        </span>
                                      }
                                      onChange={(e, i) => {
                                        TeamLeadMonthlyPlans1(e, i);
                                      }}
                                      classNamePrefix='select2-selection '
                                      className='mx-2 mb-2 mt-1'
                                    />
                                  </Col>
                                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <p className='accordation_h3_office mx-2'>
                                      Yearly
                                    </p>

                                    <Select
                                      name={e?.ResourceId}
                                      menuPortalTarget={document.body}
                                      isDisabled={!slotValue1}
                                      options={plansDropdownData}
                                      placeholder={
                                        <span className='dropdown_placeholder  '>
                                          Select Resource Plans
                                        </span>
                                      }
                                      onChange={(e, i) => {
                                        TeamLeadYearlyPlans1(e, i);
                                      }}
                                      classNamePrefix='select2-selection '
                                      className='mx-2 mb-2 mt-1'
                                    />
                                  </Col>
                                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <p className='accordation_h3_office mx-2'>
                                      Monthly
                                    </p>

                                    <Select
                                      name={e?.ResourceId}
                                      menuPortalTarget={document.body}
                                      isDisabled={!slotValue}
                                      options={plansDropdownData}
                                      placeholder={
                                        <span className='dropdown_placeholder  '>
                                          Select Resource Plans
                                        </span>
                                      }
                                      onChange={(e, i) => {
                                        TeamMemberMonthlyPlans1(e, i);
                                      }}
                                      classNamePrefix='select2-selection '
                                      className='mx-2 mb-2 mt-1'
                                    />
                                  </Col>
                                  <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <p className='accordation_h3_office mx-2'>
                                      Yearly
                                    </p>

                                    <Select
                                      name={e?.ResourceId}
                                      menuPortalTarget={document.body}
                                      isDisabled={!slotValue1}
                                      options={plansDropdownData}
                                      placeholder={
                                        <span className='dropdown_placeholder  '>
                                          Select Resource Plans
                                        </span>
                                      }
                                      onChange={(e, i) => {
                                        TeamMemberYearlyPlans1(e, i);
                                      }}
                                      classNamePrefix='select2-selection '
                                      className='mx-2 mb-2 mt-1'
                                    />
                                  </Col>
                                </Row>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </animated.div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row hidden={resourceArr?.length < 2}>
            <Col xl={9} lg={9} md={9} sm={9} xs={9}></Col>
            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
              <span
                className='deleteRescorce'
                onClick={() => removeResourceArr()}
                role='button'
              >
                <img className='mx-2 remove_css' src={Delete} alt='Dlete' />
                <span> Remove</span>
              </span>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
              <span
                hidden={resourceArr?.length > 1}
                onClick={() => setSourceArr([...resourceArr, { value: 2 }])}
                role='button'
              >
                <img src={PlusIcon} alt='plus icon' />
                <span className='add_source_css'> Add resource</span>
              </span>
            </Col>

            <Col className='p-1' xl={2} lg={2} md={2} sm={62} xs={6}>
              <Button className='waves-effect waves-light   w-100 lg-btn1 '>
                Cancel
              </Button>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={2} sm={6} xs={6}>
              <Button
                className='waves-effect waves-light  w-100 lg-btn2 '
                onClick={() => MembershupSettingCreate()}
              >
                Add
              </Button>
              {/* </Link> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MembershipSettings;
