import { useSettingControlInfo } from "./useSetting";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import AccessLevel from "../accessLevelPage";
import Departments from "../departmentsPage";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ActiveAccessLevelImg from "../../../images/sidenavicons/activeAccessLevel.svg";
import AccessLevelImg from "../../../images/sidenavicons/accesslevel.svg";
import ActiveDepartment from "../../../images/sidenavicons/activeDeparment.svg";
import Department from "../../../images/sidenavicons/departmentImg.svg";
import "../css/styles.css";

const AccessSetting = (props) => {
  const { settingDisplay, setSettingDisplay } = useSettingControlInfo(props);

  return (
    <>
      <Row className='p-3'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Card className='accessSettingCard p-3'>
            <Row>
              <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                <div className='accessSettingSideTabs w-100 '>
                  <div className='settingsTabContainer w-100'>
                    {/* <Button
                      className={
                        settingDisplay === 1
                          ? `activeSideBtn w-100 `
                          : `nonActiveSideBtn w-100 `
                      }
                      id={1}
                      onClick={(e) => setSettingDisplay(Number(e.target.id))}
                    >
                      {settingDisplay === 1 ? (
                        <img
                          src={ActiveTimeControl}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      ) : (
                        <img
                          src={TimeControl}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      )}
                      Time Control
                    </Button> */}

                    <Button
                      className={
                        settingDisplay === 1
                          ? `activeSideBtn w-100 `
                          : `nonActiveSideBtn w-100 `
                      }
                      id={1}
                      onClick={(e) => setSettingDisplay(Number(e.target.id))}
                    >
                      {settingDisplay === 1 ? (
                        <img
                          src={ActiveAccessLevelImg}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      ) : (
                        <img
                          src={AccessLevelImg}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      )}
                      Access levels
                    </Button>
                    <Button
                      className={
                        settingDisplay === 2
                          ? `activeSideBtn w-100 `
                          : `nonActiveSideBtn w-100 `
                      }
                      id={2}
                      onClick={(e) => setSettingDisplay(Number(e.target.id))}
                    >
                      {settingDisplay === 2 ? (
                        <img
                          src={ActiveDepartment}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      ) : (
                        <img
                          src={Department}
                          alt='accessImg'
                          className='accessLevelIcon'
                        />
                      )}
                      Departments
                    </Button>
                  </div>

                  <div className='vertical-hr'></div>
                </div>
              </Col>
              <Col md={9} lg={9} xl={9} sm={12} xs={12}>
                <Row className='accessTimeListContainer'>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    {
                      // settingDisplay === 1 ? (
                      //   <AccessControll />
                      // ) :
                      settingDisplay === 1 ? (
                        <AccessLevel />
                      ) : settingDisplay === 2 ? (
                        <Departments />
                      ) : (
                        ""
                      )
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AccessSetting;
