import { useAccountLockedInfo } from "./useAccountLocked";
import React from "react";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import Login_Frame from "../../images/icons/Login Pic.svg";
import BottomLogo from "../../images/icons/loginBottomLogo.svg";
import TopLogo from "../../images/icons/loginTopLogo.svg";
import "./css/styles.css";
import Card from "react-bootstrap/Card";
import PasswordModel from "../../components/molecules/passwordModel";
import "font-awesome/css/font-awesome.min.css";
import Spinner from "react-bootstrap/Spinner";

const LockedAccount = (props) => {
  const {
    email,
    otp,
    otpVerify,
    createOtpMutation,
    verifyAccountMutation,
    handleVerifyAccount,
    handleVerifyOtp,
    setEmail,
    setOtp,
  } = useAccountLockedInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='lgMain'>
            <Col md={12} lg={3} xl={5} sm={12} xs={12}>
              <img src={TopLogo} alt='NexdusuLogo' className='topLogo' />
              <img src={Login_Frame} className='loginImg' alt='NexdusuLogo' />
            </Col>
            <Col md={12} lg={9} sm={12} xs={12} xl={7}>
              <Row className='loginCard '>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
                <Col md={6} lg={6} sm={12} xs={12} xl={6}>
                  <Card className='forgetPasswordCard'>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <h1>Account Locked</h1>
                        <p className='headerJustify'>
                          Please verify your account ownership to unblock it.
                          Please enter the OTP that has been sent to your email
                        </p>
                        {otpVerify ? (
                          <Form onSubmit={handleVerifyOtp}>
                            <FormGroup>
                              <h6>Enter OTP</h6>
                              <div>
                                <Input
                                  id='example-date-input-email'
                                  onChange={(e) => setOtp(e.target.value)}
                                  placeholder='Email address'
                                  className='lgInput'
                                  min={0}
                                  type='number'
                                  // ref={inputRef}
                                />
                              </div>
                            </FormGroup>

                            {!verifyAccountMutation.isLoading ? (
                              <Button
                                className='waves-effect waves-light w-100 lgbtn '
                                // onClick={() => {
                                //   modelFunc(true);
                                // }}
                              >
                                Verify
                              </Button>
                            ) : (
                              <Button
                                className='waves-effect waves-light w-100 lgbtn '
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Verify
                              </Button>
                            )}
                          </Form>
                        ) : (
                          <Form onSubmit={handleVerifyAccount}>
                            <FormGroup>
                              <h6>Enter your email</h6>
                              <div>
                                {" "}
                                <Input
                                  id='example-date-input-email'
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder='Email address'
                                  className='lgInput'
                                  type='email'
                                  // ref={inputRef}
                                />
                              </div>
                            </FormGroup>

                            {!createOtpMutation.isLoading ? (
                              <Button
                                className='waves-effect waves-light w-100 lgbtn '
                                // onClick={() => {
                                //   modelFunc(true);
                                // }}
                              >
                                Send Email
                              </Button>
                            ) : (
                              <Button
                                className='waves-effect waves-light w-100 lgbtn '
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Send Email
                              </Button>
                            )}
                          </Form>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={3} lg={3} sm={3} xs={3} xl={3}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} xxl={12}>
            <img src={BottomLogo} alt='NexudusLogo' className='bottomLogo' />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default LockedAccount;
