import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const tourSetting = {
  postTourSetting: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(`/tour-settings`, body, {
      headers: headers,
    });

    return data;
  },

  postTourEnable: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(`/tour-enable`, body, {
      headers: headers,
    });

    return data;
  },

  getTourSetting: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/tour-settings`, {
      headers: headers,
    });

    return data?.data?.tour;
  },

  postTourSlots: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    body.toursDays.map(async (day) => {
      const data = await request.post(
        `/tour-slots`,
        {
          maxTourPerSlot: body.maxTourPerSlot,
          startTime: body.startTime,
          endTime: body.endTime,
          tourDay: day,
        },
        {
          headers: headers,
        }
      );

      return data;
    });
  },

  getTourSlots: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/tour-slots`, {
      headers: headers,
    });

    return data?.data;
  },

  archivedAllSlots: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.put(
      `/tour-slots-archived`,
      {},
      {
        headers: headers,
      }
    );

    return data;
  },

  archiveOneSlot: async (slotId) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.put(
      `/tour-slot-archive/${slotId}`,
      {},
      {
        headers: headers,
      }
    );

    return data;
  },

  getOpeningTime: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/tour-slots-nexudus`, {
      headers: headers,
    });

    return data?.data;
  },

  getBookedTime: async (apiCall, startTime, endTime) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (apiCall && startTime) {
      const data = await request.get(
        `/tour-slots-days/${startTime}/${endTime}`,
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },

  createHoliday: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(
      `/add-holiday`,
      {
        title: body?.title,
        onDay: body?.onDay,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
      },
      {
        headers: headers,
      }
    );
    return data?.data;
  },

  updateHoliday: async (requestData) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/update-holiday/${requestData?.holidayId}`,
      requestData?.body,

      {
        headers: headers,
      }
    );
    return data?.data;
  },
};

export default apiWrapper(tourSetting, [
  "postTourEnable",
  "postTourSetting",
  "getTourSetting",
  "postTourSlots",
  "getTourSlots",
  "archivedAllSlots",
  "archiveOneSlot",
  "getOpeningTime",
  "getBookedTime",
]);
