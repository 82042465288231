import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalBody } from "reactstrap";
import Frame from "../../../images/icons/Frame.svg";
import { GrFormClose } from "react-icons/gr";
import { FiDownload } from "react-icons/fi";
import "./css/styles.css";

const DepartmentDetail = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.show}
        centered={true}
        className='departModelContaier'
        size={"lg"}
      >
        <h2 className=''>
          <Row className='p-2 px-3'>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12} className=''>
                    <span className='model-text'>{props?.data?.name}</span>
                  </Col>
                  <Col md={2} xl={2} lg={2} sm={1} xs={1}>
                    <h2>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </h2>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <h2>Access Levels</h2>
                    <p>
                      {props?.data?.accessLevels?.map((accesslevel, index) => {
                        return `${accesslevel.name}, `;
                      })}
                    </p>
                  </Col>
                  <hr className='mb-5' />
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <h2>Resource Type </h2>
                    <p>{props?.data?.resourceType?.name}</p>
                  </Col>
                  <hr className='mb-5' />
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <h2>Resources </h2>
                    <p>
                      {props?.data?.resources?.map((resource, index) => {
                        return `${resource.name}, `;
                      })}
                    </p>
                  </Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </h2>
      </Modal>
    </div>
  );
};

export default DepartmentDetail;
