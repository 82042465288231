import React from "react";
import { Card, Col, Row, Button, Table } from "reactstrap";
import "../requests/css/styles.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import DeniedModel from "../../../components/molecules/deniedModel";
import { useDetailInfo } from "./useDetail";
import moment from "moment";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
const Loader = require("react-loader");

const DetailPage = (props) => {
  const {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    tourID,
    loading,
    deniedModel,
    tourHistoryLoading,
    tourHistory,
    approvalFunc,
    deniedFunc,
    deneiedApproval,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    modelFunc2,
    modelRequestFunc,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  } = useDetailInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <>
      {!tourHistoryLoading ? (
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row className='tourDetailPage mt-4'>
              <Col>
                <Card className='toursCardView p-4  '>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='detailPageHeader'>
                        <h3>Personal Details</h3>

                        {data.status === "approved" ? (
                          <button className='detailApprovedBtn' disabled>
                            APPROVED
                          </button>
                        ) : data.status === "deny" ? (
                          <button className='detailDeniedBtn' disabled>
                            DENIED
                          </button>
                        ) : data.status === "cancelled" ? (
                          <button className='detailDeniedBtn' disabled>
                            CANCELLED
                          </button>
                        ) : data.status === "rescheduled" ? (
                          <button className='detailDeniedBtn' disabled>
                            RESCHEDULED
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr />
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>First Name</h5>
                            <h6>{userData?.data?.firstName}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Last Name</h5>
                            <h6>{userData?.data?.lastName}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Phone</h5>
                            <h6>{userData?.data?.phoneNumber}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Employment Status</h5>
                            <h6>{userData?.data?.employmentStatus}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Email Address</h5>
                            <h6>{userData?.data?.email}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Date</h5>
                            <h6>{data.date}</h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Occupation</h5>
                            <h6>{userData?.data?.occupation}</h6>
                          </div>
                        </Col>
                        <Col md={12} lg={2} sm={12} xs={12} xl={2}></Col>
                        <Col md={12} lg={5} xl={5} sm={12} xs={12}>
                          <div className='info-heading cardHeading'>
                            <h5>Time Slot</h5>
                            <h6>{data.slotTime}</h6>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  {data.status === "pending" ? (
                    <>
                      <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
                      <Col md={4} xl={4} lg={4} sm={12} xs={12}>
                        <div className='membershipDetailBtn mt-4'>
                          <Button
                            className=' w-100 datails-btn1'
                            onClick={(e) => {
                              rejectApproval(data.id);
                            }}
                          >
                            Decline
                          </Button>
                          <Button
                            // color=''
                            className=' w-100 datails-btn2'
                            onClick={(e) => {
                              approval(data.id);
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      </Col>
                    </>
                  ) : data.status === "approved" ? (
                    <>
                      <Col md={10} xl={10} lg={10} sm={12} xs={12}></Col>{" "}
                      <Col md={2} xl={2} lg={2} sm={12} xs={12}>
                        <div className='membershipDetailBtn mt-4'>
                          <Button
                            className='w-100 detailApprovedCancelBtn'
                            onClick={(e) => {
                              deneiedApproval(data.id);
                            }}
                          >
                            {" "}
                            CANCEL
                          </Button>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <Card className='membershipCard mt-4'>
                      <Row>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='membershipCardHeading'>
                            <h3>Past Requests</h3>
                          </div>
                          <hr />
                        </Col>
                        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                          <div className='teamCardHeading'>
                            <Table responsive='sm xl lg xs md '>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email Address</th>
                                  <th>Requested on</th>
                                  <th>Slot Date</th>
                                  <th>Slot Time</th>
                                  <th>Request Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tourHistory?.history?.map((tour, index) => {
                                  return (
                                    <tr key={tour.id}>
                                      <td>{userData?.data?.fullName}</td>
                                      <td>{userData?.data?.email}</td>
                                      <td>
                                        {moment(tour.createdAt)
                                          .format("DD MMMM,YYYY h:mm a")
                                          .toString()}
                                      </td>
                                      <td>
                                        {moment(tour?.date)
                                          .format("DD MMMM,YYYY")
                                          .toString()}
                                      </td>
                                      <td>{`${tour?.slot?.startTime} - ${tour?.slot?.endTime}`}</td>
                                      <td className='d-flex'>
                                        {tour.status === "deny" ? (
                                          <button
                                            className='detailDeniedBtn'
                                            disabled
                                          >
                                            DENIED
                                          </button>
                                        ) : tour.status === "approved" ? (
                                          <button
                                            className='detailApprovedBtn'
                                            disabled
                                          >
                                            APPROVED
                                          </button>
                                        ) : tour.status === "pending" ? (
                                          <button
                                            className='detailPendingBtn'
                                            disabled
                                          >
                                            PENDING
                                          </button>
                                        ) : tour.status === "cancelled" ? (
                                          <button
                                            className='detailPendingBtn'
                                            disabled
                                          >
                                            CANCELLED
                                          </button>
                                        ) : tour.status === "rescheduled" ? (
                                          <button
                                            className='detailPendingBtn'
                                            disabled
                                          >
                                            RESCHEDULED
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}
      <ApproveModel
        approvalFunc={approvalFunc}
        deniedFunc={deniedFunc}
        show={modelShow}
        setModelShow={modelFunc}
        setApproved={modelFunc}
        reason={declineReason}
        ID={tourID}
        request={request}
        loading={loading}
        status={status}
        headerText='Approve Request'
        textBody='Are you sure you want to approve this request?'
      />
      <ApproveModel
        show={modelShow1}
        deniedFunc={deniedFunc}
        setModelShow={modelFunc1}
        setApproved={modelRequestFunc}
        reason={declineReason}
        loading={loading}
        ID={tourID}
        status={status}
        request={request}
        headerText='Rejected Request'
        textBody='Are you sure you want to reject this request?'
      />

      <DeclineModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        setRequest={setRequestFunc}
        templates={templatesList?.data}
        setDeclineReason={setDeclineFunc}
        ID={tourID}
      />
      <DeniedModel
        show={deniedModel}
        setModelShow={declineModelFunc}
        deniedFunc={deniedFunc}
        setDenyRequest={modelFunc2}
        setRequest={setRequestFunc}
        setDeclineReason={setDeclineFunc}
        ID={tourID}
      />
    </>
  );
};

export default DetailPage;
