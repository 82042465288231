import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useLogsDetailInfo } from "./useLogsDetail";
import "../css/styles.css";

const LogsDetail = (props) => {
  const { data } = useLogsDetailInfo(props);
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row className='logsDetailPage mt-4'>
            <Col>
              <Card className='logsCardView p-4  '>
                <Row>
                  <Col md={4} lg={4} sm={4} xs={4} xl={4}>
                    <div className='logsCardHeader'>
                      {" "}
                      <h1>{data?.action}</h1>
                      {data?.status === "Successfull" ? (
                        <div className='successContainer px-1 mx-2'>
                          <span>{data?.status}</span>
                        </div>
                      ) : (
                        <div className='failedContainer px-1 mx-2'>
                          <span>{data?.status}</span>
                        </div>
                      )}
                    </div>
                    <p>{data?.date}</p>
                  </Col>
                </Row>
                <Row className='pt-1'>
                  <Col md={3} lg={3} sm={3} xs={3} xl={3}>
                    <h2>User</h2>
                    <h3>{data?.userName}</h3>
                  </Col>
                </Row>
                <Row className='pt-1'>
                  <Col md={3} lg={3} sm={3} xs={3} xl={3}>
                    <h2>Log Type</h2>
                    <h3>{data?.component}</h3>
                  </Col>
                  <Col md={3} lg={3} sm={3} xs={3} xl={3}>
                    <h2>severity</h2>
                    <h3>{data?.severity}</h3>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LogsDetail;
