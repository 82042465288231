import { useMutation } from "react-query";
import { CREATE_BOOKING_PAYMENT } from "./keys";

import bookingAndPayment from "../services/bookingAndPaymentApi";

export const useBookingCreateMutation = (history) => {
  const response = useMutation(bookingAndPayment.createBookingSetting, {
    mutationKey: CREATE_BOOKING_PAYMENT,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
