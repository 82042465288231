import { useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useContext } from "react";
import { TabContext } from "../../context/TabContext";
import { NavLink, useNavigate } from "react-router-dom";
import Frame from "../../images/icons/Frame1.svg";
import Members from "../../images/sidenavicons/members.svg";
import ActiveMembers from "../../images/sidenavicons/activeMembers.svg";
import Logout from "../../images/sidenavicons/logout.svg";
import Home from "../../images/sidenavicons/home.svg";
import ActiveHome from "../../images/sidenavicons/activeHome.svg";
import AccessControll from "../../images/sidenavicons/accessControll.svg";
import NonActiveControll from "../../images/sidenavicons/nonActiveAccessControll.svg";
import NexudusIcon from "../../images/sidenavicons/nexudusIcon.svg";
import Logs from "../../images/sidenavicons/logs.svg";
import ActiveLogs from "../../images/sidenavicons/activeLogs.svg";
import Payments from "../../images/sidenavicons/payments.svg";
import ActivePayments from "../../images/sidenavicons/activePayments.svg";
import Cross from "../../images/icons/cross.svg";
import { Col, Row } from "react-bootstrap";
import "./css/sidebar.scss";
import "./css/sidebar.css";

const Sidebar = ({ navshow, setNavShow }) => {
  const [memberToggle, setMemberToggle] = useState(false);
  const [bookingToggle, setBookingToggle] = useState(false);
  const [requestToggle, setRequestToggle] = useState(false);
  const [logsToggle, setLogsToggle] = useState(false);
  const { setActiveTabJustify } = useContext(TabContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
    navigate("/");
  };

  const handleSideBar = (sideMenu) => {
    if (sideMenu === "member") {
      setMemberToggle(!memberToggle);
      setRequestToggle(false);
      setLogsToggle(false);
      setBookingToggle(false);
    } else if (sideMenu === "booking") {
      setMemberToggle(false);
      setRequestToggle(false);
      setLogsToggle(false);
      setBookingToggle(!bookingToggle);
    } else if (sideMenu === "logs") {
      setMemberToggle(false);
      setRequestToggle(false);
      setLogsToggle(!logsToggle);
      setBookingToggle(false);
    } else if (sideMenu === "request") {
      setMemberToggle(false);
      setRequestToggle(!requestToggle);
      setLogsToggle(false);
      setBookingToggle(false);
    } else {
      setMemberToggle(false);
      setRequestToggle(false);
      setLogsToggle(false);
      setBookingToggle(false);
    }
  };

  return (
    <Row className='sideNav-bar'>
      <Col md={12} xl={12} lg={12} sm={12} xs={12}>
        <Row>
          <ProSidebar className='sideNavShow'>
            <Navbar>
              <Row className='finTechIcon'>
                <Row>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                  <Col md={9} xl={9} lg={9} xs={12} sm={12}>
                    <div className='sideBarImg'>
                      <img src={Frame} alt='FintechHubImage' />
                    </div>{" "}
                  </Col>
                  <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                    <span className='side-nav-icon'>
                      <img
                        src={Cross}
                        alt='crossIcon'
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNavShow(!navshow);
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <Row className='side-Tab'>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <SidebarContent>
                      <Menu className='sidebar-scroller'>
                        <MenuItem
                          className={
                            splitLocation[1] === "dashboard"
                              ? "activesidebar"
                              : ""
                          }
                          onClick={() => handleSideBar(null)}
                        >
                          <NavLink to='dashboard' className=' waves-effect'>
                            {splitLocation[1] === "dashboard" ? (
                              <span>
                                <img src={ActiveHome} alt='dashboardIcon' />
                              </span>
                            ) : (
                              <span>
                                <img src={Home} alt='dashboardIcon' />
                              </span>
                            )}
                            <span className=' sidebarheadings'> Dashboard</span>
                          </NavLink>
                        </MenuItem>

                        <div>
                          <SubMenu
                            open={memberToggle}
                            onOpenChange={() => handleSideBar("member")}
                            className={
                              splitLocation[1] === "teams" ||
                              splitLocation[1] === "teams-detail" ||
                              splitLocation[1] === "member-request" ||
                              splitLocation[1] === "member-detail" ||
                              splitLocation[1] === "plan-requests" ||
                              splitLocation[1] === "plan-requests-detail"
                                ? "activesidebar"
                                : ""
                            }
                            title={
                              <span>
                                {splitLocation[1] === "teams" ||
                                splitLocation[1] === "teams-detail" ||
                                splitLocation[1] === "member-request" ||
                                splitLocation[1] === "member-detail" ||
                                splitLocation[1] === "plan-requests" ||
                                splitLocation[1] === "plan-requests-detail" ? (
                                  <img
                                    src={ActiveMembers}
                                    alt='membersIcons'
                                    className='verticalNavIcon '
                                  />
                                ) : (
                                  <img
                                    src={Members}
                                    alt='members Icons'
                                    className='verticalNavIcon '
                                  />
                                )}
                                Members
                              </span>
                            }
                          >
                            <MenuItem>
                              <NavLink
                                to='/teams'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Teams</span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='/member-request'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '>
                                  {" "}
                                  New Member Request
                                </span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='plan-requests'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '>
                                  {" "}
                                  Plan Requests
                                </span>
                              </NavLink>
                            </MenuItem>
                          </SubMenu>
                        </div>

                        <div>
                          <SubMenu
                            open={bookingToggle}
                            onOpenChange={() => handleSideBar("booking")}
                            className={
                              splitLocation[1] === "day-pass" ||
                              splitLocation[1] === "meeting-room" ||
                              splitLocation[1] === "team-invoices" ||
                              splitLocation[1] === "day-pass-detail" ||
                              splitLocation[1] === "meeting-room-detail" ||
                              splitLocation[1] === "team-invoice-detail"
                                ? "activesidebar"
                                : ""
                            }
                            title={
                              <span>
                                {splitLocation[1] === "day-pass" ||
                                splitLocation[1] === "meeting-room" ||
                                splitLocation[1] === "team-invoices" ||
                                splitLocation[1] === "day-pass-detail" ||
                                splitLocation[1] === "meeting-room-detail" ||
                                splitLocation[1] === "team-invoice-detail" ? (
                                  <img
                                    src={ActivePayments}
                                    alt='payment icon'
                                    className='verticalNavIcon '
                                  />
                                ) : (
                                  <img
                                    src={Payments}
                                    alt='payment icon'
                                    className='verticalNavIcon '
                                  />
                                )}
                                Bookings & Payments
                              </span>
                            }
                          >
                            <MenuItem>
                              <NavLink
                                to='/day-pass'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '>
                                  {" "}
                                  Day Pass
                                </span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='/meeting-room'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '>
                                  {" "}
                                  Meeting Room{" "}
                                </span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='/team-invoices'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '>
                                  {" "}
                                  Team Invoices{" "}
                                </span>
                              </NavLink>
                            </MenuItem>
                          </SubMenu>
                        </div>

                        <MenuItem
                          className={
                            splitLocation[1] === "access-control" ||
                            splitLocation[1] === "access-settings"
                              ? "activesidebar"
                              : ""
                          }
                          onClick={() => handleSideBar(null)}
                        >
                          <NavLink
                            to='/access-control'
                            className=' waves-effect'
                          >
                            {splitLocation[1] === "access-control" ||
                            splitLocation[1] === "access-settings" ? (
                              <span>
                                <img src={AccessControll} alt='dashboardIcon' />
                              </span>
                            ) : (
                              <span>
                                <img
                                  src={NonActiveControll}
                                  alt='dashboardIcon'
                                />
                              </span>
                            )}
                            <span className=' sidebarheadings'>
                              {" "}
                              Access Control
                            </span>
                          </NavLink>
                        </MenuItem>

                        <div>
                          <SubMenu
                            className={
                              splitLocation[1] === "membership" ||
                              splitLocation[1] === "membership-detail" ||
                              splitLocation[1] === "tours" ||
                              splitLocation[1] === "tour-detail" ||
                              splitLocation[1] === "tours" ||
                              splitLocation[1] === "tour-detail" ||
                              splitLocation[1] === "passes" ||
                              splitLocation[1] === "pass-detail"
                                ? "activesidebar"
                                : ""
                            }
                            open={requestToggle}
                            onOpenChange={(e) => handleSideBar("request")}
                            title={
                              <span>
                                {splitLocation[1] === "membership" ||
                                splitLocation[1] === "membership-detail" ||
                                splitLocation[1] === "tours" ||
                                splitLocation[1] === "tour-detail" ||
                                splitLocation[1] === "tours" ||
                                splitLocation[1] === "tour-detail" ||
                                splitLocation[1] === "passes" ||
                                splitLocation[1] === "pass-detail" ? (
                                  <img
                                    src={ActivePayments}
                                    alt='requests icon'
                                    className='verticalNavIcon'
                                  />
                                ) : (
                                  <img
                                    src={Payments}
                                    alt='requests icon'
                                    className='verticalNavIcon'
                                  />
                                )}
                                Requests
                              </span>
                            }
                          >
                            <MenuItem>
                              <NavLink
                                to='/membership'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Teams</span>
                              </NavLink>
                            </MenuItem>

                            <MenuItem>
                              <NavLink
                                to='/passes'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Passes</span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='/tours'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Tours</span>
                              </NavLink>
                            </MenuItem>
                          </SubMenu>
                        </div>

                        <div>
                          <SubMenu
                            className={
                              splitLocation[1] === "access-log" ||
                              splitLocation[1] === "logs" ||
                              splitLocation[1] === "logs-details"
                                ? "activesidebar"
                                : ""
                            }
                            open={logsToggle}
                            onOpenChange={(e) => handleSideBar("logs")}
                            title={
                              <span>
                                {splitLocation[1] === "access-log" ||
                                splitLocation[1] === "logs" ? (
                                  <img
                                    src={ActiveLogs}
                                    alt='Logs Icon'
                                    className='verticalNavIcon'
                                  />
                                ) : (
                                  <img
                                    src={Logs}
                                    alt='Logs Icon'
                                    className='verticalNavIcon'
                                  />
                                )}
                                Logs
                              </span>
                            }
                          >
                            <MenuItem>
                              <NavLink
                                to='/access-log'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Access</span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink
                                to='/logs'
                                className=' waves-effect'
                                onClick={() => {
                                  setActiveTabJustify("1");
                                  setNavShow(!navshow);
                                }}
                              >
                                <span className=' sideMenuLinks '> Portal</span>
                              </NavLink>
                            </MenuItem>
                          </SubMenu>
                        </div>

                        <div className='nexudusItem'>
                          <MenuItem
                            className={
                              splitLocation[1] === "nexudus"
                                ? "activesidebar "
                                : ""
                            }
                            onClick={() => handleSideBar(null)}
                          >
                            <span>
                              <img src={NexudusIcon} alt='NexudusIcon' />
                            </span>
                            <span className=' sidebarheadings'>
                              {" "}
                              <a
                                href='https://dashboard.nexudus.com'
                                target='_blank'
                              >
                                Nexudus Operations
                              </a>
                            </span>
                          </MenuItem>
                        </div>

                        <hr className='vlHR' />
                        <MenuItem
                          className={
                            splitLocation[1] === "logout" ? "activesidebar" : ""
                          }
                          onClick={() => handleSideBar(null)}
                        >
                          <div
                            onClick={() => {
                              logout();
                            }}
                          >
                            <span></span>
                            <span className=' sidebarlogout'>
                              {" "}
                              <img
                                src={Logout}
                                alt='logout icon'
                                className='verticalNavIcon '
                              />
                              Logout
                            </span>
                          </div>
                        </MenuItem>
                      </Menu>
                    </SidebarContent>
                  </Col>
                </Row>
              </Row>
            </Navbar>
          </ProSidebar>
        </Row>
      </Col>
    </Row>
  );
};

export default Sidebar;
