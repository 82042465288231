import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import usersApi from "../../../services/usersApi";
import passesApi from "../../../services/passesApi";
import { useQuery } from "react-query";
import {
  usePassesApprovedMutation,
  usePassesDeniedMutation,
  usePassesSuspendMutation,
} from "../../../rc-query/passesHook";
import { useGenerateErrorLogsMutation } from "../../../rc-query/logsHook";
import "../css/styles.css";

export const useDetailInfo = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [suspendModel, setSuspendModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passID, setPassID] = useState("");
  const [request, setRequest] = useState("");
  const [declineModel, setDeclineModel] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [status, setStatus] = useState("");
  const approvalMutation = usePassesApprovedMutation(props.history);
  const deniedMutation = usePassesDeniedMutation(props.history);
  const generateErrMutaion = useGenerateErrorLogsMutation(props.history);
  const suspendPassMutaion = usePassesSuspendMutation(props.history);

  const { data: userData, isLoading: userLoading } = useQuery(
    ["passUserData"],
    () => usersApi.FindUserById(data?.userId),
    {
      retry: 0,
    }
  );

  const { data: passHistory, isLoading: passHistoryLoading } = useQuery(
    ["passHistory"],
    () => passesApi.getPassHistory(data?.userId),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ************setStatesValues**********
  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const declineModelFunc = (value) => {
    setDeclineModel(value);
  };
  const setRequestFunc = (value) => {
    setRequest(value);
  };

  const setDeclineFunc = (value) => {
    setDeclineReason(value);
  };
  // *****Passes Aproval Request api*********
  const approvalFunc = (data) => {
    setLoading(true);

    approvalMutation
      .mutateAsync(data)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("Successfully Approved", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "Pass Approval",
              severity: "info",
              status: "Successfull",
              component: "Pass Requests",
            })
            .then((data) => {});
        }
        navigate("/passes");
        setModelShow(false);
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Pass approval",
            severity: "info",
            status: "Failed",
            component: "Pass Requests",
          })
          .then((data) => {});
      });
  };

  // *****Passes Deny Request api*********
  const deniedFunc = (data) => {
    setLoading(true);

    deniedMutation
      .mutateAsync(data)
      .then((data) => {
        setLoading(false);

        if (data?.data?.statusCode === 200) {
          addToast("Successfully Denied", {
            appearance: "success",
            autoDismiss: true,
          });
          generateErrMutaion
            .mutateAsync({
              action: "Pass deny",
              severity: "info",
              status: "Successfull",
              component: "Pass Requests",
            })
            .then((data) => {});
          navigate("/passes");
        }
        setModelShow1(false);
      })
      .catch((err) => {
        generateErrMutaion
          .mutateAsync({
            action: "Pass deny",
            severity: "info",
            status: "Failed",
            component: "Pass Requests",
          })
          .then((data) => {});
      });
  };

  const approval = (id) => {
    setPassID(id);
    setModelShow(true);
    setRequest("approval");
    setStatus("passes");
  };

  const rejectApproval = (id) => {
    setPassID(id);
    setRequest("deny");
    setStatus("passes");
    setDeclineModel(true);
  };

  const handleSuspendUser = (id) => {
    setPassID(id);
    setRequest("SuspendAccount");
    setStatus("passes");
    setSuspendModel(true);
  };
  const suspendUserFunc = () => {
    suspendPassMutaion.mutateAsync(passID).then((data) => {
      // setLoading(false);
      // if (data?.data?.statusCode === 200) {
      //   addToast("Successfully Denied", {
      //     appearance: "success",
      //     autoDismiss: true,
      //   });
      //   generateErrMutaion
      //     .mutateAsync({
      //       action: "Pass deny",
      //       severity: "info",
      //       status: "Successfull",
      //       component: "Pass Requests",
      //     })
      //     .then((data) => {});
      //   navigate("/passes");
      // }
      // setModelShow1(false);
    });
    // .catch((err) => {
    //   generateErrMutaion
    //     .mutateAsync({
    //       action: "Pass deny",
    //       severity: "info",
    //       status: "Failed",
    //       component: "Pass Requests",
    //     })
    //     .then((data) => {});
    // });
  };

  return {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    passID,
    loading,
    passHistory,
    passHistoryLoading,
    suspendModel,
    suspendUserFunc,
    setSuspendModel,
    handleSuspendUser,
    approvalFunc,
    deniedFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  };
};
