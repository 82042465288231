import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const usersApi = {
  FindUserById: async (body) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.get(`/users/findUser/${body}`, {
      headers: headers,
    });
  },

  createOtp: async (body) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.post(
      `/users/password-create-otp`,
      {
        oldPassword: body.oldPassword,
        newPassword: body.newPassword,
      },
      {
        headers: headers,
      }
    );
  },

  accountCreateOtp: async (userEmail) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.put(`/users/request-create-otp`, {
      email: userEmail,
    });
  },

  verifyLockedAccount: async (body) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.patch(`/users/verify-account`, body);
  },

  changePasswordRequest: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.post(
      `/users/change-password-otp`,
      {
        otp: Number(body.otp),
        oldPassword: body.oldPassword,
        newPassword: body.newPassword,
      },
      {
        headers: headers,
      }
    );
  },

  changeForgotPassword: async (password) => {
    const jwt = localStorage.getItem("passwordToken");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.post(
      `/users/request-update-password`,
      {
        password: password,
      },
      {
        headers: headers,
      }
    );
  },

  createAdminUser: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    return await request.post(
      `/user-admin-create`,
      {
        name: body.name,
        password: body.password,
        email: body.email,
        role: body.role,
        firstLogin: false,
        portalUser: true,
      },
      {
        headers: headers,
      }
    );
  },

  getAllPortalUser: async (page, size) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/user-portal-user/${page}/${size}`, {
      headers: headers,
    });

    const totalUsers = data?.data?.data?.map((user, index) => ({
      id: user.id,
      index_id: index + 1,
      userName: user.fullName,
      userEmail: user.email,
      userRole: user.role.name,
      roleId: user.role.id,
    }));

    return { users: totalUsers, totalPages: data?.data?.totalUsers };
  },

  updateUserRole: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/user-admin-update/${body.id}`,
      {
        name: body?.user?.name,
        password: body?.user?.password,
        email: body?.user?.email,
        role: body?.user?.role,
      },
      {
        headers: headers,
      }
    );

    return data;
  },

  deleteUserRole: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/user-admin-delete/${body}`,
      {},
      {
        headers: headers,
      }
    );

    return data;
  },

  getUserProfile: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/whoAmI`, {
      headers: headers,
    });

    return data?.data;
  },

  updateUserProfile: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/users/${body.id}`,
      { fullName: body.fullName, email: body.email },
      {
        headers: headers,
      }
    );

    return data;
  },
};

export default apiWrapper(usersApi, [
  "FindUserById",
  "createOtp",
  "changePasswordRequest",
  "createAdminUser",
  "getAllPortalUser",
  "verifyLockedAccount",
  "updateUserRole",
  "accountCreateOtp",
  "updateUserProfile",
  "changeForgotPassword",
]);
