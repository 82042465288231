import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const planApi = {
  plansList: async (statusData = "pending", page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      `/plan-requests/${statusData}/${page}/${size}`,
      {
        headers: headers,
        params: { search: search, date: date },
      }
    );

    
    const totalPlans = data?.data?.data?.map((plans, index) => ({
      id: plans.id,
      index_id: index + 1,
      userName: plans.name,
      userId: plans.userId,
      teamId: plans.teamId,
      price: plans.price,
      userCompany: plans.companyName,
      duration: plans.planDuration,
      planType: plans.planType,
      months: plans.RenewalMonths,
      capacity: plans.deskCapacity,
      slotTime: plans.slot,
      status: plans.status,
      ResourceTypeId: plans.ResourceTypeId,
      privateResourceTypeId: plans.privateResourceTypeId,
      dedicatedResourceTypeId: plans.dedicatedResourceTypeId,
      dedicatedMembers: plans.dedicatedMembers,
      privateMembers: plans.privateMembers,
      Desk: plans.Desk,
      teamMembers: plans.teamMembers,
      createdAt: moment(plans.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
      startDate: moment(plans?.startDate)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
      date: moment(plans?.date)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { plans: totalPlans, totalPages: data?.data?.totalPlans };
  },

  exportPlansList: async (statusData = "pending", search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-plan-requests/${statusData}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalPlans = data?.data?.data?.map((plans, index) => ({
      id: plans.id,
      index_id: index + 1,
      userName: plans.name,
      userId: plans.userId,
      teamId: plans.teamId,
      price: plans.price,
      userCompany: plans.companyName,
      duration: plans.planDuration,
      planType: plans.planType,
      capacity: plans.deskCapacity,
      slotTime: plans.slot,
      status: plans.status,
      ResourceTypeId: plans.ResourceTypeId,
      privateResourceTypeId: plans.privateResourceTypeId,
      dedicatedResourceTypeId: plans.dedicatedResourceTypeId,
      dedicatedMembers: plans?.dedicatedMembers
        ? plans?.dedicatedMembers?.map((member) => member.fullName).join(", ")
        : "",
      privateMembers: plans.privateMembers
        ? plans.privateMembers.map((member) => member.fullName).join(", ")
        : "",
      Desk: plans.Desk,
      teamMembers: plans.teamMembers
        ? plans.teamMembers.map((member) => member.fullName).join(", ")
        : "",
      declineReason: plans.declineReason,
      createdAt: moment(plans.createdAt)
        .format("DD MMMM,YYYY h:mm a")
        .toString(),
      startDate: moment(plans?.startDate)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
      date: moment(plans?.date)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { plans: totalPlans, totalPages: data?.data?.totalPlans };
  },

  plansListByTeamId: async (statusData) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/plan-requests-teamId/${statusData}`, {
      headers: headers,
    });

    const totalPlans = data?.data?.map((plans, index) => ({
      desk : plans?.Desk,
      planDuration: plans?.planDuration,
      duration: plans.RenewalMonths,
      planType: plans.planType,
      members: plans.teamMembers.length,
      status: plans.status,
      date: moment(plans.date).format("DD-MM-YYYY"),
    }));

    return totalPlans;
  },

  GetResources: async (statusData) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(
      `/resource/${statusData.Id}/${statusData.capacity}`,
      {
        headers: headers,
      }
    );

    const totalResources = data?.data?.data?.Records?.map(
      (resource, index) => ({
        name: resource?.ResourceName,
        Id: resource?.ResourceId,
        resourceId: resource?.Id,
        contractedCowrkerId: resource?.CoworkerContractIds,
        contractedTeamName: resource?.CoworkerTeamNames,
        capacity: resource?.ResourceAllocation,
      })
    );

    return totalResources;
  },

  plansRequestApproval: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/plan-requests/${statusData.id}`,
      {
        userId: statusData.userId,
        status: "approved",
      },
      {
        headers: headers,
      }
    );
  },

  plansRequestDenial: async (statusData) => {
    const jwt = localStorage.getItem("token");

    const headers = {
      Authorization: "Bearer " + jwt,
    };
    return await request.patch(
      `/plan-requests/${statusData.id}`,
      {
        userId: statusData.userId,
        status: statusData.status,
        declineReason: statusData.declineReason,
      },
      {
        headers: headers,
      }
    );
  },

  patchPlanRequest: async (statusData) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/plan-requests-update/${statusData.id}`,
      
      statusData.patchData,
      {
        headers: headers,
      }
    );

    return data;
  },

  getDedicatedResources: async (statusData) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    if (statusData.type === true) {
      const data = await request.get(`/dedicated-resources/${statusData.Id}`, {
        headers: headers,
      });

      const totalResourses = data?.data?.Desks?.map((resources, index) => ({
        Id: resources.Id,
        name: resources.Name,
        contractedCowrkerId: resources?.CoworkerContractIds,
        contractedTeamName: resources?.CoworkerTeamNames,
      }));

      return totalResourses;
    }
  },
};

export default apiWrapper(planApi, [
  "plansList",
  "plansRequestApproval",
  "plansRequestDenial",
  "patchPlanRequest",
  "GetResources",
  "getDedicatedResources",
  "plansListByTeamId",
  "exportPlansList",
]);
