import { useDetailInfo } from "./useMembershipDetail";
import { useRejectionInfo } from "../../regectionEmail/useRejection";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import Table from "react-bootstrap/Table";
import "../css/styles.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import filePdfSolid from "../../.././images/icons/file-pdf-solid.svg";
import PdfNotFound from "../../.././images/icons/pdf_not_found.png";
import ImageNotFound from "../../.././images/icons/image_not_found.png";
const Loader = require("react-loader");

const MembershipDetail = (props) => {
  const {
    declineModel,
    status,
    declineReason,
    request,
    modelShow,
    modelShow1,
    data,
    userData,
    membershipID,
    loading,
    approvalFunc,
    deniedFunc,
    approval,
    rejectApproval,
    modelFunc,
    modelFunc1,
    declineModelFunc,
    setRequestFunc,
    setDeclineFunc,
  } = useDetailInfo(props);
  const { templatesList } = useRejectionInfo(props);

  return (
    <>
      {userData ? (
        <Row className='membershipPage'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col md={12} xl={6} lg={6} sm={12} xs={12} className='p-3'>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Personal Details</h3>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='cardHeading memberCardData'>
                        <h5>First Name</h5>
                        <h6>{userData?.data?.firstName} </h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Last Name</h5>
                        <h6> {userData?.data?.lastName}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Phone Number</h5>
                        <h6> {userData?.data?.phoneNumber}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Email</h5>
                        <h6> {userData?.data?.email}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Work Title</h5>
                        <h6> {userData?.data?.workTitle}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Employment Status</h5>
                        <h6> {userData?.data?.employmentStatus}</h6>{" "}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12} xl={6} lg={6} sm={12} xs={12} className='p-3'>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Company Information</h3>
                        {data.status === "approved" ? (
                          <button className='detailApprovedBtn' disabled>
                            APPROVED
                          </button>
                        ) : data.status === "deny" ? (
                          <button className='detailDeniedBtn' disabled>
                            DENIED
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='cardHeading memberCardData'>
                        <h5>Company Name</h5>
                        <h6>{ (data?.userCompany && data?.userCompany.length > 50)
  ? data?.userCompany.slice(0, 40) + '...'
  : data?.userCompany} </h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Company Status</h5>
                        <h6> Active</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Registration Status</h5>
                        <h6> {data?.registration}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Email</h5>
                        <h6> {data?.email}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Phone Number</h5>
                        <h6>{data?.phoneNumber}</h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Website</h5>
                        <a
                          href={data?.website}
                          target='blank'
                          className='mob-anchor'
                        >
                          <h6>{data?.website}</h6>
                        </a>
                      </div>

                      <div className='cardHeading memberCardData'>
                        <h5>Linkedin</h5>
                        <h6>
                          {" "}
                          <a
                            href={data?.linkedin}
                            target='blank'
                            className='mob-anchor'
                          >
                            {data?.linkedin?.split("in")[3]}
                          </a>
                        </h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Twitter</h5>
                        <h6>
                          {" "}
                          <a
                            href={data?.twitter}
                            target='blank'
                            className='mob-anchor'
                          >
                            {data?.twitter?.split("com")[1]}
                          </a>
                        </h6>{" "}
                      </div>
                      <div className='cardHeading memberCardData'>
                        <h5>Description</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='memberDescription'>
                        <p>{data?.description}</p>
                      </div>
                    </Col>
                  </Row>
                 
                  <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='cardHeading memberCardData'>
                        <h5>Document</h5>
                      </div>

                    <p className="rds-h2 mx-2">
                     {userData?.data?.images[0] ? 
                        <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={"image"}
                        src={
                            filePdfSolid 
                        }
                      />
                      :

                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={"image"}
                        src={
                          PdfNotFound
                        }
                      />
                      }
                     
                    </p>
                  </Col>
                  </Row>
                  <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='cardHeading memberCardData'>
                        <h5>Logo</h5>
                      </div>

                    <p className="rds-h2 mx-2">
                     {userData?.data?.attachedLogo ? 
                        <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={"logo"}
                        src={
                          // photoDataByApi1[0]
                          userData?.data?.attachedLogo
                           ? userData?.data?.attachedLogo : ""
                        }
                      />
                      :
                      <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={"logo"}
                      src={
                        ImageNotFound
                      }
                    />
                    }
                    </p>
                  </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12} className='p-3'>
                <Card className='membershipCard'>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='membershipCardHeading'>
                        <h3>Teams</h3>
                      </div>
                      <hr />
                    </Col>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='teamCardHeading'>
                        <Table responsive='sm xl lg xs md '>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Work Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.members?.map((member) => {
                              return (
                                <tr>
                                  <td>
                                    {member?.name}
                                    <div className='teamStatus'>
                                      {member.isPayingMember === true ? (
                                        <span className='payingMember '>
                                          Paying Member
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {member.isAdministrator === true ? (
                                        <span className='adminstrator'>
                                          Adminstrator
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                  <td>{member?.email}</td>
                                  <td>{member?.phoneNumber}</td>
                                  <td>{member?.workTitle}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={8} xl={8} lg={8} sm={12} xs={12}></Col>
              <Col md={4} xl={4} lg={4} sm={12} xs={12} className='p-3'>
                {data?.status === "pending" ? (
                  <div className='membershipDetailBtn'>
                    <Button
                      className='waves-effect waves-light w-100 datails-btn1'
                      onClick={(e) => {
                        rejectApproval(data.id);
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      className='waves-effect waves-light w-100 datails-btn2'
                      onClick={(e) => {
                        approval(data.id);
                      }}
                    >
                      Approve
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}

      <ApproveModel
        approvalFunc={approvalFunc}
        deniedFunc={deniedFunc}
        show={modelShow}
        setModelShow={modelFunc}
        setApproved={modelFunc}
        reason={declineReason}
        ID={membershipID}
        request={request}
        loading={loading}
        status={status}
        headerText='Approve Request'
        textBody='Are you sure you want to approve this request?'
      />
      <ApproveModel
        show={modelShow1}
        deniedFunc={deniedFunc}
        setModelShow={modelFunc1}
        reason={declineReason}
        loading={loading}
        ID={membershipID}
        status={status}
        request={request}
        headerText='Rejected Request'
        textBody='Are you sure you want to reject this request?'
      />

      <DeclineModel
        show={declineModel}
        setModelShow={declineModelFunc}
        setDenyRequest={modelFunc1}
        setRequest={setRequestFunc}
        templates={templatesList?.data}
        setDeclineReason={setDeclineFunc}
        ID={membershipID}
      />
    </>
  );
};

export default MembershipDetail;
