import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const memberRequest = {
  memberRequestList: async (body = "pending", page, size, search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/member-requests/${body}/${page}/${size}`, {
      headers: headers,
      params: { search: search, date: date },
    });

    const totalMembers = data?.data?.data?.map((members, index) => ({
      id: members.id,
      index_id: index + 1,
      userName: members.name,
      nexudusTeamId: members.nexudusTeamId,
      resourceTypeId: members.resourceTypeId,
      workEmail: members.email,
      companyName: members.companyName,
      workTitle: members.workTitle,
      phoneNumber: members.phoneNumber,
      status: members.status,
      date: moment(members?.createdAt)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));

    return { members: totalMembers, totalPages: data?.data?.totalMembers };
  },

  exportMemberRequestList: async (body = "pending", search, date) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.get(`/export-member-requests/${body}`, {
      headers: headers,
      params: { search: search, date: date },
    });
    const totalMembers = data?.data?.data?.map((members, index) => ({
      id: members.id,
      index_id: index + 1,
      userName: members.name,
      nexudusTeamId: members.nexudusTeamId,
      resourceTypeId: members.resourceTypeId,
      workEmail: members.email,
      companyName: members.companyName,
      workTitle: members.workTitle,
      phoneNumber: members.phoneNumber,
      status: members.status,
      date: moment(members?.createdAt)
        .subtract(moment().utcOffset(), "minutes")
        .format("DD MMMM,YYYY"),
    }));
    return { members: totalMembers, totalPages: data?.data?.totalMembers };
  },

  memberDetail: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/single-member-request/${body}`, {
      headers: headers,
    });

    return data?.data;
  },

  patchMemberRequest: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body.status === "deny") {
      const data = await request.patch(
        `/member-requests/${body.id}`,
        {
          status: body?.status,
          declineReason: body.reason,
        },
        {
          headers: headers,
        }
      );

      return data?.data;
    } else {
      const data = await request.patch(
        `/member-requests/${body.id}`,
        {
          status: body?.status,
        },
        {
          headers: headers,
          params: { desk: body?.desk },
        }
      );

      return data?.data;
    }
  },
};

export default apiWrapper(memberRequest, [
  "memberRequestList",
  "memberDetail",
  "patchMemberRequest",
  "exportMemberRequestList",
]);
