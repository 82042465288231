import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalBody, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";
import "./css/styles.css";
import Spinner from "react-bootstrap/Spinner";

const ObjectionModel = (props) => {
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState("");

  async function approvalDeny(e) {
    e.preventDefault();
    if (reason) {
      props.objectionFunc(reason);
    }
  }

  return (
    <>
      <Modal
        isOpen={props.show}
        centered={true}
        size={"lg sm md xl xs"}
        className='rejectModel'
      >
        <div className='d-body'>
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <ModalBody>
                <Row className='mb-3'>
                  <Col md={10} xl={11} lg={11} sm={10} xs={10}>
                    <span
                      style={{
                        color: "#000000",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      Objection on Receipt
                    </span>
                  </Col>
                  <Col md={2} xl={1} lg={1} sm={2} xs={2}>
                    <span className='lgModelCancel'>
                      <GrFormClose
                        size={25}
                        onClick={() => {
                          props.setModelShow(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                    <p>
                      Please provide a reason for the objection on this receipt.
                      The reason of objection will be seen by the user
                    </p>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col md={11} xl={11} lg={11} sm={11} xs={11}>
                    <span style={{ color: "#999999" }}>Add Reasons</span>
                  </Col>
                  <Col
                    md={1}
                    xl={1}
                    lg={1}
                    sm={1}
                    xs={1}
                    className='checkbox'
                  ></Col>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      id='example-date-input4'
                      type='textarea'
                      placeholder=''
                      className='dm-textarea'
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='approval-btn mt-4'>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                  <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                    <Button
                      className='waves-effect waves-light mr-5 w-100 dm-btn1 '
                      onClick={() => {
                        props.setModelShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>

                  <Col md={4} xl={4} lg={4} sm={4} xs={4}>
                    {props.loading ? (
                      <Button
                        className='waves-effect waves-light mr-1 w-100 dm-btn2 '
                        disabled={true}
                      >
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />{" "}
                        Submiting
                      </Button>
                    ) : (
                      <Button
                        className='waves-effect waves-light mr-1 w-100 dm-btn2 '
                        onClick={(e) => approvalDeny(e)}
                      >
                        Send Objection
                      </Button>
                    )}
                  </Col>
                  <Col md={1} xl={1} lg={1} sm={1} xs={1}></Col>
                </Row>
              </ModalBody>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ObjectionModel;
