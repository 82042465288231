import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useToasts } from "react-toast-notifications";
import { useCreateRoleMutation } from "../../../rc-query/rolesHook";
import "../css/styles.css";

let resourcess = [
  {
    id: 1,
    name: "Team",
    description: "Team permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 2,
    name: "Request",
    description: "Request permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 3,
    name: "Payment",
    description: "Payment permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 4,
    name: "Settings",
    description: "Settings permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 5,
    name: "Bookings",
    description: "Bookings permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
  {
    id: 6,
    name: "Logs",
    description: "Logs permissions can be deal here",
    permissions: {
      view: false,
      manage: false,
      all: false,
    },
  },
];

export const useCreateRole = (props) => {
  const navigate = useNavigate();
  const [staticResource, setStaticResource] = useState([
    {
      id: 1,
      name: "Team",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 2,
      name: "Request",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 3,
      name: "Payment",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 4,
      name: "Settings",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 5,
      name: "Logs",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 6,
      name: "Bookings",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
  ]);
  const { addToast } = useToasts();
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const createRoleMutation = useCreateRoleMutation(props.history);
  const [roleName, setRoleName] = useState("");
  const [tableData, setTableData] = useState(resourcess);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelFunc = (value) => {
    setModelShow(value);
  };
  const modelFunc1 = (value) => {
    setModelShow1(value);
  };

  const newResource = {
    name: roleName,
    resources: {
      Team: {
        permissions: [],
      },
      Request: {
        permissions: [],
      },
      Payment: {
        permissions: [],
      },
      Settings: {
        permissions: [],
      },
      Bookings: { permissions: [] },
      Logs: { permissions: [] },
    },
  };
  const updatePermissions = (data, name) => {
    if (data.name === name) {
      if (data.permissions.view === true) {
        newResource.resources[name].permissions.push("view");
      }
      if (data.permissions.manage === true) {
        newResource.resources[name].permissions.push("manage");
      }
    }
  };

  const createRole = (data) => {
    setLoading(true);

    data.forEach((d) => {
      updatePermissions(d, d.name);
    });
    resourcess = staticResource;
    const name = newResource.name;
    const resources = newResource.resources;
    for (const resource in resources) {
      if (resources[resource].permissions.length === 0) {
        delete resources[resource];
      }
    }
    createRoleMutation
      .mutateAsync({ name: name, resources: resources })
      .then((data) => {
        if (data?.status === 200) {
          setLoading(false);
          setRoleName("");
          addToast("Successfully Created", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(-1);
        } else {
          setLoading(false);
          setRoleName("");
          addToast(data?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleCheckboxChange = (row, id, isChecked, permission) => {
    const newTableData = [...tableData];
    let viewVal = newTableData.find((r) => r.id === id).permissions.view;
    let manageVal = newTableData.find((r) => r.id === id).permissions.manage;

    if (permission === "view") {
      newTableData.find((r) => r.id === id).permissions.view = !viewVal;
      if (viewVal === false && manageVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = true;
      } else {
        newTableData.find((r) => r.id === id).permissions.all = false;
      }
      setTableData(newTableData);
    } else if (permission === "manage") {
      newTableData.find((r) => r.id === id).permissions.manage = !manageVal;
      if (manageVal === false && viewVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = true;
      } else {
        newTableData.find((r) => r.id === id).permissions.all = false;
      }
      setTableData(newTableData);
    } else if (permission === "all") {
      let allVal = newTableData.find((r) => r.id === id).permissions.all;
      if (allVal === true) {
        newTableData.find((r) => r.id === id).permissions.all = false;
        newTableData.find((r) => r.id === id).permissions.view = false;
        newTableData.find((r) => r.id === id).permissions.manage = false;
        setTableData(newTableData);
      } else {
        newTableData.find((r) => r.id === id).permissions.all = true;
        newTableData.find((r) => r.id === id).permissions.view = true;
        newTableData.find((r) => r.id === id).permissions.manage = true;
        setTableData(newTableData);
      }
    }
  };

  let resources = [
    {
      id: 1,
      name: "Team",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 2,
      name: "Request",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 3,
      name: "Payment",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 4,
      name: "Settings",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 5,
      name: "Logs",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
    {
      id: 6,
      name: "Bookings",
      permissions: {
        view: false,
        manage: false,
        all: false,
      },
    },
  ];

  const columns = [
    {
      dataField: "name",
      text: "Permissions",
      style: { width: "65%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <div>
          <div>{cell}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#999",
              fontWeight: "400",
              lineHeight: "12.19px",
            }}
          >
            {row.description}
          </div>
        </div>
      ),
    },
    {
      dataField: "permissions.manage",
      text: "Manage",
      editable: true,

      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          role='button'
          checked={row.permissions.manage}
          className='input-tours'
          onChange={(e) => {
            handleCheckboxChange(row, row.id, e.target.checked, "manage");
          }}
        />
      ),
    },
    {
      dataField: "permissions.view",
      text: "View",
      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },
      formatter: (cell, row) => (
        <input
          type='checkbox'
          role='button'
          checked={cell}
          className='input-tours'
          onChange={(e) =>
            handleCheckboxChange(row, row.id, e.target.checked, "view")
          }
        />
      ),
    },

    {
      dataField: "permissions.all",
      text: "All",
      editable: true,
      editor: {
        type: "checkbox",
        value: "true:false",
      },
      style: { width: "10%", padding: "15px 10px" },

      formatter: (cell, row) => (
        <input
          type='checkbox'
          role='button'
          checked={row.permissions.manage && row.permissions.view}
          className='input-tours'
          onChange={(e) => {
            handleCheckboxChange(row, row.id, e.target.checked, "all");
          }}
        />
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const NoDataIndication = () => (
    <div className='data-indication'>
      <h1>No Data Found</h1>
    </div>
  );

  return {
    roleName,
    modelShow,
    columns,
    defaultSorted,
    modelShow1,
    loading,
    tableData,
    setRoleName,
    createRole,
    modelFunc1,
    modelFunc,
    NoDataIndication,
  };
};
