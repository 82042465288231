import axios from "axios";
import { Saudi_Fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});

const bookingAndPayment = {
  getDiscountCode: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/booking-payment-settings/discount-codes`, {
      headers: headers,
    });

    const discountCode = data?.data?.data.map((discount, index) => ({
      index_id: index + 1,
      code: discount.Code,
      Id: discount.Id,
    }));

    return discountCode;
  },

  bookingSetting: async () => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/booking-payment-settings`, {
      headers: headers,
    });

    return data?.data?.setting;
  },

  createBookingSetting: async (body) => {
    const jwt = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const data = await request.post(`/booking-payment-settings`, body, {
      headers: headers,
    });
    return data;
  },
};

export default apiWrapper(bookingAndPayment, [
  "getDiscountCode",
  "createBookingSetting",
  "bookingSetting",
]);
