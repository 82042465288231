import { useUserUsersInfo } from "./useUsers";
import React from "react";
import {
  Card,
  Col,
  Row,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import classnames from "classnames";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Add from "../../../images/icons/add.svg";
import ApproveModel from "../../../components/molecules/approvalModel";
import DeclineModel from "../../../components/molecules/declineModel";
import AddUser from "../../../components/molecules/addUser";
import { Link, useNavigate } from "react-router-dom";
import "../css/styles.css";

const Loader = require("react-loader");

const UserRoles = (props) => {
  const {
    columns,
    defaultSorted,
    isOpen,
    pageSize,
    modelShow,
    userID,
    request,
    loading,
    addUser,
    usersLoading,
    portalUsers,
    isUpdate,
    userData,
    modelShow1,
    handleIncrementBtn,
    setModelShow,
    updateUserRoleFunc,
    setIsUpdate,
    createNewUser,
    addUserFunc,
    approvalFunc,
    modelFunc,
    totalPages,
    pageSizeFunc,
    toggle,
    NoDataIndication,
  } = useUserUsersInfo(props);

  const navigate = useNavigate();

  return (
    <div>
      {!usersLoading ? (
        <Row className='p-4 '>
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <div>
              <ToolkitProvider
                keyField='index_id'
                columns={columns}
                data={portalUsers ? portalUsers?.users : []}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row>
                      <Col
                        md={3}
                        lg={3}
                        xl={3}
                        sm={3}
                        xs={3}
                        className='tours-setting-header '
                      ></Col>
                      <Col md={2} lg={5} xl={5} sm={1} xs={1}></Col>
                      <Col md={7} lg={4} xl={4} sm={8} xs={8}>
                        <div className='membershipDetailBtn w-100'>
                          <Button
                            className='w-100 rolesBtn'
                            onClick={() => navigate("/users-roles")}
                            // to="/settings/roles"
                          >
                            Roles
                          </Button>
                          <Button
                            className='tours-add-slot-btn w-100'
                            onClick={() => addUserFunc(true)}
                          >
                            Add User
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className=' mt-4'>
                      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                        <Card className='input-wrap2 p-2 '>
                          <div className='table-responsive table-wrap '>
                            <BootstrapTable
                              // condensed={true}
                              loading={true}
                              hover
                              keyField='id'
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle tableBody view-table "
                              }
                              headerWrapperClasses={"thead-light"}
                              headerClasses='tableHeadercss'
                              wrapperClasses='table-responsive'
                              {...toolkitProps.baseProps}
                              noDataIndication={() => <NoDataIndication />}
                            />
                          </div>
                          <div className='inner-custom-pagination d-flex '>
                            <span className='paginationitmes'>Items</span>
                            <div className='d-inline pagination-btn '>
                              <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                                <DropdownToggle caret className='pageBtn'>
                                  {pageSize}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    value='1'
                                    onClick={() => pageSizeFunc(10)}
                                  >
                                    10
                                  </DropdownItem>
                                  <DropdownItem
                                    value='2'
                                    onClick={() => pageSizeFunc(30)}
                                  >
                                    30
                                  </DropdownItem>

                                  <DropdownItem
                                    value='3'
                                    onClick={() => pageSizeFunc(50)}
                                  >
                                    50
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                            <span className='paginationitmes1 '>Show</span>
                            <div className='text-md-right ms-auto'>
                              <AiOutlineLeft
                                className='page-btn'
                                onClick={() => handleIncrementBtn(false)}
                                role='button'
                              />

                              {totalPages()}

                              <AiOutlineRight
                                className='page-btn'
                                onClick={() => handleIncrementBtn(true)}
                                role='button'
                              />
                            </div>
                          </div>
                        </Card>

                        <Row className='align-items-md-center mt-3 bottom-btn '>
                          <Col className='inner-custom-pagination d-flex'></Col>
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader className='spinner' />
      )}
      <ApproveModel
        approvalFunc={approvalFunc}
        show={modelShow1}
        setModelShow={modelFunc}
        loading={loading}
        setApproved={modelFunc}
        userID={userID}
        request={request}
        headerText='Delete User ?'
        textBody='Are you sure you want to delete this user?'
      />

      <AddUser
        show={addUser}
        setModelShow={addUserFunc}
        create={createNewUser}
      />

      <AddUser
        show={modelShow}
        setModelShow={setModelShow}
        isUpdate={isUpdate}
        update={updateUserRoleFunc}
        setIsUpdate={setIsUpdate}
        data={userData}
      />
    </div>
  );
};

export default UserRoles;
