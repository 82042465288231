import { useCreateRole } from "./useCreateRoles";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ApproveModel from "../../../components/molecules/approvalModel";
import "../css/styles.css";

const UserRoles = (props) => {
  const {
    roleName,
    modelShow,
    columns,
    defaultSorted,
    modelShow1,
    loading,
    tableData,
    setRoleName,
    createRole,
    modelFunc1,
    modelFunc,
    NoDataIndication,
  } = useCreateRole(props);

  const { addToast } = useToasts();

  const [res, setRes] = React.useState(tableData);

  return (
    <div>
      <Row className='p-4 '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <div>
            <ToolkitProvider
              keyField='id'
              data={res}
              columns={columns}
              cellEdit={() => {}}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row>
                    <Col md={8} lg={8} xl={8} sm={0} xs={0}></Col>
                    <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                      <div className='membershipDetailBtn w-100'>
                        <Button
                          className='w-100 tours-remove-slot-btn'
                          onClick={() => modelFunc1(true)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className='tours-add-slot-btn w-100'
                          onClick={() => {
                            if (!roleName) {
                              addToast("Please Provide Role Name", {
                                appearance: "error",
                                autoDismiss: true,
                              });
                              return;
                            } else {
                              modelFunc(true);
                            }
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <Card className='input-wrap2 p-2 '>
                        <div style={{ margin: "10px" }}>
                          <h4>
                            Role Name
                            <span style={{ color: "red" }}>*</span>
                          </h4>
                          <input
                            placeholder='Enter Name'
                            type='text'
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            style={{ padding: "5px 10px" }}
                          />
                        </div>
                        <div className='table-responsive table-wrap '>
                          <BootstrapTable
                            // condensed={true}
                            loading={true}
                            hover
                            keyField='id'
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle tableBody view-table "}
                            headerWrapperClasses={"thead-light"}
                            headerClasses='tableHeadercss'
                            wrapperClasses='table-responsive'
                            {...toolkitProps.baseProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Card>

                      <Row className='align-items-md-center mt-3 bottom-btn '>
                        <Col className='inner-custom-pagination d-flex'></Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      </Row>
      <ApproveModel
        approvalFunc={createRole}
        show={modelShow}
        setModelShow={modelFunc}
        loading={loading}
        request={"createRole"}
        createData={res}
        headerText='Create Role?'
        textBody='Are you sure you want to create this role?'
      />
      <ApproveModel
        show={modelShow1}
        setModelShow={modelFunc1}
        loading={loading}
        request={"cancelRequest"}
        headerText='Discard Information?'
        textBody='Are you sure you want to discard this information?'
      />
    </div>
  );
};

export default UserRoles;
