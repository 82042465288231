import axios from "axios";
import { useMutation } from "react-query";
import { Saudi_Fintech_URL } from "../constants/config";
import {
  GET_MEMBERSHIP_Type_SETTING_MUTATION,
  PATCH_MEMBERSHIP_Type_SETTING_MUTATION,
  POST_MEMBERSHIP_Type_SETTING_MUTATION,
  DELETE_MEMBERSHIP_Type_SETTING_MUTATION,
} from "./keys";
import membershipTypeSettingApi from "../services/membershipTypeSetting";
import { toast } from "react-toastify";
const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});
export const useMembershipTypeSettingCreateMutation = (history) => {
  const response = useMutation(
    membershipTypeSettingApi.membershipTypeSettingCreate,
    {
      mutationKey: POST_MEMBERSHIP_Type_SETTING_MUTATION,
      onSuccess: (data) => {},
      onError: (error) => {
        toast.error(error?.response?.data?.error?.message);
      },
    }
  );
  return response;
};
//Update
export const useMembershipTypeSettingUpdateMutation = (history) => {
  const response = useMutation(
    membershipTypeSettingApi.membershipTypeSettingUpdate,
    {
      mutationKey: PATCH_MEMBERSHIP_Type_SETTING_MUTATION,
      onSuccess: (data) => {},
      onError: (error) => {
        toast.error(error?.response?.data?.error?.message);
      },
    }
  );
  return response;
};
// delete
export const useMembershipTypeSettingDeleteMutation = (history) => {
  const response = useMutation(
    membershipTypeSettingApi.membershipTypeSettingDelete,
    {
      mutationKey: DELETE_MEMBERSHIP_Type_SETTING_MUTATION,
      onSuccess: (data) => {},
      onError: (error) => {
        toast.error(error?.response?.data?.error?.message);
      },
    }
  );
  return response;
};
// export default apiWrapper(memberShipTypeSettingApi, [
//   "POST_MEMBERSHIP_Type_SETTING_MUTATION",
// ]);

export const useUploadPhoto = (history) => {
  const response = useMutation(membershipTypeSettingApi.uploadPhoto, {
    mutationKey: "upload Image",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
