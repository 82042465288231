import axios from "axios";
import { useMutation } from "react-query";
import { Saudi_Fintech_URL } from "../constants/config";
import { CREATE_NOTIFICATION_SETTINGS } from "./keys";
import notificationsApi from "../services/notificationsApi";
import { toast } from "react-toastify";
const request = axios.create({
  baseURL: Saudi_Fintech_URL,
});
export const useNotificationSettingsMutation = (history) => {
  const response = useMutation(notificationsApi.notificationSettings, {
    mutationKey: CREATE_NOTIFICATION_SETTINGS,
    onSuccess: (data) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.error?.message);
    },
  });
  return response;
};
