import { useMutation } from "react-query";
import {
  MEETINGROOM_OBJECTED,
  MEETINGROOM_Approved,
  MEETINGROOM_DENIED,
  MEETINGROOM_REFUND,
} from "./keys";

import meetingRoom from "../services/meetingRoomApi";

export const useMeetingRoomObjectedMutaion = (history) => {
  const response = useMutation(meetingRoom.meetingRoomObjected, {
    mutationKey: MEETINGROOM_OBJECTED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useMeetingRoomApprovedMutaion = (history) => {
  const response = useMutation(meetingRoom.meetingRoomApproved, {
    mutationKey: MEETINGROOM_Approved,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useMeetingRoomDeniedMutaion = (history) => {
  const response = useMutation(meetingRoom.meetingRoomDenied, {
    mutationKey: MEETINGROOM_DENIED,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useMeetingRoomRefundMutaion = (history) => {
  const response = useMutation(meetingRoom.meetingRoomRefund, {
    mutationKey: MEETINGROOM_REFUND,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
