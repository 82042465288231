import { UseMembershipDetailInfo } from "./useMembershipDetail";
import { Card, Col, Row, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Membership from "../../../images/icons/membershipSetting.svg";
import "../css/styles.css";

const MembershipTypeDetail = (props) => {
  const navigate = useNavigate();
  const { membershipsettingplansData } = UseMembershipDetailInfo();
  return (
    <>
      <Row className='mx-4'>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <>
              <Col md={10} xl={10} lg={10} sm={8} xs={8}></Col>
              <Col md={2} xl={2} lg={2} sm={4} xs={4}>
                <div className=' mt-4'>
                  <Button
                    className='waves-effect waves-light w-100 addNewBtn'
                    onClick={() => navigate("/membership-type-settings")}
                  >
                    Add New
                  </Button>
                </div>
              </Col>
            </>
          </Row>
          <Row className='h-100'>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              {membershipsettingplansData?.length === 0 ? (
                <Card className='ms_Card h-100 pt-5  pb-3 mt-4'>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                      <div className='h-100 ms_Container'>
                        <img src={Membership} alt='' />
                      </div>
                      <div className='ms_Container pt-4'>
                        <p>You currently do not have any membership plans</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card className='ms_Card  h-100   pb-3 mt-4'>
                  <Row>
                    {membershipsettingplansData?.map((ele, i) => {
                      
                      return (
                        <Col
                          className=' pt-5  px-4'
                          xl={4}
                          md={4}
                          lg={4}
                          sm={12}
                          xs={12}
                          key={ele?.id}
                        >
                          <Link
                            to={`/membership-setting-detail/${ele?.id}`}
                            className='link_line'
                          >
                            <Card className='p-2 db_card so_cc_main   mt-4 w-100 h-100'>
                              <img
                                src={`${ele?.ResourceImage}`}
                                alt='Img'
                                className='w-100'
                              />
                              <span className='d-flex pos_cardusersicon mt-3 '>
                                <Label className='so_h2  '>
                                  {ele?.ResourceName}
                                </Label>

                                <span className=''>
                                  <Label className='so_total_users '>
                                    {ele?.isMonthAllow === true &&
                                    ele?.isYearAllow === true
                                      ? "Monthly and Yearly"
                                      : ele?.isYearAllow === true
                                      ? "Yearly"
                                      : ele?.isMonthAllow === true
                                      ? "Month"
                                      : ""}
                                  </Label>
                                </span>
                              </span>
                              <Row>
                                <Col xl={5} md={5} lg={5} sm={12} xs={12}>
                                  <span className='so_total_hh2'>
                                    Starting Month
                                  </span>{" "}
                                  <p className='so_total_users'>
                                    {ele?.startMonth === 1
                                      ? "Next Month"
                                      : `flexible (${ele?.startMonth} months)`}
                                  </p>
                                </Col>
                                <Col xl={7} md={7} lg={7} sm={12} xs={12}>
                                  <span className='so_total_hh2'>
                                    No, of resource types
                                  </span>
                                  <p className='so_total_users'>
                                    {" "}
                                    {ele?.isMultiple === true ? "2" : "1"}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })}
                    <Col md={3} lg={3} sm={3} xs={12} xl={12}></Col>
                    <Col md={3} lg={3} sm={3} xs={12} xl={12}></Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MembershipTypeDetail;
